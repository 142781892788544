import { FormSettings, WorkflowConfig } from '@btrway/api-workflow';
import { useWorkflowUpdater } from '@btrway/workflow-service-provider';
import { useCallback } from 'react';
import { SettingsActions } from '../types/actions';

export const useWorkflowSettingsActions = (
  workflowUpdater: ReturnType<typeof useWorkflowUpdater>,
  setWorkflowConfig: React.Dispatch<React.SetStateAction<WorkflowConfig>>
): SettingsActions => {
  const updateFormSettings = useCallback(
    async (settings: FormSettings) => {
      setWorkflowConfig((prev) => ({
        ...prev,
        settings,
      }));

      await workflowUpdater.updateConfig({
        workflowConfig: { settings },
      });

      return settings;
    },
    [workflowUpdater]
  );

  // const updateTaskListSettings = useCallback(
  //   async (settings: TaskListSettings) => {
  //     setWorkflowConfig((prev) => ({
  //       ...prev,
  //       settings,
  //     }));

  //     await workflowUpdater.updateConfig({
  //       workflowConfig: { settings },
  //     });

  //     return settings;
  //   },
  //   [workflowUpdater]
  // );

  return {
    updateFormSettings,
    // updateTaskListSettings,
  };
};
