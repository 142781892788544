import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { PersonFieldProperties } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EntityTag } from '@btrway/entity-tags';
import { FormContextValues } from '@btrway/form-completion-manager';
import { GlobalSearchTagInput } from '@btrway/global-search';
import { useUserRoles } from '@btrway/role-manager';
import { Stack, Text } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import {
  BaseFieldProps,
  DisplayMode,
  isDisplayMode,
  isReadOnly,
} from '../../types/baseField';

interface PersonFieldProps extends BaseFieldProps<number> {
  formContext?: FormContextValues;
}

export const PersonField: React.FC<PersonFieldProps> = ({
  field,
  value,
  onChange,
  displayMode = DisplayMode.Input,
  formContext,
}) => {
  const readonly = isReadOnly(displayMode) || field.readOnly;
  const { rootWorkgroupId } = useAuthenticatedUser();
  const { getUserRolesBySlugs } = useUserRoles();
  const personFieldProperties = field.fieldProperties as PersonFieldProperties;

  // Memoize user role IDs calculation
  const userRoleIds = useMemo(() => {
    if (!personFieldProperties?.userRoleSlugs) return undefined;
    return getUserRolesBySlugs(personFieldProperties.userRoleSlugs).map(
      (role) => role.id
    );
  }, [getUserRolesBySlugs, personFieldProperties?.userRoleSlugs]);

  // Memoize workgroup filter calculation
  const filterByWorkgroupIds = personFieldProperties?.limitToWorkflowWorkgroup
    ? [formContext?.workgroupId || rootWorkgroupId]
    : undefined;

  // Handle selection of person from search, storing only the entityId
  const handleSelect = useCallback(
    (result: GlobalSearchResponse) => {
      onChange?.(result.entityId);
    },
    [onChange]
  );

  // Show EntityTag in display mode
  if (isDisplayMode(displayMode)) {
    if (!value) {
      return (
        <Stack gap="xs">
          <Text size="sm" c="dimmed">
            No person selected
          </Text>
        </Stack>
      );
    }

    return (
      <Stack gap="xs">
        <EntityTag
          allowClick={false}
          dataType={DataTypeEnum.person}
          id={value}
        />
      </Stack>
    );
  }

  return (
    <Stack gap="xs">
      <GlobalSearchTagInput
        entityTypes={[DataTypeEnum.person]}
        filterByWorkgroupIds={filterByWorkgroupIds}
        filterByUserRoleIds={userRoleIds}
        onSelect={handleSelect}
        required={field.required}
        placeholder="Search for a person..."
        clearOnSelect={true}
        includeChildWorkgroups={true}
        includeChildUserRoles={true}
        limit={20}
        onlyTopResults={true}
        // disabled={readonly}
      />
    </Stack>
  );
};

export default PersonField;
