import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import React, { createContext, useContext } from 'react';
import { useWorkflowDefinition } from '../hooks/useWorkflowDefinition';
import { useWorkflowTemplate } from '../hooks/useWorkflowTemplate';
import { WorkflowService } from '../types/service';

export const WorkflowServiceContext = createContext<WorkflowService | null>(
  null
);

interface WorkflowServiceProviderProps {
  children: React.ReactNode;
  workflowKey?: string;
}

export const WorkflowServiceProvider: React.FC<
  WorkflowServiceProviderProps
> = ({ children, workflowKey: defaultWorkflowKey }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { sourceType } = useTemplateDefinition();

  const template = useWorkflowTemplate(defaultWorkflowKey);
  const definition = useWorkflowDefinition(
    currentOrganization?.id!,
    defaultWorkflowKey
  );

  const service: WorkflowService =
    sourceType === 'template'
      ? {
          workflowKey: defaultWorkflowKey,
          getByKey: (key?: string) => {
            // Use provided key or fall back to default
            const effectiveKey = key || defaultWorkflowKey;
            if (effectiveKey !== defaultWorkflowKey) {
              // If a different key is provided, fetch new data
              const newTemplate = useWorkflowTemplate(effectiveKey);
              return newTemplate.workflowResult;
            }
            return template.workflowResult;
          },
          getWorkflows: () => template.workflowsResult,
          getWorkflowsByType: (type: WorkflowTypeEnum) =>
            template.getWorkflowsByType(type),
          updateConfig: async (key, config) =>
            template.updateConfig({
              workflowTemplateKey: key,
              ...config,
            }),
          updateWorkflow: async (workflow) => {
            if (workflow.sourceType !== 'template') {
              throw new Error(
                'Cannot save definition workflow in template context'
              );
            }
            await template.updateWorkflow(workflow);
          },
          deleteWorkflow: async (workflow) => {
            if (workflow.sourceType !== 'template') {
              throw new Error(
                'Cannot delete definition workflow in template context'
              );
            }
            if (!workflow.id) {
              throw new Error('Cannot delete workflow without an id');
            }
            await template.deleteWorkflow(workflow);
          },
        }
      : {
          workflowKey: defaultWorkflowKey,
          getByKey: (key?: string) => {
            // Use provided key or fall back to default
            const effectiveKey = key || defaultWorkflowKey;
            if (effectiveKey !== defaultWorkflowKey) {
              // If a different key is provided, fetch new data
              const newDefinition = useWorkflowDefinition(
                currentOrganization?.id!,
                effectiveKey
              );
              return newDefinition.workflowResult;
            }
            return definition.workflowResult;
          },
          getWorkflows: () => definition.workflowsResult,
          getWorkflowsByType: (type: WorkflowTypeEnum) =>
            definition.getWorkflowsByType(type),
          updateConfig: async (key, config) => {
            return definition.updateConfig({
              workflowDefinitionKey: key,
              organizationId: currentOrganization?.id!,
              ...config,
            });
          },
          updateWorkflow: async (workflow) => {
            if (workflow.sourceType !== 'definition') {
              throw new Error(
                'Cannot save template workflow in definition context'
              );
            }
            await definition.updateWorkflow(workflow);
          },
          deleteWorkflow: async (workflow) => {
            if (workflow.sourceType !== 'definition') {
              throw new Error(
                'Cannot delete template workflow in definition context'
              );
            }
            if (!workflow.id) {
              throw new Error('Cannot delete workflow without an id');
            }
            await template.deleteWorkflow(workflow);
          },
        };

  return (
    <WorkflowServiceContext.Provider value={service}>
      {children}
    </WorkflowServiceContext.Provider>
  );
};

export const useWorkflowService = (): WorkflowService => {
  const context = useContext(WorkflowServiceContext);
  if (!context) {
    throw new Error(
      'useWorkflowService must be used within a WorkflowServiceProvider'
    );
  }
  return context;
};
