import { Checkbox, MantineTheme } from '@mantine/core';

interface NodeCheckboxProps {
  checked: boolean;
  isIndirect: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}

export const NodeCheckbox = ({
  checked,
  isIndirect,
  label,
  onChange,
}: NodeCheckboxProps) => {
  return (
    <Checkbox
      defaultChecked={true}
      checked={checked}
      onChange={(event) => onChange(event.currentTarget.checked)}
      onClick={(e) => e.stopPropagation()}
      label={label}
      styles={(theme: MantineTheme) => ({
        root: { width: 'auto' },
        body: { width: 'auto' },
        input: {
          ...(isIndirect &&
            checked && {
              backgroundColor: theme.colors.gray[4],
              borderColor: theme.colors.gray[4],
              '&:hover': {
                backgroundColor: theme.colors.gray[5],
                borderColor: theme.colors.gray[5],
              },
            }),
        },
        icon: {
          ...(isIndirect &&
            checked && {
              color: theme.white,
            }),
        },
      })}
    />
  );
};
