/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetTaskListDefinitionByKeyParams,
  GetTaskListTemplateByKeyParams,
  TaskCategoryRequest,
  TaskCategoryResponse,
  TaskListAssignmentRequest,
  TaskListAssignmentResponse,
  TaskListDefinitionConfigUpdate,
  TaskListDefinitionRequest,
  TaskListDefinitionResponse,
  TaskListTemplateConfigUpdate,
  TaskListTemplateRequest,
  TaskListTemplateResponse,
  TaskTypeDTO
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveTaskListDefinition = (
    taskListDefinitionRequest: TaskListDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/task-list-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListDefinitionRequest
    },
      options);
    }
  


export const getSaveTaskListDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListDefinition>>, TError,{data: TaskListDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskListDefinition>>, TError,{data: TaskListDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskListDefinition>>, {data: TaskListDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskListDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskListDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskListDefinition>>>
    export type SaveTaskListDefinitionMutationBody = TaskListDefinitionRequest
    export type SaveTaskListDefinitionMutationError = unknown

    export const useSaveTaskListDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListDefinition>>, TError,{data: TaskListDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskListDefinition>>,
        TError,
        {data: TaskListDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskListDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskListTemplates = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListTemplateResponse[]>(
      {url: `/api/tasklist/task-list-templates`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListTemplatesQueryKey = () => {
    return [`/api/tasklist/task-list-templates`] as const;
    }

    
export const getGetTaskListTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListTemplates>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListTemplates>>> = ({ signal }) => getTaskListTemplates(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListTemplates>>>
export type GetTaskListTemplatesQueryError = unknown



export function useGetTaskListTemplates<TData = Awaited<ReturnType<typeof getTaskListTemplates>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveTaskListTemplate = (
    taskListTemplateRequest: TaskListTemplateRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/tasklist/task-list-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListTemplateRequest
    },
      options);
    }
  


export const getSaveTaskListTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListTemplate>>, TError,{data: TaskListTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskListTemplate>>, TError,{data: TaskListTemplateRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskListTemplate>>, {data: TaskListTemplateRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskListTemplate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskListTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskListTemplate>>>
    export type SaveTaskListTemplateMutationBody = TaskListTemplateRequest
    export type SaveTaskListTemplateMutationError = unknown

    export const useSaveTaskListTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListTemplate>>, TError,{data: TaskListTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskListTemplate>>,
        TError,
        {data: TaskListTemplateRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskListTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateTaskListTemplateConfig = (
    taskListTemplateConfigUpdate: TaskListTemplateConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/tasklist/task-list-config/template`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListTemplateConfigUpdate
    },
      options);
    }
  


export const getUpdateTaskListTemplateConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskListTemplateConfig>>, TError,{data: TaskListTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskListTemplateConfig>>, TError,{data: TaskListTemplateConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskListTemplateConfig>>, {data: TaskListTemplateConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateTaskListTemplateConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskListTemplateConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskListTemplateConfig>>>
    export type UpdateTaskListTemplateConfigMutationBody = TaskListTemplateConfigUpdate
    export type UpdateTaskListTemplateConfigMutationError = unknown

    export const useUpdateTaskListTemplateConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskListTemplateConfig>>, TError,{data: TaskListTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateTaskListTemplateConfig>>,
        TError,
        {data: TaskListTemplateConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateTaskListTemplateConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateTaskListDefinitionConfig = (
    taskListDefinitionConfigUpdate: TaskListDefinitionConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/tasklist/task-list-config/definition`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListDefinitionConfigUpdate
    },
      options);
    }
  


export const getUpdateTaskListDefinitionConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>, TError,{data: TaskListDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>, TError,{data: TaskListDefinitionConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>, {data: TaskListDefinitionConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateTaskListDefinitionConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskListDefinitionConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>>
    export type UpdateTaskListDefinitionConfigMutationBody = TaskListDefinitionConfigUpdate
    export type UpdateTaskListDefinitionConfigMutationError = unknown

    export const useUpdateTaskListDefinitionConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>, TError,{data: TaskListDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateTaskListDefinitionConfig>>,
        TError,
        {data: TaskListDefinitionConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateTaskListDefinitionConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveTaskListAssignment = (
    taskListAssignmentRequest: TaskListAssignmentRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/tasklist/task-list-assignments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListAssignmentRequest
    },
      options);
    }
  


export const getSaveTaskListAssignmentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListAssignment>>, TError,{data: TaskListAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskListAssignment>>, TError,{data: TaskListAssignmentRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskListAssignment>>, {data: TaskListAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskListAssignment(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskListAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskListAssignment>>>
    export type SaveTaskListAssignmentMutationBody = TaskListAssignmentRequest
    export type SaveTaskListAssignmentMutationError = unknown

    export const useSaveTaskListAssignment = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListAssignment>>, TError,{data: TaskListAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskListAssignment>>,
        TError,
        {data: TaskListAssignmentRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskListAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskCategories = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskCategoryResponse[]>(
      {url: `/api/tasklist/task-categories`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskCategoriesQueryKey = () => {
    return [`/api/tasklist/task-categories`] as const;
    }

    
export const getGetTaskCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getTaskCategories>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskCategoriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskCategories>>> = ({ signal }) => getTaskCategories(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskCategories>>>
export type GetTaskCategoriesQueryError = unknown



export function useGetTaskCategories<TData = Awaited<ReturnType<typeof getTaskCategories>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveTaskCategory = (
    taskCategoryRequest: TaskCategoryRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/tasklist/task-categories`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskCategoryRequest
    },
      options);
    }
  


export const getSaveTaskCategoryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskCategory>>, {data: TaskCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskCategory(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskCategory>>>
    export type SaveTaskCategoryMutationBody = TaskCategoryRequest
    export type SaveTaskCategoryMutationError = unknown

    export const useSaveTaskCategory = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskCategory>>,
        TError,
        {data: TaskCategoryRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskListDefinitionById = (
    taskListDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListDefinitionResponse>(
      {url: `/api/workflow/task-list-definitions/${taskListDefinitionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListDefinitionByIdQueryKey = (taskListDefinitionId: number,) => {
    return [`/api/workflow/task-list-definitions/${taskListDefinitionId}`] as const;
    }

    
export const getGetTaskListDefinitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListDefinitionById>>, TError = unknown>(taskListDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListDefinitionByIdQueryKey(taskListDefinitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListDefinitionById>>> = ({ signal }) => getTaskListDefinitionById(taskListDefinitionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskListDefinitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListDefinitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListDefinitionById>>>
export type GetTaskListDefinitionByIdQueryError = unknown



export function useGetTaskListDefinitionById<TData = Awaited<ReturnType<typeof getTaskListDefinitionById>>, TError = unknown>(
 taskListDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListDefinitionByIdQueryOptions(taskListDefinitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskListDefinition = (
    taskListDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/task-list-definitions/${taskListDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskListDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListDefinition>>, TError,{taskListDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListDefinition>>, TError,{taskListDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskListDefinition>>, {taskListDefinitionId: number}> = (props) => {
          const {taskListDefinitionId} = props ?? {};

          return  deleteTaskListDefinition(taskListDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskListDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskListDefinition>>>
    
    export type DeleteTaskListDefinitionMutationError = unknown

    export const useDeleteTaskListDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListDefinition>>, TError,{taskListDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskListDefinition>>,
        TError,
        {taskListDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskListDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskListDefinitionsByTemplateId = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListDefinitionResponse[]>(
      {url: `/api/workflow/task-list-definitions/templates/${workflowTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListDefinitionsByTemplateIdQueryKey = (workflowTemplateId: number,) => {
    return [`/api/workflow/task-list-definitions/templates/${workflowTemplateId}`] as const;
    }

    
export const getGetTaskListDefinitionsByTemplateIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>, TError = unknown>(workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListDefinitionsByTemplateIdQueryKey(workflowTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>> = ({ signal }) => getTaskListDefinitionsByTemplateId(workflowTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListDefinitionsByTemplateIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>>
export type GetTaskListDefinitionsByTemplateIdQueryError = unknown



export function useGetTaskListDefinitionsByTemplateId<TData = Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>, TError = unknown>(
 workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListDefinitionsByTemplateIdQueryOptions(workflowTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListDefinitionsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListDefinitionResponse[]>(
      {url: `/api/workflow/task-list-definitions/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListDefinitionsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/workflow/task-list-definitions/organizations/${organizationId}`] as const;
    }

    
export const getGetTaskListDefinitionsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListDefinitionsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>> = ({ signal }) => getTaskListDefinitionsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListDefinitionsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>>
export type GetTaskListDefinitionsByOrganizationQueryError = unknown



export function useGetTaskListDefinitionsByOrganization<TData = Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListDefinitionsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListDefinitionByKey = (
    organizationId: number,
    workflowKey: string,
    params?: GetTaskListDefinitionByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListDefinitionResponse>(
      {url: `/api/workflow/task-list-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTaskListDefinitionByKeyQueryKey = (organizationId: number,
    workflowKey: string,
    params?: GetTaskListDefinitionByKeyParams,) => {
    return [`/api/workflow/task-list-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTaskListDefinitionByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListDefinitionByKey>>, TError = unknown>(organizationId: number,
    workflowKey: string,
    params?: GetTaskListDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListDefinitionByKeyQueryKey(organizationId,workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListDefinitionByKey>>> = ({ signal }) => getTaskListDefinitionByKey(organizationId,workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListDefinitionByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListDefinitionByKey>>>
export type GetTaskListDefinitionByKeyQueryError = unknown



export function useGetTaskListDefinitionByKey<TData = Awaited<ReturnType<typeof getTaskListDefinitionByKey>>, TError = unknown>(
 organizationId: number,
    workflowKey: string,
    params?: GetTaskListDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListDefinitionByKeyQueryOptions(organizationId,workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListDefinitionsByOrganizationAndType = (
    organizationId: number,
    taskListType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListDefinitionResponse[]>(
      {url: `/api/workflow/task-list-definitions/organizations/${organizationId}/types/${taskListType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListDefinitionsByOrganizationAndTypeQueryKey = (organizationId: number,
    taskListType: string,) => {
    return [`/api/workflow/task-list-definitions/organizations/${organizationId}/types/${taskListType}`] as const;
    }

    
export const getGetTaskListDefinitionsByOrganizationAndTypeQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>, TError = unknown>(organizationId: number,
    taskListType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListDefinitionsByOrganizationAndTypeQueryKey(organizationId,taskListType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>> = ({ signal }) => getTaskListDefinitionsByOrganizationAndType(organizationId,taskListType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && taskListType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListDefinitionsByOrganizationAndTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>>
export type GetTaskListDefinitionsByOrganizationAndTypeQueryError = unknown



export function useGetTaskListDefinitionsByOrganizationAndType<TData = Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>, TError = unknown>(
 organizationId: number,
    taskListType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListDefinitionsByOrganizationAndTypeQueryOptions(organizationId,taskListType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskTypeDTO[]>(
      {url: `/api/tasklist/task-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskTypesQueryKey = () => {
    return [`/api/tasklist/task-types`] as const;
    }

    
export const getGetTaskTypesQueryOptions = <TData = Awaited<ReturnType<typeof getTaskTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskTypes>>> = ({ signal }) => getTaskTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskTypes>>>
export type GetTaskTypesQueryError = unknown



export function useGetTaskTypes<TData = Awaited<ReturnType<typeof getTaskTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListTemplateById = (
    taskListTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListTemplateResponse>(
      {url: `/api/tasklist/task-list-templates/${taskListTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListTemplateByIdQueryKey = (taskListTemplateId: number,) => {
    return [`/api/tasklist/task-list-templates/${taskListTemplateId}`] as const;
    }

    
export const getGetTaskListTemplateByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListTemplateById>>, TError = unknown>(taskListTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListTemplateByIdQueryKey(taskListTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListTemplateById>>> = ({ signal }) => getTaskListTemplateById(taskListTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskListTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListTemplateById>>>
export type GetTaskListTemplateByIdQueryError = unknown



export function useGetTaskListTemplateById<TData = Awaited<ReturnType<typeof getTaskListTemplateById>>, TError = unknown>(
 taskListTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListTemplateByIdQueryOptions(taskListTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskListTemplate = (
    taskListTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/tasklist/task-list-templates/${taskListTemplateId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskListTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListTemplate>>, TError,{taskListTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListTemplate>>, TError,{taskListTemplateId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskListTemplate>>, {taskListTemplateId: number}> = (props) => {
          const {taskListTemplateId} = props ?? {};

          return  deleteTaskListTemplate(taskListTemplateId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskListTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskListTemplate>>>
    
    export type DeleteTaskListTemplateMutationError = unknown

    export const useDeleteTaskListTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListTemplate>>, TError,{taskListTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskListTemplate>>,
        TError,
        {taskListTemplateId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskListTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskListTemplateByKey = (
    workflowKey: string,
    params?: GetTaskListTemplateByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListTemplateResponse>(
      {url: `/api/tasklist/task-list-templates/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTaskListTemplateByKeyQueryKey = (workflowKey: string,
    params?: GetTaskListTemplateByKeyParams,) => {
    return [`/api/tasklist/task-list-templates/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTaskListTemplateByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListTemplateByKey>>, TError = unknown>(workflowKey: string,
    params?: GetTaskListTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListTemplateByKeyQueryKey(workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListTemplateByKey>>> = ({ signal }) => getTaskListTemplateByKey(workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListTemplateByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListTemplateByKey>>>
export type GetTaskListTemplateByKeyQueryError = unknown



export function useGetTaskListTemplateByKey<TData = Awaited<ReturnType<typeof getTaskListTemplateByKey>>, TError = unknown>(
 workflowKey: string,
    params?: GetTaskListTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListTemplateByKeyQueryOptions(workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkTaskListTemplateExists = (
    workflowKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<boolean>(
      {url: `/api/tasklist/task-list-templates/workflow-key/${workflowKey}/exists`, method: 'GET', signal
    },
      options);
    }
  

export const getCheckTaskListTemplateExistsQueryKey = (workflowKey: string,) => {
    return [`/api/tasklist/task-list-templates/workflow-key/${workflowKey}/exists`] as const;
    }

    
export const getCheckTaskListTemplateExistsQueryOptions = <TData = Awaited<ReturnType<typeof checkTaskListTemplateExists>>, TError = unknown>(workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkTaskListTemplateExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckTaskListTemplateExistsQueryKey(workflowKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkTaskListTemplateExists>>> = ({ signal }) => checkTaskListTemplateExists(workflowKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkTaskListTemplateExists>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckTaskListTemplateExistsQueryResult = NonNullable<Awaited<ReturnType<typeof checkTaskListTemplateExists>>>
export type CheckTaskListTemplateExistsQueryError = unknown



export function useCheckTaskListTemplateExists<TData = Awaited<ReturnType<typeof checkTaskListTemplateExists>>, TError = unknown>(
 workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkTaskListTemplateExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCheckTaskListTemplateExistsQueryOptions(workflowKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListTemplatesByType = (
    taskListType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListTemplateResponse[]>(
      {url: `/api/tasklist/task-list-templates/type/${taskListType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListTemplatesByTypeQueryKey = (taskListType: string,) => {
    return [`/api/tasklist/task-list-templates/type/${taskListType}`] as const;
    }

    
export const getGetTaskListTemplatesByTypeQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListTemplatesByType>>, TError = unknown>(taskListType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListTemplatesByTypeQueryKey(taskListType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListTemplatesByType>>> = ({ signal }) => getTaskListTemplatesByType(taskListType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskListType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplatesByType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListTemplatesByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListTemplatesByType>>>
export type GetTaskListTemplatesByTypeQueryError = unknown



export function useGetTaskListTemplatesByType<TData = Awaited<ReturnType<typeof getTaskListTemplatesByType>>, TError = unknown>(
 taskListType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListTemplatesByTypeQueryOptions(taskListType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListAssignmentById = (
    taskListAssignmentId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListAssignmentResponse>(
      {url: `/api/tasklist/task-list-assignments/${taskListAssignmentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListAssignmentByIdQueryKey = (taskListAssignmentId: number,) => {
    return [`/api/tasklist/task-list-assignments/${taskListAssignmentId}`] as const;
    }

    
export const getGetTaskListAssignmentByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListAssignmentById>>, TError = unknown>(taskListAssignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListAssignmentByIdQueryKey(taskListAssignmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListAssignmentById>>> = ({ signal }) => getTaskListAssignmentById(taskListAssignmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskListAssignmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListAssignmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListAssignmentById>>>
export type GetTaskListAssignmentByIdQueryError = unknown



export function useGetTaskListAssignmentById<TData = Awaited<ReturnType<typeof getTaskListAssignmentById>>, TError = unknown>(
 taskListAssignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListAssignmentByIdQueryOptions(taskListAssignmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskListAssignment = (
    taskListAssignmentId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/tasklist/task-list-assignments/${taskListAssignmentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskListAssignmentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListAssignment>>, TError,{taskListAssignmentId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListAssignment>>, TError,{taskListAssignmentId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskListAssignment>>, {taskListAssignmentId: number}> = (props) => {
          const {taskListAssignmentId} = props ?? {};

          return  deleteTaskListAssignment(taskListAssignmentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskListAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskListAssignment>>>
    
    export type DeleteTaskListAssignmentMutationError = unknown

    export const useDeleteTaskListAssignment = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListAssignment>>, TError,{taskListAssignmentId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskListAssignment>>,
        TError,
        {taskListAssignmentId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskListAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskListAssignmentsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListAssignmentResponse[]>(
      {url: `/api/tasklist/task-list-assignments/organization/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListAssignmentsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/tasklist/task-list-assignments/organization/${organizationId}`] as const;
    }

    
export const getGetTaskListAssignmentsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListAssignmentsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>> = ({ signal }) => getTaskListAssignmentsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListAssignmentsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>>
export type GetTaskListAssignmentsByOrganizationQueryError = unknown



export function useGetTaskListAssignmentsByOrganization<TData = Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListAssignmentsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListAssignmentsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDistinctUserRoleIdsWithTaskAssignments = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<number[]>(
      {url: `/api/tasklist/task-list-assignments/organization/${organizationId}/user-role-ids`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDistinctUserRoleIdsWithTaskAssignmentsQueryKey = (organizationId: number,) => {
    return [`/api/tasklist/task-list-assignments/organization/${organizationId}/user-role-ids`] as const;
    }

    
export const getGetDistinctUserRoleIdsWithTaskAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDistinctUserRoleIdsWithTaskAssignmentsQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>> = ({ signal }) => getDistinctUserRoleIdsWithTaskAssignments(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDistinctUserRoleIdsWithTaskAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>>
export type GetDistinctUserRoleIdsWithTaskAssignmentsQueryError = unknown



export function useGetDistinctUserRoleIdsWithTaskAssignments<TData = Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDistinctUserRoleIdsWithTaskAssignmentsQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskCategory = (
    taskCategoryId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/tasklist/task-categories/${taskCategoryId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskCategoryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskCategory>>, {taskCategoryId: number}> = (props) => {
          const {taskCategoryId} = props ?? {};

          return  deleteTaskCategory(taskCategoryId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskCategory>>>
    
    export type DeleteTaskCategoryMutationError = unknown

    export const useDeleteTaskCategory = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskCategory>>,
        TError,
        {taskCategoryId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
