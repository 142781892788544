import type { AssetResponse } from '@btrway/api-courseware';
import {
  CourseTaskProperties,
  TaskConfig,
  TaskTypeEnum,
} from '@btrway/api-task';
import { useCourses } from '@btrway/courseware-manager';
import { SelectInternalCourse } from '@btrway/select-internal-course';
import { getTypedTaskProperties } from '@btrway/task-list-config-provider';
import { Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskConfigProps } from '../../types/taskTypeComponentProps';

export const CourseTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.completeCourse
  );

  const { getCourse } = useCourses();

  const [properties, setProperties] = useState<CourseTaskProperties>({
    courseSelection: {
      courseUid: {
        value: taskProperties?.courseSelection?.courseUid?.value,
      },
    },
  });

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        courseSelection: {
          courseUid: {
            value: taskProperties.courseSelection?.courseUid?.value,
          },
        },
      });
    }
  }, [taskProperties]);

  const handleCourseChange = (course: AssetResponse | null) => {
    // Get the current course and its title before making changes
    const currentCourseId = properties.courseSelection?.courseUid?.value;
    const currentCourse = currentCourseId ? getCourse(currentCourseId) : null;
    const currentCourseTitle = currentCourse?.title || 'Take Course';

    const updatedProperties: CourseTaskProperties = {
      courseSelection: {
        courseUid: {
          value: course?.uid,
        },
      },
    };

    setProperties(updatedProperties);

    const newTitle = course?.title || 'Take Course';
    const shouldUpdateTitle = config.metadata?.title === currentCourseTitle;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        title: shouldUpdateTitle ? newTitle : config.metadata?.title,
      },
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <SelectInternalCourse
        value={properties.courseSelection?.courseUid?.value || null}
        onChange={handleCourseChange}
        placeholder="Select a course"
        label="Course"
        required
      />
    </Stack>
  );
};
