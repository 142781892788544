import { PermissionTypeEnum } from '@btrway/api-security';
import { ActionIcon, Tooltip } from '@mantine/core';
import { Icon } from '@tabler/icons-react';
import React from 'react';

interface AddPermissionActionIconProps {
  icon: Icon;
  tooltipLabel: string;
  permissionType: PermissionTypeEnum;
  onClick: (type: PermissionTypeEnum) => void;
}

export const AddPermissionActionIcon: React.FC<
  AddPermissionActionIconProps
> = ({ icon: IconComponent, tooltipLabel, permissionType, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(permissionType);
  };

  return (
    <Tooltip label={tooltipLabel}>
      <ActionIcon variant="light" onClick={handleClick} title={tooltipLabel}>
        <IconComponent size={16} />
      </ActionIcon>
    </Tooltip>
  );
};
