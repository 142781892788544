import { TaskConfig } from '@btrway/api-task';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface TaskCreatorContextState {
  isOpen: boolean;
  taskList: CommonTaskList | null;
  taskContainerKey?: string;
  onTaskAdded?: (task: TaskConfig) => void;
}

interface TaskCreatorContextValue extends TaskCreatorContextState {
  openCreator: (params: {
    taskList: CommonTaskList;
    taskContainerKey?: string;
    onTaskAdded?: (task: TaskConfig) => void;
  }) => void;
  closeCreator: () => void;
}

const TaskCreatorContext = createContext<TaskCreatorContextValue | null>(null);

export const TaskCreatorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<TaskCreatorContextState>({
    isOpen: false,
    taskList: null,
  });

  const openCreator = useCallback(
    (params: {
      taskList: CommonTaskList;
      taskContainerKey?: string;
      onTaskAdded?: (task: TaskConfig) => void;
    }) => {
      setState({
        isOpen: true,
        taskList: params.taskList,
        taskContainerKey: params.taskContainerKey,
        onTaskAdded: params.onTaskAdded,
      });
    },
    []
  );

  const closeCreator = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const value = {
    ...state,
    openCreator,
    closeCreator,
  };

  return (
    <TaskCreatorContext.Provider value={value}>
      {children}
    </TaskCreatorContext.Provider>
  );
};

export const useTaskCreator = () => {
  const context = useContext(TaskCreatorContext);
  if (!context) {
    throw new Error('useTaskCreator must be used within a TaskCreatorProvider');
  }
  return context;
};
