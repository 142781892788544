import { TaskConfig, TaskListTaskProperties } from '@btrway/api-tasklist';
import { SelectTaskList } from '@btrway/select-tasklist';
import {
  getTaskListProperties,
  getTaskListTypeForTask,
} from '@btrway/task-list-config-provider';
import {
  CommonTaskList,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskConfigProps } from '../../types/taskTypeComponentProps';

export const TaskListTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskListService = useTaskListService();
  const taskProperties = getTaskListProperties(config);

  const [properties, setProperties] = useState<TaskListTaskProperties>({
    taskListSelection: {
      taskListKey: {
        value: taskProperties?.taskListSelection?.taskListKey?.value || '',
      },
    },
  });

  // Get the current workflow if one is selected
  const currentTaskListKey = properties.taskListSelection?.taskListKey?.value;
  const { data: selectedTaskList } =
    taskListService.getByKey(currentTaskListKey);

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        taskListSelection: {
          taskListKey: {
            value: taskProperties.taskListSelection?.taskListKey?.value || '',
          },
        },
      });
    }
  }, [taskProperties]);

  const handleTaskListChange = (taskList: CommonTaskList) => {
    if (!taskList.taskListKey) return;

    const updatedProperties: TaskListTaskProperties = {
      taskListSelection: {
        taskListKey: {
          value: taskList.taskListKey,
        },
      },
    };

    setProperties(updatedProperties);

    const shouldUpdateTitle =
      selectedTaskList && config.metadata?.title === selectedTaskList.name;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        title: shouldUpdateTitle
          ? taskList.name
          : config.metadata?.title || 'Complete Task List',
      },
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  const taskListType = getTaskListTypeForTask(config.taskType);
  if (!taskListType) {
    return null;
  }

  return (
    <Stack>
      <SelectTaskList
        taskListType={taskListType}
        onChange={handleTaskListChange}
        value={selectedTaskList || undefined}
      />
    </Stack>
  );
};
