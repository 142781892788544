import { CourseExecutionPage } from '@btrway/courseware-library-components';
import { TaskListBuilderPage } from '@btrway/task-list-builder';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowBuilderPage } from '@btrway/workflow-builder';

export const fullScreenRoutes: RouteConfig[] = [
  {
    path: 'settings/workflow/automations/:workflowKey',
    element: <WorkflowBuilderPage />,
  },
  {
    path: 'settings/workflow/forms/:workflowKey',
    element: <WorkflowBuilderPage />,
  },
  {
    path: 'settings/workflow/form-packets/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'settings/workflow/todo-lists/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'settings/workflow/curriculums/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'settings/workflow/certifications/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'education/course-viewer/:courseUid',
    element: <CourseExecutionPage />,
  },
];
