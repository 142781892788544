import { useGetPersonApprovalTasks } from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useEffect } from 'react';
import { useTasksManagerContext } from '../providers/TasksManagerProvider';
import { useStateReconciliation } from './useStateReconciliation';

interface UseApprovalTasksOptions {
  personId?: number;
}

export const useApprovalTasks = ({
  personId,
}: UseApprovalTasksOptions = {}) => {
  const { currentPerson } = useAuthenticatedUser();
  const { setState } = useTasksManagerContext();
  const { reconcileTasks } = useStateReconciliation();

  const targetPersonId = personId || currentPerson?.id;
  const query = useGetPersonApprovalTasks(targetPersonId);

  useEffect(() => {
    if (query.data) {
      setState((prev) => ({
        ...prev,
        tasks: reconcileTasks(prev.tasks ?? [], query.data),
      }));
    }
  }, [query.data, setState, targetPersonId, reconcileTasks]);

  return {
    approvalTasks: query.data || [],
    isLoading: query.isLoading,
    error: query.error as Error | null,
    refetch: query.refetch,
  };
};
