/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type PermissionTypeEnum = typeof PermissionTypeEnum[keyof typeof PermissionTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionTypeEnum = {
  module: 'module',
  entity: 'entity',
  tool: 'tool',
  setting: 'setting',
  group: 'group',
} as const;
