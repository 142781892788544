import type { WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  CommonWorkflow,
  useWorkflowService,
  WorkflowServiceProvider,
} from '@btrway/workflow-service-provider';
import { Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { WorkflowCard } from '../WorkflowCard/WorkflowCard';

interface SelectWorkflowOptionsProps {
  selectedWorkflow?: CommonWorkflow;
  workflowType: WorkflowTypeEnum;
  onSelect: (workflow: CommonWorkflow) => void;
  onClose: () => void;
}

const SelectWorkflowOptionsInner: React.FC<SelectWorkflowOptionsProps> = ({
  selectedWorkflow,
  workflowType,
  onSelect,
  onClose,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const workflowService = useWorkflowService();
  const { workflows, isLoading } =
    workflowService.getWorkflowsByType(workflowType);

  const filteredWorkflows = useMemo(() => {
    return workflows
      ?.filter((workflow) =>
        workflow.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflows, searchQuery]);

  return (
    <div>
      <TextInput
        autoFocus
        data-autofocus
        placeholder={`Search ${pluralize(workflowType)}...`}
        leftSection={<IconSearch size={16} />}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.currentTarget.value)}
        mb="sm"
      />

      {isLoading ? (
        <Text>Loading workflows...</Text>
      ) : (
        <div style={{ overflowY: 'auto' }}>
          {filteredWorkflows?.map((workflow) => (
            <WorkflowCard
              key={workflow.id || workflow.templateKey}
              workflow={workflow}
              isSelected={selectedWorkflow?.id === workflow.id}
              onClick={() => onSelect(workflow)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const SelectWorkflowOptions: React.FC<SelectWorkflowOptionsProps> = (
  props
) => {
  return (
    <WorkflowServiceProvider>
      <SelectWorkflowOptionsInner {...props} />
    </WorkflowServiceProvider>
  );
};
