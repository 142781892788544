import { TaskConfig, TaskTypeEnum } from '@btrway/api-tasklist';
import { useTaskTypes } from '@btrway/workflow-manager';
import { createBaseTaskConfig, createBaseTaskListTaskConfig, createBaseExternalCourseTaskConfig, createBaseWorkflowTaskConfig, createBaseCourseTaskConfig } from '../utils/configCreators';

export type ConfigFactory = {
  createTaskConfig: (taskType: TaskTypeEnum) => TaskConfig;
  createTaskContainerConfig: () => TaskConfig;
  createWorkflowTaskConfig: (
    workflowKey: string,
    workflowName: string,
    taskType: TaskTypeEnum
  ) => TaskConfig;
  createTaskListTaskConfig: (
    taskListKey: string,
    taskListName: string,
    taskType: TaskTypeEnum
  ) => TaskConfig;
  createCourseTaskConfig: (courseUid: string, courseName: string) => TaskConfig;
  createExternalCourseTaskConfig: (
    externalCourseKey: string,
    courseName: string
  ) => TaskConfig;
};

export const useConfigFactory = (): ConfigFactory => {
  const { getTaskType } = useTaskTypes();

  const createTaskConfig = (taskType: TaskTypeEnum) => {
    const type = getTaskType(taskType);
    return createBaseTaskConfig(taskType, type?.name);
  };

  const createTaskContainerConfig = () => {
    const taskType = TaskTypeEnum.taskContainer;
    const type = getTaskType(taskType);
    const taskContainerConfig = createBaseTaskConfig(
      taskType,
      type?.name
    );
    return {
      ...taskContainerConfig,
      childTasks: [],
    };
  };

  const createWorkflowTaskConfig = (
    workflowKey: string,
    workflowName: string,
    taskType: TaskTypeEnum
  ) => {
    return createBaseWorkflowTaskConfig(
      workflowKey,
      workflowName,
      taskType
    );
  };

  const createTaskListTaskConfig = (
    taskListKey: string,
    taskListName: string,
    taskType: TaskTypeEnum
  ) => {
    return createBaseTaskListTaskConfig(
      taskListKey,
      taskListName,
      taskType
    );
  };

  const createCourseTaskConfig = (courseUid: string, courseName: string) => {
    return createBaseCourseTaskConfig(courseUid, courseName);
  };

  const createExternalCourseTaskConfig = (
    externalCourseKey: string,
    courseName: string
  ) => {
    return createBaseExternalCourseTaskConfig(
      externalCourseKey,
      courseName
    );
  };

  return {
    // Base creators that need type information
    createTaskConfig,
    createTaskContainerConfig,

    // Specialized creators that use base creators
    createWorkflowTaskConfig,
    createTaskListTaskConfig,
    createCourseTaskConfig,
    createExternalCourseTaskConfig,
  };
};
