import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Box, Stack, Textarea, TextInput } from '@mantine/core';
import { TaskListClassificationEditor } from '../TaskListClassificationEditor/TaskListClassificationEditor';

interface TaskListDescriptionEditorProps {
  taskList: CommonTaskList;
  onChange: (taskList: CommonTaskList) => void;
}

export const TaskListDescriptionEditor: React.FC<
  TaskListDescriptionEditorProps
> = ({ taskList, onChange }) => {
  const handleChange = (field: keyof CommonTaskList, value: any) => {
    onChange({
      ...taskList,
      [field]: value,
    });
  };

  const handleMetadataChange = (value: any) => {
    handleChange('taskListMetadata', {
      ...taskList.taskListMetadata,
      ...value,
    });
  };

  return (
    <Box>
      <Stack gap="md">
        <TextInput
          label="Name"
          value={taskList.name}
          onChange={(event) => handleChange('name', event.currentTarget.value)}
        />

        <TaskListClassificationEditor
          taskListType={taskList.taskListType}
          taskListClassification={
            taskList.taskListMetadata?.taskListClassification || ''
          }
          onChange={(classification) =>
            handleMetadataChange({
              taskListClassification: classification,
            })
          }
        />

        <Textarea
          label="Short Description"
          value={taskList.taskListMetadata?.shortDescription || ''}
          onChange={(event) =>
            handleMetadataChange({
              shortDescription: event.currentTarget.value,
            })
          }
          rows={3}
        />

        <Textarea
          label="Long Description"
          value={taskList.taskListMetadata?.longDescription || ''}
          onChange={(event) =>
            handleMetadataChange({
              longDescription: event.currentTarget.value,
            })
          }
          rows={8}
        />
      </Stack>
    </Box>
  );
};
