import {
  getGetTaskListTemplateByKeyQueryKey,
  getGetTaskListTemplatesByTypeQueryKey,
  getGetTaskListTemplatesQueryKey,
  TaskListTemplateConfigUpdate,
  TaskListTemplateRequest,
  TaskListTypeEnum,
  useDeleteTaskListTemplate,
  useGetTaskListTemplateByKey,
  useGetTaskListTemplates,
  useGetTaskListTemplatesByType,
  useSaveTaskListTemplate,
  useUpdateTaskListTemplateConfig,
} from '@btrway/api-tasklist';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonTaskList } from '../types/commonTaskList';
import { TaskListResult, TaskListsResult } from '../types/service';
import {
  convertCommonToTaskListRequest,
  convertResponseToCommon,
  validateTaskListBeforeConversion,
} from '../utils/commonTaskListConversions';

export function useTaskListTemplate(tasklistKey?: string) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'template';

  const { mutateAsync: updateConfig } = useUpdateTaskListTemplateConfig();
  const { mutateAsync: saveTaskListTemplate } = useSaveTaskListTemplate();
  const { mutateAsync: deleteTaskListTemplate } = useDeleteTaskListTemplate();

  const {
    data,
    isLoading,
    error,
    refetch: refetchTemplate,
  } = useGetTaskListTemplateByKey(
    tasklistKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!tasklistKey,
        queryKey: getGetTaskListTemplateByKeyQueryKey(tasklistKey || ''),
      },
    }
  );

  const {
    data: templatesData,
    isLoading: templatesLoading,
    error: templatesError,
    refetch: refetchTemplates,
  } = useGetTaskListTemplates({
    query: {
      enabled: isEnabled,
      queryKey: getGetTaskListTemplatesQueryKey(),
    },
  });

  const result: TaskListResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchTemplate,
  };

  const templatesResult: TaskListsResult = {
    taskLists: (templatesData || []).map(convertResponseToCommon),
    isLoading: templatesLoading,
    error: templatesError as Error | undefined,
    refetch: refetchTemplates,
  };

  const getTaskListsByType = (type: TaskListTypeEnum) => {
    const {
      data: typeData,
      isLoading: typeLoading,
      error: typeError,
      refetch: refetchType,
    } = useGetTaskListTemplatesByType(type, {
      query: {
        enabled: isEnabled,
        queryKey: getGetTaskListTemplatesByTypeQueryKey(type),
      },
    });

    return {
      taskLists: (typeData || []).map(convertResponseToCommon),
      isLoading: typeLoading,
      error: typeError as Error | undefined,
      refetch: refetchType,
    };
  };

  return {
    tasklistResult: result,
    tasklistsResult: templatesResult,
    getTaskListsByType,
    updateConfig: async (config: TaskListTemplateConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateTaskList: async (tasklist: CommonTaskList) => {
      if (tasklist.sourceType !== 'template') {
        throw new Error('Cannot save definition tasklist in template context');
      }
      const validationErrors = validateTaskListBeforeConversion(tasklist);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid tasklist: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToTaskListRequest(
        tasklist
      ) as TaskListTemplateRequest;
      return await saveTaskListTemplate({ data: request });
    },
    deleteTaskList: async (taskList: CommonTaskList) => {
      if (taskList.sourceType !== 'template') {
        throw new Error(
          'Cannot delete definition workflow in template context'
        );
      }
      if (!taskList.id) {
        throw new Error('Cannot delete workflow without an id');
      }
      return await deleteTaskListTemplate({ taskListTemplateId: taskList.id });
    },
  };
}
