import {
  TaskListTypeEnum,
  TaskStatisticsRequest,
  TaskStatisticsResponse,
} from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { LoadingSpinner } from '@btrway/shared-components';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useFetchPersonStats } from '../hooks/useFetchPersonStats';
import {
  AggregatedStats,
  EnrichedPersonStats,
  EnrichedTaskListStats,
  TaskListTypeFilter,
} from '../types/stats';

interface PersonStatsContextValue {
  stats: EnrichedPersonStats[] | undefined;
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
  getStatsByFilter: (
    filter?: TaskListTypeFilter
  ) => EnrichedPersonStats[] | undefined;
  getAggregatedStats: (
    filter?: TaskListTypeFilter
  ) => AggregatedStats | undefined;
}

const PersonStatsContext = createContext<PersonStatsContextValue | undefined>(
  undefined
);

export const usePersonStats = () => {
  const context = useContext(PersonStatsContext);
  if (!context) {
    throw new Error('usePersonStats must be used within a PersonStatsProvider');
  }
  return context;
};

interface PersonStatsProviderProps {
  children: React.ReactNode;
  workgroupId: number;
  personId?: number;
  taskListKey?: string;
  workgroupTypeId?: number;
  taskListType?: TaskListTypeEnum;
  taskListClassification?: string;
  showLoading?: boolean;
}

// Helper function to transform TaskStatisticsResponse to EnrichedTaskListStats
const enrichTaskListStats = (
  taskList: TaskStatisticsResponse
): EnrichedTaskListStats => {
  const completedTasks = taskList.completedTasks || 0;
  const totalTasks = taskList.totalTasks || 0;
  const completedTaskLists = taskList.completedTaskLists || 0;
  const totalTaskLists = taskList.totalTaskLists || 0;

  return {
    ...taskList,
    progressPercentage:
      totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0,
    taskListProgressPercentage:
      totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0,
    remainingTasks: totalTasks - completedTasks,
    remainingTaskLists: totalTaskLists - completedTaskLists,
    isOverdue: (taskList.averageOverdueDays || 0) > 0,
  };
};

export const PersonStatsProvider: React.FC<PersonStatsProviderProps> = ({
  children,
  workgroupId,
  personId,
  taskListKey,
  workgroupTypeId,
  taskListType,
  taskListClassification,
  showLoading = true,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const organizationId = currentOrganization.id;

  const request = useMemo<TaskStatisticsRequest>(
    () => ({
      organizationId,
      workgroupId,
      taskListKey,
      workgroupTypeId,
      taskListType,
      taskListClassification,
      groupBy: 'person',
    }),
    [
      organizationId,
      workgroupId,
      taskListKey,
      taskListType,
      taskListType,
      workgroupTypeId,
    ]
  );

  const { data, isLoading, error, refetch } = useFetchPersonStats(request);

  const filterTaskLists = useCallback(
    (taskLists: TaskStatisticsResponse[], filter?: TaskListTypeFilter) => {
      if (!filter) return taskLists;

      return taskLists.filter((taskList) => {
        const matchesTaskListType =
          !filter.taskListType || taskList.taskListType === filter.taskListType;
        const matchesTaskListClassification =
          !filter.taskListClassification ||
          taskList.taskListClassification === filter.taskListClassification;

        return matchesTaskListType && matchesTaskListClassification;
      });
    },
    []
  );

  const getStatsByFilter = useCallback(
    (filter?: TaskListTypeFilter) => {
      if (!data) return undefined;

      // If personId is provided, filter to just that person
      let filteredData = data;
      if (personId) {
        filteredData = data.filter((stat) => stat.personId === personId);
      }

      return filteredData.map((personStats): EnrichedPersonStats => {
        const filteredTaskLists = filterTaskLists(
          personStats.taskLists,
          filter
        );
        const enrichedTaskLists = filteredTaskLists.map(enrichTaskListStats);
        const aggregatedStats = calculateAggregatedStats(filteredTaskLists);

        return {
          ...personStats,
          taskLists: enrichedTaskLists,
          taskListStatistics: enrichedTaskLists,
          ...aggregatedStats,
          totalRemainingTasks: enrichedTaskLists.reduce(
            (sum, w) => sum + w.remainingTasks,
            0
          ),
          totalRemainingTaskLists: enrichedTaskLists.reduce(
            (sum, w) => sum + w.remainingTaskLists,
            0
          ),
          averageTaskProgress:
            enrichedTaskLists.length > 0
              ? enrichedTaskLists.reduce(
                  (sum, w) => sum + w.progressPercentage,
                  0
                ) / enrichedTaskLists.length
              : 0,
          averageTaskListProgress:
            enrichedTaskLists.length > 0
              ? enrichedTaskLists.reduce(
                  (sum, w) => sum + w.taskListProgressPercentage,
                  0
                ) / enrichedTaskLists.length
              : 0,
          overdueTaskListsCount: enrichedTaskLists.filter((w) => w.isOverdue)
            .length,
        };
      });
    },
    [data, filterTaskLists, personId]
  );

  const getAggregatedStats = useCallback(
    (filter?: TaskListTypeFilter) => {
      if (!data) return undefined;

      // If personId is provided, filter to just that person
      let filteredData = data;
      if (personId) {
        filteredData = data.filter((stat) => stat.personId === personId);
      }

      const allTaskLists = filteredData.flatMap((person) => person.taskLists);
      const filteredTaskLists = filterTaskLists(allTaskLists, filter);

      return calculateAggregatedStats(filteredTaskLists);
    },
    [data, filterTaskLists, personId]
  );

  if (isLoading && showLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PersonStatsContext.Provider
      value={{
        stats: data,
        isLoading,
        error,
        refetch,
        getStatsByFilter,
        getAggregatedStats,
      }}
    >
      {children}
    </PersonStatsContext.Provider>
  );
};

// Helper function to calculate aggregated stats
const calculateAggregatedStats = (
  taskLists: TaskStatisticsResponse[]
): AggregatedStats => {
  const totalTasks = taskLists.reduce((sum, w) => sum + (w.totalTasks || 0), 0);
  const completedTasks = taskLists.reduce(
    (sum, w) => sum + (w.completedTasks || 0),
    0
  );
  const totalTaskLists = taskLists.reduce(
    (sum, w) => sum + (w.totalTaskLists || 0),
    0
  );
  const completedTaskLists = taskLists.reduce(
    (sum, w) => sum + (w.completedTaskLists || 0),
    0
  );
  const overdueCompletedTasks = taskLists.reduce(
    (sum, w) => sum + (w.overdueCompletedTasks || 0),
    0
  );

  const overdueDays = taskLists
    .map((w) => w.averageOverdueDays)
    .filter((days): days is number => days !== undefined && days > 0);
  const averageOverdueDays =
    overdueDays.length > 0
      ? overdueDays.reduce((sum, days) => sum + days, 0) / overdueDays.length
      : 0;

  return {
    completedTasks,
    completedTaskLists,
    totalTasks,
    totalTaskLists,
    overdueCompletedTasks,
    averageOverdueDays,
    taskCompletionPercentage:
      totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0,
    taskListCompletionPercentage:
      totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0,
  };
};
