import { DataTypeEnum, PermissionTypeEnum } from '@btrway/api-security';
import {
  PermissionNode,
  usePermissionAdmin,
  usePermissionMode,
} from '@btrway/permission-manager';
import { getDataTypeLabel } from '@btrway/utils';
import { Badge, Card, Group, Stack, Text, Title } from '@mantine/core';
import React, { useState } from 'react';
import { usePermissionDrag } from '../../hooks/usePermissionDrag';
import { PermissionControl } from '../PermissionControl/PermissionControl';
import { PermissionEdit } from '../PermissionEdit/PermissionEdit';
import { PermissionNodesByUserRole } from '../PermissionNodesByUserRole/PermissionNodesByUserRole';
import { PermissionNodesContainer } from '../PermissionNodesContainer/PermissionNodesContainer';

interface PermissionNodeCardProps {
  node: PermissionNode;
  level?: number;
  selected?: boolean;
  onDelete?: () => void;
}

export const PermissionNodeCard: React.FC<PermissionNodeCardProps> = ({
  node,
  level = 0,
  selected = false,
  onDelete,
}) => {
  const [editingNodeId, setEditingNodeId] = useState<string | null>(null);
  const {
    mode,
    canEditNode,
    showBetaBadge,
    showTypeBadge,
    showDescription,
    showSettingPermissions,
    getContainerDescriptions,
    getPermissionControlSettings,
    setSelectedModuleId,
  } = usePermissionMode();
  const { markForDeletion } = usePermissionAdmin();

  const { isDragging, drag } = usePermissionDrag(node);

  const canDrag =
    mode === 'admin' &&
    (node.permissionType === PermissionTypeEnum.tool ||
      node.permissionType === PermissionTypeEnum.setting);

  const isEditing =
    mode === 'admin' && (editingNodeId === node.clientId || node.name === '');
  const isTopLevel = level === 0;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent bubbling to parent cards

    // Don't trigger selection when clicking controls
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('[data-control]') ||
        e.target.closest('[data-expand-button]'))
    ) {
      return;
    }

    if (canEditNode(node)) {
      setEditingNodeId(node.clientId);
    } else {
      setSelectedModuleId(node.clientId);
    }
  };

  const handleCancel = () => {
    setEditingNodeId(null);
  };

  const handleDelete = () => {
    if (mode !== 'admin') return;

    const confirmed = window.confirm(
      'Are you sure you want to delete this permission? This action cannot be undone.'
    );

    if (confirmed) {
      const success = markForDeletion(node.clientId);
      if (success) {
        setEditingNodeId(null);
        onDelete?.();
      }
    }
  };

  const dragRef = (el: HTMLDivElement) => {
    if (canDrag) {
      drag(el);
    }
  };

  if (isEditing) {
    return (
      <PermissionEdit
        permission={node}
        onCancel={handleCancel}
        onDelete={handleDelete}
        title={
          node.permissionType.charAt(0).toUpperCase() +
          node.permissionType.slice(1)
        }
      />
    );
  }

  const renderChildNode = (childNode: PermissionNode) => (
    <PermissionNodeCard
      key={childNode.clientId}
      node={childNode}
      level={level + 1}
      selected={selected}
      onDelete={onDelete}
    />
  );

  return (
    <div
      ref={dragRef}
      style={{
        cursor: canDrag ? 'grab' : 'pointer',
        opacity: isDragging ? 0.5 : 1,
      }}
      onClick={handleClick}
    >
      <Card withBorder={!isTopLevel} p="sm">
        <Stack gap="sm">
          <Group justify="space-between" align="flex-start">
            <Stack gap="xs">
              <Group gap="xs">
                {isTopLevel ? (
                  <Title order={3}>{node.name}</Title>
                ) : (
                  <Text fw={500}>{node.name}</Text>
                )}
                {node.permissionType === PermissionTypeEnum.entity &&
                  node.entityType &&
                  showTypeBadge && (
                    <Badge color="gray">
                      {getDataTypeLabel(node.entityType)}
                    </Badge>
                  )}
                {showBetaBadge && node.permissionAttributes?.beta && (
                  <Badge color="blue">Beta</Badge>
                )}
              </Group>
              {showDescription && node.description && (
                <Text size="sm" c="dimmed">
                  {node.description}
                </Text>
              )}
            </Stack>
            {/* Only show PermissionControl if not a user role entity type or not in userRoleGrant mode */}
            {!(
              node.entityType === DataTypeEnum.userRole &&
              mode === 'userRoleGrant'
            ) && <PermissionControl node={node} />}
          </Group>

          {/* Render role-specific permissions if this is a user role entity type */}
          {node.entityType === DataTypeEnum.userRole &&
            mode === 'userRoleGrant' && (
              <PermissionNodesByUserRole node={node} />
            )}

          {node.permissionType === PermissionTypeEnum.module && (
            <Stack gap="md">
              <PermissionNodesContainer
                title="Entities"
                description={getContainerDescriptions().entities}
                parentNode={node}
                permissionType={PermissionTypeEnum.entity}
                renderNode={renderChildNode}
              />
              <PermissionNodesContainer
                title="Tools"
                description={getContainerDescriptions().tools}
                parentNode={node}
                permissionType={PermissionTypeEnum.tool}
                renderNode={renderChildNode}
              />
              {showSettingPermissions && (
                <PermissionNodesContainer
                  title="ABW Controlled Settings"
                  description={getContainerDescriptions().settings}
                  parentNode={node}
                  permissionType={PermissionTypeEnum.setting}
                  renderNode={renderChildNode}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Card>
    </div>
  );
};
