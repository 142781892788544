/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DataTypeDTO,
  EventTypeDTO,
  GetWorkflowDefinitionByKeyParams,
  GetWorkflowTemplateByKeyParams,
  StepTypeDTO,
  WorkflowAssignedEventRequest,
  WorkflowDefinitionConfigUpdate,
  WorkflowDefinitionRequest,
  WorkflowDefinitionResponse,
  WorkflowInstanceGridRequest,
  WorkflowInstanceGridResponse,
  WorkflowInstanceResponse,
  WorkflowTemplateConfigUpdate,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getWorkflowTemplates = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse[]>(
      {url: `/api/workflow/workflow-templates`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplatesQueryKey = () => {
    return [`/api/workflow/workflow-templates`] as const;
    }

    
export const getGetWorkflowTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplates>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplates>>> = ({ signal }) => getWorkflowTemplates(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplates>>>
export type GetWorkflowTemplatesQueryError = unknown



export function useGetWorkflowTemplates<TData = Awaited<ReturnType<typeof getWorkflowTemplates>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveWorkflowTemplate = (
    workflowTemplateRequest: WorkflowTemplateRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowTemplateRequest
    },
      options);
    }
  


export const getSaveWorkflowTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkflowTemplate>>, {data: WorkflowTemplateRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkflowTemplate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkflowTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkflowTemplate>>>
    export type SaveWorkflowTemplateMutationBody = WorkflowTemplateRequest
    export type SaveWorkflowTemplateMutationError = unknown

    export const useSaveWorkflowTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkflowTemplate>>,
        TError,
        {data: WorkflowTemplateRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkflowTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get workflow instances grid view
 */
export const getWorkflowInstanceGrid = (
    workflowInstanceGridRequest: WorkflowInstanceGridRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<WorkflowInstanceGridResponse[]>(
      {url: `/api/workflow/workflow-instances/grid`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowInstanceGridRequest
    },
      options);
    }
  


export const getGetWorkflowInstanceGridMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getWorkflowInstanceGrid>>, TError,{data: WorkflowInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getWorkflowInstanceGrid>>, TError,{data: WorkflowInstanceGridRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getWorkflowInstanceGrid>>, {data: WorkflowInstanceGridRequest}> = (props) => {
          const {data} = props ?? {};

          return  getWorkflowInstanceGrid(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetWorkflowInstanceGridMutationResult = NonNullable<Awaited<ReturnType<typeof getWorkflowInstanceGrid>>>
    export type GetWorkflowInstanceGridMutationBody = WorkflowInstanceGridRequest
    export type GetWorkflowInstanceGridMutationError = unknown

    /**
 * @summary Get workflow instances grid view
 */
export const useGetWorkflowInstanceGrid = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getWorkflowInstanceGrid>>, TError,{data: WorkflowInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getWorkflowInstanceGrid>>,
        TError,
        {data: WorkflowInstanceGridRequest},
        TContext
      > => {

      const mutationOptions = getGetWorkflowInstanceGridMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const assignWorkflows = (
    workflowAssignedEventRequest: WorkflowAssignedEventRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-events/assign-workflow`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowAssignedEventRequest
    },
      options);
    }
  


export const getAssignWorkflowsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignWorkflows>>, {data: WorkflowAssignedEventRequest}> = (props) => {
          const {data} = props ?? {};

          return  assignWorkflows(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignWorkflowsMutationResult = NonNullable<Awaited<ReturnType<typeof assignWorkflows>>>
    export type AssignWorkflowsMutationBody = WorkflowAssignedEventRequest
    export type AssignWorkflowsMutationError = unknown

    export const useAssignWorkflows = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof assignWorkflows>>,
        TError,
        {data: WorkflowAssignedEventRequest},
        TContext
      > => {

      const mutationOptions = getAssignWorkflowsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveWorkflowDefinition = (
    workflowDefinitionRequest: WorkflowDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowDefinitionRequest
    },
      options);
    }
  


export const getSaveWorkflowDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkflowDefinition>>, {data: WorkflowDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkflowDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkflowDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkflowDefinition>>>
    export type SaveWorkflowDefinitionMutationBody = WorkflowDefinitionRequest
    export type SaveWorkflowDefinitionMutationError = unknown

    export const useSaveWorkflowDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkflowDefinition>>,
        TError,
        {data: WorkflowDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkflowDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateWorkflowTemplateConfig = (
    workflowTemplateConfigUpdate: WorkflowTemplateConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-config/template`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowTemplateConfigUpdate
    },
      options);
    }
  


export const getUpdateWorkflowTemplateConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, {data: WorkflowTemplateConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateWorkflowTemplateConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkflowTemplateConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>>
    export type UpdateWorkflowTemplateConfigMutationBody = WorkflowTemplateConfigUpdate
    export type UpdateWorkflowTemplateConfigMutationError = unknown

    export const useUpdateWorkflowTemplateConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>,
        TError,
        {data: WorkflowTemplateConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateWorkflowTemplateConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateWorkflowDefinitionConfig = (
    workflowDefinitionConfigUpdate: WorkflowDefinitionConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-config/definition`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowDefinitionConfigUpdate
    },
      options);
    }
  


export const getUpdateWorkflowDefinitionConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, {data: WorkflowDefinitionConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateWorkflowDefinitionConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkflowDefinitionConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>>
    export type UpdateWorkflowDefinitionConfigMutationBody = WorkflowDefinitionConfigUpdate
    export type UpdateWorkflowDefinitionConfigMutationError = unknown

    export const useUpdateWorkflowDefinitionConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>,
        TError,
        {data: WorkflowDefinitionConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateWorkflowDefinitionConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowTemplateById = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse>(
      {url: `/api/workflow/workflow-templates/${workflowTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplateByIdQueryKey = (workflowTemplateId: number,) => {
    return [`/api/workflow/workflow-templates/${workflowTemplateId}`] as const;
    }

    
export const getGetWorkflowTemplateByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError = unknown>(workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplateByIdQueryKey(workflowTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplateById>>> = ({ signal }) => getWorkflowTemplateById(workflowTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplateById>>>
export type GetWorkflowTemplateByIdQueryError = unknown



export function useGetWorkflowTemplateById<TData = Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError = unknown>(
 workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplateByIdQueryOptions(workflowTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkflowTemplate = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-templates/${workflowTemplateId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkflowTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, {workflowTemplateId: number}> = (props) => {
          const {workflowTemplateId} = props ?? {};

          return  deleteWorkflowTemplate(workflowTemplateId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkflowTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowTemplate>>>
    
    export type DeleteWorkflowTemplateMutationError = unknown

    export const useDeleteWorkflowTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkflowTemplate>>,
        TError,
        {workflowTemplateId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkflowTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowTemplateByKey = (
    workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse>(
      {url: `/api/workflow/workflow-templates/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetWorkflowTemplateByKeyQueryKey = (workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams,) => {
    return [`/api/workflow/workflow-templates/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWorkflowTemplateByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError = unknown>(workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplateByKeyQueryKey(workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>> = ({ signal }) => getWorkflowTemplateByKey(workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplateByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>>
export type GetWorkflowTemplateByKeyQueryError = unknown



export function useGetWorkflowTemplateByKey<TData = Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError = unknown>(
 workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplateByKeyQueryOptions(workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowTemplatesByType = (
    workflowDefinitionType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse[]>(
      {url: `/api/workflow/workflow-templates/type/${workflowDefinitionType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplatesByTypeQueryKey = (workflowDefinitionType: string,) => {
    return [`/api/workflow/workflow-templates/type/${workflowDefinitionType}`] as const;
    }

    
export const getGetWorkflowTemplatesByTypeQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError = unknown>(workflowDefinitionType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplatesByTypeQueryKey(workflowDefinitionType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>> = ({ signal }) => getWorkflowTemplatesByType(workflowDefinitionType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowDefinitionType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplatesByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>>
export type GetWorkflowTemplatesByTypeQueryError = unknown



export function useGetWorkflowTemplatesByType<TData = Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError = unknown>(
 workflowDefinitionType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplatesByTypeQueryOptions(workflowDefinitionType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get workflow instance by ID
 */
export const getWorkflowInstanceById = (
    workflowInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowInstanceResponse>(
      {url: `/api/workflow/workflow-instances/${workflowInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowInstanceByIdQueryKey = (workflowInstanceId: number,) => {
    return [`/api/workflow/workflow-instances/${workflowInstanceId}`] as const;
    }

    
export const getGetWorkflowInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError = unknown>(workflowInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowInstanceByIdQueryKey(workflowInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowInstanceById>>> = ({ signal }) => getWorkflowInstanceById(workflowInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowInstanceById>>>
export type GetWorkflowInstanceByIdQueryError = unknown


/**
 * @summary Get workflow instance by ID
 */

export function useGetWorkflowInstanceById<TData = Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError = unknown>(
 workflowInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowInstanceByIdQueryOptions(workflowInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionById = (
    workflowDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse>(
      {url: `/api/workflow/workflow-definitions/${workflowDefinitionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionByIdQueryKey = (workflowDefinitionId: number,) => {
    return [`/api/workflow/workflow-definitions/${workflowDefinitionId}`] as const;
    }

    
export const getGetWorkflowDefinitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError = unknown>(workflowDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionByIdQueryKey(workflowDefinitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionById>>> = ({ signal }) => getWorkflowDefinitionById(workflowDefinitionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowDefinitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionById>>>
export type GetWorkflowDefinitionByIdQueryError = unknown



export function useGetWorkflowDefinitionById<TData = Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError = unknown>(
 workflowDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionByIdQueryOptions(workflowDefinitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkflowDefinition = (
    workflowDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-definitions/${workflowDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkflowDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, {workflowDefinitionId: number}> = (props) => {
          const {workflowDefinitionId} = props ?? {};

          return  deleteWorkflowDefinition(workflowDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkflowDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowDefinition>>>
    
    export type DeleteWorkflowDefinitionMutationError = unknown

    export const useDeleteWorkflowDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkflowDefinition>>,
        TError,
        {workflowDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkflowDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowDefinitionsByTemplateId = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/templates/${workflowTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByTemplateIdQueryKey = (workflowTemplateId: number,) => {
    return [`/api/workflow/workflow-definitions/templates/${workflowTemplateId}`] as const;
    }

    
export const getGetWorkflowDefinitionsByTemplateIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError = unknown>(workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByTemplateIdQueryKey(workflowTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>> = ({ signal }) => getWorkflowDefinitionsByTemplateId(workflowTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByTemplateIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>>
export type GetWorkflowDefinitionsByTemplateIdQueryError = unknown



export function useGetWorkflowDefinitionsByTemplateId<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError = unknown>(
 workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByTemplateIdQueryOptions(workflowTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}`] as const;
    }

    
export const getGetWorkflowDefinitionsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>> = ({ signal }) => getWorkflowDefinitionsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>>
export type GetWorkflowDefinitionsByOrganizationQueryError = unknown



export function useGetWorkflowDefinitionsByOrganization<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionByKey = (
    organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionByKeyQueryKey = (organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWorkflowDefinitionByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError = unknown>(organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionByKeyQueryKey(organizationId,workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>> = ({ signal }) => getWorkflowDefinitionByKey(organizationId,workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>>
export type GetWorkflowDefinitionByKeyQueryError = unknown



export function useGetWorkflowDefinitionByKey<TData = Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError = unknown>(
 organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionByKeyQueryOptions(organizationId,workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionsByOrganizationAndType = (
    organizationId: number,
    workflowType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}/types/${workflowType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey = (organizationId: number,
    workflowType: string,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}/types/${workflowType}`] as const;
    }

    
export const getGetWorkflowDefinitionsByOrganizationAndTypeQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError = unknown>(organizationId: number,
    workflowType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey(organizationId,workflowType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>> = ({ signal }) => getWorkflowDefinitionsByOrganizationAndType(organizationId,workflowType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByOrganizationAndTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>>
export type GetWorkflowDefinitionsByOrganizationAndTypeQueryError = unknown



export function useGetWorkflowDefinitionsByOrganizationAndType<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError = unknown>(
 organizationId: number,
    workflowType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByOrganizationAndTypeQueryOptions(organizationId,workflowType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDistinctWorkflowCategories = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<string[]>(
      {url: `/api/workflow/workflow-categories/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDistinctWorkflowCategoriesQueryKey = (organizationId: number,) => {
    return [`/api/workflow/workflow-categories/organizations/${organizationId}`] as const;
    }

    
export const getGetDistinctWorkflowCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDistinctWorkflowCategoriesQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>> = ({ signal }) => getDistinctWorkflowCategories(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDistinctWorkflowCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>>
export type GetDistinctWorkflowCategoriesQueryError = unknown



export function useGetDistinctWorkflowCategories<TData = Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDistinctWorkflowCategoriesQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getStepTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<StepTypeDTO[]>(
      {url: `/api/workflow/step-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStepTypesQueryKey = () => {
    return [`/api/workflow/step-types`] as const;
    }

    
export const getGetStepTypesQueryOptions = <TData = Awaited<ReturnType<typeof getStepTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStepTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStepTypes>>> = ({ signal }) => getStepTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStepTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getStepTypes>>>
export type GetStepTypesQueryError = unknown



export function useGetStepTypes<TData = Awaited<ReturnType<typeof getStepTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStepTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEventTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventTypeDTO[]>(
      {url: `/api/workflow/event-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventTypesQueryKey = () => {
    return [`/api/workflow/event-types`] as const;
    }

    
export const getGetEventTypesQueryOptions = <TData = Awaited<ReturnType<typeof getEventTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypes>>> = ({ signal }) => getEventTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypes>>>
export type GetEventTypesQueryError = unknown



export function useGetEventTypes<TData = Awaited<ReturnType<typeof getEventTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDataTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<DataTypeDTO[]>(
      {url: `/api/workflow/data-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataTypesQueryKey = () => {
    return [`/api/workflow/data-types`] as const;
    }

    
export const getGetDataTypesQueryOptions = <TData = Awaited<ReturnType<typeof getDataTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataTypes>>> = ({ signal }) => getDataTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDataTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getDataTypes>>>
export type GetDataTypesQueryError = unknown



export function useGetDataTypes<TData = Awaited<ReturnType<typeof getDataTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDataTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




