import { useCallback, useEffect } from 'react';
import { useRoleManager } from '../providers/RoleManagerProvider';
import { RoleNode } from '../types/roleNode';
import { responseToNode, validateNode } from '../utils/roleUtils';
import { useUserRoles } from './useUserRoles';

export const useRoleAdmin = () => {
  const { state, setState } = useRoleManager();
  const { userRoles, isLoading, error } = useUserRoles();

  // Initialize/update roles when userRoles changes
  useEffect(() => {
    if (userRoles) {
      const nodes = userRoles.map(responseToNode);
      const rolesMap = new Map(nodes.map((node) => [node.id, node]));

      setState((prev) => ({
        ...prev,
        roles: nodes,
        rolesMap,
        loading: isLoading,
        error:
          error instanceof Error
            ? error
            : error
            ? new Error(String(error))
            : null,
      }));
    }
  }, [userRoles, isLoading, error, setState]);

  const setSelectedRoleId = useCallback(
    (id: number | null) => {
      setState((prev) => ({ ...prev, selectedRoleId: id }));
    },
    [setState]
  );

  const updateRole = useCallback(
    (roleId: number, updates: Partial<RoleNode>) => {
      setState((prev) => {
        const role = prev.rolesMap.get(roleId);
        if (!role) return prev;

        const updatedRole = { ...role, ...updates };

        // Validate the updated role
        const errors = validateNode(updatedRole, prev.roles);
        if (errors.length > 0) {
          console.error('Role validation failed:', errors);
          return prev;
        }

        const updatedRoles = prev.roles.map((r) =>
          r.id === roleId ? updatedRole : r
        );

        const newMap = new Map(updatedRoles.map((r) => [r.id, r]));

        return {
          ...prev,
          rolesMap: newMap,
          roles: updatedRoles,
        };
      });
    },
    [setState]
  );

  const deleteRole = useCallback(
    (roleId: number) => {
      setState((prev) => {
        // Get all descendant roles to delete
        const rolesToDelete = new Set<number>([roleId]);
        let searchIds = [roleId];

        while (searchIds.length > 0) {
          const parentId = searchIds.pop()!;
          prev.roles.forEach((role) => {
            if (role.parentId === parentId) {
              rolesToDelete.add(role.id);
              searchIds.push(role.id);
            }
          });
        }

        const updatedRoles = prev.roles.filter((r) => !rolesToDelete.has(r.id));
        const newMap = new Map(updatedRoles.map((r) => [r.id, r]));

        return {
          ...prev,
          rolesMap: newMap,
          roles: updatedRoles,
          selectedRoleId:
            prev.selectedRoleId === roleId ? null : prev.selectedRoleId,
        };
      });
    },
    [setState]
  );

  const addRole = useCallback(
    (role: Partial<RoleNode>): RoleNode => {
      const newRole: RoleNode = {
        id: -Math.floor(Math.random() * 1000000),
        name: '',
        slug: '',
        description: '',
        systemRole: false,
        canCascade: false,
        userRoleGroup: false,
        organizationId: 0,
        parentId: null,
        level: 0,
        path: '',
        ...role,
      };

      // Validate the new role
      setState((prev) => {
        const errors = validateNode(newRole, prev.roles, true);
        if (errors.length > 0) {
          console.error('Role validation failed:', errors);
          return prev;
        }

        const updatedRoles = [...prev.roles, newRole];
        const newMap = new Map(updatedRoles.map((r) => [r.id, r]));

        return {
          ...prev,
          rolesMap: newMap,
          roles: updatedRoles,
        };
      });

      return newRole;
    },
    [setState]
  );

  const reorganizeRole = useCallback(
    (roleId: number, newParentId: number | null) => {
      setState((prev) => {
        const role = prev.rolesMap.get(roleId);
        if (!role) return prev;

        // Check if the new parent would create a circular reference
        let currentParent = newParentId;
        while (currentParent !== null) {
          if (currentParent === roleId) {
            console.error('Cannot move role: would create circular reference');
            return prev;
          }
          const parent = prev.rolesMap.get(currentParent);
          currentParent = parent?.parentId ?? null;
        }

        // Update the role with new parent and correct level
        const newLevel =
          newParentId !== null
            ? (prev.rolesMap.get(newParentId)?.level ?? 0) + 1
            : 0;

        const updatedRole = {
          ...role,
          parentId: newParentId,
          level: newLevel,
        };

        const updatedRoles = prev.roles.map((r) =>
          r.id === roleId ? updatedRole : r
        );

        const newMap = new Map(updatedRoles.map((r) => [r.id, r]));

        return {
          ...prev,
          rolesMap: newMap,
          roles: updatedRoles,
        };
      });
    },
    [setState]
  );

  const getRoleById = useCallback(
    (id: number) => {
      return state.rolesMap.get(id);
    },
    [state.rolesMap]
  );

  const getChildRoles = useCallback(
    (parentId: number | null) => {
      return state.roles
        .filter((role) => role.parentId === parentId)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    [state.roles]
  );

  const getRootRoles = useCallback(() => {
    return getChildRoles(null);
  }, [getChildRoles]);

  return {
    // State
    roles: state.roles,
    rolesMap: state.rolesMap,
    loading: state.loading,
    error: state.error,
    selectedRoleId: state.selectedRoleId,

    // Actions
    setSelectedRoleId,
    updateRole,
    deleteRole,
    addRole,
    reorganizeRole,

    // Queries
    getRoleById,
    getChildRoles,
    getRootRoles,
  };
};
