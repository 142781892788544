import { ExternalCourseTemplateRequest, ExternalCourseTemplateResponse, useGetProviders } from '@btrway/api-external-provider';
import { Badge, Button, Card, Group, Select, Spoiler, Stack, Text, TextInput, Textarea, UnstyledButton } from '@mantine/core';
import { IconExternalLink, IconEdit, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';

interface ExternalCourseCardProps {
  externalCourse: ExternalCourseTemplateResponse;
  isNew?: boolean;
  onSave?: (updatedCourse: ExternalCourseTemplateRequest) => void;
}

const ExternalCourseCard: React.FC<ExternalCourseCardProps> = ({
  externalCourse,
  isNew,
  onSave,
}) => {
  const { courseConfiguration, provider } = externalCourse;
  const [isEditing, setIsEditing] = useState(isNew);
  const [editedValues, setEditedValues] = useState( {
    title: courseConfiguration?.title || '',
    description: courseConfiguration?.description || '',
    url: courseConfiguration?.url || '',
    providerId: provider.id,
  });
  const {data: providers} = useGetProviders();

  console.log(editedValues.providerId);

  const handleSave = () => {
    if (onSave) {
      const updatedCourse: ExternalCourseTemplateRequest = {
        ...externalCourse,
        courseConfiguration: {
          ...courseConfiguration,
          title: editedValues?.title,
          description: editedValues?.description,
          url: editedValues.url
        },
        providerId: editedValues.providerId
      };
      onSave(updatedCourse);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedValues({
      title: courseConfiguration?.title || '',
      description: courseConfiguration?.description || '',
      url: courseConfiguration?.url || '',
      providerId: provider.id
    });
    setIsEditing(false);
  };

  const providerSelectData = providers?.map(provider => ({
    value: provider.id.toString(),
    label: provider.name
  })) ?? [];

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack gap="xs">
        <Group justify="space-between" align="center" mb={4}>
          {/* Title and Provider section */}
          <Group align="center" gap="sm">
            {isEditing ? (
              <TextInput
                value={editedValues.title}
                onChange={(e) =>
                  setEditedValues({ ...editedValues, title: e.target.value })
                }
                placeholder="Course Title"
                size="lg"
                style={{ flex: 1, width: 400 }}
              />
            ) : (
              <Text fw={500} size="lg">
                {courseConfiguration?.title || 'Untitled Course'}
              </Text>
            )}
            {isEditing ? (
              <Select
                data={providerSelectData}
                value={editedValues.providerId.toString()}
                onChange={(e) => {
                  const providerId = Number(e);
                  setEditedValues({...editedValues, providerId: providerId})
                  }
                }
                size="sm"
              />
            ) : (
              <Badge size="sm" variant="light" color="blue">
                {provider.name}
              </Badge>
            )}
          </Group>
          
          {/* Edit/Save/Cancel buttons */}
          {isEditing ? (
            <Group>
              <Button
                variant="light"
                color="green"
                size="xs"
                onClick={handleSave}
                leftSection={<IconCheck size="1rem" />}
              >
                Save
              </Button>
              <Button
                variant="light"
                color="red"
                size="xs"
                onClick={handleCancel}
                leftSection={<IconX size="1rem" />}
              >
                Cancel
              </Button>
            </Group>
          ) : (
            <Button
              variant="light"
              size="xs"
              onClick={() => setIsEditing(true)}
              leftSection={<IconEdit size="1rem" />}
            >
              Edit
            </Button>
          )}
        </Group>

        {/* Visit Course button */}
        {!isEditing && courseConfiguration?.url && (
          <UnstyledButton
            component="a"
            href={courseConfiguration.url}
            // target="_blank"
            variant="light"
            size="xs"
            // rightSection={<IconExternalLink size="1rem" />}
            style={{ alignSelf: 'flex-start' }}
          >
            {courseConfiguration.url}
          </UnstyledButton>
        )}

        {/* URL and Description fields */}
        {isEditing ? (
          <Stack gap="xs">
            <TextInput
              value={editedValues.url}
              onChange={(e) =>
                setEditedValues({ ...editedValues, url: e.target.value })
              }
              placeholder="Course URL"
              size="sm"
            />
            <Textarea
              value={editedValues.description}
              onChange={(e) =>
                setEditedValues({ ...editedValues, description: e.target.value })
              }
              placeholder="Course Description"
              size="sm"
              autosize
              minRows={2}
            />
          </Stack>
        ) : (
          courseConfiguration?.description && (
            <Spoiler hideLabel={'Less'} showLabel={'More'} maxHeight={25}>
              {courseConfiguration.description}
            </Spoiler>
          )
        )}
      </Stack>
    </Card>
  );
};

export default ExternalCourseCard;