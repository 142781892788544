import { TaskConfig, TaskTypeEnum } from '@btrway/api-tasklist';
import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { getTypedTaskProperties } from '@btrway/task-list-config-provider';
import { uuid } from '@btrway/utils';
import { useWorkflowService } from '@btrway/workflow-service-provider';
import { Box, ScrollArea, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useMemo, useState } from 'react';
import { EditorSearchInput } from '../EditorSearchInput/EditorSearchInput';
import { EditorSwitchCard } from '../EditorSwitchCard/EditorSwitchCard';

interface FormPacketFormPickerProps {
  selectedTasks: TaskConfig[];
  onSelectedTasksChange: (tasks: TaskConfig[]) => void;
}

export const FormPacketFormPicker: React.FC<FormPacketFormPickerProps> = ({
  selectedTasks,
  onSelectedTasksChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const workflowService = useWorkflowService();
  const { workflows, isLoading } = workflowService.getWorkflows();

  const formWorkflows = useMemo(() => {
    return (workflows || [])
      .filter((w) => w.workflowType === WorkflowTypeEnum.form)
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflows]);

  const createTaskConfig = (
    workflow: (typeof formWorkflows)[0]
  ): TaskConfig => ({
    taskKey: uuid(),
    taskType: TaskTypeEnum.submitForm,
    metadata: {
      title: workflow.name || '',
    },
    taskProperties: {
      workflowSelection: {
        workflowDefinitionKey: {
          value:
            workflow.sourceType === 'definition'
              ? workflow.workflowKey!
              : workflow.templateKey!,
        },
      },
    },
  });

  const filteredWorkflows = useMemo(() => {
    const searchTermLower = debouncedSearchTerm.toLowerCase();
    return formWorkflows.filter((workflow) =>
      workflow.name?.toLowerCase().includes(searchTermLower)
    );
  }, [formWorkflows, debouncedSearchTerm]);

  const handleToggle = (taskConfig: TaskConfig) => {
    const taskExists = selectedTasks.find(
      (task) => task.taskKey === taskConfig.taskKey
    );

    if (taskExists) {
      onSelectedTasksChange(
        selectedTasks.filter((task) => task.taskKey !== taskConfig.taskKey)
      );
    } else {
      onSelectedTasksChange([...selectedTasks, taskConfig]);
    }
  };

  if (isLoading) {
    return <Text>Loading forms...</Text>;
  }

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ScrollArea style={{ flex: 1 }}>
        <Stack p="md">
          <Text fz="lg" fw={600}>
            Select Forms
          </Text>
          <EditorSearchInput
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Stack gap="xs">
            {filteredWorkflows.map((workflow) => {
              const workflowKey =
                workflow.sourceType === 'definition'
                  ? workflow.workflowKey!
                  : workflow.templateKey!;

              const isSelected = selectedTasks.some((task) => {
                const selectedTaskProperties = getTypedTaskProperties(
                  task,
                  TaskTypeEnum.submitForm
                );
                return (
                  selectedTaskProperties?.workflowSelection
                    .workflowDefinitionKey.value === workflowKey
                );
              });

              // Find existing task config if it exists
              const existingTask = selectedTasks.find((task) => {
                const props = getTypedTaskProperties(
                  task,
                  TaskTypeEnum.submitForm
                );
                return (
                  props?.workflowSelection.workflowDefinitionKey.value ===
                  workflowKey
                );
              });

              return (
                <EditorSwitchCard
                  key={workflowKey}
                  item={{
                    id: workflowKey,
                    name: workflow.name || '',
                  }}
                  isSelected={isSelected}
                  onToggle={() =>
                    handleToggle(existingTask || createTaskConfig(workflow))
                  }
                />
              );
            })}
          </Stack>
        </Stack>
      </ScrollArea>
    </Box>
  );
};

export default FormPacketFormPicker;
