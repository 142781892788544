/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AssignedTaskResponse,
  SubmitFormTaskDTO,
  SubmitReviewTaskDTO,
  TaskAssignmentRequest,
  TaskAssignmentResponse,
  TaskInstanceRequest,
  TaskInstanceResponse,
  TaskListInstanceRequest,
  TaskListInstanceResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveTaskListInstance = (
    taskListInstanceRequest: TaskListInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/task-list-instances/save`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskListInstanceRequest
    },
      options);
    }
  


export const getSaveTaskListInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListInstance>>, TError,{data: TaskListInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskListInstance>>, TError,{data: TaskListInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskListInstance>>, {data: TaskListInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskListInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskListInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskListInstance>>>
    export type SaveTaskListInstanceMutationBody = TaskListInstanceRequest
    export type SaveTaskListInstanceMutationError = unknown

    export const useSaveTaskListInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskListInstance>>, TError,{data: TaskListInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskListInstance>>,
        TError,
        {data: TaskListInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskListInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveTaskInstance = (
    taskInstanceRequest: TaskInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/task-instances/save`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskInstanceRequest
    },
      options);
    }
  


export const getSaveTaskInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskInstance>>, {data: TaskInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskInstance>>>
    export type SaveTaskInstanceMutationBody = TaskInstanceRequest
    export type SaveTaskInstanceMutationError = unknown

    export const useSaveTaskInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskInstance>>,
        TError,
        {data: TaskInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const completeTaskInstance = (
    taskInstanceRequest: TaskInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/task-completion/complete`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskInstanceRequest
    },
      options);
    }
  


export const getCompleteTaskInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof completeTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeTaskInstance>>, {data: TaskInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  completeTaskInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CompleteTaskInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof completeTaskInstance>>>
    export type CompleteTaskInstanceMutationBody = TaskInstanceRequest
    export type CompleteTaskInstanceMutationError = unknown

    export const useCompleteTaskInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof completeTaskInstance>>,
        TError,
        {data: TaskInstanceRequest},
        TContext
      > => {

      const mutationOptions = getCompleteTaskInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const submitReviewTask = (
    submitReviewTaskDTO: SubmitReviewTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/form-submission/submitReviewTask`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitReviewTaskDTO
    },
      options);
    }
  


export const getSubmitReviewTaskMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitReviewTask>>, {data: SubmitReviewTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  submitReviewTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitReviewTaskMutationResult = NonNullable<Awaited<ReturnType<typeof submitReviewTask>>>
    export type SubmitReviewTaskMutationBody = SubmitReviewTaskDTO
    export type SubmitReviewTaskMutationError = unknown

    export const useSubmitReviewTask = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitReviewTask>>,
        TError,
        {data: SubmitReviewTaskDTO},
        TContext
      > => {

      const mutationOptions = getSubmitReviewTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const submitFormTask = (
    submitFormTaskDTO: SubmitFormTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/form-submission/submitFormTask`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitFormTaskDTO
    },
      options);
    }
  


export const getSubmitFormTaskMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitFormTask>>, {data: SubmitFormTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  submitFormTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitFormTaskMutationResult = NonNullable<Awaited<ReturnType<typeof submitFormTask>>>
    export type SubmitFormTaskMutationBody = SubmitFormTaskDTO
    export type SubmitFormTaskMutationError = unknown

    export const useSubmitFormTask = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitFormTask>>,
        TError,
        {data: SubmitFormTaskDTO},
        TContext
      > => {

      const mutationOptions = getSubmitFormTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const startWorkflow = (
    submitFormTaskDTO: SubmitFormTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/task/form-submission/start`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitFormTaskDTO
    },
      options);
    }
  


export const getStartWorkflowMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startWorkflow>>, {data: SubmitFormTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  startWorkflow(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StartWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof startWorkflow>>>
    export type StartWorkflowMutationBody = SubmitFormTaskDTO
    export type StartWorkflowMutationError = unknown

    export const useStartWorkflow = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof startWorkflow>>,
        TError,
        {data: SubmitFormTaskDTO},
        TContext
      > => {

      const mutationOptions = getStartWorkflowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssignedTasks = (
    taskAssignmentRequest: TaskAssignmentRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TaskAssignmentResponse>(
      {url: `/api/task/assignments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskAssignmentRequest
    },
      options);
    }
  


export const getGetAssignedTasksMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasks>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasks>>, TError,{data: TaskAssignmentRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getAssignedTasks>>, {data: TaskAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  getAssignedTasks(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetAssignedTasksMutationResult = NonNullable<Awaited<ReturnType<typeof getAssignedTasks>>>
    export type GetAssignedTasksMutationBody = TaskAssignmentRequest
    export type GetAssignedTasksMutationError = unknown

    export const useGetAssignedTasks = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasks>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getAssignedTasks>>,
        TError,
        {data: TaskAssignmentRequest},
        TContext
      > => {

      const mutationOptions = getGetAssignedTasksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssignedTasksOld = (
    taskAssignmentRequest: TaskAssignmentRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<AssignedTaskResponse>(
      {url: `/api/task/assigned`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskAssignmentRequest
    },
      options);
    }
  


export const getGetAssignedTasksOldMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasksOld>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasksOld>>, TError,{data: TaskAssignmentRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getAssignedTasksOld>>, {data: TaskAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  getAssignedTasksOld(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetAssignedTasksOldMutationResult = NonNullable<Awaited<ReturnType<typeof getAssignedTasksOld>>>
    export type GetAssignedTasksOldMutationBody = TaskAssignmentRequest
    export type GetAssignedTasksOldMutationError = unknown

    export const useGetAssignedTasksOld = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssignedTasksOld>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getAssignedTasksOld>>,
        TError,
        {data: TaskAssignmentRequest},
        TContext
      > => {

      const mutationOptions = getGetAssignedTasksOldMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPersonApprovalTasks = (
    personId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskInstanceResponse[]>(
      {url: `/api/workflow/approval-tasks/person/${personId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPersonApprovalTasksQueryKey = (personId: number,) => {
    return [`/api/workflow/approval-tasks/person/${personId}`] as const;
    }

    
export const getGetPersonApprovalTasksQueryOptions = <TData = Awaited<ReturnType<typeof getPersonApprovalTasks>>, TError = unknown>(personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonApprovalTasks>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonApprovalTasksQueryKey(personId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonApprovalTasks>>> = ({ signal }) => getPersonApprovalTasks(personId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(personId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersonApprovalTasks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonApprovalTasksQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonApprovalTasks>>>
export type GetPersonApprovalTasksQueryError = unknown



export function useGetPersonApprovalTasks<TData = Awaited<ReturnType<typeof getPersonApprovalTasks>>, TError = unknown>(
 personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonApprovalTasks>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPersonApprovalTasksQueryOptions(personId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskListInstanceById = (
    taskListInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskListInstanceResponse>(
      {url: `/api/task/task-list-instances/${taskListInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskListInstanceByIdQueryKey = (taskListInstanceId: number,) => {
    return [`/api/task/task-list-instances/${taskListInstanceId}`] as const;
    }

    
export const getGetTaskListInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskListInstanceById>>, TError = unknown>(taskListInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskListInstanceByIdQueryKey(taskListInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskListInstanceById>>> = ({ signal }) => getTaskListInstanceById(taskListInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskListInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskListInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskListInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskListInstanceById>>>
export type GetTaskListInstanceByIdQueryError = unknown



export function useGetTaskListInstanceById<TData = Awaited<ReturnType<typeof getTaskListInstanceById>>, TError = unknown>(
 taskListInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskListInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskListInstanceByIdQueryOptions(taskListInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskListInstance = (
    taskListInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/task/task-list-instances/${taskListInstanceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskListInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListInstance>>, TError,{taskListInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListInstance>>, TError,{taskListInstanceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskListInstance>>, {taskListInstanceId: number}> = (props) => {
          const {taskListInstanceId} = props ?? {};

          return  deleteTaskListInstance(taskListInstanceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskListInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskListInstance>>>
    
    export type DeleteTaskListInstanceMutationError = unknown

    export const useDeleteTaskListInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskListInstance>>, TError,{taskListInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskListInstance>>,
        TError,
        {taskListInstanceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskListInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskInstanceById = (
    taskInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskInstanceResponse>(
      {url: `/api/task/task-instances/${taskInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskInstanceByIdQueryKey = (taskInstanceId: number,) => {
    return [`/api/task/task-instances/${taskInstanceId}`] as const;
    }

    
export const getGetTaskInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskInstanceById>>, TError = unknown>(taskInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskInstanceByIdQueryKey(taskInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskInstanceById>>> = ({ signal }) => getTaskInstanceById(taskInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskInstanceById>>>
export type GetTaskInstanceByIdQueryError = unknown



export function useGetTaskInstanceById<TData = Awaited<ReturnType<typeof getTaskInstanceById>>, TError = unknown>(
 taskInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskInstanceByIdQueryOptions(taskInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskInstance = (
    taskInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/task/task-instances/${taskInstanceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskInstance>>, {taskInstanceId: number}> = (props) => {
          const {taskInstanceId} = props ?? {};

          return  deleteTaskInstance(taskInstanceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskInstance>>>
    
    export type DeleteTaskInstanceMutationError = unknown

    export const useDeleteTaskInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskInstance>>,
        TError,
        {taskInstanceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
