import {
  TaskListTypeEnum,
  TaskStatisticsGroupByEnum,
  TaskStatisticsRequest,
  TaskStatisticsResponse,
  useGetTaskStatistics,
} from '@btrway/api-taskstats';
import { useEffect, useState } from 'react';
import {
  EnrichedTaskListStats,
  EnrichedWorkgroupStats,
  TaskListTypeTaskStatisticsResponse,
} from '../types/stats';

interface UseFetchWorkgroupStatsResult {
  data: EnrichedWorkgroupStats[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const calculateTaskListTypeStats = (
  taskLists: TaskStatisticsResponse[],
  taskListType: TaskListTypeEnum
): TaskListTypeTaskStatisticsResponse => {
  const filteredTaskLists = taskLists.filter(
    (w) => w.taskListType === taskListType
  );

  return {
    completedTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.completedTasks || 0),
      0
    ),
    completedTaskLists: filteredTaskLists.reduce(
      (sum, w) => sum + (w.completedTaskLists || 0),
      0
    ),
    overdueCompletedTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.overdueCompletedTasks || 0),
      0
    ),
    totalTaskLists: filteredTaskLists.reduce(
      (sum, w) => sum + (w.totalTaskLists || 0),
      0
    ),
    totalTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.totalTasks || 0),
      0
    ),
    taskListType,
  };
};

const calculateTaskListProgress = (
  completedTaskLists: number = 0,
  totalTaskLists: number = 0
): number => {
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

const calculateTaskProgress = (
  completedTasks: number = 0,
  totalTasks: number = 0
): number => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};
const transformToEnrichedTaskList = (
  taskList: TaskStatisticsResponse
): EnrichedTaskListStats => {
  const completedTasks = taskList.completedTasks || 0;
  const totalTasks = taskList.totalTasks || 0;
  const completedTaskLists = taskList.completedTaskLists || 0;
  const totalTaskLists = taskList.totalTaskLists || 0;

  return {
    ...taskList, // Make sure we spread all properties first
    taskListKey: taskList.taskListKey,
    taskListName: taskList.taskListName,
    taskListType: taskList.taskListType,
    taskListClassification: taskList.taskListClassification,
    averageOverdueDays: taskList.averageOverdueDays,
    completedTaskLists,
    completedTasks,
    overdueCompletedTasks: taskList.overdueCompletedTasks,
    totalTaskLists,
    totalTasks,
    progressPercentage: calculateTaskProgress(completedTasks, totalTasks),
    taskListProgressPercentage: calculateTaskListProgress(
      completedTaskLists,
      totalTaskLists
    ),
    remainingTasks: totalTasks - completedTasks,
    remainingTaskLists: totalTaskLists - completedTaskLists,
    isOverdue: (taskList.averageOverdueDays || 0) > 0,
  };
};

const transformToEnrichedWorkgroup = (
  workgroupId: number,
  workgroupName: string,
  taskLists: TaskStatisticsResponse[]
): EnrichedWorkgroupStats => {
  // When mapping taskLists, ensure we preserve all properties including taskListType
  const enrichedTaskLists = taskLists.map((taskList) => ({
    ...transformToEnrichedTaskList(taskList),
    taskListType: taskList.taskListType, // Explicitly ensure taskListType is preserved
  }));

  const taskListTypes = [
    ...new Set(taskLists.map((w) => w.taskListType).filter(Boolean)),
  ] as TaskListTypeEnum[];

  const totalRemaining = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.remainingTasks,
    0
  );

  const totalRemainingTaskLists = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.remainingTaskLists,
    0
  );

  const totalTaskProgress = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.progressPercentage,
    0
  );

  const totalTaskListProgress = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.taskListProgressPercentage,
    0
  );

  const overdueCount = enrichedTaskLists.filter(
    (taskList) => taskList.isOverdue
  ).length;

  return {
    workgroupId,
    workgroupName,
    taskLists: enrichedTaskLists,
    taskListStatistics: enrichedTaskLists,
    taskListTypeStats: taskListTypes.map((type) =>
      calculateTaskListTypeStats(taskLists, type)
    ),
    totalRemainingTasks: totalRemaining,
    totalRemainingTaskLists: totalRemainingTaskLists,
    averageTaskProgress:
      enrichedTaskLists.length > 0
        ? totalTaskProgress / enrichedTaskLists.length
        : 0,
    averageTaskListProgress:
      enrichedTaskLists.length > 0
        ? totalTaskListProgress / enrichedTaskLists.length
        : 0,
    overdueTaskListsCount: overdueCount,
  };
};

export const useFetchWorkgroupStats = (
  request: TaskStatisticsRequest
): UseFetchWorkgroupStatsResult => {
  const [data, setData] = useState<EnrichedWorkgroupStats[] | undefined>(
    undefined
  );

  const {
    mutateAsync: getTaskStatistics,
    isPending: isLoading,
    isError,
    error: queryError,
  } = useGetTaskStatistics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const workgroupRequest: TaskStatisticsRequest = {
          ...request,
          groupBy: TaskStatisticsGroupByEnum.workgroup,
        };

        const response = await getTaskStatistics({ data: workgroupRequest });
        const statistics = Array.isArray(response) ? response : [response];

        // Group by workgroup
        const workgroupMap = new Map<number, TaskStatisticsResponse[]>();
        statistics.forEach((stat) => {
          if (!stat.workgroupId) return;
          const existing = workgroupMap.get(stat.workgroupId) || [];
          workgroupMap.set(stat.workgroupId, [...existing, stat]);
        });

        // Transform to enriched workgroups
        const enrichedStats = Array.from(workgroupMap.entries()).map(
          ([workgroupId, taskLists]) =>
            transformToEnrichedWorkgroup(
              workgroupId,
              taskLists[0].workgroupName || '',
              taskLists
            )
        );

        setData(enrichedStats);
      } catch (err) {
        console.error('Error fetching workgroup stats:', err);
        setData(undefined);
      }
    };

    fetchData();
  }, [getTaskStatistics, request]);

  const refetch = async () => {
    await getTaskStatistics({ data: request });
  };

  return {
    data,
    isLoading,
    isError,
    error: queryError instanceof Error ? queryError : null,
    refetch,
  };
};
