import { PermissionNode } from '@btrway/permission-manager';

export const sortNodes = (nodes: PermissionNode[]): PermissionNode[] => {
  return [...nodes].sort((a, b) => {
    // First sort by index
    if (a.index !== b.index) {
      return a.index - b.index;
    }
    // If indexes are equal, sort by name
    return a.name.localeCompare(b.name);
  });
};
