import { HelpRepositoryEnum } from '@btrway/api-help';
import {
  CoursePage,
  CoursesPage,
  VideosPage,
} from '@btrway/courseware-administration-components';
import { ExternalCoursesPage } from '@btrway/external-provider-administration';
import { GlobalUserManagementPage } from '@btrway/global-users-components';
import { TagsView } from '@btrway/help-admin-components';
import { InternalDashboardPage } from '@btrway/internal-dashboard';
import { TopNavigationLayout } from '@btrway/layout-components';
import {
  OrganizationEntitiesView,
  OrganizationHeader,
  OrganizationPermissionsPage,
  OrganizationProvider,
  OrganizationsView,
  OrganizationTemplatesView,
  WorkgroupTypesView,
} from '@btrway/organization-admin-components';
import { PermissionsAdminPage } from '@btrway/system-security-components';
import { TaskCategoriesView } from '@btrway/task-list-administration';
import { RouteConfig } from '@btrway/web-routing';
import {
  IconBuilding,
  IconExternalLink,
  IconHelp,
  IconHome,
  IconLock,
  IconMail,
  IconSettings,
  IconStack3,
  IconUsers,
} from '@tabler/icons-react';
import { lazy } from 'react';
import { templateAdminRoutes } from './templateAdminRoutes';

//TODO: Change imports to lazy

// Lazy loaded workflow components
// const WorkflowTemplatesPage = lazy(() =>
//   import('@btrway/workflow-template-components').then((module) => ({
//     default: module.WorkflowTemplatesPage,
//   }))
// );

// Lazy loaded help components
const HelpResourcesPage = lazy(() =>
  import('@btrway/help-admin-components').then((module) => ({
    default: module.HelpResourcesPage,
  }))
);

const HelpResourcePage = lazy(() =>
  import('@btrway/help-admin-components').then((module) => ({
    default: module.HelpResourcePage,
  }))
);

const EmailTemplatesPage = lazy(() =>
  import('@btrway/internal-email-components').then((module) => ({
    default: module.EmailTemplatesPage,
  }))
);

export const appShellRoutes: RouteConfig[] = [
  {
    path: 'dashboard',
    element: <InternalDashboardPage />,
    label: 'Dashboard',
    icon: IconHome,
    navLevel: 'primary',
    navContainer: 'first',
  },
  {
    path: 'organizations',
    element: <TopNavigationLayout />,
    label: 'Organizations',
    icon: IconBuilding,
    navLevel: 'primary',
    navContainer: 'first',
    children: [
      {
        path: 'orgs',
        element: <OrganizationsView />,
        label: 'Organizations',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'presets',
        element: <OrganizationTemplatesView />,
        label: 'Organization Templates',
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  {
    path: 'organizations/:organizationId',
    element: (
      <OrganizationProvider>
        <TopNavigationLayout header={<OrganizationHeader />} />
      </OrganizationProvider>
    ),
    children: [
      {
        path: 'workgroups-users',
        element: <OrganizationEntitiesView />,
        label: 'Workgroups & Users',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'workgroup-types',
        element: <WorkgroupTypesView />,
        label: 'Workgroup Types',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'permissions',
        element: <OrganizationPermissionsPage />,
        label: 'Permissions',
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  {
    path: 'users',
    element: <GlobalUserManagementPage />,
    label: 'Users',
    icon: IconUsers,
    navLevel: 'primary',
    navContainer: 'first',
  },
  {
    path: 'courseware',
    element: <TopNavigationLayout />,
    label: 'Courseware',
    icon: IconStack3,
    navLevel: 'primary',
    navContainer: 'second',
    children: [
      {
        path: 'courses',
        element: <CoursesPage />,
        label: 'Courses',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'courses/:courseId',
        element: <CoursePage />,
        label: 'Course',
      },
      {
        path: 'videos',
        element: <VideosPage />,
        label: 'Video Assets',
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  ...templateAdminRoutes,
  {
    path: 'external-courses',
    element: <ExternalCoursesPage />,
    label: 'External Courses',
    icon: IconExternalLink,
    navLevel: 'primary',
    navContainer: 'second',
  },
  {
    path: 'email-templates',
    element: <EmailTemplatesPage />,
    label: 'Email Templates',
    icon: IconMail,
    navLevel: 'primary',
    navContainer: 'second',
  },
  {
    path: 'settings',
    element: <TopNavigationLayout />,
    label: 'Settings',
    icon: IconSettings,
    navLevel: 'primary',
    navContainer: 'second',
    children: [
      {
        path: 'task-categories',
        element: <TaskCategoriesView />,
        label: 'Task Categories',
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  {
    path: 'help-management',
    element: <TopNavigationLayout />,
    label: 'Help Content',
    icon: IconHelp,
    navLevel: 'primary',
    navContainer: 'third',
    children: [
      {
        path: HelpRepositoryEnum.clientHow,
        element: <HelpResourcesPage />,
        label: 'How-to Content',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: `${HelpRepositoryEnum.clientHow}/:helpResourceId`,
        element: <HelpResourcePage />,
      },
      {
        path: HelpRepositoryEnum.clientWhy,
        element: <HelpResourcesPage />,
        label: 'Why Content',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: `${HelpRepositoryEnum.clientWhy}/:helpResourceId`,
        element: <HelpResourcePage />,
      },
      {
        path: HelpRepositoryEnum.clientFiles,
        element: <HelpResourcesPage />,
        label: 'Files',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: `${HelpRepositoryEnum.clientFiles}/:helpResourceId`,
        element: <HelpResourcePage />,
      },
      {
        path: 'tags',
        element: <TagsView />,
        label: 'Tags',
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  {
    path: 'permissions',
    element: <PermissionsAdminPage />,
    label: 'System Permissions',
    icon: IconLock,
    navLevel: 'primary',
    navContainer: 'second',
  },
];
