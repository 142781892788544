import { DataTypeEnum } from '@btrway/api-security';
import { PermissionNode, usePermissionValue } from '@btrway/permission-manager';
import { RoleNode } from '@btrway/role-manager';
import { Card, Group, Text } from '@mantine/core';
import React from 'react';
import { PermissionControl } from '../PermissionControl/PermissionControl';

interface RolePermissionNodeProps {
  role: RoleNode;
  node: PermissionNode;
  level?: number;
}

export const RolePermissionNode: React.FC<RolePermissionNodeProps> = ({
  role,
  node,
  level = 0,
}) => {
  const { value, setValue } = usePermissionValue(node, {
    entityType: DataTypeEnum.userRole,
    entityId: role.id,
  });

  return (
    <Card withBorder p="xs" style={{ marginLeft: `${level * 16}px` }}>
      <Group justify="space-between" align="center">
        <Text size="sm">{role.name}</Text>
        <PermissionControl
          node={node}
          forceBase={true}
          value={value}
          onValueChange={setValue}
        />
      </Group>
    </Card>
  );
};
