import { ActionIcon, Box, Group, Text } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

interface StepContentProps {
  activeStep: number;
  stepTitle: string;
  onBack: () => void;
  children: React.ReactNode;
}
export const StepContent: React.FC<StepContentProps> = ({
  activeStep,
  stepTitle,
  onBack,
  children,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          flexShrink: 0,
          borderBottom: '1px solid var(--mantine-color-gray-3)',
          backgroundColor: 'var(--mantine-color-white)',
          padding: 'var(--mantine-spacing-md)',
        }}
      >
        <Group mb={0} align="center">
          {activeStep > 0 && (
            <ActionIcon variant="subtle" onClick={onBack} size="lg">
              <IconChevronLeft size={20} />
            </ActionIcon>
          )}
          <Text size="lg" fw={500}>
            {stepTitle}
          </Text>
        </Group>
      </Box>

      <Box
        style={{
          flex: '1 1 auto',
          minHeight: 0,
          overflow: 'auto',
          padding: 'var(--mantine-spacing-md)',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
