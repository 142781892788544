import { useWorkflowService } from '@btrway/workflow-service-provider';
import { Box, ScrollArea } from '@mantine/core';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProcessPanelDragDrop } from '../ProcessPanelDragDrop/ProcessPanelDragDrop';
import { ProcessView } from '../ProcessView/ProcessView';

interface WorkflowBuilderProps {
  workflowKey: string;
}

export const WorkflowBuilder: React.FC<WorkflowBuilderProps> = ({
  workflowKey,
}) => {
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();

  if (isLoading || !workflow || !workflow.workflowConfiguration) {
    return null;
  }

  return (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <ProcessPanelDragDrop>
        <DndProvider backend={HTML5Backend}>
          <Box
            style={{
              flexGrow: 1,
              height: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                position: 'relative',
              }}
            >
              <Box style={{ flex: 1, width: '100%' }}>
                <ScrollArea style={{ height: '100%' }}>
                  <ProcessView workflow={workflow} />
                </ScrollArea>
              </Box>
            </Box>
          </Box>
        </DndProvider>
      </ProcessPanelDragDrop>
    </Box>
  );
};
