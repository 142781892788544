import { TaskListTypeEnum } from '@btrway/api-task';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import { WorkgroupTaskStatsView } from '../../components/WorkgroupTaskStatsView/WorkgroupTaskStatsView';
import styles from './CertificationsPage.module.css';

const CertificationsPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection
          taskListType={TaskListTypeEnum.certification}
          taskListClassification="Coach Certification"
        /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <WorkgroupTaskStatsView
                taskListType={TaskListTypeEnum.certification}
                taskListClassification="Coach Certification"
              />
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Stack>
  );
};

export default CertificationsPage;
