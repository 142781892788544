import { EventTypeResponse, SystemEventTypeEnum } from '@btrway/api-calendar';
import { useCalendarEventTypes } from '@btrway/event-type-manager';
import { HierarchicalSelector } from '@btrway/hierarchical-selector';

export interface EventTypeHierarchicalSelectorProps {
  selectedEventTypeSlugs?: string[];
  selectedEventTypeIds?: number[];
  onChange: (selection: {
    eventTypeIds: number[];
    eventTypeSlugs: string[];
  }) => void;
  allowMultiple?: boolean;
}

export const EventTypeHierarchicalSelector = ({
  selectedEventTypeSlugs,
  selectedEventTypeIds,
  onChange,
  allowMultiple = true,
}: EventTypeHierarchicalSelectorProps) => {
  const { eventTypes } = useCalendarEventTypes();

  // Transform EventTypeResponse to include required path property
  const eventTypesWithPath =
    eventTypes
    ?.filter(
      (eventType) => eventType.systemType !== SystemEventTypeEnum.availability
    ).map((eventType) => ({
      ...eventType,
      path: eventType.path, // Simple path using just the ID for now
    })) || [];

  // Function to get additional IDs from slugs if needed
  const getAdditionalIdsFromSlugs = (items: EventTypeResponse[]) => {
    if (!selectedEventTypeSlugs) return [];
    return items
      .filter((eventType) => selectedEventTypeSlugs.includes(eventType.slug))
      .map((eventType) => eventType.id);
  };

  // Handle changes from the generic selector
  const handleChange = (selection: { ids: number[] }) => {
    const selectedEventTypes =
      eventTypes?.filter((eventType) => selection.ids.includes(eventType.id)) ||
      [];

    onChange({
      eventTypeIds: selection.ids,
      eventTypeSlugs: selectedEventTypes.map((eventType) => eventType.slug),
    });
  };

  // Custom label renderer to include description if available
  const renderLabel = (eventType: EventTypeResponse) => {
    if (eventType.description) {
      return `${eventType.name} - ${eventType.description}`;
    }
    return eventType.name;
  };

  return (
    <HierarchicalSelector<EventTypeResponse & { path: string }>
      items={eventTypesWithPath}
      selectedIds={selectedEventTypeIds}
      getAdditionalIds={
        selectedEventTypeSlugs ? getAdditionalIdsFromSlugs : undefined
      }
      onChange={handleChange}
      renderLabel={renderLabel}
      allowMultiple={allowMultiple}
    />
  );
};
