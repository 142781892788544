import { PermissionNode } from '@btrway/permission-manager';
import { useDrag } from 'react-dnd';
import { DragItem } from '../types/dragItem';

export const usePermissionDrag = (node: PermissionNode) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'PERMISSION',
    item: (): DragItem => ({
      node: node,
      type: node.permissionType,
      sourceParentClientId: node.parentClientId,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return {
    isDragging,
    drag,
  };
};
