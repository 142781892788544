import { TaskListTemplateSettings } from '@btrway/api-tasklist';
import { useUserRoles } from '@btrway/role-manager';
import { Stack, TagsInput, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

interface TaskListTemplateSettingsEditorProps {
  settings: TaskListTemplateSettings;
  onSettingsChange: (settings: TaskListTemplateSettings) => void;
}

export const TaskListTemplateSettingsEditor: React.FC<
  TaskListTemplateSettingsEditorProps
> = ({ settings, onSettingsChange }) => {
  const { userRoles, getUserRolesBySlugs } = useUserRoles();

  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>(
    settings.defaultUserRoles || []
  );

  const userRoleSuggestions = useMemo(() => {
    return userRoles.map((role) => ({
      label: role.name,
      value: role.slug,
    }));
  }, [userRoles]);

  const selectedUserRoleNames = useMemo(() => {
    const rolesBySlug = getUserRolesBySlugs(selectedUserRoles);
    return rolesBySlug.map((role) => role.name);
  }, [selectedUserRoles, getUserRolesBySlugs]);

  const handleUserRolesChange = (values: string[]) => {
    const newSlugs = values.map((value) => {
      const suggestion = userRoleSuggestions.find((s) => s.label === value);
      return suggestion ? suggestion.value : value;
    });
    setSelectedUserRoles(newSlugs);
    onSettingsChange({
      ...settings,
      defaultUserRoles: newSlugs,
    });
  };

  return (
    <Stack gap="md">
      <div>
        <Text fz="md" fw={600} mb="md">
          Default User Roles
        </Text>
        <TagsInput
          value={selectedUserRoleNames}
          onChange={handleUserRolesChange}
          data={userRoleSuggestions.map((s) => s.label)}
          placeholder="Select user roles"
          clearable
        />
      </div>
    </Stack>
  );
};
