import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { ActionIcon } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import { useStepCreator } from '../../providers/StepCreatorProvider';
import { StepCreatorDrawer } from '../StepCreatorDrawer/StepCreatorDrawer';

interface StepCreatorActionIconProps {
  workflow: CommonWorkflow;
}

export const StepCreatorActionIcon: React.FC<StepCreatorActionIconProps> = ({
  workflow,
}) => {
  const { isOpen, closeCreator, openCreator } = useStepCreator();
  const { processActions } = useWorkflowConfig();

  const handleOpenToolbox = () => {
    if (workflow.workflowType) {
      openCreator({
        workflowType: workflow.workflowType,
        onStepAdded: (stepConfig) => {
          processActions.appendStep(stepConfig);
        },
      });
    }
  };

  return (
    <>
      <ActionIcon
        size="xl"
        bg="white"
        color="gray.4"
        variant="outline"
        radius="xl"
        onClick={handleOpenToolbox}
      >
        <IconPlus size={24} color="var(--mantine-color-gray-8)" />
      </ActionIcon>

      <StepCreatorDrawer
        opened={isOpen}
        onClose={closeCreator}
        workflowType={workflow.workflowType!}
        onStepAdded={(stepConfig) => {
          processActions.appendStep(stepConfig);
          closeCreator();
        }}
      />
    </>
  );
};

export default StepCreatorActionIcon;
