/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  TaskStatisticsRequest,
  TaskStatisticsResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getTaskStatistics = (
    taskStatisticsRequest: TaskStatisticsRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TaskStatisticsResponse[]>(
      {url: `/api/workflow/task-statistics/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskStatisticsRequest
    },
      options);
    }
  


export const getGetTaskStatisticsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getTaskStatistics>>, {data: TaskStatisticsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getTaskStatistics(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetTaskStatisticsMutationResult = NonNullable<Awaited<ReturnType<typeof getTaskStatistics>>>
    export type GetTaskStatisticsMutationBody = TaskStatisticsRequest
    export type GetTaskStatisticsMutationError = unknown

    export const useGetTaskStatistics = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getTaskStatistics>>,
        TError,
        {data: TaskStatisticsRequest},
        TContext
      > => {

      const mutationOptions = getGetTaskStatisticsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
