import {
  DataTypeEnum,
  FieldStyles,
  FieldTypeEnum,
  PropertyReference,
} from '@btrway/api-workflow';
import { FormCompletionProvider } from '@btrway/form-completion-manager';
import { useFormConfig } from '@btrway/form-configuration-manager';
import { fieldSettingsRegistry, getFieldDataType } from '@btrway/form-fields';
import { FormFieldSelect } from '@btrway/form-toolbox';
import { PropertyGroup } from '@btrway/property-reference-manager';
import { PropertyTreeSelector } from '@btrway/property-tree-selector';
import { useFlyoutPanel } from '@btrway/shared-components';
import { TriggerSetsBuilder } from '@btrway/trigger-set-builder';
import {
  Box,
  Button,
  Group,
  ScrollArea,
  Stack,
  Switch,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { FieldStylesEditor } from '../FieldStylesEditor/FieldStylesEditor';

interface FieldSettingsProps {
  rulePropertyReferences?: PropertyReference[];
  bindingPropertyReferences?: PropertyReference[];
  groupedBindingPropertyReferences?: PropertyGroup[];
}

export const FieldSettings: React.FC<FieldSettingsProps> = ({
  rulePropertyReferences = [],
  bindingPropertyReferences = [],
  groupedBindingPropertyReferences = [],
}) => {
  const { closePanel } = useFlyoutPanel();
  const { selectedFieldKey, getField, updateField, allFields, selectField } =
    useFormConfig();

  const field = selectedFieldKey ? getField(selectedFieldKey) : null;

  // Get the field type specific settings component
  const FieldTypeSpecificSettings = field
    ? fieldSettingsRegistry.getComponent(field.type)
    : null;

  // Define available tabs based on whether FieldTypeSpecificSettings exists
  const availableTabs = useMemo(() => {
    const tabs = [];
    if (FieldTypeSpecificSettings) {
      tabs.push({ value: 'settings', label: 'Settings' });
    }
    tabs.push(
      { value: 'rules', label: 'Rules' },
      { value: 'binding', label: 'Data Binding' }
    );
    return tabs;
  }, [FieldTypeSpecificSettings]);

  // Set initial active tab based on available tabs
  const [activeTab, setActiveTab] = useState<string>(
    availableTabs[0]?.value || 'rules'
  );

  const handleTabChange = (value: string | null) => {
    setActiveTab(value || availableTabs[0].value);
  };

  if (!field) return null;

  const availableFields = React.useMemo(
    () =>
      allFields.filter(
        (f) =>
          f.fieldKey !== field.fieldKey && getFieldDataType(f.type) !== null
      ),
    [allFields, field.fieldKey]
  );

  const handleSettingChange = (key: string, value: any) => {
    updateField(field.fieldKey, { [key]: value });
  };

  const handleFieldPropertiesChange = (updates: any) => {
    updateField(field.fieldKey, {
      fieldProperties: {
        ...field.fieldProperties,
        ...updates,
      },
    });
  };

  const handleStylesChange = (styles: FieldStyles) => {
    updateField(field.fieldKey, { styles });
  };

  const handlePropertySelect = (property: PropertyReference | undefined) => {
    updateField(field.fieldKey, {
      propertyReference: property
        ? { ...property, dataType: DataTypeEnum.person }
        : property,
    });
  };

  const handleTriggerSetsChange = (triggerSets: any) => {
    updateField(field.fieldKey, { triggerSets });
  };

  const handleClose = () => {
    closePanel();
    selectField(null);
  };

  const handleFieldTypeChange = (newType: FieldTypeEnum) => {
    updateField(field.fieldKey, {
      type: newType,
      fieldProperties: {},
      styles: undefined,
      label: field.label,
      description: field.description,
      required: field.required,
      startWithNewLine: field.startWithNewLine,
      propertyReference: field.propertyReference,
      triggerSets: field.triggerSets,
    });
  };

  return (
    <FormCompletionProvider>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ScrollArea style={{ flex: 1 }} offsetScrollbars>
          <Box p="md">
            <Stack gap="md">
              <TextInput
                label="Field Label"
                value={field.label || ''}
                onChange={(e) =>
                  handleSettingChange('label', e.currentTarget.value)
                }
              />
              <TextInput
                label="Instructions"
                value={field.description || ''}
                onChange={(e) =>
                  handleSettingChange('description', e.currentTarget.value)
                }
              />
              <Switch
                label="Required"
                checked={field.required}
                onChange={(event) =>
                  handleSettingChange('required', event.currentTarget.checked)
                }
              />
              <Box>
                <Text size="sm" fw={500} mb="xs">
                  Field Type
                </Text>

                <FormFieldSelect
                  currentFieldType={field.type}
                  onFieldTypeSelect={handleFieldTypeChange}
                />
              </Box>

              <FieldStylesEditor field={field} onChange={handleStylesChange} />
            </Stack>

            <Tabs
              mt="xl"
              value={activeTab}
              onChange={handleTabChange}
              defaultValue={availableTabs[0].value}
            >
              <Tabs.List grow>
                {availableTabs.map((tab) => (
                  <Tabs.Tab key={tab.value} value={tab.value}>
                    {tab.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>

              <Box py="md">
                {FieldTypeSpecificSettings && (
                  <Tabs.Panel value="settings">
                    <Stack gap="md">
                      <FieldTypeSpecificSettings
                        field={field}
                        onChange={handleFieldPropertiesChange}
                      />
                    </Stack>
                  </Tabs.Panel>
                )}

                <Tabs.Panel value="rules">
                  <Stack gap="md">
                    {availableFields.length > 0 ? (
                      <TriggerSetsBuilder
                        initialTriggerSets={field.triggerSets}
                        availableFields={availableFields}
                        description="Configure conditional rules to control when this field should be visible. If any of these are true, the field will display."
                        buttonLabel="Add Display Rule"
                        onChange={handleTriggerSetsChange}
                      />
                    ) : (
                      <Text c="dimmed">
                        No other fields are available to create rules with. Add
                        more fields to the form first.
                      </Text>
                    )}
                  </Stack>
                </Tabs.Panel>

                <Tabs.Panel value="binding">
                  <PropertyTreeSelector
                    groupedBindingPropertyReferences={
                      groupedBindingPropertyReferences
                    }
                    field={field}
                    selectedProperty={field.propertyReference}
                    onPropertySelect={handlePropertySelect}
                  />
                </Tabs.Panel>
              </Box>
            </Tabs>
          </Box>
        </ScrollArea>

        <Box
          style={{
            borderTop: '1px solid var(--mantine-color-gray-3)',
            backgroundColor: 'var(--mantine-color-gray-0)',
            padding: 'var(--mantine-spacing-md)',
            borderBottomLeftRadius: 'var(--mantine-radius-lg)',
            borderBottomRightRadius: 'var(--mantine-radius-lg)',
          }}
        >
          <Group justify="flex-end">
            <Button onClick={handleClose}>Close</Button>
          </Group>
        </Box>
      </div>
    </FormCompletionProvider>
  );
};

export default FieldSettings;
