import { TaskConfig, TaskTypeEnum } from '@btrway/api-task';
import {
  EventConfig,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
} from '@btrway/api-workflow';
import {
  useEventTypes,
  useStepTypes,
  useTaskTypes,
} from '@btrway/workflow-manager';
import { createBaseTaskConfig, createBaseStepConfig, createBaseEventConfig, createBaseWorkflowTaskConfig, createBaseCourseTaskConfig } from '../utils/configCreators';

export type ConfigFactory = {
  createTaskConfig: (taskType: TaskTypeEnum) => TaskConfig;
  createStepConfig: (
    type: 'step' | 'task',
    code: StepTypeEnum | TaskTypeEnum
  ) => StepConfig;
  createEventConfig: (eventType: EventTypeEnum) => EventConfig;
  createWorkflowTaskConfig: (
    workflowKey: string,
    workflowName: string,
    taskType: TaskTypeEnum
  ) => TaskConfig;
  createCourseTaskConfig: (courseUid: string, courseName: string) => TaskConfig;
};

export const useConfigFactory = (): ConfigFactory => {
  const { getTaskType } = useTaskTypes();
  const { getStepType } = useStepTypes();
  const { getEventType } = useEventTypes();

  const createTaskConfig = (taskType: TaskTypeEnum) => {
    const type = getTaskType(taskType);
    return createBaseTaskConfig(taskType, type?.name);
  };

  const createStepConfig = (
    type: 'step' | 'task',
    code: StepTypeEnum | TaskTypeEnum
  ) => {
    let title: string;
    let stepTypeCode: StepTypeEnum;

    if (type === 'step') {
      const stepType = getStepType(code as StepTypeEnum);
      title = stepType ? stepType.name : 'New Step';
      stepTypeCode = code as StepTypeEnum;
    } else {
      const taskType = getTaskType(code as TaskTypeEnum);
      title = taskType ? taskType.name : 'New Task';
      stepTypeCode = StepTypeEnum.assignTask;
    }

    if (type === 'task') {
      const taskConfig = createTaskConfig(code as TaskTypeEnum);
      return createBaseStepConfig(
        stepTypeCode,
        title,
        taskConfig
      );
    }

    return createBaseStepConfig(stepTypeCode, title);
  };

  const createEventConfig = (eventType: EventTypeEnum) => {
    const eventTypeDetails = getEventType(eventType);
    return createBaseEventConfig(
      eventType,
      eventTypeDetails?.name
    );
  };

  const createWorkflowTaskConfig = (
    workflowKey: string,
    workflowName: string,
    taskType: TaskTypeEnum
  ) => {
    return createBaseWorkflowTaskConfig(
      workflowKey,
      workflowName,
      taskType
    );
  };

  const createCourseTaskConfig = (courseUid: string, courseName: string) => {
    return createBaseCourseTaskConfig(courseUid, courseName);
  };

  return {
    // Base creators that need type information
    createTaskConfig,
    createStepConfig,
    createEventConfig,

    // Specialized creators that use base creators
    createWorkflowTaskConfig,
    createCourseTaskConfig,
  };
};
