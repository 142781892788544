import { useAuthenticatedUser } from '@btrway/current-user';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import React, { createContext, useContext, useMemo } from 'react';
import { useExternalCourseDefinition } from '../hooks/useExternalCourseDefinition';
import { useExternalCourseTemplate } from '../hooks/useExternalCourseTemplate';
import { CommonExternalCourse } from '../types/commonExternalCourse';
import { ExternalCourseService } from '../types/service';

export const ExternalCourseServiceContext =
  createContext<ExternalCourseService | null>(null);

interface ExternalCourseServiceProviderProps {
  children: React.ReactNode;
  courseKey?: string;
}

export const ExternalCourseServiceProvider: React.FC<
  ExternalCourseServiceProviderProps
> = ({ children, courseKey: defaultCourseKey }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { sourceType } = useTemplateDefinition();

  // Create all possible template hooks we might need upfront
  const template = useExternalCourseTemplate(defaultCourseKey);

  // Create all possible definition hooks we might need upfront
  const definition = useExternalCourseDefinition(
    currentOrganization?.id!,
    defaultCourseKey
  );

  const service: ExternalCourseService = useMemo(() => {
    if (sourceType === 'template') {
      return {
        courseKey: defaultCourseKey,
        getCourseByKey: (key?: string) => {
          // Instead of creating new hooks, use the existing ones
          // and return appropriate data based on the key
          if (key && key !== defaultCourseKey) {
            // Return empty result for different keys
            return {
              data: null,
              isLoading: false,
              error: new Error('Dynamic key loading not supported'),
              refetch: async () => {},
            };
          }
          return template.getByKeyResult;
        },
        getCourseById: (courseId?: number) => {
          if (!courseId) {
            return {
              data: null,
              isLoading: false,
              error: new Error('Course ID is required'),
              refetch: async () => {},
            };
          }
          return template.getByIdResult;
        },
        getCourses: () => template.coursesResult,
        updateCourse: async (course: CommonExternalCourse) => {
          if (course.sourceType !== 'template') {
            throw new Error(
              'Cannot save definition course in template context'
            );
          }
          await template.updateCourse(course);
        },
        deleteCourse: async (course: CommonExternalCourse) => {
          if (course.sourceType !== 'template') {
            throw new Error(
              'Cannot delete definition course in template context'
            );
          }
          if (!course.id) {
            throw new Error('Cannot delete course without an id');
          }
          await template.deleteCourse(course);
        },
      };
    } else {
      return {
        courseKey: defaultCourseKey,
        getCourseByKey: (key?: string) => {
          if (key && key !== defaultCourseKey) {
            // Return empty result for different keys
            return {
              data: null,
              isLoading: false,
              error: new Error('Dynamic key loading not supported'),
              refetch: async () => {},
            };
          }
          return definition.getByKeyResult;
        },
        getCourseById: (courseId?: number) => {
          if (!courseId) {
            return {
              data: null,
              isLoading: false,
              error: new Error('Course ID is required'),
              refetch: async () => {},
            };
          }
          return definition.getByIdResult;
        },
        getCourses: () => definition.coursesResult,
        updateCourse: async (course: CommonExternalCourse) => {
          if (course.sourceType !== 'definition') {
            throw new Error(
              'Cannot save template course in definition context'
            );
          }
          await definition.updateCourse(course);
        },
        deleteCourse: async (course: CommonExternalCourse) => {
          if (course.sourceType !== 'definition') {
            throw new Error(
              'Cannot delete template course in definition context'
            );
          }
          if (!course.id) {
            throw new Error('Cannot delete course without an id');
          }
          await definition.deleteCourse(course);
        },
      };
    }
  }, [sourceType, defaultCourseKey, template, definition]);

  return (
    <ExternalCourseServiceContext.Provider value={service}>
      {children}
    </ExternalCourseServiceContext.Provider>
  );
};

export const useExternalCourseService = (): ExternalCourseService => {
  const context = useContext(ExternalCourseServiceContext);
  if (!context) {
    throw new Error(
      'useExternalCourseService must be used within an ExternalCourseServiceProvider'
    );
  }
  return context;
};
