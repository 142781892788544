import { TaskConfig, TaskListConfig } from '@btrway/api-tasklist';
import { useTaskListUpdater } from '@btrway/task-list-service-provider';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useTaskActions } from '../hooks/useTaskActions';
import { TaskActions } from '../types/actions';
import { findTaskByKey } from '../utils/taskTreeUtils';

export interface TaskListConfigContextType {
  taskListConfig: TaskListConfig;
  isLoading: boolean;
  taskActions: TaskActions;
  selectedTaskKey: string | null;
  selectTask: (taskKey: string | null) => void;
  findTask: (taskKey: string) => TaskConfig | null;
}

export interface TaskListConfigProviderProps {
  children: React.ReactNode;
  initialConfig?: TaskListConfig;
  onChange?: (config: TaskListConfig) => void;
  isLoading?: boolean;
}

const TaskListConfigContext = createContext<TaskListConfigContextType | null>(
  null
);

export const TaskListConfigProvider: React.FC<TaskListConfigProviderProps> = ({
  children,
  initialConfig,
  onChange,
  isLoading = false,
}) => {
  const taskListUpdater = useTaskListUpdater();
  const [selectedTaskKey, setSelectedTaskKey] = useState<string | null>(null);
  const [taskListConfig, setTaskListConfig] = useState<TaskListConfig>(() => ({
    tasks: [],
    ...initialConfig,
  }));

  const taskActions = useTaskActions(
    taskListUpdater,
    taskListConfig,
    setTaskListConfig
  );

  const selectTask = useCallback((taskKey: string | null) => {
    setSelectedTaskKey(taskKey);
  }, []);

  const findTask = useCallback(
    (taskKey: string): TaskConfig | null => {
      console.log('findTask for key: ', taskKey);
      console.log('searching among: ', taskListConfig.tasks);
      const result = findTaskByKey(taskListConfig.tasks, taskKey);
      return result ? result.task : null;
    },
    [taskListConfig.tasks]
  );

  const value: TaskListConfigContextType = {
    taskListConfig,
    isLoading,
    taskActions,
    selectedTaskKey,
    selectTask,
    findTask,
  };

  return (
    <TaskListConfigContext.Provider value={value}>
      {children}
    </TaskListConfigContext.Provider>
  );
};

export const useTaskListConfig = () => {
  const context = useContext(TaskListConfigContext);
  if (!context) {
    throw new Error(
      'useTaskListConfig must be used within a TaskListConfigProvider'
    );
  }
  return context;
};
