import {
  PermissionApplicationEnum,
  PermissionTypeEnum,
} from '@btrway/api-security';
import { uuid } from '@btrway/utils';
import { PermissionNode } from '../types/permissionNode';

export interface CreateNodeParams {
  permissionType: PermissionTypeEnum;
  application: PermissionApplicationEnum;
  parentNode: PermissionNode | null;
  // Optional fields that could have defaults
  name?: string;
  code?: string;
}

export const createNode = (params: CreateNodeParams): PermissionNode => ({
  // Required from params
  permissionType: params.permissionType,
  application: params.application,

  // Tree structure
  clientId: uuid(),
  parentClientId: params.parentNode?.clientId || null,
  level: params.parentNode ? params.parentNode.level + 1 : 0,
  index: 0,

  // Fields with defaults
  name: params.name || '',
  code: params.code || '',
  active: true,

  // Optional fields
  description: undefined,
  permissionAttributes: undefined,
  path: '', // Empty since it's new
  id: undefined,
});
