import { Box, SimpleGrid, Stack, Text } from '@mantine/core';
import { ToolboxItem, ToolboxSection } from '../../types/itemType';
import { sortToolboxItems } from '../../utils/toolboxSort';
import { ToolboxCard } from '../ToolboxCard/ToolboxCard';

interface ToolboxPanelProps {
  title?: string;
  sections?: ToolboxSection[];
  items?: ToolboxItem[];
  onCardSelect: (item: ToolboxItem) => void;
  droppableId: string;
  variant?: 'compact' | 'full';
  isDraggable?: boolean;
  filter?: (item: ToolboxItem) => boolean;
}
export const ToolboxPanel: React.FC<ToolboxPanelProps> = ({
  title,
  sections,
  items,
  onCardSelect,
  droppableId,
  variant = 'compact',
  isDraggable = false,
  filter,
}) => {
  const renderSection = (sectionTitle: string, sectionItems: ToolboxItem[]) => {
    const filteredItems = filter ? sectionItems.filter(filter) : sectionItems;
    const sortedItems = sortToolboxItems(filteredItems);

    if (sortedItems.length === 0) return null;

    return (
      <Stack gap="xs" key={sectionTitle}>
        <Text fw={700} fz="sm" c="dimmed">
          {sectionTitle}
        </Text>
        <SimpleGrid cols={variant === 'compact' ? 3 : 1} spacing="xs">
          {sortedItems.map((item, index) => (
            <ToolboxCard
              key={`${item.type}-${item.data.code}`}
              item={item}
              onClick={onCardSelect}
              index={index}
              isDraggable={isDraggable}
              variant={variant}
            />
          ))}
        </SimpleGrid>
      </Stack>
    );
  };

  const content = sections ? (
    sections.map((section) => renderSection(section.title, section.items))
  ) : items ? (
    <SimpleGrid cols={variant === 'compact' ? 3 : 1} spacing="xs">
      {sortToolboxItems(filter ? items.filter(filter) : items).map(
        (item, index) => (
          <ToolboxCard
            key={`${item.type}-${item.data.code}`}
            item={item}
            onClick={onCardSelect}
            index={index}
            isDraggable={isDraggable}
            variant={variant}
          />
        )
      )}
    </SimpleGrid>
  ) : null;

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Stack
        gap="md"
        style={{
          flex: 1,
          minHeight: 0,
          overflowY: 'auto',
        }}
      >
        {title && (
          <Text fw={700} fz="sm" c="dimmed">
            {title}
          </Text>
        )}
        {content}
      </Stack>
    </Box>
  );
};
