import {
  DateAssignedTypeEnum,
  DateDueTypeEnum,
  TaskListDateMethodEnum,
  TaskListSettings,
} from '@btrway/api-task';
import { MonthDaySelector } from '@btrway/shared-components';
import {
  Box,
  LoadingOverlay,
  NumberInput,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';

export interface TaskListSettingsEditorProps {
  initialSettings?: TaskListSettings;
  onChange?: (settings: TaskListSettings) => void;
}

export const TaskListSettingsEditor: React.FC<TaskListSettingsEditorProps> = ({
  initialSettings,
  onChange,
}) => {
  // Initialize state from initial settings
  const [settings, setSettings] = useState<TaskListSettings>(() => {
    const baseSettings = initialSettings || {};
    if (
      baseSettings.monthValue === undefined &&
      baseSettings.dayValue === undefined
    ) {
      return {
        ...baseSettings,
        monthValue: 6,
        dayValue: 1,
        taskListDateMethod: TaskListDateMethodEnum.byStartDate,
      };
    }
    return baseSettings;
  });

  // Update settings when initialSettings change
  useEffect(() => {
    if (initialSettings) {
      if (
        initialSettings.monthValue === undefined &&
        initialSettings.dayValue === undefined
      ) {
        setSettings({
          ...initialSettings,
          monthValue: 6,
          dayValue: 1,
          taskListDateMethod: TaskListDateMethodEnum.byStartDate,
        });
      } else {
        setSettings(initialSettings);
      }
    }
  }, [initialSettings]);

  const updateSettings = (updates: Partial<TaskListSettings>) => {
    const updatedSettings = { ...settings, ...updates };

    // If not using taskSpecific for assignment, ensure due date is set to relative
    if (
      updatedSettings.assignedDateType !== DateAssignedTypeEnum.taskSpecific
    ) {
      updatedSettings.dueDateType = DateDueTypeEnum.relative;
    }

    setSettings(updatedSettings);
    onChange?.(updatedSettings);
  };

  const handleDateMethodChange = (value: TaskListDateMethodEnum) => {
    const updates: Partial<TaskListSettings> = {
      taskListDateMethod: value,
    };

    if (value === TaskListDateMethodEnum.byDueDate) {
      updates.dueDateType = DateDueTypeEnum.fixed;
    }

    updateSettings(updates);
  };

  const handleMonthDayChange = (
    month: number | undefined,
    day: number | undefined
  ) => {
    updateSettings({
      monthValue: month,
      dayValue: day,
    });
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={false} />
      <Stack gap="lg">
        <Stack gap="md">
          <Text fz="lg" fw={600}>
            Task List Date
          </Text>
          <Text size="sm">
            Configure whether task lists are based on start or due dates:
          </Text>

          <Box
            p="md"
            style={{ border: '1px solid var(--mantine-color-gray-4)' }}
          >
            <Radio.Group
              value={
                settings.taskListDateMethod ||
                TaskListDateMethodEnum.byStartDate
              }
              onChange={(value) =>
                handleDateMethodChange(value as TaskListDateMethodEnum)
              }
            >
              <Stack gap="xs">
                <Radio
                  value={TaskListDateMethodEnum.byStartDate}
                  label="Start Date"
                />
                <Radio
                  value={TaskListDateMethodEnum.byDueDate}
                  label="Due Date"
                />
              </Stack>
            </Radio.Group>
          </Box>

          <Text size="sm">Choose the annual month and day:</Text>
          <Box style={{ border: '1px solid var(--mantine-color-gray-4)' }}>
            <MonthDaySelector
              month={settings.monthValue}
              day={settings.dayValue}
              onChange={handleMonthDayChange}
            />
          </Box>
        </Stack>

        {settings.taskListDateMethod === TaskListDateMethodEnum.byStartDate && (
          <Stack gap="md">
            <Text fz="lg" fw={600}>
              Individual Task Assignment
            </Text>
            <Text size="sm">
              Configure when individual tasks should be assigned
            </Text>

            <Radio.Group
              value={
                settings.assignedDateType || DateAssignedTypeEnum.immediate
              }
              onChange={(value) =>
                updateSettings({
                  assignedDateType: value as DateAssignedTypeEnum,
                })
              }
            >
              <Stack gap="xs">
                <Radio
                  value={DateAssignedTypeEnum.taskSpecific}
                  label="Specify assign and due dates by task"
                />
                <Radio
                  value={DateAssignedTypeEnum.immediate}
                  label="Assign all tasks immediately"
                />
                <Radio
                  value={DateAssignedTypeEnum.incremental}
                  label="Incrementally assign tasks"
                />
              </Stack>
            </Radio.Group>

            {settings.assignedDateType === DateAssignedTypeEnum.incremental && (
              <NumberInput
                label="Days between assignments"
                value={settings.assignedDateValue || 0}
                onChange={(value) =>
                  updateSettings({
                    assignedDateValue: value as number,
                  })
                }
                min={0}
              />
            )}

            {settings.assignedDateType !==
              DateAssignedTypeEnum.taskSpecific && (
              <NumberInput
                label="Days after assignment that they are due"
                value={settings.dueDateValue || 0}
                onChange={(value) =>
                  updateSettings({
                    dueDateValue: value as number,
                  })
                }
                min={0}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
