import {
  getAllWorkgroupChildren,
  getOrganizations,
  getWorkgroupsWithChildren,
  OrganizationResponse,
} from '@btrway/api-core';
import { getUserRolesByOrganizationId } from '@btrway/api-security';
import { useAuth } from '@btrway/auth-core';
import { Box, Modal } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface GlobalInviteNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GlobalInviteNewUserModal: React.FC<
  GlobalInviteNewUserModalProps
> = ({ isOpen, onClose }) => {
  const { inviteNewUser } = useAuth();
  const [organizations, setOrganizations] = useState<OrganizationResponse[]>(
    []
  );
  const [survey, setSurvey] = useState<any | null>(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const orgs = await getOrganizations();
      setOrganizations(orgs);
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    const surveyJson = {
      elements: [
        {
          type: 'text',
          name: 'email',
          title: 'Email',
          isRequired: true,
          validators: [{ type: 'email' }],
        },
        {
          type: 'text',
          name: 'firstName',
          title: 'First Name',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'lastName',
          title: 'Last Name',
          isRequired: true,
        },
        {
          type: 'dropdown',
          name: 'organizationId',
          title: 'Organization',
          isRequired: true,
          choices: organizations.map((org) => ({
            value: org.id.toString(),
            text: org.name,
          })),
        },
        {
          type: 'dropdown',
          name: 'userRoleId',
          title: 'User Role',
          isRequired: true,
          choices: [],
          visibleIf: '{organizationId} notempty',
        },
        {
          type: 'dropdown',
          name: 'workgroupId',
          title: 'Workgroup',
          isRequired: true,
          choices: [],
          visibleIf: '{organizationId} notempty',
        },
        {
          type: 'dropdown',
          name: 'workgroupChildId',
          title: 'Workgroup Team (Optional)',
          choices: [],
          visibleIf: '{workgroupId} notempty',
        },
      ],
    };

    // const newSurvey = new Model(surveyJson);
    // newSurvey.onValueChanged.add(handleSurveyValueChange);
    // setSurvey(newSurvey);
  }, [organizations]);

  const handleSurveyValueChange = async (sender: any, options: any) => {
    if (options.name === 'organizationId') {
      const organizationId = options.value;
      if (organizationId) {
        const userRoles = await getUserRolesByOrganizationId(
          parseInt(organizationId)
        );
        const workgroups = await getWorkgroupsWithChildren(
          parseInt(organizationId)
        );

        sender.getQuestionByName('userRoleId').choices = userRoles.map(
          (role) => ({
            value: role.id.toString(),
            text: role.name,
          })
        );

        sender.getQuestionByName('workgroupId').choices = workgroups.map(
          (workgroup) => ({
            value: workgroup.id.toString(),
            text: workgroup.name,
          })
        );

        sender.getQuestionByName('workgroupChildId').choices = [];
      }
    }

    if (options.name === 'workgroupId') {
      const workgroupId = options.value;
      const organizationId = sender.getValue('organizationId');
      if (workgroupId && organizationId) {
        const workgroups = await getWorkgroupsWithChildren(
          parseInt(organizationId)
        );
        const selectedWorkgroup = workgroups.find(
          (w) => w.id.toString() === workgroupId
        );
        if (
          selectedWorkgroup &&
          selectedWorkgroup.workgroupType.name === 'School'
        ) {
          const children = await getAllWorkgroupChildren(
            parseInt(organizationId),
            parseInt(workgroupId)
          );
          sender.getQuestionByName('workgroupChildId').choices = children.map(
            (child) => ({
              value: child.id.toString(),
              text: child.name,
            })
          );
          sender.getQuestionByName('workgroupChildId').visible = true;
        } else {
          sender.getQuestionByName('workgroupChildId').choices = [];
          sender.getQuestionByName('workgroupChildId').visible = false;
        }
      }
    }
  };

  const handleSubmit = async (sender: any) => {
    const values = sender.data;
    try {
      const result = await inviteNewUser({
        ...values,
        organizationId: parseInt(values.organizationId),
        userRoleId: parseInt(values.userRoleId),
        workgroupId: parseInt(values.workgroupChildId || values.workgroupId),
      });

      if (result.success) {
        console.log('Invitation sent successfully');
        onClose();
      } else {
        console.error('Failed to send invitation:', result.error);
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  };

  // Custom CSS to ensure all fields are visible without scrolling
  const customCss = {
    root: 'survey-container',
    container: 'survey-content-container',
    body: 'survey-body',
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Invite New User"
      centered
      size="xl"
    >
      <Box>
        {/*{survey && (*/}
        {/*  <Survey model={survey} onComplete={handleSubmit} css={customCss} />*/}
        {/*)}*/}
      </Box>
    </Modal>
  );
};
