import {
  ExternalCourseDefinitionRequest,
  getGetExternalCourseDefinitionByIdQueryKey,
  getGetExternalCourseDefinitionByKeyQueryKey,
  getGetExternalCourseDefinitionsQueryKey,
  useDeleteExternalCourseDefinition,
  useGetExternalCourseDefinitionById,
  useGetExternalCourseDefinitionByKey,
  useGetExternalCourseDefinitions,
  useSaveExternalCourseDefinition,
} from '@btrway/api-external-provider';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonExternalCourse } from '../types/commonExternalCourse';
import { ExternalCourseResult, ExternalCoursesResult } from '../types/service';
import {
  convertCommonToCourseRequest,
  convertResponseToCommon,
  validateCourseBeforeConversion,
} from '../utils/commonExternalCourseConversions';

export function useExternalCourseDefinition(
  organizationId: number,
  courseKey?: string,
  courseId?: number
) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'definition';
  const { mutateAsync: saveExternalCourseDefinition } =
    useSaveExternalCourseDefinition();
  const { mutateAsync: deleteDefinition } = useDeleteExternalCourseDefinition();

  const {
    data: dataByKey,
    isLoading: isLoadingByKey,
    error: errorByKey,
    refetch: refetchByKey,
  } = useGetExternalCourseDefinitionByKey(courseKey || '', {
    query: {
      enabled: isEnabled && !!courseKey,
      queryKey: getGetExternalCourseDefinitionByKeyQueryKey(courseKey || ''),
    },
  });

  const {
    data: dataById,
    isLoading: isLoadingById,
    error: errorById,
    refetch: refetchById,
  } = useGetExternalCourseDefinitionById(courseId || 0, {
    query: {
      enabled: isEnabled && !!courseId,
      queryKey: getGetExternalCourseDefinitionByIdQueryKey(courseId || 0),
    },
  });

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    refetch: refetchDefinitions,
  } = useGetExternalCourseDefinitions({
    query: {
      enabled: isEnabled,
      queryKey: getGetExternalCourseDefinitionsQueryKey(),
    },
  });

  const getByKeyResult: ExternalCourseResult = {
    data: dataByKey ? convertResponseToCommon(dataByKey) : null,
    isLoading: isLoadingByKey,
    error: errorByKey as Error | null,
    refetch: refetchByKey,
  };

  const getByIdResult: ExternalCourseResult = {
    data: dataById ? convertResponseToCommon(dataById) : null,
    isLoading: isLoadingById,
    error: errorById as Error | null,
    refetch: refetchById,
  };

  const definitionsResult: ExternalCoursesResult = {
    courses: (definitionsData || []).map(convertResponseToCommon),
    isLoading: definitionsLoading,
    error: definitionsError as Error | undefined,
    refetch: refetchDefinitions,
  };

  return {
    getByKeyResult,
    getByIdResult,
    coursesResult: definitionsResult,
    updateCourse: async (course: CommonExternalCourse) => {
      if (course.sourceType !== 'definition') {
        throw new Error('Cannot save template course in definition context');
      }
      const validationErrors = validateCourseBeforeConversion(course);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid course: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToCourseRequest(
        course
      ) as ExternalCourseDefinitionRequest;
      return await saveExternalCourseDefinition({ data: request });
    },
    deleteCourse: async (course: CommonExternalCourse) => {
      if (course.sourceType !== 'definition') {
        throw new Error('Cannot delete template course in definition context');
      }
      if (!course.id) {
        throw new Error('Cannot delete course without an id');
      }
      return await deleteDefinition({ externalCourseDefinitionId: course.id });
    },
  };
}
