import type { CommonTaskList } from '@btrway/task-list-service-provider';
import { Card, Text } from '@mantine/core';

interface TaskListCardProps {
  taskList: CommonTaskList;
  isSelected: boolean;
  onClick: () => void;
}

export const TaskListCard: React.FC<TaskListCardProps> = ({
  taskList,
  isSelected,
  onClick,
}) => {
  return (
    <Card
      key={taskList.id || taskList.templateKey}
      shadow="none"
      withBorder
      mb="xs"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        backgroundColor: isSelected ? 'var(--mantine-color-blue-0)' : undefined,
        borderColor: isSelected
          ? 'var(--mantine-color-blue-5)'
          : 'var(--mantine-color-gray-4)',
      }}
    >
      <Text size="sm" fw={500}>
        {taskList.name}
      </Text>
    </Card>
  );
};
