import { ActionIcon } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import React, { useState } from 'react';
import { TaskListSettingsDrawer } from '../TaskListSettingsDrawer/TaskListSettingsDrawer';

export const TaskListSettingsActionIcon: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleSave = () => setIsOpen(false);
  const handleCancel = () => setIsOpen(false);

  return (
    <>
      <ActionIcon
        size="xl"
        bg="gray.4"
        color="gray.4"
        variant="outline"
        radius="xl"
        onClick={() => setIsOpen(true)}
      >
        <IconSettings size={24} color="var(--mantine-color-gray-8)" />
      </ActionIcon>

      <TaskListSettingsDrawer
        opened={isOpen}
        onClose={handleClose}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </>
  );
};
