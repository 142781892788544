import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { TopNavigationLayout } from '@btrway/layout-components';
import { TaskListServiceProvider } from '@btrway/task-list-service-provider';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowServiceProvider } from '@btrway/workflow-service-provider';
import {
  IconConfetti,
  IconHome,
  IconListCheck,
  IconRowInsertBottom,
  IconSettingsSpark,
  IconShieldCheckFilled,
  IconTemplate,
} from '@tabler/icons-react';
import { lazy } from 'react';

const WorkflowTypeTemplatesPage = lazy(() =>
  import('@btrway/workflow-administration').then((module) => ({
    default: module.WorkflowAdministrationView,
  }))
);

const TaskListAdministrationView = lazy(() =>
  import('@btrway/task-list-administration').then((module) => ({
    default: module.TaskListAdministrationView,
  }))
);

const WorkflowTaskListDashboard = lazy(() =>
  import('@btrway/workflow-tasklist-dashboard').then((module) => ({
    default: module.WorkflowTaskListDashboard,
  }))
);

// Helper component to create workflow definition views with type
const WorkflowTemplatesViewWithType: React.FC<{
  workflowType: WorkflowTypeEnum;
}> = ({ workflowType }) => {
  return <WorkflowTypeTemplatesPage workflowType={workflowType} />;
};

const TaskListAdministrationViewWithType: React.FC<{
  taskListType: TaskListTypeEnum;
}> = ({ taskListType }) => {
  return <TaskListAdministrationView taskListType={taskListType} />;
};

export const templateAdminRoutes: RouteConfig[] = [
  {
    path: 'template-admin',
    element: (
      <WorkflowServiceProvider>
        <TaskListServiceProvider>
          <TopNavigationLayout />
        </TaskListServiceProvider>
      </WorkflowServiceProvider>
    ),
    label: 'Process Templates',
    icon: IconSettingsSpark,
    navLevel: 'primary',
    navContainer: 'second',
    // permissions: ['WORKFLOW_ADMIN'],
    children: [
      {
        path: 'dashboard',
        element: <WorkflowTaskListDashboard />,
        label: 'Dashboard',
        icon: IconHome,
        displayType: 'icon',
        navLevel: 'secondary',
      },
      {
        path: 'forms',
        element: <WorkflowTemplatesViewWithType workflowType="form" />,
        label: 'Forms',
        icon: IconTemplate,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'form-packets',
        element: (
          <TaskListAdministrationViewWithType taskListType="formPacket" />
        ),
        label: 'Form Packets',
        icon: IconTemplate,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'todo-lists',
        element: <TaskListAdministrationViewWithType taskListType="todoList" />,
        label: 'To-Do Lists',
        icon: IconListCheck,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'curriculums',
        element: (
          <TaskListAdministrationViewWithType taskListType="curriculum" />
        ),
        label: 'Curriculums',
        navLevel: 'secondary',
        icon: IconRowInsertBottom,
        displayType: 'label',
      },
      {
        path: 'automations',
        element: <WorkflowTemplatesViewWithType workflowType="automation" />,
        label: 'Automations',
        navLevel: 'secondary',
        icon: IconConfetti,
        displayType: 'label',
      },
      {
        path: 'certifications',
        element: (
          <TaskListAdministrationViewWithType taskListType="certification" />
        ),
        label: 'Certifications',
        navLevel: 'secondary',
        icon: IconShieldCheckFilled,
        displayType: 'label',
      },
    ],
  },
];
