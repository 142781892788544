import {
  getGetTaskCategoriesQueryKey,
  useGetTaskCategories,
} from '@btrway/api-tasklist';
import { useCallback } from 'react';

export const useTaskCategories = () => {
  const {
    data: taskCategories,
    isLoading,
    error,
    refetch,
  } = useGetTaskCategories({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetTaskCategoriesQueryKey(),
    },
  });

  const getTaskCategory = useCallback(
    (taskCategoryKey: string | undefined) => {
      if (taskCategoryKey === undefined) {
        return undefined;
      }

      return taskCategories?.find(
        (taskCategory) => taskCategory.key === taskCategoryKey
      );
    },
    [taskCategories]
  );

  const getTaskCategoryByName = useCallback(
    (categoryName: string | undefined) => {
      if (categoryName === undefined) {
        return undefined;
      }

      return taskCategories?.find(
        (taskCategory) =>
          taskCategory.name.toLowerCase() === categoryName.toLowerCase()
      );
    },
    [taskCategories]
  );

  return {
    taskCategories: taskCategories ?? [],
    isLoading,
    error,
    getTaskCategory,
    getTaskCategoryByName,
    refetchTaskCategories: refetch,
  };
};
