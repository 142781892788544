/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskListTypeEnum = typeof TaskListTypeEnum[keyof typeof TaskListTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskListTypeEnum = {
  curriculum: 'curriculum',
  formPacket: 'formPacket',
  todoList: 'todoList',
  certification: 'certification',
} as const;
