import {
  GlobalSearchRequest,
  GlobalSearchResponse,
  useSearchGlobal,
} from '@btrway/api-core';
import {
  DataTypeEnum,
  TaskAssignmentConfig,
  TaskAssignmentTypeEnum,
} from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { usePropertyReferences } from '@btrway/property-reference-manager';
import { useUserRoles } from '@btrway/role-manager';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { SectionState } from '../types/sectionState';

// First, update the hook interface and implementation
interface UseApproverEditorProps {
  taskAssignmentConfig?: TaskAssignmentConfig;
  onConfigChange: (config: TaskAssignmentConfig, description: string) => void;
  onClose: () => void;
}

export const useApproverSelectOptions = ({
  taskAssignmentConfig,
  onConfigChange,
  onClose,
}: UseApproverEditorProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const [sections, setSections] = useState<SectionState>({
    involved: true,
    roles: true,
    people: true,
  });
  const [searchResults, setSearchResults] = useState<GlobalSearchResponse[]>(
    []
  );

  const { rootWorkgroupId, currentOrganization } = useAuthenticatedUser();
  const { userRoles, getUserRoleBySlug } = useUserRoles();
  const { selectedStepKey } = useWorkflowConfig();
  const { bindingPropertyReferences } = usePropertyReferences(
    selectedStepKey || ''
  );
  const { mutate: searchGlobal, isPending: isSearching } = useSearchGlobal();

  // Filter property references for people involved
  const filteredPreviousStepProperties = useMemo(() => {
    return bindingPropertyReferences.filter(
      (prop) => prop.dataType === DataTypeEnum.person && !prop.entityProperty
    );
  }, [bindingPropertyReferences]);

  // Filter roles based on search and exclude group roles
  const filteredRoles = useMemo(() => {
    const nonGroupRoles = userRoles.filter((role) => !role.userRoleGroup);

    if (!searchValue) return nonGroupRoles;

    return nonGroupRoles.filter((role) =>
      role.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [userRoles, searchValue]);

  // Handle global search
  useEffect(() => {
    if (debouncedSearchValue && currentOrganization) {
      const searchRequest: GlobalSearchRequest = {
        organizationId: currentOrganization.id,
        searchValue: debouncedSearchValue,
        filterByWorkgroupIds: [rootWorkgroupId],
      };
      searchGlobal(
        { data: searchRequest },
        {
          onSuccess: (data: unknown) => {
            const searchResponse = data as GlobalSearchResponse[];
            setSearchResults(searchResponse);
          },
        }
      );
    } else {
      setSearchResults([]);
    }
  }, [
    debouncedSearchValue,
    currentOrganization,
    rootWorkgroupId,
    searchGlobal,
  ]);

  // Auto-expand sections with results when searching
  useEffect(() => {
    if (searchValue) {
      const newSections = { ...sections };
      if (
        filteredPreviousStepProperties.some((prop) =>
          prop.label?.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        newSections.involved = true;
      }
      if (filteredRoles.length > 0) {
        newSections.roles = true;
      }
      if (searchResults.length > 0) {
        newSections.people = true;
      }
      setSections(newSections);
    }
  }, [searchValue, filteredRoles.length, searchResults.length]);

  const toggleSection = (section: keyof SectionState) => {
    setSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handlePrimaryEntitySelect = (description: string) => {
    onConfigChange(
      {
        assignmentType: TaskAssignmentTypeEnum.primaryWorkflowPerson,
      },
      description
    );
    onClose();
  };

  const handleRoleSelect = (roleSlug: string) => {
    const role = getUserRoleBySlug(roleSlug);
    onConfigChange(
      {
        assignmentType: TaskAssignmentTypeEnum.userRole,
        userRoleSlug: { value: roleSlug },
      },
      role?.name || 'Unknown Role'
    );
    onClose();
  };

  const handlePersonSelect = (person: GlobalSearchResponse) => {
    onConfigChange(
      {
        assignmentType: TaskAssignmentTypeEnum.person,
        personId: {
          value: person.entityId,
          dataType: DataTypeEnum.person,
        },
      },
      person.displayName
    );
    onClose();
  };

  const handlePropertyReferenceSelect = (propertyReference: any) => {
    onConfigChange(
      {
        assignmentType: TaskAssignmentTypeEnum.person,
        personId: {
          reference: {
            label: propertyReference.label,
            propertyKey: propertyReference.propertyKey,
            dataType: DataTypeEnum.person,
          },
          dataType: DataTypeEnum.person,
        },
      },
      propertyReference.label || propertyReference.propertyKey
    );
    onClose();
  };

  return {
    searchValue,
    setSearchValue,
    sections,
    toggleSection,
    isSearching,
    filteredRoles,
    searchResults,
    filteredPreviousStepProperties,
    handlePrimaryEntitySelect,
    handleRoleSelect,
    handlePersonSelect,
    handlePropertyReferenceSelect,
  };
};
