// components/WorkflowToolbox/WorkflowToolbox.tsx
import { TaskTypeEnum } from '@btrway/api-task';
import { StepTypeEnum, WorkflowTypeEnum } from '@btrway/api-workflow';
import { ToolboxItem, ToolboxPanel } from '@btrway/workflow-builder-toolbox';
import { useStepTypes, useTaskTypes } from '@btrway/workflow-manager';
import React from 'react';

const SECTIONS = {
  TASKS: 'Tasks',
  ACTIONS: 'Actions',
} as const;

const getAvailableTaskTypes = (
  workflowType: WorkflowTypeEnum
): TaskTypeEnum[] => {
  switch (workflowType) {
    case WorkflowTypeEnum.form:
      return [TaskTypeEnum.completeWorkflowStep];
    case WorkflowTypeEnum.automation:
      return [
        TaskTypeEnum.addtoWorkgroup,
        TaskTypeEnum.completeCourse,
        TaskTypeEnum.completeCurriculum,
        TaskTypeEnum.completeFormPacket,
        TaskTypeEnum.completeTask,
        TaskTypeEnum.completeTaskList,
        TaskTypeEnum.createCalendarEvent,
        TaskTypeEnum.sendEmail,
        TaskTypeEnum.submitForm,
        TaskTypeEnum.uploadFile,
        TaskTypeEnum.thirdParty,
        TaskTypeEnum.externalCourse,
      ];
    default:
      return [];
  }
};

const getAvailableStepTypes = (): StepTypeEnum[] => {
  return [
    StepTypeEnum.actionCreateCertificate,
    StepTypeEnum.actionCreateEvent,
    StepTypeEnum.actionCreateWorkgroupMember,
    StepTypeEnum.actionDeleteWorkgroupMember,
    StepTypeEnum.actionPushNotification,
    StepTypeEnum.actionSendEmail,
    StepTypeEnum.actionSendUserInvite,
    StepTypeEnum.actionSms,
    StepTypeEnum.actionUpdatePersonData,
  ];
};

interface WorkflowToolboxProps {
  workflowType: WorkflowTypeEnum;
  onSelect: (item: ToolboxItem) => void;
}

export const WorkflowToolbox: React.FC<WorkflowToolboxProps> = ({
  workflowType,
  onSelect,
}) => {
  const { getTaskType, taskTypes } = useTaskTypes();
  const { getStepType, stepTypes } = useStepTypes();

  const sections = React.useMemo(() => {
    const availableTaskTypes = getAvailableTaskTypes(workflowType);
    const availableStepTypes = getAvailableStepTypes();

    return [
      {
        title: SECTIONS.TASKS,
        items: availableTaskTypes
          .map((type) => {
            const taskType = getTaskType(type);
            if (!taskType) return null;
            return {
              type: 'taskType' as const,
              data: taskType,
            };
          })
          .filter((item): item is NonNullable<typeof item> => item !== null),
      },
      {
        title: SECTIONS.ACTIONS,
        items: availableStepTypes
          .map((type) => {
            const stepType = getStepType(type);
            if (!stepType) return null;
            return {
              type: 'stepType' as const,
              data: stepType,
            };
          })
          .filter((item): item is NonNullable<typeof item> => item !== null),
      },
    ];
  }, [workflowType, getTaskType, getStepType]);

  if (!taskTypes.length || !stepTypes.length) {
    return null;
  }

  return (
    <ToolboxPanel
      title="Steps"
      sections={sections}
      onCardSelect={onSelect}
      droppableId="workflow-toolbox-droppable"
      variant="compact"
    />
  );
};

export default WorkflowToolbox;
