import { TopNavigationLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import { IconAlignBoxLeftTop, IconBulb, IconForms } from '@tabler/icons-react';
import { lazy } from 'react';
import { myRoutes } from './myRoutes';
import { opsRoutes } from './opsRoutes';
import { oversightRoutes } from './oversightRoutes';
import { personRoutes } from './personRoutes';
import { settingsRoutes } from './settingsRoutes';

const MyProfilePage = lazy(() =>
  import('@web/poc-pages/MyProfilePage').then((module) => ({
    default: module.default,
  }))
);

// Lazy loaded courseware components
const CourseLandingPage = lazy(() =>
  import('@btrway/courseware-library-components').then((module) => ({
    default: module.CourseLandingPage,
  }))
);
const EducationPage = lazy(() =>
  import('@btrway/courseware-library-components').then((module) => ({
    default: module.EducationPage,
  }))
);

// Lazy loaded form components
const LaunchFormList = lazy(() =>
  import('@btrway/form-library').then((module) => ({
    default: module.LaunchFormList,
  }))
);
const SubmittedFormsView = lazy(() =>
  import('@btrway/form-submission').then((module) => ({
    default: module.SubmittedFormsView,
  }))
);
const AllFormsGrid = lazy(() =>
  import('@btrway/form-submission').then((module) => ({
    default: module.AllFormsGrid,
  }))
);

// Lazy loaded dashboard components
const ParentDashboardPage = lazy(() =>
  import('@btrway/parent-dashboard').then((module) => ({
    default: module.ParentDashboardPage,
  }))
);
const StudentDashboardPage = lazy(() =>
  import('@btrway/student-dashboard').then((module) => ({
    default: module.StudentDashboardPage,
  }))
);

export const appShellRoutes: RouteConfig[] = [
  ...myRoutes,
  // {
  //   path: 'parent',
  //   element: <ParentDashboardPage />,
  //   label: "Parent's Dashboard",
  //   icon: IconHome,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['PARENT_DASHBOARD'],
  // },
  // {
  //   path: 'student',
  //   element: <StudentDashboardPage />,
  //   label: "Student's Dashboard",
  //   icon: IconHome,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['STUDENT_DASHBOARD'],
  // },
  {
    path: 'forms',
    element: <TopNavigationLayout />,
    label: 'Forms',
    icon: IconAlignBoxLeftTop,
    navLevel: 'primary',
    navContainer: 'first',
    // permissions: ['FORMS'],
    children: [
      {
        path: 'library',
        element: <LaunchFormList />,
        label: 'Form Library',
        icon: IconForms,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'myforms',
        element: <AllFormsGrid />,
        label: 'My Forms',
        icon: IconForms,
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  {
    path: 'education',
    element: <EducationPage />,
    label: 'Learn',
    icon: IconBulb,
    navLevel: 'primary',
    navContainer: 'first',
    forceDarkMode: true,
    // permissions: ['LEARNING_CENTER'],
    children: [
      {
        path: 'curriculum/:curriculumId/course/:courseUid',
        element: <CourseLandingPage />,
        label: 'Course',
        navLevel: 'secondary',
      },
    ],
  },
  {
    path: 'profile',
    element: <MyProfilePage />,
    label: 'My Profile',
  },
  {
    path: 'education/course/:courseUid',
    element: <CourseLandingPage />,
  },
  ...opsRoutes,
  ...oversightRoutes,
  ...settingsRoutes,
  ...personRoutes,
];
