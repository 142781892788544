import { TaskInstanceResponse, TaskTypeEnum } from '@btrway/api-task';
import { StyledModal } from '@btrway/styled-modal';
import { Box, Text } from '@mantine/core';
import React from 'react';
import { TaskActionProvider } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';
import { getTaskComponent } from '../../utils/getTaskComponent';

const HIDE_MODAL_HEADER_TASK_TYPES: Set<TaskTypeEnum> = new Set([
  TaskTypeEnum.completeCourse,
]);

interface TaskCompleterProps {
  taskInstance?: TaskInstanceResponse;
  isOpen: boolean;
  onClose: () => void;
}

const TaskContent: React.FC<{
  taskInstance: TaskInstanceResponse;
  onClose: () => void;
}> = ({ taskInstance, onClose }) => {
  if (!taskInstance.taskType) {
    return <Text>Task type is not provided</Text>;
  }

  const TaskComponent = getTaskComponent(taskInstance.taskType);

  if (!TaskComponent) {
    return <Text>Unsupported task type: {taskInstance.taskType}</Text>;
  }

  const taskProps: TaskComponentProps = {
    task: taskInstance,
    onClose,
  };

  return (
    <React.Suspense fallback={<Text>Loading task...</Text>}>
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TaskComponent {...taskProps} />
      </Box>
    </React.Suspense>
  );
};

export const TaskCompleter: React.FC<TaskCompleterProps> = ({
  taskInstance,
  isOpen,
  onClose,
}) => {
  // console.log('TaskComplter: taskInstance: ', taskInstance);
  const shouldHideHeader =
    taskInstance?.taskType &&
    HIDE_MODAL_HEADER_TASK_TYPES.has(taskInstance.taskType);

  return (
    <StyledModal
      opened={isOpen}
      onClose={onClose}
      fullScreen
      withCloseButton={shouldHideHeader ? false : undefined}
      variant="xl"
    >
      {taskInstance ? (
        <TaskActionProvider taskInstance={taskInstance} onSuccess={onClose}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TaskContent taskInstance={taskInstance} onClose={onClose} />
          </Box>
        </TaskActionProvider>
      ) : (
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Text>No task configuration provided!</Text>
        </Box>
      )}
    </StyledModal>
  );
};
