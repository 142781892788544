import {
  FormSettings,
  PropertyReference,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useStepTypes } from '@btrway/workflow-manager';
import { useWorkflowService } from '@btrway/workflow-service-provider';
import { useMemo } from 'react';
import { PropertyGroup, PropertyGroupType } from '../types/propertyGroup';
import { usePropertyReferences } from './usePropertyReferences';

export interface GroupedPropertyReferencesResult {
  groupedRulePropertyReferences: PropertyGroup[];
  groupedBindingPropertyReferences: PropertyGroup[];
}

export const useGroupedPropertyReferences = (
  stepKey: string
): GroupedPropertyReferencesResult => {
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();
  const { getStepType } = useStepTypes();
  const {
    rulePropertyReferences,
    bindingPropertyReferences,
    aboutEntityProperties,
  } = usePropertyReferences(stepKey);

  return useMemo(() => {
    const workflowConfig = workflow?.workflowConfiguration;
    if (!workflowConfig?.steps) {
      return {
        groupedRulePropertyReferences: [],
        groupedBindingPropertyReferences: [],
      };
    }

    // Get current step
    const currentStep = workflowConfig.steps.find(
      (step) => step.stepKey === stepKey
    );
    if (!currentStep) {
      return {
        groupedRulePropertyReferences: [],
        groupedBindingPropertyReferences: [],
      };
    }

    // Group rule properties by step
    const groupedRulePropertyReferences: PropertyGroup[] = [];
    const rulePropertiesByStep = new Map<string, PropertyReference[]>();

    rulePropertyReferences.forEach((prop) => {
      if (prop.stepKey) {
        const stepProps = rulePropertiesByStep.get(prop.stepKey) || [];
        stepProps.push(prop);
        rulePropertiesByStep.set(prop.stepKey, stepProps);
      }
    });

    // Add current step group
    if (rulePropertiesByStep.has(currentStep.stepKey)) {
      groupedRulePropertyReferences.push({
        key: `form_${currentStep.stepKey}`,
        label: currentStep.title || currentStep.stepKey,
        type: PropertyGroupType.FormField,
        stepKey: currentStep.stepKey,
        properties: rulePropertiesByStep.get(currentStep.stepKey) || [],
      });
    }

    // Add previous steps groups
    rulePropertiesByStep.forEach((properties, stepKey) => {
      if (stepKey !== currentStep.stepKey) {
        const step = workflowConfig.steps?.find((s) => s.stepKey === stepKey);
        if (step) {
          groupedRulePropertyReferences.push({
            key: `form_${stepKey}`,
            label: step.title || stepKey,
            type: PropertyGroupType.FormField,
            stepKey,
            properties,
          });
        }
      }
    });

    // Create binding property references groups
    const groupedBindingPropertyReferences: PropertyGroup[] = [];

    // Add About Entity group if properties exist
    if (aboutEntityProperties.length > 0) {
      const formSettings =
        workflow?.workflowType === WorkflowTypeEnum.form
          ? (workflow.workflowConfiguration?.settings as FormSettings)
          : undefined;
      const aboutEntityDescription =
        formSettings?.aboutSettings?.aboutEntityDescription;

      groupedBindingPropertyReferences.push({
        key: 'about_entity',
        label: aboutEntityDescription || 'About Entity',
        type: PropertyGroupType.AboutEntity,
        properties: aboutEntityProperties,
      });
    }

    // Group binding properties by step (excluding About Entity properties)
    const bindingPropertiesByStep = new Map<string, PropertyReference[]>();
    bindingPropertyReferences
      .filter((prop) => !aboutEntityProperties.includes(prop))
      .forEach((prop) => {
        if (prop.stepKey) {
          const stepProps = bindingPropertiesByStep.get(prop.stepKey) || [];
          stepProps.push(prop);
          bindingPropertiesByStep.set(prop.stepKey, stepProps);
        }
      });

    // Add step type property groups
    bindingPropertiesByStep.forEach((properties, stepKey) => {
      const step = workflowConfig.steps?.find((s) => s.stepKey === stepKey);
      if (step) {
        const stepTypeDTO = getStepType(step.stepTypeCode);

        // If step has defined properties in its StepTypeDTO, create individual groups
        if (stepTypeDTO?.properties) {
          stepTypeDTO.properties.forEach((prop) => {
            const matchingProps = properties.filter(
              (p) => p.propertyKey === prop.key
            );
            if (matchingProps.length > 0) {
              groupedBindingPropertyReferences.push({
                key: `output_${stepKey}_${prop.key}`,
                label: prop.label,
                type: PropertyGroupType.StepType,
                stepKey,
                properties: matchingProps,
              });
            }
          });
        } else {
          // If no StepTypeDTO properties, create a single group
          groupedBindingPropertyReferences.push({
            key: `step_type_${stepKey}`,
            label: `${step.title || stepKey} Properties`,
            type: PropertyGroupType.StepType,
            stepKey,
            properties,
          });
        }
      }
    });

    return {
      groupedRulePropertyReferences,
      groupedBindingPropertyReferences,
    };
  }, [
    workflow,
    stepKey,
    rulePropertyReferences,
    bindingPropertyReferences,
    aboutEntityProperties,
    getStepType,
  ]);
};
