import { TaskListTypeEnum } from '@btrway/api-task';

export interface MetricContext {
  taskListLabel: string;
  taskLabel: string;
  showTaskLists: boolean;
  showTasks: boolean;
}

export const getMetricContext = (
  taskListType?: TaskListTypeEnum,
  taskListClassification?: string
): MetricContext => {
  if (!taskListType) {
    return {
      taskListLabel: 'Lists',
      taskLabel: 'Tasks',
      showTaskLists: true,
      showTasks: true,
    };
  }

  switch (taskListType) {
    case TaskListTypeEnum.certification:
      if (taskListClassification === 'Coach Certification') {
        return {
          taskListLabel: 'Coaches Certified',
          taskLabel: 'Requirements',
          showTaskLists: true,
          showTasks: false,
        };
      } else if (taskListClassification === 'Student-Athlete Eligibility') {
        return {
          taskListLabel: 'Student-Athletes Eligible',
          taskLabel: 'Requirements',
          showTaskLists: true,
          showTasks: false,
        };
      }
      return {
        taskListLabel: 'Certifications',
        taskLabel: 'Requirements',
        showTaskLists: true,
        showTasks: false,
      };

    case TaskListTypeEnum.todoList:
      return {
        taskListLabel: 'To-Do Lists',
        taskLabel: 'To-Dos',
        showTaskLists: false,
        showTasks: true,
      };

    case TaskListTypeEnum.curriculum:
      return {
        taskListLabel: 'Curriculums',
        taskLabel: 'Courses',
        showTaskLists: true,
        showTasks: true,
      };

    default:
      return {
        taskListLabel: 'Lists',
        taskLabel: 'Tasks',
        showTaskLists: true,
        showTasks: true,
      };
  }
};
