import { UserRoleResponse } from '@btrway/api-security';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

export interface RoleNode extends UserRoleResponse {
  parentId: number | null;
  level: number;
}

export interface RoleManagerState {
  roles: RoleNode[];
  rolesMap: Map<number, RoleNode>;
  selectedRoleId: number | null;
  loading: boolean;
  error: Error | null;
}

const initialState: RoleManagerState = {
  roles: [],
  rolesMap: new Map(),
  selectedRoleId: null,
  loading: true,
  error: null,
};

export interface RoleManagerContextValue {
  state: RoleManagerState;
  setState: Dispatch<SetStateAction<RoleManagerState>>;
}

const RoleManagerContext = createContext<RoleManagerContextValue | undefined>(
  undefined
);

export const useRoleManager = () => {
  const context = useContext(RoleManagerContext);
  if (!context) {
    throw new Error('useRoleManager must be used within a RoleManagerProvider');
  }
  return context;
};

interface RoleManagerProviderProps {
  children: React.ReactNode;
}

export const RoleManagerProvider: React.FC<RoleManagerProviderProps> = ({
  children,
}) => {
  const [state, setState] = React.useState<RoleManagerState>(initialState);

  return (
    <RoleManagerContext.Provider value={{ state, setState }}>
      {children}
    </RoleManagerContext.Provider>
  );
};
