import { TaskConfig, TaskListTypeEnum } from '@btrway/api-tasklist';
import {
  useTaskListService,
  useTaskListUpdater,
} from '@btrway/task-list-service-provider';
import { Box, Button, Group } from '@mantine/core';
import React, { useState } from 'react';
import { CurriculumCoursePicker } from '../CurriculumCoursePicker/CurriculumCoursePicker';
import { FormPacketFormPicker } from '../FormPacketFormPicker/FormPacketFormPicker';

interface TaskListTasksEditorProps {
  onClose: () => void;
}

export const TaskListTasksEditor: React.FC<TaskListTasksEditorProps> = ({
  onClose,
}) => {
  const taskListService = useTaskListService();
  const { updateConfig } = useTaskListUpdater();
  const { data: taskList } = taskListService.getByKey();

  // Initialize local state with current tasks
  const [selectedTasks, setSelectedTasks] = useState<TaskConfig[]>(
    taskList?.taskListConfiguration?.tasks || []
  );

  const handleSave = async () => {
    try {
      await updateConfig({
        taskListConfig: { tasks: selectedTasks },
      });
      onClose();
    } catch (error) {
      console.error('Error saving task list changes:', error);
      // Handle error appropriately
    }
  };

  // Render loading state while taskList data is being fetched
  if (!taskList) {
    return null;
  }

  const renderTaskPicker = () => {
    switch (taskList.taskListType) {
      case TaskListTypeEnum.curriculum:
        return (
          <CurriculumCoursePicker
            selectedTasks={selectedTasks}
            onSelectedTasksChange={setSelectedTasks}
          />
        );
      case TaskListTypeEnum.formPacket:
        return (
          <FormPacketFormPicker
            selectedTasks={selectedTasks}
            onSelectedTasksChange={setSelectedTasks}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ flex: 1 }}>{renderTaskPicker()}</Box>

      <Box
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          backgroundColor: 'var(--mantine-color-gray-0)',
          padding: 'var(--mantine-spacing-md)',
        }}
      >
        <Group justify="flex-end" gap="sm">
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Box>
    </Box>
  );
};

export default TaskListTasksEditor;
