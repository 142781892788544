import { ActionIcon, Card, Group, Stack, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";

interface ProviderCardProps {
    provider: {
      id?: number;
      name: string;
    };
    onEdit: () => void;
  }
  
  export const ProviderCard: React.FC<ProviderCardProps> = ({
    provider,
    onEdit,
  }) => {
    return (
      <Card shadow="xs" padding="sm" radius="md">
        <Group>
          <Stack style={{ flex: 1 }}>
            <Text size="md" fw={500}>
              {provider.name}
            </Text>
          </Stack>
          <ActionIcon size="xs" variant="subtle" onClick={onEdit}>
            <IconEdit />
          </ActionIcon>
        </Group>
      </Card>
    );
  };