import type { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Card, Text } from '@mantine/core';

interface WorkflowCardProps {
  workflow: CommonWorkflow;
  isSelected: boolean;
  onClick: () => void;
}

export const WorkflowCard: React.FC<WorkflowCardProps> = ({
  workflow,
  isSelected,
  onClick,
}) => {
  return (
    <Card
      key={workflow.id || workflow.templateKey}
      shadow="none"
      withBorder
      mb="xs"
      onClick={onClick}
      style={{
        cursor: 'pointer',
        backgroundColor: isSelected ? 'var(--mantine-color-blue-0)' : undefined,
        borderColor: isSelected
          ? 'var(--mantine-color-blue-5)'
          : 'var(--mantine-color-gray-4)',
      }}
    >
      <Text size="sm" fw={500}>
        {workflow.name}
      </Text>
    </Card>
  );
};
