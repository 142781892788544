import { PermissionNode } from '@btrway/permission-manager';
import { RoleNode, useRoleAdmin } from '@btrway/role-manager';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import { RolePermissionNode } from '../RolePermissionNode/RolePermissionNode';

interface PermissionNodesByUserRoleProps {
  node: PermissionNode;
}

export const PermissionNodesByUserRole: React.FC<
  PermissionNodesByUserRoleProps
> = ({ node }) => {
  const { getRootRoles, getChildRoles } = useRoleAdmin();

  // Recursive function to render role hierarchy
  const renderRoleHierarchy = (roles: RoleNode[], level: number = 0) => {
    return roles.map((role) => (
      <Stack key={role.id} gap="xs">
        <RolePermissionNode role={role} node={node} level={level} />
        {/* Render child roles recursively */}
        {getChildRoles(role.id).length > 0 && (
          <Stack gap="xs">
            {renderRoleHierarchy(getChildRoles(role.id), level + 1)}
          </Stack>
        )}
      </Stack>
    ));
  };

  const rootRoles = getRootRoles();

  return (
    <Stack gap="md">
      <Text size="sm" fw={500}>
        Role-specific Permissions
      </Text>
      {renderRoleHierarchy(rootRoles)}
    </Stack>
  );
};
