import { DataTypeEnum, PermissionTypeEnum } from '@btrway/api-security';
import { getDataTypeLabel } from '@btrway/utils';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  PermissionControlSettings,
  PermissionMode,
} from '../types/modeOptions';
import { PermissionNode } from '../types/permissionNode';

// Context value interface
interface PermissionModeContextValue {
  mode: PermissionMode;
  selectedModuleId: string | null;
  setSelectedModuleId: (id: string | null) => void;
  canEditNode: (node: PermissionNode) => boolean;
  canDragNode: (node: PermissionNode) => boolean;
  canAddChildren: (node: PermissionNode) => boolean;
  canDeleteNode: (node: PermissionNode) => boolean;
  getNodeHoverText: (node: PermissionNode) => string | null;
  showBetaBadge: boolean;
  showTypeBadge: boolean;
  showDescription: boolean;
  showSettingPermissions: boolean;
  getPermissionControlSettings: (
    node: PermissionNode
  ) => PermissionControlSettings;
  getContainerDescriptions: () => {
    entities: string;
    tools: string;
    settings: string;
  };
  getEntityTypeOptions: (
    node: PermissionNode
  ) => Array<{ value: string; label: string } | { value: ''; label: string }>;
}

// Create context
const PermissionModeContext = createContext<
  PermissionModeContextValue | undefined
>(undefined);

// Provider props
interface PermissionModeProviderProps {
  mode: PermissionMode;
  children: React.ReactNode;
}

export const PermissionModeProvider: React.FC<PermissionModeProviderProps> = ({
  mode,
  children,
}) => {
  // State for selected module
  const [selectedModuleId, setSelectedModuleId] = useState<string | null>(null);

  // Mode-specific behavior implementations
  const canEditNode = useCallback(
    (node: PermissionNode): boolean => {
      switch (mode) {
        case 'admin':
          return true;
        case 'organizationGrant':
        case 'userRoleGrant':
          return false;
      }
    },
    [mode]
  );

  const canDragNode = useCallback(
    (node: PermissionNode): boolean => {
      switch (mode) {
        case 'admin':
          return node.permissionType === PermissionTypeEnum.module;
        case 'organizationGrant':
        case 'userRoleGrant':
          return false;
      }
    },
    [mode]
  );

  const canAddChildren = useCallback(
    (node: PermissionNode): boolean => {
      switch (mode) {
        case 'admin':
          return (
            node.permissionType === PermissionTypeEnum.module ||
            node.permissionType === PermissionTypeEnum.group
          );
        case 'organizationGrant':
        case 'userRoleGrant':
          return false;
      }
    },
    [mode]
  );

  const canDeleteNode = useCallback(
    (node: PermissionNode): boolean => {
      switch (mode) {
        case 'admin':
          return true;
        case 'organizationGrant':
        case 'userRoleGrant':
          return false;
      }
    },
    [mode]
  );

  const getNodeHoverText = useCallback(
    (node: PermissionNode): string | null => {
      switch (mode) {
        case 'admin':
          return 'Click to edit';
        case 'organizationGrant':
          return 'Configure organization permissions';
        case 'userRoleGrant':
          return 'Configure role permissions';
      }
    },
    [mode]
  );

  // Display configuration based on mode
  const showBetaBadge = useMemo(() => mode === 'admin', [mode]);
  const showTypeBadge = useMemo(() => mode !== 'userRoleGrant', [mode]);
  const showDescription = useMemo(() => true, []);
  const showSettingPermissions = useMemo(
    () => mode !== 'userRoleGrant',
    [mode]
  );

  // Container descriptions based on mode
  const getContainerDescriptions = useCallback(() => {
    switch (mode) {
      case 'admin':
        return {
          entities: 'Configure data type permissions',
          tools:
            'Tools can be configured on/off by organization and within the organization by role.',
          settings:
            'Settings are not visible or adjustable by client administrators.',
        };
      case 'organizationGrant':
        return {
          entities:
            'Client administrators can configure any of these access levels by role.',
          tools:
            'Enabling a tool will make it available for client administrators to enable for roles.',
          settings:
            'Settings are not visible or adjustable by client administrators.',
        };
      case 'userRoleGrant':
        return {
          entities: 'Set role-specific data access levels',
          tools: 'Enable/disable tools for role',
          settings: 'Configure role settings access',
        };
    }
  }, [mode]);

  const getPermissionControlSettings = useCallback(
    (node: PermissionNode): PermissionControlSettings => {
      const controlTypes = [
        PermissionTypeEnum.entity,
        PermissionTypeEnum.setting,
        PermissionTypeEnum.tool,
        PermissionTypeEnum.module,
      ] as const;

      const shouldShow = controlTypes.includes(
        node.permissionType as (typeof controlTypes)[number]
      );

      switch (mode) {
        case 'admin':
          return {
            show: shouldShow,
            disabled: true, // Always disabled in admin mode
          };
        case 'organizationGrant':
          return {
            show: shouldShow,
            disabled: node.permissionType === PermissionTypeEnum.entity, // Only entities are disabled in org mode
          };
        case 'userRoleGrant':
          return {
            show: shouldShow,
            disabled: false, // All enabled in user role mode
          };
        default:
          return { show: false, disabled: true };
      }
    },
    [mode]
  );

  const getEntityTypeOptions = useCallback(
    (node: PermissionNode) => {
      if (
        mode === 'admin' &&
        node.permissionType === PermissionTypeEnum.entity
      ) {
        return [
          { value: '', label: 'None' },
          { value: DataTypeEnum.userRole, label: getDataTypeLabel('userRole') },
        ];
      }
      return [];
    },
    [mode]
  );

  // Create memoized context value
  const value = useMemo(
    () => ({
      mode,
      selectedModuleId,
      setSelectedModuleId,
      canEditNode,
      canDragNode,
      canAddChildren,
      canDeleteNode,
      getNodeHoverText,
      showBetaBadge,
      showTypeBadge,
      showDescription,
      showSettingPermissions,
      getPermissionControlSettings,
      getContainerDescriptions,
      getEntityTypeOptions,
    }),
    [
      mode,
      selectedModuleId,
      canEditNode,
      canDragNode,
      canAddChildren,
      canDeleteNode,
      getNodeHoverText,
      showBetaBadge,
      showTypeBadge,
      showDescription,
      showSettingPermissions,
      getPermissionControlSettings,
      getContainerDescriptions,
      getEntityTypeOptions,
    ]
  );

  return (
    <PermissionModeContext.Provider value={value}>
      {children}
    </PermissionModeContext.Provider>
  );
};

// Custom hook for accessing mode context
export const usePermissionMode = () => {
  const context = useContext(PermissionModeContext);
  if (!context) {
    throw new Error(
      'usePermissionMode must be used within PermissionModeProvider'
    );
  }
  return context;
};

// Re-export types
export type { PermissionModeContextValue };
