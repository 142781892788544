import { PermissionApplicationEnum } from '@btrway/api-security';
import { HeaderLayout } from '@btrway/layout-components';
import { PermissionsLayout } from '@btrway/permission-components';
import {
  PermissionManagerProvider,
  PermissionModeProvider,
  usePermissionAdmin,
  usePermissionManager,
  usePermissions,
} from '@btrway/permission-manager';
import { Button, Group, Select, Text } from '@mantine/core';
import React from 'react';

const PermissionsAdminContent: React.FC = () => {
  const { getRootModules, getChildPermissionsByType, getChildPermissions } =
    usePermissions();
  const {
    selectedApplication,
    addPermission,
    saveChanges,
    discardChanges,
    hasPendingChanges,
    errors,
  } = usePermissionAdmin();
  const { actions } = usePermissionManager();

  return (
    <HeaderLayout
      header="Permissions Administration"
      subtitleComponent={
        <Group>
          <Text size="md" fw={500}>
            Applications:
          </Text>
          <Select
            value={selectedApplication}
            onChange={(value) =>
              actions.setSelectedApplication(value as PermissionApplicationEnum)
            }
            data={[
              {
                value: PermissionApplicationEnum.internal,
                label: 'Internal',
              },
              { value: PermissionApplicationEnum.client, label: 'Client' },
            ]}
          />
        </Group>
      }
      rightComponent={
        <Group>
          <Button
            variant="outline"
            color="gray"
            onClick={discardChanges}
            disabled={!hasPendingChanges}
          >
            Discard Changes
          </Button>
          <Button onClick={saveChanges} disabled={!hasPendingChanges}>
            Save Changes
          </Button>
        </Group>
      }
    >
      <PermissionsLayout />
    </HeaderLayout>
  );
};

const PermissionsAdminPage: React.FC = () => {
  return (
    <PermissionModeProvider mode="admin">
      <PermissionManagerProvider>
        <PermissionsAdminContent />
      </PermissionManagerProvider>
    </PermissionModeProvider>
  );
};

export default PermissionsAdminPage;
