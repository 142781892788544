import { TaskListView } from '@btrway/task-list-builder';
import { TaskListServiceProvider } from '@btrway/task-list-service-provider';
import { Card, ScrollArea } from '@mantine/core';
import React from 'react';

interface TaskListDetailProps {
  taskListKey: string;
}

export const TaskListDetail: React.FC<TaskListDetailProps> = ({
  taskListKey,
}) => {
  return (
    <Card mt="lg" bg="gray.3" withBorder p={0} radius="md">
      <ScrollArea h={300} offsetScrollbars type="auto" pl="xs" pt={0} pb={0}>
        <TaskListServiceProvider taskListKey={taskListKey}>
          <TaskListView
            taskListKey={taskListKey}
            // onTaskSelected={(taskKey) => {}}
            // onTaskDeleted={(taskKey) => {}}
          />
        </TaskListServiceProvider>
      </ScrollArea>
    </Card>
  );
};
