import {
  PermissionNode,
  usePermissionOrdering,
} from '@btrway/permission-manager';
import { useDrop } from 'react-dnd';
import { DragItem } from '../types/dragItem';

interface DropProps {
  parentClientId: string | null;
  canAcceptChild: (draggedNode: PermissionNode) => boolean;
  onDrop?: (draggedNode: PermissionNode) => void;
  index?: number;
  level?: number;
}
export const usePermissionDrop = ({
  parentClientId,
  canAcceptChild,
  onDrop,
  index,
  level = 0,
}: DropProps) => {
  const { canReorderNode, reorderNodes } = usePermissionOrdering();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'PERMISSION',
    canDrop: (item: DragItem) => {
      return (
        canAcceptChild(item.node) &&
        canReorderNode({
          nodeClientId: item.node.clientId,
          targetParentId: parentClientId,
          targetLevel: level,
        })
      );
    },
    drop: (item: DragItem) => {
      // First call onDrop to handle permission type change
      onDrop?.(item.node);

      // Then handle reordering if needed
      if (item.sourceParentClientId !== parentClientId) {
        reorderNodes({
          targetParentId: parentClientId,
          draggedNode: item.node,
          targetIndex: index,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return {
    isOver,
    canDrop,
    drop,
  };
};
