import { Button, Card, Group, Stack, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';

export const NewTaskListCard: React.FC<{
  isEditing: boolean;
  onStartNew: () => void;
  onSave: (name: string) => void;
  onCancel: () => void;
}> = ({ isEditing, onStartNew, onSave, onCancel }) => {
  const [newTaskListName, setNewTaskListName] = useState('');

  if (!isEditing) {
    return (
      <Card
        shadow="none"
        padding="xs"
        radius="md"
        withBorder
        onClick={onStartNew}
        style={{
          cursor: 'pointer',
          backgroundColor: 'var(--mantine-color-red-0)',
          borderColor: 'var(--mantine-color-red-3)',
        }}
      >
        <Group wrap="nowrap">
          <IconPlus size={16} color="var(--mantine-color-red-6)" />
          <Text fz="sm" c="red.6">
            Create New
          </Text>
        </Group>
      </Card>
    );
  }

  return (
    <Card
      shadow="none"
      padding="xs"
      radius="md"
      withBorder
      style={{
        backgroundColor: 'var(--mantine-color-red-0)',
        borderColor: 'var(--mantine-color-red-3)',
      }}
    >
      <Stack gap="xs">
        <TextInput
          placeholder="Enter task list name"
          value={newTaskListName}
          onChange={(event) => setNewTaskListName(event.currentTarget.value)}
          autoFocus
          size="xs"
        />
        <Group justify="flex-end" gap="xs">
          <Button
            variant="subtle"
            size="xs"
            color="red"
            onClick={() => {
              setNewTaskListName('');
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            size="xs"
            color="red"
            onClick={() => {
              if (newTaskListName.trim()) {
                onSave(newTaskListName);
                setNewTaskListName('');
              }
            }}
          >
            Continue
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};
