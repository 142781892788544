import { OrganizationFilter } from '@btrway/api-workflow';
import {
  useOrganizationTypes,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import { Chip, Group, Stack, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

interface OrganizationFilterEditorProps {
  filter: OrganizationFilter;
  onFilterChange: (filter: OrganizationFilter) => void;
}

export const OrganizationFilterEditor: React.FC<
  OrganizationFilterEditorProps
> = ({ filter, onFilterChange }) => {
  const { stateProvinces, isLoading: isLoadingStates } = useStateProvinces();
  const { organizationTypes, isLoading: isLoadingOrganizationTypes } =
    useOrganizationTypes();

  const [selectedStates, setSelectedStates] = useState<string[]>(
    filter.stateCodes || []
  );
  const [selectedOrgTypes, setSelectedOrgTypes] = useState<string[]>(
    filter.organizationTypeIds?.map(String) || []
  );

  const sortedStates = useMemo(
    () =>
      stateProvinces?.slice().sort((a, b) => a.code.localeCompare(b.code)) ||
      [],
    [stateProvinces]
  );

  const sortedOrgTypes = useMemo(
    () =>
      organizationTypes
        ?.filter((type) => !type.internal)
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [organizationTypes]
  );

  const handleStateChange = (codes: string[]) => {
    setSelectedStates(codes);
    onFilterChange({
      ...filter,
      stateCodes: codes,
    });
  };

  const handleOrgTypeChange = (typeIds: string[]) => {
    setSelectedOrgTypes(typeIds);
    onFilterChange({
      ...filter,
      organizationTypeIds: typeIds.map(Number),
    });
  };

  if (isLoadingStates || isLoadingOrganizationTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Stack gap="md">
      <div>
        <Text fz="md" fw={600} mb="md">
          Filter by Organization Type
        </Text>
        <Chip.Group
          multiple
          value={selectedOrgTypes}
          onChange={handleOrgTypeChange}
        >
          <Group gap="xs" justify="flex-start">
            {sortedOrgTypes.map((orgType) => (
              <Chip key={orgType.id} value={String(orgType.id)}>
                {orgType.name}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
      </div>

      <div>
        <Text fz="md" fw={600} mb="md">
          Filter By State
        </Text>
        <Chip.Group
          multiple
          value={selectedStates}
          onChange={handleStateChange}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(5, 1fr)',
              gap: '10px',
            }}
          >
            {sortedStates.map((state) => (
              <Chip
                key={state.code}
                value={state.code}
                styles={{
                  label: {
                    width: '100%',
                    justifyContent: 'center',
                  },
                }}
              >
                {state.code}
              </Chip>
            ))}
          </div>
        </Chip.Group>
      </div>
    </Stack>
  );
};
