import { TopNavigationLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import {
  ChildWorkgroupTypeLabel,
  WorkgroupHeader,
  WorkgroupProvider,
  WorkgroupRedirect,
} from '@btrway/workgroup-components';
import { IconHome, IconTopologyStar3, IconUsers } from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded workgroup components
const WorkgroupCalendarView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupCalendarView,
  }))
);
const WorkgroupCommunicationsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupCommunicationsView,
  }))
);
const MessageDetailPage = lazy(() =>
  import('@btrway/message-detail').then((module) => ({
    default: module.MessageDetailPage,
  }))
);
const WorkgroupDashboardView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupDashboardView,
  }))
);
const WorkgroupPeopleView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupPeopleView,
  }))
);
const WorkgroupRegistrationsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupRegistrationsView,
  }))
);
const WorkgroupSubgroupsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupSubgroupsView,
  }))
);

const WorkgroupScheduleView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupScheduleView,
  }))
);

const WorkgroupFacilitiesPage = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupFacilitiesPage,
  }))
);

// Lazy loaded registration form components
const RegistrationFormsView = lazy(() =>
  import('@btrway/registration-form-administration').then((module) => ({
    default: module.RegistrationFormsView,
  }))
);
const RegistrationFormDetail = lazy(() =>
  import('@btrway/registration-form-administration').then((module) => ({
    default: module.RegistrationFormDetail,
  }))
);

export const opsRoutes: RouteConfig[] = [
  {
    path: 'ops',
    element: <WorkgroupRedirect path="ops" />,
    label: 'Day-to-Day Ops',
    // labelComponent: WorkgroupNavLabel,
    icon: IconTopologyStar3,
    navLevel: 'primary',
    navContainer: 'second',
    //permissions: ['WORKGROUP'],
  },
  {
    path: 'ops/:workgroupId',
    label: 'Day-to-Day Ops',
    element: (
      <WorkgroupProvider>
        <TopNavigationLayout
          subHeader={<WorkgroupHeader path="ops" />}
          contentPadding={0}
          variant="light"
        />
      </WorkgroupProvider>
    ),
    children: [
      {
        path: 'dashboard',
        element: <WorkgroupDashboardView />,
        label: 'Dashboard',
        icon: IconHome,
        navLevel: 'secondary',
        displayType: 'icon',
      },
      {
        path: 'people',
        element: <WorkgroupPeopleView />,
        label: 'People',
        icon: IconUsers,
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_PEOPLE'],
      },
      {
        path: 'workgroups',
        element: <WorkgroupSubgroupsView />,
        label: 'Workgroups',
        labelComponent: ChildWorkgroupTypeLabel,
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_WORKGROUPS'],
      },
      {
        path: 'calendar',
        element: <WorkgroupCalendarView />,
        label: 'Calendar',
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_CALENDAR'],
      },
      {
        path: 'schedule',
        element: <WorkgroupScheduleView />,
        label: 'Schedule',
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_CALENDAR'],
      },
      {
        path: 'facilities',
        element: <WorkgroupFacilitiesPage />,
        label: 'Facilities',
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_CALENDAR'],
      },
      {
        path: 'communications',
        element: <WorkgroupCommunicationsView />,
        label: 'Communications',
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_COMMUNICATIONS'],
      },
      {
        path: 'communications/:messageId',
        element: <MessageDetailPage />,
        //permissions: ['WORKGROUP_COMMUNICATIONS'],
      },
      {
        path: 'registrations',
        element: <WorkgroupRegistrationsView />,
        label: 'Registrations',
        navLevel: 'secondary',
        displayType: 'label',
        //permissions: ['WORKGROUP_REGISTRATIONS'],
      },
      // {
      //   path: 'settings',
      //   element: <LeftNavigationLayout displayHeader={false} />,
      //   label: 'Settings',
      //   icon: IconSettings,
      //   navLevel: 'secondary',
      //   displayType: 'icon',
      //   //permissions: ['SETTINGS'],
      //   children: [
      //     {
      //       path: 'registration-forms',
      //       element: <RegistrationFormsView />,
      //       label: 'Registration Forms',
      //       navLevel: 'secondary',
      //     },
      //     {
      //       path: 'registration-forms/:id',
      //       element: <RegistrationFormDetail />,
      //     },
      //   ],
      // },
    ],
  },
];
