import { TaskInstanceRequest, TaskInstanceResponse } from '@btrway/api-task';

/**
 * Converts a TaskInstanceResponse to a TaskInstanceRequest.
 * Used when updating or completing tasks.
 *
 * @param taskInstance The task instance response to convert
 * @param comment Optional comment to include in the request
 * @returns TaskInstanceRequest object
 */
export const convertToTaskInstanceRequest = (
  taskInstance: Partial<TaskInstanceResponse>,
  comment?: string
): TaskInstanceRequest => {
  // Destructure the fields we want to exclude or handle separately
  const { workflowInstance, workflowStepInstance, ...commonFields } =
    taskInstance;

  return {
    ...commonFields,
    // Optional comment field
    ...(comment && { comment }),
    // Ensure required fields are present with defaults
    completed: taskInstance.completed ?? false,
    organizationId: taskInstance.organizationId!,
    assignedEntityId: taskInstance.assignedEntityId!,
    assignedEntityType: taskInstance.assignedEntityType!,
    viewed: taskInstance.viewed ?? false,
  };
};
