import {
  OrganizationFilter,
  TaskListTemplateSettings,
} from '@btrway/api-tasklist';
import { useOrganizationTypes } from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/role-manager';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { DeploymentType } from '../../types/deploymentType';
import { formatRoleNames } from '../../utils/defaultRolesUtils';
import { DeploymentFilterDrawer } from '../DeploymentFilterDrawer/DeploymentFilterDrawer';

interface DeploymentFilterViewProps {
  deploymentType: DeploymentType;
  initialOrganizationFilter?: OrganizationFilter;
  initialTaskListTemplateSettings?: TaskListTemplateSettings;
  onSettingsUpdate?: (settings: {
    organizationFilter: OrganizationFilter;
    taskListTemplateSettings?: TaskListTemplateSettings;
  }) => Promise<void>;
}

export const DeploymentFilterView: React.FC<DeploymentFilterViewProps> = ({
  deploymentType,
  initialOrganizationFilter = {},
  initialTaskListTemplateSettings = {},
  onSettingsUpdate,
}) => {
  const { getOrganizationTypeById } = useOrganizationTypes();
  const { userRoles } = useUserRoles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [organizationFilter, setOrganizationFilter] =
    useState<OrganizationFilter>(initialOrganizationFilter);
  const [taskListTemplateSettings, setTaskListTemplateSettings] =
    useState<TaskListTemplateSettings>(initialTaskListTemplateSettings);
  const { isTemplateAdministration } = useTemplateDefinition();

  if (!isTemplateAdministration) {
    return null;
  }

  const handleClose = () => setIsDrawerOpen(false);

  const handleSave = async (data: {
    organizationFilter: OrganizationFilter;
    taskListTemplateSettings?: TaskListTemplateSettings;
  }) => {
    setOrganizationFilter(data.organizationFilter);
    if (data.taskListTemplateSettings) {
      setTaskListTemplateSettings(data.taskListTemplateSettings);
    }

    if (onSettingsUpdate) {
      await onSettingsUpdate(data);
    }
    handleClose();
  };

  const handleButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const renderOrgTypesSummary = () => {
    const orgTypeIds = organizationFilter?.organizationTypeIds || [];

    if (!orgTypeIds.length) {
      return <Text fz="sm">All Organization Types</Text>;
    }

    return (
      <Text fz="sm">
        {orgTypeIds
          .map((id) => getOrganizationTypeById(id)?.name)
          .filter(Boolean)
          .sort()
          .join(', ')}
      </Text>
    );
  };

  const renderStatesSummary = () => {
    const states = organizationFilter?.stateCodes || [];

    if (!states.length) {
      return <Text fz="sm">All States</Text>;
    }

    return <Text fz="sm">{[...states].sort().join(', ')}</Text>;
  };

  const renderRoleSummary = () => {
    if (deploymentType !== 'taskList') {
      return null;
    }

    const roles = taskListTemplateSettings?.defaultUserRoles || [];

    if (!roles.length) {
      return (
        <Stack gap={4}>
          <Text fz="sm" fw={600}>
            Default Roles:
          </Text>
          <Text fz="sm">None</Text>
        </Stack>
      );
    }

    const roleNames = formatRoleNames(roles, userRoles);

    return (
      <Stack gap={4}>
        <Text fz="sm" fw={600}>
          Default Roles for Assignments:
        </Text>
        <Text fz="sm">{roleNames}</Text>
      </Stack>
    );
  };

  return (
    <Stack gap="md">
      <Card
        padding="xs"
        radius="md"
        bg="red.1"
        withBorder
        style={{
          border: '1px solid var(--mantine-color-gray-5)',
          width: '300px',
        }}
      >
        <Stack gap="xs">
          <Group justify="space-between" align="center">
            <Text fw={600}>Deployment Settings</Text>
            <Button
              variant="subtle"
              size="compact-sm"
              onClick={handleButtonClick}
            >
              Edit
            </Button>
          </Group>

          <Stack gap={4}>
            <Text fz="sm" fw={600}>
              Organization Types:
            </Text>
            {renderOrgTypesSummary()}
          </Stack>

          <Stack gap={4}>
            <Text fz="sm" fw={600}>
              States:
            </Text>
            {renderStatesSummary()}
          </Stack>

          {renderRoleSummary()}
        </Stack>
      </Card>

      <DeploymentFilterDrawer
        deploymentType={deploymentType}
        opened={isDrawerOpen}
        onClose={handleClose}
        onSave={handleSave}
        initialOrganizationFilter={organizationFilter}
        initialTaskListTemplateSettings={
          deploymentType === 'taskList' ? taskListTemplateSettings : undefined
        }
      />
    </Stack>
  );
};

export default DeploymentFilterView;
