import { TaskListTypeEnum } from '@btrway/api-tasklist';
import {
  TemplateDeploymentActionProperties,
  useDeployWorkflowTemplates,
  WorkflowTypeEnum,
} from '@btrway/api-template';
import { CommonExternalCourse } from '@btrway/external-course-service-provider';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import pluralize from 'pluralize';
import React from 'react';
import { DeploymentType } from '../../types/deploymentType';

interface DeploymentButtonProps {
  type?: DeploymentType;
  workflow?: CommonWorkflow | null;
  workflowType?: WorkflowTypeEnum;
  taskList?: CommonTaskList | null;
  taskListType?: TaskListTypeEnum;
  externalCourse?: CommonExternalCourse | null;
}

const getFriendlyWorkflowType = (type: WorkflowTypeEnum): string => {
  const friendlyNames: Record<WorkflowTypeEnum, string> = {
    automation: 'Automation',
    form: 'Form',
    file: 'File',
  };
  return friendlyNames[type];
};

const getFriendlyTaskListType = (type: TaskListTypeEnum): string => {
  const friendlyNames: Record<TaskListTypeEnum, string> = {
    curriculum: 'Curriculum',
    todoList: 'To-Do List',
    formPacket: 'Form Packet',
    certification: 'Certification',
  };
  return friendlyNames[type];
};

export const DeploymentButton: React.FC<DeploymentButtonProps> = ({
  type,
  workflow,
  workflowType,
  taskList,
  taskListType,
  externalCourse,
}) => {
  const { mutateAsync: deployWorkflowTemplates } = useDeployWorkflowTemplates();
  const { isTemplateAdministration } = useTemplateDefinition();

  if (!isTemplateAdministration) {
    return null;
  }

  const createDeploymentProps = (
    deploymentType: DeploymentType
  ): TemplateDeploymentActionProperties => ({
    deployWorkflowTemplates: deploymentType === 'workflow',
    deployTaskListTemplates: deploymentType === 'taskList',
    deployExternalCourseTemplates: deploymentType === 'externalCourse',
    workflowTemplateIds:
      deploymentType === 'workflow' && workflow?.id ? [workflow.id] : undefined,
    workflowTypes:
      deploymentType === 'workflow' && workflowType
        ? [workflowType]
        : undefined,
    taskListTemplateIds:
      deploymentType === 'taskList' && taskList?.id ? [taskList.id] : undefined,
    taskListTypes:
      deploymentType === 'taskList' && taskListType
        ? [taskListType]
        : undefined,
    externalCourseTemplateIds:
      deploymentType === 'externalCourse' && externalCourse?.id
        ? [externalCourse.id]
        : undefined,
    organizationIds: undefined,
  });

  const handleClick = async () => {
    try {
      if (type) {
        // Deploy single type
        await deployWorkflowTemplates({
          data: createDeploymentProps(type),
        });
      } else {
        // Deploy all types
        const deploymentTypes: DeploymentType[] = [
          'workflow',
          'taskList',
          'externalCourse',
        ];
        await Promise.all(
          deploymentTypes.map((deployType) =>
            deployWorkflowTemplates({
              data: createDeploymentProps(deployType),
            })
          )
        );
      }

      notifications.show({
        title: 'Success',
        message: 'Deployment Initiated',
        color: 'green',
      });
    } catch (error) {
      console.error('Failed to initiate deployment:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to initiate deployment',
        color: 'red',
      });
    }
  };

  const getButtonText = () => {
    if (!type) {
      return 'Deploy All Templates';
    }

    switch (type) {
      case 'workflow':
        if (workflow) {
          return `Deploy ${getFriendlyWorkflowType(workflow.workflowType)}`;
        }
        return workflowType
          ? `Deploy All ${pluralize(getFriendlyWorkflowType(workflowType))}`
          : 'Deploy All Workflows';

      case 'taskList':
        if (taskList) {
          return `Deploy ${getFriendlyTaskListType(taskList.taskListType)}`;
        }
        return taskListType
          ? `Deploy All ${pluralize(getFriendlyTaskListType(taskListType))}`
          : 'Deploy All Task Lists';

      case 'externalCourse':
        return externalCourse
          ? 'Deploy External Course'
          : 'Deploy All External Courses';
    }
  };

  return <Button onClick={handleClick}>{getButtonText()}</Button>;
};
