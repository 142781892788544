import { TaskTypeEnum } from '@btrway/api-task';
import {
  AssignTaskInputProperties,
  FormTaskProperties,
  StepConfig,
  StepTypeEnum,
} from '@btrway/api-workflow';
import { useCourses } from '@btrway/courseware-manager';
import { useTaskTypes } from '@btrway/workflow-manager';
import { useWorkflowService } from '@btrway/workflow-service-provider';
import { Text } from '@mantine/core';
import React, { useMemo } from 'react';
import { StepConfigAssignmentView } from '../StepConfigAssignmentView/StepConfigAssignmentView';

interface StepConfigContentProps {
  step: StepConfig;
  workflowKey: string;
  selectedTitle?: string;
  isAutomatedStep?: boolean;
  stepType?: any;
}

export const StepConfigContent: React.FC<StepConfigContentProps> = ({
  step,
  workflowKey,
  selectedTitle,
  isAutomatedStep,
  stepType,
}) => {
  const { getTaskType } = useTaskTypes();
  const workflowService = useWorkflowService();
  const { workflows } = workflowService.getWorkflows();
  const { getCourse } = useCourses();

  const getWorkflowByKey = useMemo(() => {
    return (workflowKey: string) =>
      workflows.find((w) => {
        const key =
          w.sourceType === 'definition' ? w.workflowKey : w.templateKey;
        return key === workflowKey;
      });
  }, [workflows]);

  if (isAutomatedStep) {
    return <>{step?.title || stepType?.name || 'Automated Step'}</>;
  }

  if (step?.stepTypeCode === StepTypeEnum.assignTask) {
    const { taskType, taskProperties } =
      step.inputProperties as AssignTaskInputProperties;

    if (!taskType) {
      return null;
    }

    let itemName: string | null = null;
    let taskTypeName: string = '';

    switch (taskType) {
      case TaskTypeEnum.submitForm: {
        const formProperties = taskProperties as FormTaskProperties;
        const formWorkflow = getWorkflowByKey(
          formProperties?.workflowSelection.workflowDefinitionKey?.value || ''
        );
        itemName = formWorkflow?.name || 'Form';
        break;
      }

      default: {
        const taskTypeDTO = getTaskType(taskType);
        taskTypeName = taskTypeDTO?.name || taskType;
        break;
      }
    }

    const displayName =
      itemName || step.title || taskTypeName || selectedTitle || 'Task';
    const textColor = itemName ? 'blue.8' : 'inherit';

    return (
      <Text component="span">
        Assign{' '}
        <Text component="span" c={textColor} fw={600} inherit>
          {displayName}
        </Text>{' '}
        to <StepConfigAssignmentView step={step} workflowKey={workflowKey} />
      </Text>
    );
  }

  return (
    <>{step?.title || stepType?.name || selectedTitle || 'Unknown Step'}</>
  );
};

// case TaskTypeEnum.completeCurriculum: {
//   const curriculumProperties =
//     taskConfig.taskProperties as CurriculumTaskProperties;
//   const curriculumWorkflow = getWorkflowByKey(
//     curriculumProperties?.taskListSelection.taskListKey?.value || ''
//   );
//   itemName = curriculumWorkflow?.name || 'Curriculum';
//   break;
// }
// case TaskTypeEnum.completeTaskList: {
//   const taskListProperties =
//     taskConfig.taskProperties as TaskListTaskProperties;
//   const workflow = getWorkflowByKey(
//     taskListProperties?.taskListSelection.taskListKey?.value || ''
//   );
//   itemName = workflow?.name || 'Task List';
//   break;
// }
// case TaskTypeEnum.completeFormPacket: {
//   const formPacketProperties =
//     taskConfig.taskProperties as FormPacketTaskProperties;
//   const workflow = getWorkflowByKey(
//     formPacketProperties?.taskListSelection.taskListKey?.value || ''
//   );
//   itemName = workflow?.name || 'Form Packet';
//   break;
// }
// case TaskTypeEnum.certification: {
//   const certificationProperties =
//     taskConfig.taskProperties as CertificationTaskProperties;
//   const workflow = getWorkflowByKey(
//     certificationProperties?.taskListSelection.taskListKey?.value || ''
//   );
//   itemName = workflow?.name || 'Certification';
//   break;
// }
// case TaskTypeEnum.completeCourse: {
//   const courseProperties =
//     taskConfig.taskProperties as CourseTaskProperties;
//   const courseUid = courseProperties?.courseSelection.courseUid?.value;
//   const course = getCourse(courseUid || 0);
//   itemName = course?.title || 'Course';
//   break;
// }
