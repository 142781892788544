import { TopNavigationLayout } from '@btrway/layout-components';
import { MyHeader } from '@btrway/scoreboard-components';
import { RouteConfig } from '@btrway/web-routing';
import {
  IconAward,
  IconCalendar,
  IconClockCheck,
  IconDeviceTv,
  IconHome,
  IconListCheck,
  IconStar,
} from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded components
const CertificationsPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.CertificationsPage,
  }))
);

const MyCalendarPage = lazy(() =>
  import('@btrway/calendar-components').then((module) => ({
    default: module.MyCalendarPage,
  }))
);

const GroupedTasksView = lazy(() =>
  import('@btrway/task-grids').then((module) => ({
    default: module.GroupedTasksView,
  }))
);

const TaskAssignmentsGrid = lazy(() =>
  import('@btrway/task-grids').then((module) => ({
    default: module.TaskAssignmentsGrid,
  }))
);

const HomePage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.HomePage,
  }))
);
const ApprovalsPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.ApprovalsPage,
  }))
);
const ToDosPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.ToDosPage,
  }))
);
const CalendarPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.CalendarPage,
  }))
);
const CurriculumsPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.CurriculumsPage,
  }))
);

export const myRoutes: RouteConfig[] = [
  {
    path: 'my',
    element: (
      <TopNavigationLayout
        header={<MyHeader />}
        contentPadding={0}
        headerBackgroundColor="#25274D"
        variant="dark"
      />
    ),
    label: 'My Better Way',
    icon: IconStar,
    navLevel: 'primary',
    navContainer: 'first',
    // permissions: ['WORKGROUP'],
    children: [
      {
        path: 'dashboard',
        element: <HomePage />,
        label: 'Dashboard',
        icon: IconHome,
        navLevel: 'secondary',
        displayType: 'icon',
      },
      {
        path: 'certifications',
        element: <CertificationsPage />,
        label: 'Certifications',
        icon: IconAward,
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['STAFF_DASHBOARD'],
      },
      {
        path: 'approvals',
        element: <ApprovalsPage />,
        label: 'Approvals',
        // labelComponent: TasksNavLabel,
        icon: IconClockCheck,
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['TODOS'],
      },
      {
        path: 'todos',
        element: <ToDosPage />,
        label: 'To-Dos',
        icon: IconListCheck,
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['CHECKLISTS'],
      },
      {
        path: 'curriculums',
        element: <CurriculumsPage />,
        label: 'Curriculums',
        icon: IconDeviceTv,
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['SCHEDULE'],
      },
      {
        path: 'calendar',
        element: <CalendarPage />,
        label: 'Calendar',
        icon: IconCalendar,
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['SCHEDULE'],
      },
    ],
  },
];
