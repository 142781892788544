import {
  DataTypeEnum,
  TaskInstanceResponse,
  TaskListDefinitionNode,
  TaskListTypeEnum,
  TaskTypeEnum,
} from '@btrway/api-task';
import { useCallback } from 'react';
import { useTasksManager } from '../providers/TasksManagerProvider';
import { TaskStats } from '../types/taskStats';

interface UseGetPersonTasksReturn {
  getTasksByPersonId: (
    personId: number,
    taskListType?: TaskListTypeEnum
  ) => TaskInstanceResponse[];
  getTasksByListKey: (taskListKey: string) => TaskInstanceResponse[];
  getTaskStatsByPersonId: (
    personId: number,
    taskListType?: TaskListTypeEnum
  ) => TaskStats;
  getTaskListDefinitions: () => TaskListDefinitionNode[];
  getCurrentMonthTasks: (
    personId: number,
    taskListType?: TaskListTypeEnum
  ) => TaskInstanceResponse[];
  getApprovalTasksByPersonId: (personId: number) => TaskInstanceResponse[];
  getApprovalTaskStatsByPersonId: (personId: number) => TaskStats;
}

export const useGetPersonTasks = (): UseGetPersonTasksReturn => {
  const { tasks, taskGraphs } = useTasksManager();

  const getTasksByPersonId = useCallback(
    (
      personId: number,
      taskListType?: TaskListTypeEnum
    ): TaskInstanceResponse[] => {
      if (!tasks) return [];

      const personTasks = tasks.filter(
        (task) =>
          task.assignedEntityType === DataTypeEnum.person &&
          task.assignedEntityId === personId
      );

      if (!taskListType) {
        return personTasks;
      }

      // Filter tasks by taskListType using the taskGraph
      const relevantListKeys = (taskGraphs ?? [])
        .filter((node) => node.taskListType === taskListType)
        .map((node) => node.taskListKey);

      return personTasks.filter(
        (task) =>
          task.derivedTaskData?.parentTaskListKey &&
          relevantListKeys.includes(task.derivedTaskData.parentTaskListKey)
      );
    },
    [tasks, taskGraphs]
  );

  const getTasksByListKey = useCallback(
    (taskListKey: string): TaskInstanceResponse[] => {
      if (!tasks) return [];

      return tasks.filter(
        (task) => task.derivedTaskData?.parentTaskListKey === taskListKey
      );
    },
    [tasks]
  );

  const calculateTaskStats = useCallback(
    (tasks: TaskInstanceResponse[]): TaskStats => {
      const total = tasks.length;
      const completed = tasks.filter((task) => task.completed ?? false).length;

      return {
        completed,
        total,
        progress: total > 0 ? (completed / total) * 100 : 0,
        remainingCount: total - completed,
      };
    },
    []
  );

  const getTaskStatsByPersonId = useCallback(
    (personId: number, taskListType?: TaskListTypeEnum): TaskStats => {
      const tasks = getTasksByPersonId(personId, taskListType);
      return calculateTaskStats(tasks);
    },
    [getTasksByPersonId, calculateTaskStats]
  );

  const getTaskListDefinitions = useCallback((): TaskListDefinitionNode[] => {
    return taskGraphs ?? [];
  }, [taskGraphs]);

  const getCurrentMonthTasks = useCallback(
    (
      personId: number,
      taskListType?: TaskListTypeEnum
    ): TaskInstanceResponse[] => {
      const tasks = getTasksByPersonId(personId, taskListType);
      const now = new Date();
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      return tasks.filter((task: TaskInstanceResponse) => {
        if (!task.assignedAt) return false;
        const assignedDate = new Date(task.assignedAt);
        return (
          !task.completed &&
          assignedDate.getMonth() === currentMonth &&
          assignedDate.getFullYear() === currentYear
        );
      });
    },
    [getTasksByPersonId]
  );

  const getApprovalTasksByPersonId = useCallback(
    (personId: number): TaskInstanceResponse[] => {
      if (!tasks) return [];

      return tasks.filter(
        (task) =>
          ((task.assignedEntityType === DataTypeEnum.person &&
            task.assignedEntityId === personId) ||
            task.assignedEntityType === DataTypeEnum.userRole) &&
          task.taskType === TaskTypeEnum.completeWorkflowStep
      );
    },
    [tasks]
  );

  const getApprovalTaskStatsByPersonId = useCallback(
    (personId: number): TaskStats => {
      const tasks = getApprovalTasksByPersonId(personId);
      return calculateTaskStats(tasks);
    },
    [getApprovalTasksByPersonId, calculateTaskStats]
  );

  return {
    getTasksByPersonId,
    getTasksByListKey,
    getTaskStatsByPersonId,
    getTaskListDefinitions,
    getCurrentMonthTasks,
    getApprovalTasksByPersonId,
    getApprovalTaskStatsByPersonId,
  };
};
