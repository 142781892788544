import { TaskTypeEnum } from '@btrway/api-task';
import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { ToolboxItem, ToolboxPanel } from '@btrway/workflow-builder-toolbox';
import { useTaskTypes } from '@btrway/workflow-manager';
import { Box } from '@mantine/core';
import React from 'react';

const TASK_SECTIONS = {
  GROUPS: 'Groupings of Multiple Tasks',
  INDIVIDUAL: 'Individual Tasks',
} as const;

const TASK_TYPE_SECTIONS: Record<string, TaskTypeEnum[]> = {
  [TASK_SECTIONS.GROUPS]: [
    TaskTypeEnum.completeCurriculum,
    TaskTypeEnum.completeFormPacket,
    TaskTypeEnum.completeTaskList,
    TaskTypeEnum.certification,
  ],
  [TASK_SECTIONS.INDIVIDUAL]: [
    TaskTypeEnum.completeTask,
    TaskTypeEnum.completeCourse,
    TaskTypeEnum.createCalendarEvent,
    TaskTypeEnum.sendEmail,
    TaskTypeEnum.submitForm,
    TaskTypeEnum.uploadFile,
    TaskTypeEnum.thirdParty,
    TaskTypeEnum.taskContainer,
    TaskTypeEnum.externalCourse,
  ],
};

const getAllowedTaskTypes = (
  taskListType?: TaskListTypeEnum
): TaskTypeEnum[] => {
  switch (taskListType) {
    case TaskListTypeEnum.todoList:
      return [
        TaskTypeEnum.completeTask,
        TaskTypeEnum.completeCourse,
        TaskTypeEnum.completeCurriculum,
        TaskTypeEnum.createCalendarEvent,
        TaskTypeEnum.sendEmail,
        TaskTypeEnum.submitForm,
        TaskTypeEnum.completeFormPacket,
        TaskTypeEnum.uploadFile,
        TaskTypeEnum.thirdParty,
        TaskTypeEnum.completeTaskList,
        TaskTypeEnum.taskContainer,
        TaskTypeEnum.externalCourse,
      ];
    case TaskListTypeEnum.curriculum:
      return [TaskTypeEnum.completeCourse];
    case TaskListTypeEnum.formPacket:
      return [TaskTypeEnum.submitForm];
    case TaskListTypeEnum.certification:
      return [
        TaskTypeEnum.completeTask,
        TaskTypeEnum.completeCourse,
        TaskTypeEnum.completeCurriculum,
        TaskTypeEnum.completeFormPacket,
        TaskTypeEnum.submitForm,
        TaskTypeEnum.uploadFile,
        TaskTypeEnum.thirdParty,
        TaskTypeEnum.taskContainer,
        TaskTypeEnum.externalCourse,
        TaskTypeEnum.completeTaskList,
        TaskTypeEnum.certification,
      ];
    default:
      return [];
  }
};

interface TaskListToolboxProps {
  taskListType?: TaskListTypeEnum;
  onSelect: (item: ToolboxItem) => void;
}

export const TaskListToolbox: React.FC<TaskListToolboxProps> = ({
  taskListType,
  onSelect,
}) => {
  const { taskTypes } = useTaskTypes();

  const sections = React.useMemo(() => {
    const allowedTaskTypes = getAllowedTaskTypes(taskListType);

    return Object.entries(TASK_TYPE_SECTIONS).map(([title, typesList]) => ({
      title,
      items: taskTypes
        .filter(
          (taskType) =>
            typesList.includes(taskType.code) &&
            allowedTaskTypes.includes(taskType.code)
        )
        .map((taskType) => ({
          type: 'taskType' as const,
          data: taskType,
        })),
    }));
  }, [taskListType, taskTypes]);

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <ToolboxPanel
        sections={sections}
        onCardSelect={onSelect}
        droppableId="task-toolbox-droppable"
        variant="compact"
      />
    </Box>
  );
};

export default TaskListToolbox;
