import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/role-manager';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  Badge,
  Box,
  Card,
  CloseButton,
  Flex,
  Group,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconCircleCheckFilled, IconGripVertical } from '@tabler/icons-react';
import React, { useCallback, useMemo } from 'react';
import { useFieldValueMapping } from '../../hooks/useFieldValueMapping';
import { ImportedPerson } from '../../types';

interface FieldValueMappingStepProps {
  fieldKey: keyof ImportedPerson;
}

export const FieldValueMappingStep: React.FC<FieldValueMappingStepProps> = ({
  fieldKey,
}) => {
  ///////////////////////////////////////////////////////////////////////////////////
  //TODO: Abstract User Roles out of this and pass in the targetOptions generically
  const { currentOrganization } = useAuthenticatedUser();
  const { userRoles } = useUserRoles();

  const targetOptions = useMemo(
    () =>
      userRoles?.map((role) => ({
        id: role.id.toString(),
        name: role.name,
      })) || [],
    [userRoles]
  );
  ///////////////////////////////////////////////////////////////////////////////////

  // const distinctSourceValues = useSourceValues(fieldKey);
  const { mappings, updateMapping, resetMapping } =
    useFieldValueMapping(fieldKey);

  const handleDragEnd = useCallback(
    (result: any) => {
      console.log('handleDragEnd called', result);
      if (!result.destination) return;

      const sourceId = result.draggableId;
      const destinationId = result.destination.droppableId;

      if (destinationId === 'source') {
        resetMapping(sourceId);
      } else {
        updateMapping(sourceId, destinationId);
      }
    },
    [resetMapping, updateMapping]
  );

  const getMappedSourceValues = useCallback(
    (targetId: string) => {
      return mappings
        .filter((m) => m.targetValue === targetId)
        .map((m) => m.sourceValue);
    },
    [mappings]
  );

  const unmappedSourceValues = mappings
    .filter((m) => !m.targetValue)
    .map((m) => m.sourceValue);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Flex>
        <Box w="25%" mr="md">
          <Title order={3} mb="sm">
            Source Values
          </Title>
          {unmappedSourceValues.length === 0 ? (
            <Card radius="md" withBorder p="md">
              <Stack gap="md">
                <Group justify="center" wrap="nowrap" gap="xs">
                  <IconCircleCheckFilled size={36} color="green" />
                  <Text ta="center" fz="xl" fw={600}>
                    Done!
                  </Text>
                </Group>
                <Text ta="center" c="dimmed">
                  All source values are mapped to target values
                </Text>
              </Stack>
            </Card>
          ) : (
            <Droppable droppableId="source">
              {(provided) => (
                <ScrollArea h={500}>
                  <Stack {...provided.droppableProps} ref={provided.innerRef}>
                    <Title c="dimmed" fz="sm">
                      These are values from the source file that need to be
                      associated with a target value.
                    </Title>

                    {unmappedSourceValues.map((value, index) => (
                      <Draggable key={value} draggableId={value} index={index}>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            withBorder
                            radius="md"
                            p="sm"
                          >
                            <Flex align="center">
                              <IconGripVertical
                                size={18}
                                style={{ marginRight: 5 }}
                              />
                              <Text>{value}</Text>
                            </Flex>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                </ScrollArea>
              )}
            </Droppable>
          )}
        </Box>
        <Box w="75%">
          <ScrollArea h="100%">
            <Flex wrap="wrap" gap="md">
              {targetOptions.map((option) => (
                <Droppable key={option.id} droppableId={option.id}>
                  {(provided) => (
                    <Card
                      radius="sm"
                      withBorder
                      bg="gray.0"
                      p="sm"
                      w="calc(33% - 8px)"
                      style={{ marginBottom: 16 }}
                    >
                      <Text fw={600} c="gray.6" mb="sm">
                        {option.name}
                      </Text>
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        mih={50}
                      >
                        {getMappedSourceValues(option.id).map(
                          (value, index) => (
                            <Draggable
                              key={value}
                              draggableId={value}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  radius="md"
                                  withBorder
                                  p="xs"
                                  mb="xs"
                                >
                                  <Flex align="center" justify="space-between">
                                    <Text size="sm">{value}</Text>
                                    <Group gap="xs">
                                      {mappings.find(
                                        (m) => m.sourceValue === value
                                      )?.autoMatched && (
                                        <Badge
                                          color={
                                            (mappings.find(
                                              (m) => m.sourceValue === value
                                            )?.autoMatchedValue || 0) > 0.8
                                              ? 'green'
                                              : 'yellow'
                                          }
                                        >
                                          {Math.round(
                                            (mappings.find(
                                              (m) => m.sourceValue === value
                                            )?.autoMatchedValue || 0) * 100
                                          )}
                                          %
                                        </Badge>
                                      )}
                                      <CloseButton
                                        size="xs"
                                        onClick={() => resetMapping(value)}
                                        aria-label="Remove mapping"
                                      />
                                    </Group>
                                  </Flex>
                                </Card>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </Box>
                    </Card>
                  )}
                </Droppable>
              ))}
            </Flex>
          </ScrollArea>
        </Box>
      </Flex>
    </DragDropContext>
  );
};
