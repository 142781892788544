import type { TaskListTypeEnum } from '@btrway/api-tasklist';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Card, Group, Popover, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { SelectTaskListOptions } from '../SelectTaskListOptions/SelectTaskListOptions';

interface SelectTaskListProps {
  value?: CommonTaskList;
  taskListType: TaskListTypeEnum;
  onChange: (taskList: CommonTaskList) => void;
}

export const SelectTaskList: React.FC<SelectTaskListProps> = ({
  value,
  taskListType,
  onChange,
}) => {
  const [opened, setOpened] = useState(false);

  const getDisplayValue = useCallback((taskList?: CommonTaskList): string => {
    if (!taskList?.name) return 'Select Task List';
    return taskList.name;
  }, []);

  const handleClick = () => {
    setOpened(true);
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      width={400}
      shadow="md"
    >
      <Popover.Target>
        <Card
          shadow="none"
          miw={300}
          px="xs"
          py={5}
          radius="sm"
          withBorder
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: 'fit-content',
            borderColor: 'var(--mantine-color-gray-4)',
          }}
        >
          <Group gap="xs" justify="space-between" wrap="nowrap">
            <Text size="sm">{getDisplayValue(value)}</Text>
            <IconChevronDown size={16} />
          </Group>
        </Card>
      </Popover.Target>

      <Popover.Dropdown>
        <SelectTaskListOptions
          selectedTaskList={value}
          taskListType={taskListType}
          onSelect={(taskList) => {
            onChange(taskList);
            setOpened(false);
          }}
          onClose={() => setOpened(false)}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
