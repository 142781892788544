import { PermissionTypeEnum } from '@btrway/api-security';
import {
  PermissionNode,
  usePermissionAdmin,
  usePermissionMode,
  usePermissions,
} from '@btrway/permission-manager';
import { Button, Stack } from '@mantine/core';
import { IconFolderPlus } from '@tabler/icons-react';
import React from 'react';
import { ModuleTreeNode } from '../ModuleTreeNode/ModuleTreeNode';

export interface ModuleTreeProps {
  modules: PermissionNode[];
  getChildModules: (node: PermissionNode) => PermissionNode[];
  onDrop?: (draggedNode: PermissionNode, targetIndex: number) => void;
  header?: React.ReactNode;
}

export const ModuleTree: React.FC<ModuleTreeProps> = ({
  modules,
  getChildModules,
  onDrop,
  header,
}) => {
  const { mode, setSelectedModuleId, selectedModuleId } = usePermissionMode();
  const { selectedApplication, addPermission } = usePermissionAdmin();
  const { getPermissionByClientId } = usePermissions();

  const handleAddModule = () => {
    let parentNode: PermissionNode | null = null;

    if (selectedModuleId) {
      const selectedModule = getPermissionByClientId(selectedModuleId);
      if (selectedModule) {
        if (selectedModule.level === 0) {
          // If selected module is root level, add under it
          parentNode = selectedModule;
        } else {
          // If selected module is a child, add under its parent
          const parentId = selectedModule.parentClientId;
          if (parentId) {
            parentNode = getPermissionByClientId(parentId) || null;
          }
        }
      }
    }

    const result = addPermission({
      permissionType: PermissionTypeEnum.module,
      application: selectedApplication,
      parentNode,
    });

    if (result.success && result.clientId) {
      setSelectedModuleId(result.clientId);
    }
  };

  // Filter out modules with empty names
  const visibleModules = modules.filter((module) => module.name !== '');

  return (
    <Stack gap="md">
      {mode === 'admin' && (
        <Button
          variant="light"
          size="sm"
          leftSection={<IconFolderPlus size={16} />}
          onClick={handleAddModule}
          fullWidth
        >
          Add Module
        </Button>
      )}
      {header}
      <Stack gap={0}>
        {visibleModules.map((module) => (
          <ModuleTreeNode
            key={module.clientId}
            node={module}
            getChildModules={getChildModules}
            onDrop={onDrop}
          />
        ))}
      </Stack>
    </Stack>
  );
};
