import { TaskTypeEnum } from '@btrway/api-task';
import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { WorkflowTypeEnum } from '@btrway/api-workflow';

// Type-safe workflow selector types
export type WorkflowSelectorTaskType = 'submitForm';

// Type-safe tasklist selector types
export type TaskListSelectorTaskType =
  | 'completeTaskList'
  | 'certification'
  | 'completeFormPacket'
  | 'completeCurriculum';

// Type-safe direct config types
export type DirectConfigTaskType =
  | 'addtoWorkgroup'
  | 'completeTask'
  | 'completeWorkflowStep'
  | 'createCalendarEvent'
  | 'sendEmail'
  | 'uploadFile'
  | 'thirdParty'
  | 'taskContainer';

// Type-safe multi-step types
export type MultiStepTaskType = 'completeCurriculum' | 'completeFormPacket';

export const workflowTaskTypes: Record<
  WorkflowSelectorTaskType,
  {
    type: TaskTypeEnum;
    workflowType: WorkflowTypeEnum;
  }
> = {
  submitForm: {
    type: TaskTypeEnum.submitForm,
    workflowType: WorkflowTypeEnum.form,
  },
};

export const taskListTaskTypes: Record<
  TaskListSelectorTaskType,
  {
    type: TaskTypeEnum;
    taskListType: TaskListTypeEnum;
  }
> = {
  completeTaskList: {
    type: TaskTypeEnum.completeTaskList,
    taskListType: TaskListTypeEnum.todoList,
  },
  certification: {
    type: TaskTypeEnum.certification,
    taskListType: TaskListTypeEnum.certification,
  },
  completeFormPacket: {
    type: TaskTypeEnum.completeFormPacket,
    taskListType: TaskListTypeEnum.formPacket,
  },
  completeCurriculum: {
    type: TaskTypeEnum.completeCurriculum,
    taskListType: TaskListTypeEnum.curriculum,
  },
};

// Type guard functions
export const isWorkflowSelectorType = (
  type: TaskTypeEnum
): type is WorkflowSelectorTaskType => {
  return type in workflowTaskTypes;
};

export const isTaskListSelectorType = (
  type: TaskTypeEnum
): type is TaskListSelectorTaskType => {
  return type in taskListTaskTypes;
};

export const isDirectConfigType = (
  type: TaskTypeEnum
): type is DirectConfigTaskType => {
  const directConfigTypes = new Set<DirectConfigTaskType>([
    'addtoWorkgroup',
    'completeTask',
    'completeWorkflowStep',
    'createCalendarEvent',
    'sendEmail',
    'uploadFile',
    'thirdParty',
    'taskContainer',
  ]);
  return directConfigTypes.has(type as DirectConfigTaskType);
};

export const isMultiStepType = (
  type: TaskTypeEnum
): type is MultiStepTaskType => {
  return type === 'completeCurriculum' || type === 'completeFormPacket';
};

export const getWorkflowType = (
  type: TaskTypeEnum
): WorkflowTypeEnum | undefined => {
  if (isWorkflowSelectorType(type)) {
    return workflowTaskTypes[type].workflowType;
  }
  return undefined;
};

export const getTaskListType = (
  type: TaskTypeEnum
): TaskListTypeEnum | undefined => {
  if (isTaskListSelectorType(type)) {
    return taskListTaskTypes[type].taskListType;
  }
  return undefined;
};
