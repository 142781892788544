import React, { createContext, useContext } from 'react';
import { TemplateDefinitionSourceType } from '../types/sourceType';

interface TemplateDefinitionContextValue {
  sourceType: TemplateDefinitionSourceType;
  isTemplateAdministration: boolean;
}

const TemplateDefinitionContext =
  createContext<TemplateDefinitionContextValue | null>(null);

interface TemplateDefinitionProviderProps {
  children: React.ReactNode;
  sourceType: TemplateDefinitionSourceType;
}

export const TemplateDefinitionProvider: React.FC<
  TemplateDefinitionProviderProps
> = ({ children, sourceType }) => {
  const isTemplateAdministration = sourceType === 'template';

  return (
    <TemplateDefinitionContext.Provider
      value={{
        sourceType,
        isTemplateAdministration,
      }}
    >
      {children}
    </TemplateDefinitionContext.Provider>
  );
};

export const useTemplateDefinition = (): TemplateDefinitionContextValue => {
  const context = useContext(TemplateDefinitionContext);
  if (!context) {
    throw new Error(
      'useTemplateDefinition must be used within a TemplateDefinitionProvider'
    );
  }
  return context;
};
