import { DataTypeEnum, PermissionTypeEnum } from '@btrway/api-security';
import {
  PermissionNode,
  usePermissionAdmin,
  usePermissionMode,
} from '@btrway/permission-manager';
import {
  Button,
  Card,
  Group,
  Select,
  Stack,
  TextInput,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface PermissionEditProps {
  permission: PermissionNode;
  onCancel: () => void;
  onDelete?: () => void;
  title?: string;
}

export const PermissionEdit: React.FC<PermissionEditProps> = ({
  permission,
  onCancel,
  onDelete,
  title,
}) => {
  // Local state
  const [name, setName] = useState(permission.name);
  const [code, setCode] = useState(permission.code);
  const [description, setDescription] = useState(permission.description || '');
  const [entityType, setEntityType] = useState<DataTypeEnum | undefined>(
    permission.entityType
  );
  const [hasLocalChanges, setHasLocalChanges] = useState(false);

  // Hooks
  const { updatePermission, removePermission, errors } = usePermissionAdmin();
  const { mode, getEntityTypeOptions } = usePermissionMode();

  const isModule = permission.permissionType === PermissionTypeEnum.module;
  const isEntity = permission.permissionType === PermissionTypeEnum.entity;
  const entityTypeOptions = getEntityTypeOptions(permission);

  const deleteTooltip = isModule
    ? 'Modules cannot be deleted'
    : 'Delete permission';

  // Reset form when permission changes
  useEffect(() => {
    setName(permission.name);
    setCode(permission.code);
    setDescription(permission.description || '');
    setHasLocalChanges(false);
  }, [permission]);

  // Handle changes
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    setName(newName);
    checkForChanges(newName, code, description);
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = event.currentTarget.value
      .toUpperCase()
      .replace(/[^A-Z0-9_]/g, '');
    setCode(newCode);
    checkForChanges(name, newCode, description);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDescription = event.currentTarget.value;
    setDescription(newDescription);
    checkForChanges(name, code, newDescription);
  };

  const handleEntityTypeChange = (value: string | null) => {
    const newEntityType = value ? (value as DataTypeEnum) : undefined;
    setEntityType(newEntityType);
    checkForChanges(name, code, description, newEntityType);
  };

  const checkForChanges = (
    newName: string,
    newCode: string,
    newDescription: string,
    newEntityType?: string
  ) => {
    const hasChanges =
      newName !== permission.name ||
      newCode !== permission.code ||
      newDescription !== (permission.description || '') ||
      newEntityType !== permission.entityType;

    setHasLocalChanges(hasChanges);
  };

  const handleSave = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!name.trim() || !code.trim()) return;

    const updates: Partial<PermissionNode> = {
      name,
      code,
      description: description || undefined,
      entityType: entityType,
    };

    const success = updatePermission(permission, updates);

    if (success) {
      onCancel();
    }
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    // If this is a new permission that hasn't been saved yet, remove it
    if (!permission.id && permission.code === '') {
      removePermission(permission.clientId);
    }
    onCancel();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete?.();
  };

  const validationError = errors.find(
    (error) => error.type === 'validation' && error.message.includes('code')
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Card withBorder>
        <Stack gap="md">
          <TextInput
            label="Code"
            autoFocus
            description={
              !Boolean(permission.id)
                ? 'Only uppercase letters, numbers, and underscore allowed'
                : undefined
            }
            value={code}
            onChange={handleCodeChange}
            disabled={Boolean(permission.id)}
            error={validationError?.message}
            pattern="[A-Z0-9_]*"
            required
          />
          <TextInput
            label={`${title || 'Permission'} Name`}
            autoFocus={Boolean(permission.id)}
            value={name}
            onChange={handleNameChange}
            required
          />
          {mode === 'admin' && isEntity && (
            <Select
              label="Entity Type"
              value={entityType || ''}
              onChange={handleEntityTypeChange}
              data={entityTypeOptions}
              clearable
            />
          )}
          <Textarea
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
            minRows={3}
          />
          <Group justify="space-between" mt="md">
            {onDelete && (
              <Tooltip label={deleteTooltip}>
                <span>
                  <Button
                    variant="subtle"
                    color="red"
                    leftSection={<IconTrash size={16} />}
                    onClick={handleDelete}
                    disabled={isModule}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            )}
            <Group ml="auto">
              <Button variant="subtle" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave} disabled={!hasLocalChanges}>
                Save Changes
              </Button>
            </Group>
          </Group>
        </Stack>
      </Card>
    </div>
  );
};
