import { FormTaskProperties, TaskConfig, TaskTypeEnum } from '@btrway/api-task';
import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { SelectWorkflow } from '@btrway/select-workflow';
import { getTypedTaskProperties } from '@btrway/task-list-config-provider';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-service-provider';
import { Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskConfigProps } from '../../types/taskTypeComponentProps';

export const SubmitFormTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const workflowService = useWorkflowService();
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.submitForm
  );

  const [properties, setProperties] = useState<FormTaskProperties>({
    workflowSelection: {
      workflowDefinitionKey: {
        value:
          taskProperties?.workflowSelection?.workflowDefinitionKey?.value || '',
      },
    },
  });

  // Get the current workflow if one is selected
  const currentWorkflowKey =
    properties.workflowSelection?.workflowDefinitionKey?.value;
  const { data: selectedWorkflow } =
    workflowService.getByKey(currentWorkflowKey);

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        workflowSelection: {
          workflowDefinitionKey: {
            value:
              taskProperties.workflowSelection?.workflowDefinitionKey?.value ||
              '',
          },
        },
      });
    }
  }, [taskProperties]);

  const handleWorkflowChange = (workflow: CommonWorkflow) => {
    if (!workflow.workflowKey) return;

    const updatedProperties: FormTaskProperties = {
      workflowSelection: {
        workflowDefinitionKey: {
          value: workflow.workflowKey,
        },
      },
    };

    setProperties(updatedProperties);

    const shouldUpdateTitle =
      selectedWorkflow && config.metadata?.title === selectedWorkflow.name;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        title: shouldUpdateTitle
          ? workflow.name
          : config.metadata?.title || 'Submit Form',
      },
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <SelectWorkflow
        workflowType={WorkflowTypeEnum.form}
        onChange={handleWorkflowChange}
        value={selectedWorkflow || undefined}
      />
    </Stack>
  );
};

// export const SubmitFormTaskConfiguration: React.FC<TaskConfigProps> = (
//   props
// ) => {
//   return (
//     <WorkflowServiceProvider>
//       <SubmitFormTaskConfigurationInner {...props} />
//     </WorkflowServiceProvider>
//   );
// };
