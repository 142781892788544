import { HeaderLayout } from '@btrway/layout-components';
import React from 'react';
import ExternalCourses from '../../components/ExternalCourses/ExternalCourses';

const ExternalCoursesPage: React.FC = () => {

  return (
    <HeaderLayout>
      <div
        style={{
          flex: '1',
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        <ExternalCourses />
      </div>
    </HeaderLayout>
  );
};

export default ExternalCoursesPage;
