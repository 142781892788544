import { PermissionsLayout } from '@btrway/permission-components';
import {
  PermissionGrantProvider,
  PermissionManagerProvider,
  PermissionModeProvider,
  usePermissionGrant,
} from '@btrway/permission-manager';
import { Button, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';

const OrganizationPermissionsContent: React.FC<{
  organizationId: number;
}> = ({ organizationId }) => {
  const {
    state: { modifications: pendingChanges, error },
    actions: { saveChanges, discardChanges },
  } = usePermissionGrant();

  const hasPendingChanges = pendingChanges.size > 0;

  return (
    <Stack gap="md">
      <Group justify="flex-end">
        <Button
          variant="outline"
          color="gray"
          onClick={discardChanges}
          disabled={!hasPendingChanges}
        >
          Discard Changes
        </Button>
        <Button onClick={saveChanges} disabled={!hasPendingChanges}>
          Save Changes
        </Button>
      </Group>

      <PermissionsLayout />
    </Stack>
  );
};

export const OrganizationPermissionsPage: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const organizationIdNumber = Number(organizationId);

  if (!organizationId) {
    return <Text>Organization ID is required</Text>;
  }

  return (
    <PermissionModeProvider mode="organizationGrant">
      <PermissionManagerProvider>
        <PermissionGrantProvider organizationId={organizationIdNumber}>
          <OrganizationPermissionsContent
            organizationId={organizationIdNumber}
          />
        </PermissionGrantProvider>
      </PermissionManagerProvider>
    </PermissionModeProvider>
  );
};

export default OrganizationPermissionsPage;
