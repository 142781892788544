import { Box, Button, Group } from '@mantine/core';
import React from 'react';

interface TaskCreatorFooterProps {
  showSaveButton: boolean;
  canSave: boolean;
  onSave: () => void;
  onCancel: () => void;
}
export const TaskCreatorFooter: React.FC<TaskCreatorFooterProps> = ({
  showSaveButton,
  canSave,
  onSave,
  onCancel,
}) => {
  return (
    <Box
      style={{
        borderTop: '1px solid var(--mantine-color-gray-3)',
        backgroundColor: 'var(--mantine-color-gray-0)',
        padding: 'var(--mantine-spacing-md)',
        borderBottomLeftRadius: 'var(--mantine-radius-lg)',
        borderBottomRightRadius: 'var(--mantine-radius-lg)',
      }}
    >
      <Group justify="flex-end">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        {showSaveButton && (
          <Button onClick={onSave} disabled={!canSave}>
            Save
          </Button>
        )}
      </Group>
    </Box>
  );
};
