import {
  CourseTaskProperties,
  CustomTaskProperties,
  ExternalCourseTaskProperties,
  FileUploadTaskProperties,
  FormTaskProperties,
  SendEmailTaskProperties,
  TaskConfig,
  TaskListTaskProperties,
  TaskTypeEnum,
  ThirdPartyTaskProperties,
  WorkflowStepTaskProperties,
} from '@btrway/api-tasklist';

type TaskPropertiesMap = {
  [TaskTypeEnum.completeCourse]: CourseTaskProperties;
  [TaskTypeEnum.submitForm]: FormTaskProperties;
  [TaskTypeEnum.completeCurriculum]: TaskListTaskProperties;
  [TaskTypeEnum.completeTaskList]: TaskListTaskProperties;
  [TaskTypeEnum.uploadFile]: FileUploadTaskProperties;
  [TaskTypeEnum.completeWorkflowStep]: WorkflowStepTaskProperties;
  [TaskTypeEnum.sendEmail]: SendEmailTaskProperties;
  [TaskTypeEnum.thirdParty]: ThirdPartyTaskProperties;
  [TaskTypeEnum.completeFormPacket]: TaskListTaskProperties;
  [TaskTypeEnum.certification]: TaskListTaskProperties;
  [TaskTypeEnum.completeTask]: CustomTaskProperties;
  [TaskTypeEnum.externalCourse]: ExternalCourseTaskProperties;
  [TaskTypeEnum.addtoWorkgroup]: never;
  [TaskTypeEnum.createCalendarEvent]: never;
};

export function getTypedTaskProperties<T extends keyof TaskPropertiesMap>(
  config: TaskConfig | undefined,
  expectedTaskType: T
): TaskPropertiesMap[T] | undefined {
  if (config?.taskType === expectedTaskType) {
    return config.taskProperties as TaskPropertiesMap[T];
  }
  return undefined;
}

export const getTaskListProperties = (
  config: TaskConfig
): TaskListTaskProperties | undefined => {
  switch (config.taskType) {
    case TaskTypeEnum.completeTaskList:
      return getTypedTaskProperties(config, TaskTypeEnum.completeTaskList);
    case TaskTypeEnum.completeFormPacket:
      return getTypedTaskProperties(config, TaskTypeEnum.completeFormPacket);
    case TaskTypeEnum.completeCurriculum:
      return getTypedTaskProperties(config, TaskTypeEnum.completeCurriculum);
    case TaskTypeEnum.certification:
      return getTypedTaskProperties(config, TaskTypeEnum.certification);
    default:
      return undefined;
  }
};
