/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type PermissionGrantLevelEnum = typeof PermissionGrantLevelEnum[keyof typeof PermissionGrantLevelEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionGrantLevelEnum = {
  hidden: 'hidden',
  visible: 'visible',
  editable: 'editable',
  deleteable: 'deleteable',
} as const;
