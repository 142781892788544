import {
  PermissionGrantLevelEnum,
  PermissionGrantRequest,
  PermissionGrantResponse,
} from '@btrway/api-security';

export const responseToGrantRequest = (
  response: PermissionGrantResponse,
  organizationId: number,
  userRoleId?: number,
  workgroupId?: number,
  personId?: number
): PermissionGrantRequest => {
  return {
    id: response.id,
    grantLevel: response.grantLevel,
    permissionId: response.permission.id,
    organizationId,
    userRoleId,
    workgroupId,
    personId,
  };
};

// Helper to create a Map of grants by permissionId
export const createGrantsMap = (
  grants: PermissionGrantRequest[]
): Map<number, PermissionGrantRequest> => {
  return new Map(grants.map((grant) => [grant.permissionId, grant]));
};

/**
 * Creates a map of permission values from grant responses
 */
export const createPermissionValuesMap = (
  responses: PermissionGrantResponse[]
): Record<number, PermissionGrantLevelEnum> => {
  return responses.reduce(
    (acc, response) => ({
      ...acc,
      [response.permission.id]: response.grantLevel || '',
    }),
    {}
  );
};
