import {
  CertificationRequiredConfig,
  CertificationRequiredTypeEnum,
  CertificationValidConfig,
  CertificationValidTypeEnum,
  TimeUnitEnum,
} from '@btrway/api-tasklist';
import { Badge, Group } from '@mantine/core';
import { IconCheck, IconClock } from '@tabler/icons-react';
import React from 'react';

interface CertificationInfoProps {
  requiredConfig?: CertificationRequiredConfig;
  validConfig?: CertificationValidConfig;
}

const formatTimeDisplay = (value?: number, unit?: TimeUnitEnum): string => {
  if (!value || !unit) return '';

  const unitDisplay = (() => {
    switch (unit) {
      case TimeUnitEnum.year:
        return value === 1 ? 'year' : 'years';
      case TimeUnitEnum.month:
        return value === 1 ? 'month' : 'months';
      case TimeUnitEnum.day:
        return value === 1 ? 'day' : 'days';
      default:
        return '';
    }
  })();

  return `${value} ${unitDisplay}`;
};

export const CertificationInfo: React.FC<CertificationInfoProps> = ({
  requiredConfig,
  validConfig,
}) => {
  const getRequiredBadgeText = () => {
    if (!requiredConfig) return 'Not Required';

    if (requiredConfig.requiredType === CertificationRequiredTypeEnum.yes) {
      return 'Required';
    }

    if (
      requiredConfig.requiredType ===
      CertificationRequiredTypeEnum.withinTimeframe
    ) {
      const timeDisplay = formatTimeDisplay(
        requiredConfig.timeValue,
        requiredConfig.timeUnit
      );
      return timeDisplay ? `Required within ${timeDisplay}` : 'Not Required';
    }

    return 'Not Required';
  };

  return (
    <Group justify="flex-end" wrap="nowrap" gap="xs">
      <Badge
        variant="outline"
        size="lg"
        leftSection={<IconClock size={14} />}
        color="green"
        styles={{ root: { whiteSpace: 'nowrap' } }}
      >
        {validConfig?.validType === CertificationValidTypeEnum.ever
          ? 'Valid Forever'
          : validConfig?.validType === CertificationValidTypeEnum.thisYear
          ? 'Valid This Year'
          : validConfig?.validType === CertificationValidTypeEnum.withinDays
          ? `Valid for ${validConfig.withinDays}d`
          : 'Not Set'}
      </Badge>
      <Badge
        variant="outline"
        size="lg"
        leftSection={<IconCheck size={14} />}
        color="blue"
        styles={{ root: { whiteSpace: 'nowrap' } }}
      >
        {getRequiredBadgeText()}
      </Badge>
    </Group>
  );
};
