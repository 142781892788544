import {
  getGetTaskListDefinitionByKeyQueryKey,
  getGetTaskListDefinitionsByOrganizationAndTypeQueryKey,
  getGetTaskListDefinitionsByOrganizationQueryKey,
  TaskListDefinitionConfigUpdate,
  TaskListDefinitionRequest,
  TaskListTypeEnum,
  useDeleteTaskListDefinition,
  useGetTaskListDefinitionByKey,
  useGetTaskListDefinitionsByOrganization,
  useGetTaskListDefinitionsByOrganizationAndType,
  useSaveTaskListDefinition,
  useUpdateTaskListDefinitionConfig,
} from '@btrway/api-tasklist';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonTaskList } from '../types/commonTaskList';
import { TaskListResult, TaskListsResult } from '../types/service';
import {
  convertCommonToTaskListRequest,
  convertResponseToCommon,
  validateTaskListBeforeConversion,
} from '../utils/commonTaskListConversions';

export function useTaskListDefinition(
  organizationId: number,
  taskListKey?: string
) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'definition';
  const { mutateAsync: updateConfig } = useUpdateTaskListDefinitionConfig();
  const { mutateAsync: saveTaskListDefinition } = useSaveTaskListDefinition();
  const { mutateAsync: deleteTaskListDefinition } =
    useDeleteTaskListDefinition();

  const {
    data,
    isLoading,
    error,
    refetch: refetchDefinition,
  } = useGetTaskListDefinitionByKey(
    organizationId,
    taskListKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!taskListKey,
        queryKey: getGetTaskListDefinitionByKeyQueryKey(
          organizationId,
          taskListKey || ''
        ),
      },
    }
  );

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    refetch: refetchDefinitions,
  } = useGetTaskListDefinitionsByOrganization(organizationId, {
    query: {
      enabled: isEnabled,
      queryKey: getGetTaskListDefinitionsByOrganizationQueryKey(organizationId),
    },
  });

  const result: TaskListResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchDefinition,
  };

  const definitionsResult: TaskListsResult = {
    taskLists: (definitionsData || []).map(convertResponseToCommon),
    isLoading: definitionsLoading,
    error: definitionsError as Error | undefined,
    refetch: refetchDefinitions,
  };

  const getTaskListsByType = (type: TaskListTypeEnum) => {
    const {
      data: typeData,
      isLoading: typeLoading,
      error: typeError,
      refetch: refetchType,
    } = useGetTaskListDefinitionsByOrganizationAndType(organizationId, type, {
      query: {
        enabled: isEnabled,
        queryKey: getGetTaskListDefinitionsByOrganizationAndTypeQueryKey(
          organizationId,
          type
        ),
      },
    });

    return {
      taskLists: (typeData || []).map(convertResponseToCommon),
      isLoading: typeLoading,
      error: typeError as Error | undefined,
      refetch: refetchType,
    };
  };

  return {
    tasklistResult: result,
    tasklistsResult: definitionsResult,
    getTaskListsByType,
    updateConfig: async (config: TaskListDefinitionConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateTaskList: async (tasklist: CommonTaskList) => {
      if (tasklist.sourceType !== 'definition') {
        throw new Error('Cannot save template tasklist in definition context');
      }
      const validationErrors = validateTaskListBeforeConversion(tasklist);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid tasklist: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToTaskListRequest(
        tasklist
      ) as TaskListDefinitionRequest;
      return await saveTaskListDefinition({ data: request });
    },
    deleteTaskList: async (taskList: CommonTaskList) => {
      if (taskList.sourceType !== 'definition') {
        throw new Error(
          'Cannot delete template workflow in definition context'
        );
      }
      if (!taskList.id) {
        throw new Error('Cannot delete workflow without an id');
      }
      return await deleteTaskListDefinition({
        taskListDefinitionId: taskList.id,
      });
    },
  };
}
