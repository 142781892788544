// import { AssetResponse } from '@btrway/api-courseware';
// import { TaskTypeEnum } from '@btrway/api-task';
// import { TaskConfig } from '@btrway/api-tasklist';
// import { CommonExternalCourse } from '@btrway/external-course-service-provider';
// import { SelectExternalCourseOptions } from '@btrway/select-external-course';
// import { SelectInternalCourseOptions } from '@btrway/select-internal-course';
// import { SelectTaskListOptions } from '@btrway/select-tasklist';
// import { SelectWorkflowOptions } from '@btrway/select-workflow';
// import { TaskEditorCore } from '@btrway/task-editor';
// import {
//   CurriculumCoursePicker,
//   FormPacketFormPicker,
// } from '@btrway/task-list-editor';
// import { CommonTaskList } from '@btrway/task-list-service-provider';
// import {
//   CommonWorkflow,
//   WorkflowServiceProvider,
// } from '@btrway/workflow-service-provider';
// import { Box } from '@mantine/core';
// import React from 'react';
// import {
//   isDirectConfigType,
//   isMultiStepType,
//   isTaskListSelectorType,
//   isWorkflowSelectorType,
//   taskListTaskTypes,
//   workflowTaskTypes,
// } from '../../utils/taskCreatorUtils';
// import TaskListToolbox from '../TaskListToolbox/TaskListToolbox';

// interface TaskCreatorStepContentProps {
//   activeStep: number;
//   selectedTaskType: TaskTypeEnum | null;
//   taskList: CommonTaskList;
//   newTaskList: CommonTaskList | null;
//   selectedCourses: TaskConfig[];
//   selectedForms: TaskConfig[];
//   currentDirectConfig: TaskConfig | null;
//   onToolboxSelect: (item: any) => void;
//   onWorkflowSelect: (workflow: CommonWorkflow) => void;
//   onTaskListSelect: (taskList: CommonTaskList, isNew?: boolean) => void;
//   onCourseSelect: (course: AssetResponse) => void;
//   onExternalCourseSelect: (externalCourse: CommonExternalCourse) => void;
//   onDirectConfigChange: (config: TaskConfig) => void;
//   setSelectedCourses: (courses: TaskConfig[]) => void;
//   setSelectedForms: (forms: TaskConfig[]) => void;
//   onCancel: () => void;
//   configFactory: any;
// }

// export const TaskCreatorStepContent: React.FC<TaskCreatorStepContentProps> = ({
//   activeStep,
//   selectedTaskType,
//   taskList,
//   newTaskList,
//   selectedCourses,
//   selectedForms,
//   currentDirectConfig,
//   onToolboxSelect,
//   onWorkflowSelect,
//   onTaskListSelect,
//   onCourseSelect,
//   onExternalCourseSelect,
//   onDirectConfigChange,
//   setSelectedCourses,
//   setSelectedForms,
//   onCancel,
//   configFactory,
// }) => {
//   switch (activeStep) {
//     case 0:
//       return (
//         <Box style={{ height: '100%' }}>
//           <TaskListToolbox
//             taskListType={taskList.taskListType}
//             onSelect={onToolboxSelect}
//           />
//         </Box>
//       );
//     case 1:
//       if (!selectedTaskType) return null;

//       if (selectedTaskType === 'completeCourse') {
//         return (
//           <Box style={{ height: '100%' }}>
//             <SelectInternalCourseOptions
//               selectedCourseUid={null}
//               onSelect={onCourseSelect}
//             />
//           </Box>
//         );
//       }

//       if (selectedTaskType === 'externalCourse') {
//         return (
//           <Box style={{ height: '100%' }}>
//             <SelectExternalCourseOptions
//               selectedCourseKey={null}
//               onSelect={onExternalCourseSelect}
//             />
//           </Box>
//         );
//       }

//       if (isWorkflowSelectorType(selectedTaskType)) {
//         return (
//           <Box
//             style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
//           >
//             <Box style={{ flex: 1, minHeight: 0 }}>
//               <SelectWorkflowOptions
//                 workflowType={workflowTaskTypes[selectedTaskType].workflowType}
//                 onSelect={onWorkflowSelect}
//                 onClose={onCancel}
//                 selectedWorkflow={undefined}
//               />
//             </Box>
//           </Box>
//         );
//       }

//       if (isTaskListSelectorType(selectedTaskType)) {
//         return (
//           <Box style={{ height: '100%' }}>
//             <SelectTaskListOptions
//               taskListType={taskListTaskTypes[selectedTaskType].taskListType}
//               onSelect={onTaskListSelect}
//               onClose={onCancel}
//               selectedTaskList={undefined}
//               allowCreation={true}
//             />
//           </Box>
//         );
//       }

//       if (isDirectConfigType(selectedTaskType)) {
//         const initialConfig = configFactory.createTaskConfig(selectedTaskType);
//         return (
//           <Box style={{ height: '100%' }}>
//             <TaskEditorCore
//               config={currentDirectConfig || initialConfig}
//               onConfigChange={onDirectConfigChange}
//             />
//           </Box>
//         );
//       }
//       return null;

//     case 2:
//       if (
//         !newTaskList ||
//         !selectedTaskType ||
//         !isMultiStepType(selectedTaskType)
//       ) {
//         return null;
//       }

//       if (selectedTaskType === 'completeCurriculum') {
//         return (
//           <Box style={{ height: '100%' }}>
//             <CurriculumCoursePicker
//               selectedTasks={selectedCourses}
//               onSelectedTasksChange={setSelectedCourses}
//             />
//           </Box>
//         );
//       }

//       if (selectedTaskType === 'completeFormPacket') {
//         return (
//           <WorkflowServiceProvider>
//             <Box style={{ height: '100%' }}>
//               <FormPacketFormPicker
//                 selectedTasks={selectedForms}
//                 onSelectedTasksChange={setSelectedForms}
//               />
//             </Box>
//           </WorkflowServiceProvider>
//         );
//       }
//       return null;

//     default:
//       return null;
//   }
// };

import { AssetResponse } from '@btrway/api-courseware';
import { TaskTypeEnum } from '@btrway/api-task';
import { TaskConfig } from '@btrway/api-tasklist';
import { CommonExternalCourse } from '@btrway/external-course-service-provider';
import { SelectExternalCourseOptions } from '@btrway/select-external-course';
import { SelectInternalCourseOptions } from '@btrway/select-internal-course';
import { SelectTaskListOptions } from '@btrway/select-tasklist';
import { SelectWorkflowOptions } from '@btrway/select-workflow';
import { TaskEditorCore } from '@btrway/task-editor';
import {
  CurriculumCoursePicker,
  FormPacketFormPicker,
} from '@btrway/task-list-editor';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import {
  CommonWorkflow,
  WorkflowServiceProvider,
} from '@btrway/workflow-service-provider';
import { Box } from '@mantine/core';
import React from 'react';
import {
  isDirectConfigType,
  isMultiStepType,
  isTaskListSelectorType,
  isWorkflowSelectorType,
  taskListTaskTypes,
  workflowTaskTypes,
} from '../../utils/taskCreatorUtils';
import TaskListToolbox from '../TaskListToolbox/TaskListToolbox';

interface TaskCreatorStepContentProps {
  activeStep: number;
  selectedTaskType: TaskTypeEnum | null;
  taskList: CommonTaskList;
  newTaskList: CommonTaskList | null;
  selectedCourses: TaskConfig[];
  selectedForms: TaskConfig[];
  currentDirectConfig: TaskConfig | null;
  onToolboxSelect: (item: any) => void;
  onWorkflowSelect: (workflow: CommonWorkflow) => void;
  onTaskListSelect: (taskList: CommonTaskList, isNew?: boolean) => void;
  onCourseSelect: (course: AssetResponse) => void;
  onExternalCourseSelect: (externalCourse: CommonExternalCourse) => void;
  onDirectConfigChange: (config: TaskConfig) => void;
  setSelectedCourses: (courses: TaskConfig[]) => void;
  setSelectedForms: (forms: TaskConfig[]) => void;
  onCancel: () => void;
  configFactory: any;
}

export const TaskCreatorStepContent: React.FC<TaskCreatorStepContentProps> = ({
  activeStep,
  selectedTaskType,
  taskList,
  newTaskList,
  selectedCourses,
  selectedForms,
  currentDirectConfig,
  onToolboxSelect,
  onWorkflowSelect,
  onTaskListSelect,
  onCourseSelect,
  onExternalCourseSelect,
  onDirectConfigChange,
  setSelectedCourses,
  setSelectedForms,
  onCancel,
  configFactory,
}) => {
  const containerStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as const,
    overflow: 'hidden',
  };

  const contentStyle = {
    flex: '1 1 auto',
    minHeight: 0,
    overflow: 'auto',
  };

  switch (activeStep) {
    case 0:
      return (
        <Box style={containerStyle}>
          <Box style={contentStyle}>
            <TaskListToolbox
              taskListType={taskList.taskListType}
              onSelect={onToolboxSelect}
            />
          </Box>
        </Box>
      );

    case 1:
      if (!selectedTaskType) return null;

      if (selectedTaskType === 'completeCourse') {
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <SelectInternalCourseOptions
                selectedCourseUid={null}
                onSelect={onCourseSelect}
              />
            </Box>
          </Box>
        );
      }

      if (selectedTaskType === 'externalCourse') {
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <SelectExternalCourseOptions
                selectedCourseKey={null}
                onSelect={onExternalCourseSelect}
              />
            </Box>
          </Box>
        );
      }

      if (isWorkflowSelectorType(selectedTaskType)) {
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <SelectWorkflowOptions
                workflowType={workflowTaskTypes[selectedTaskType].workflowType}
                onSelect={onWorkflowSelect}
                onClose={onCancel}
                selectedWorkflow={undefined}
              />
            </Box>
          </Box>
        );
      }

      if (isTaskListSelectorType(selectedTaskType)) {
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <SelectTaskListOptions
                taskListType={taskListTaskTypes[selectedTaskType].taskListType}
                onSelect={onTaskListSelect}
                onClose={onCancel}
                selectedTaskList={undefined}
                allowCreation={true}
              />
            </Box>
          </Box>
        );
      }

      if (isDirectConfigType(selectedTaskType)) {
        const initialConfig = configFactory.createTaskConfig(selectedTaskType);
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <TaskEditorCore
                config={currentDirectConfig || initialConfig}
                onConfigChange={onDirectConfigChange}
              />
            </Box>
          </Box>
        );
      }
      return null;

    case 2:
      if (
        !newTaskList ||
        !selectedTaskType ||
        !isMultiStepType(selectedTaskType)
      ) {
        return null;
      }

      if (selectedTaskType === 'completeCurriculum') {
        return (
          <Box style={containerStyle}>
            <Box style={contentStyle}>
              <CurriculumCoursePicker
                selectedTasks={selectedCourses}
                onSelectedTasksChange={setSelectedCourses}
              />
            </Box>
          </Box>
        );
      }

      if (selectedTaskType === 'completeFormPacket') {
        return (
          <WorkflowServiceProvider>
            <Box style={containerStyle}>
              <Box style={contentStyle}>
                <FormPacketFormPicker
                  selectedTasks={selectedForms}
                  onSelectedTasksChange={setSelectedForms}
                />
              </Box>
            </Box>
          </WorkflowServiceProvider>
        );
      }
      return null;

    default:
      return null;
  }
};
