import { ExternalCourseServiceProvider } from '@btrway/external-course-service-provider';
import { TaskCreatorProvider } from '@btrway/task-creator';
import { TaskListConfigProvider } from '@btrway/task-list-config-provider';
import {
  TaskListServiceProvider,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowServiceProvider } from '@btrway/workflow-service-provider';
import React from 'react';
import { useParams } from 'react-router-dom';
import TaskListBuilder from '../../components/TaskListBuilder/TaskListBuilder';
import { TaskListBuilderLayout } from '../../components/TaskListBuilderLayout/TaskListBuilderLayout';

const TaskListBuilderPageInner: React.FC = () => {
  const { taskListKey } = useParams<{ taskListKey: string }>();
  const taskListService = useTaskListService();
  const { data: taskList, isLoading } = taskListService.getByKey();

  if (!taskListKey) {
    return <div>No task list key provided</div>;
  }

  if (isLoading) {
    return <div>Loading task list...</div>;
  }

  if (!taskList) {
    return <div>No task list found</div>;
  }

  return (
    <RightSectionProvider>
      <SettingsDisplayProvider>
        <WorkflowServiceProvider>
          <ExternalCourseServiceProvider>
            <TaskCreatorProvider>
              <TaskListConfigProvider
                initialConfig={taskList.taskListConfiguration}
              >
                <TaskListBuilderLayout taskList={taskList}>
                  <TaskListBuilder taskList={taskList} />
                </TaskListBuilderLayout>
              </TaskListConfigProvider>
            </TaskCreatorProvider>
          </ExternalCourseServiceProvider>
        </WorkflowServiceProvider>
      </SettingsDisplayProvider>
    </RightSectionProvider>
  );
};

const TaskListBuilderPage: React.FC = () => {
  const { taskListKey } = useParams<{ taskListKey: string }>();

  if (!taskListKey) {
    return <div>No task list key provided</div>;
  }

  return (
    <TaskListServiceProvider taskListKey={taskListKey}>
      <TaskListBuilderPageInner />
    </TaskListServiceProvider>
  );
};

export default TaskListBuilderPage;
