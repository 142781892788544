import { TaskListBuilderPage } from '@btrway/task-list-builder';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowBuilderPage } from '@btrway/workflow-builder';

export const fullScreenRoutes: RouteConfig[] = [
  {
    path: 'template-admin/automations/:workflowKey',
    element: <WorkflowBuilderPage />,
  },
  {
    path: 'template-admin/forms/:workflowKey',
    element: <WorkflowBuilderPage />,
  },
  // {
  //   path: 'template-admin/files/:workflowKey',
  //   element: <WorkflowBuilderPage />,
  // },
  {
    path: 'template-admin/form-packets/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'template-admin/certifications/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'template-admin/todo-lists/:taskListKey',
    element: <TaskListBuilderPage />,
  },
  {
    path: 'template-admin/curriculums/:taskListKey',
    element: <TaskListBuilderPage />,
  },
];
