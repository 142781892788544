import { TaskTypeEnum } from '@btrway/api-task';
import { AssignTaskInputProperties, StepTypeEnum } from '@btrway/api-workflow';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { StepTypeIcon, TaskTypeIcon } from '@btrway/workflow-icons';
import React from 'react';

interface StepConfigCardIconProps {
  stepKey: string;
}

export const StepConfigCardIcon: React.FC<StepConfigCardIconProps> = ({
  stepKey,
}) => {
  const { processActions } = useWorkflowConfig();
  const { getStep } = processActions;
  const step = getStep(stepKey);

  if (step?.stepTypeCode === StepTypeEnum.assignTask) {
    const assignTaskInputProperties =
      step.inputProperties as AssignTaskInputProperties;
    return (
      <TaskTypeIcon
        iconName={assignTaskInputProperties.taskType as TaskTypeEnum}
        size={24}
      />
    );
  }

  return (
    <StepTypeIcon iconName={step?.stepTypeCode as StepTypeEnum} size={24} />
  );
};
