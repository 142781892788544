import {
  TaskCompletionResponse,
  TaskCompletionResults,
  TaskInstanceRequestTaskInstanceData,
  TaskInstanceResponse,
  useCompleteTaskInstance,
} from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback } from 'react';
import { useTasksManagerContext } from '../providers/TasksManagerProvider';
import { TaskCompletionResult } from '../types/TaskCompletionResult';
import { convertToTaskInstanceRequest } from '../utils/convertToTaskInstanceRequest';
import { useStateReconciliation } from './useStateReconciliation';

interface UseTaskCompletionOptions {
  taskInstance: TaskInstanceResponse;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useTaskCompletion = ({
  taskInstance,
  onSuccess,
  onError,
}: UseTaskCompletionOptions) => {
  const { currentPerson } = useAuthenticatedUser();
  const { mutateAsync: completeTaskInstance } = useCompleteTaskInstance();
  const { setState } = useTasksManagerContext();
  const { reconcileTasks, reconcileTaskLists, reconcileTaskGraphs } =
    useStateReconciliation();

  const completeTask = useCallback(
    async (
      comment?: string,
      taskInstanceData?: TaskInstanceRequestTaskInstanceData
    ): Promise<TaskCompletionResult> => {
      try {
        const taskRequest = convertToTaskInstanceRequest(
          {
            ...taskInstance,
            completed: true,
            completedAt: new Date().toISOString(),
            completedBy: currentPerson.id,
          },
          comment
        );

        const taskRequestWithData = {
          ...taskRequest,
          taskInstanceData,
        };

        const completionResults = (await completeTaskInstance({
          data: taskRequestWithData,
        })) as TaskCompletionResponse;

        setState((prev) => ({
          ...prev,
          tasks: reconcileTasks(
            prev.tasks ?? [],
            completionResults.tasks ?? []
          ),
          taskLists: reconcileTaskLists(
            prev.taskLists ?? [],
            completionResults.taskLists ?? []
          ),
        }));

        onSuccess?.();

        return {
          success: true,
          taskInstanceId: completionResults.tasks?.[0]?.id,
        };
      } catch (error) {
        const err =
          error instanceof Error ? error : new Error('Unknown error occurred');
        onError?.(err);
        return { success: false, error: err };
      }
    },
    [
      taskInstance,
      currentPerson.id,
      completeTaskInstance,
      setState,
      reconcileTasks,
      reconcileTaskLists,
      reconcileTaskGraphs,
      onSuccess,
      onError,
    ]
  );

  const markTaskViewed = useCallback(async () => {
    if (taskInstance.viewed) return;

    try {
      const taskRequest = convertToTaskInstanceRequest({
        ...taskInstance,
        viewed: true,
        lastViewedAt: new Date().toISOString(),
      });

      const completionResults = (await completeTaskInstance({
        data: taskRequest,
      })) as TaskCompletionResults;

      setState((prev) => ({
        ...prev,
        tasks: reconcileTasks(
          prev.tasks ?? [],
          completionResults.taskInstances ?? []
        ),
        taskLists: reconcileTaskLists(
          prev.taskLists ?? [],
          completionResults.taskLists ?? []
        ),
      }));
    } catch (error) {
      console.error('Failed to mark task as viewed:', error);
    }
  }, [
    taskInstance,
    completeTaskInstance,
    setState,
    reconcileTasks,
    reconcileTaskLists,
    reconcileTaskGraphs,
  ]);

  return {
    completeTask,
    markTaskViewed,
  };
};
