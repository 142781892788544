import {
  OrganizationFilter,
  TaskListTemplateSettings,
} from '@btrway/api-tasklist';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { Button, Card, Group, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { DeploymentType } from '../../types/deploymentType';
import { OrganizationFilterEditor } from '../OrganizationFilterEditor/OrganizationFilterEditor';
import { TaskListTemplateSettingsEditor } from '../TaskListTemplateSettingsEditor/TaskListTemplateSettingsEditor';

interface DeploymentFilterEditProps {
  deploymentType: DeploymentType;
  initialOrganizationFilter?: OrganizationFilter;
  initialTaskListTemplateSettings?: TaskListTemplateSettings;
  onSave: (data: {
    organizationFilter: OrganizationFilter;
    taskListTemplateSettings?: TaskListTemplateSettings;
  }) => void;
  onCancel?: () => void;
}

export const DeploymentFilterEdit: React.FC<DeploymentFilterEditProps> = ({
  deploymentType,
  initialOrganizationFilter = {},
  initialTaskListTemplateSettings = {},
  onSave,
  onCancel,
}) => {
  const { isTemplateAdministration } = useTemplateDefinition();

  if (!isTemplateAdministration) {
    return null;
  }

  const [organizationFilter, setOrganizationFilter] =
    useState<OrganizationFilter>(initialOrganizationFilter);
  const [taskListTemplateSettings, setTaskListTemplateSettings] =
    useState<TaskListTemplateSettings>(initialTaskListTemplateSettings);

  const handleSave = () => {
    onSave({
      organizationFilter,
      taskListTemplateSettings:
        deploymentType === 'taskList' ? taskListTemplateSettings : undefined,
    });
  };

  return (
    <Card padding="md">
      <Stack>
        <OrganizationFilterEditor
          filter={organizationFilter}
          onFilterChange={setOrganizationFilter}
        />

        {deploymentType === 'taskList' && (
          <TaskListTemplateSettingsEditor
            settings={taskListTemplateSettings}
            onSettingsChange={setTaskListTemplateSettings}
          />
        )}

        <Group justify="flex-end" gap="sm">
          {onCancel && (
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Stack>
    </Card>
  );
};

export default DeploymentFilterEdit;
