import { TaskListSettings, TaskListTypeEnum } from '@btrway/api-tasklist';
import {
  CommonTaskList,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { Box, Button, Group, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TaskListDescriptionEditor } from '../TaskListDescriptionEditor/TaskListDescriptionEditor';
import { TaskListSettingsEditor } from '../TaskListSettingsEditor/TaskListSettingsEditor';

interface TaskListSettingsPanelProps {
  onSave?: () => void;
  onCancel?: () => void;
}

const TASK_LIST_TYPES = [
  TaskListTypeEnum.todoList,
  TaskListTypeEnum.certification,
  TaskListTypeEnum.curriculum,
  TaskListTypeEnum.formPacket,
] as const;

export const TaskListSettingsPanel: React.FC<TaskListSettingsPanelProps> = ({
  onSave,
  onCancel,
}) => {
  const taskListService = useTaskListService();
  const { data: taskList } = taskListService.getByKey();
  const [activeTab, setActiveTab] = useState<string | null>('description');
  const [localTaskList, setLocalTaskList] = useState<CommonTaskList | null>(
    null
  );

  useEffect(() => {
    if (taskList) {
      setLocalTaskList(taskList);
    }
  }, [taskList]);

  const handleTaskListChange = (updatedTaskList: CommonTaskList) => {
    setLocalTaskList(updatedTaskList);
  };

  const handleTaskListSettingsChange = (taskListSettings: TaskListSettings) => {
    if (!localTaskList) return;

    setLocalTaskList((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        taskListSettings: taskListSettings,
      };
    });
  };

  const handleSave = async () => {
    if (!localTaskList) return;

    try {
      // Update task list through the service
      await taskListService.updateTaskList(localTaskList);
      onSave?.();
    } catch (error) {
      console.error('Error saving task list settings:', error);
      // Handle error appropriately
    }
  };

  const handleCancel = () => {
    if (taskList) {
      setLocalTaskList(taskList);
    }
    onCancel?.();
  };

  if (!localTaskList) return null;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Tabs value={activeTab} onChange={setActiveTab} style={{ flex: 1 }}>
        <Tabs.List>
          <Tabs.Tab value="description">Description</Tabs.Tab>
          <Tabs.Tab value="taskSettings">Task Assignment Settings</Tabs.Tab>
        </Tabs.List>

        <Box p="md" style={{ flex: 1, overflow: 'auto' }}>
          <Tabs.Panel value="description">
            <TaskListDescriptionEditor
              taskList={localTaskList}
              onChange={handleTaskListChange}
            />
          </Tabs.Panel>

          <Tabs.Panel value="taskSettings">
            <TaskListSettingsEditor
              initialSettings={localTaskList.taskListSettings}
              onChange={handleTaskListSettingsChange}
            />
          </Tabs.Panel>
        </Box>
      </Tabs>

      <Box
        p="md"
        style={{ borderTop: '1px solid var(--mantine-color-gray-3)' }}
      >
        <Group justify="flex-end" gap="md">
          <Button variant="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Box>
    </Box>
  );
};
