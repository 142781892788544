import { TaskMetadata } from '@btrway/api-tasklist';
import { useTaskListService } from '@btrway/task-list-service-provider';
import { useTaskCategories } from '@btrway/workflow-manager';
import { Select, Stack, TextInput } from '@mantine/core';
import React from 'react';

interface TaskMetadataEditorProps {
  metadata: TaskMetadata;
  onChange: (metadata: TaskMetadata) => void;
}

export const TaskMetadataEditor: React.FC<TaskMetadataEditorProps> = ({
  metadata,
  onChange,
}) => {
  const { taskCategories } = useTaskCategories();
  const taskListService = useTaskListService();
  const taskList = taskListService.getByKey().data;
  const isABWToDoList =
    taskList?.taskListMetadata?.taskListClassification === 'ABW To-Do List';

  const handleMetadataChange = (field: keyof TaskMetadata, value: any) => {
    onChange({
      ...metadata,
      [field]: value,
    });
  };

  return (
    <>
      <Stack gap="md">
        <Stack gap="sm">
          <TextInput
            label="Title"
            placeholder="Enter task title"
            value={metadata?.title || ''}
            onChange={(event) =>
              handleMetadataChange('title', event.currentTarget.value)
            }
          />
          <TextInput
            label="Subtitle"
            placeholder="Enter task subtitle"
            value={metadata?.subtitle || ''}
            onChange={(event) =>
              handleMetadataChange('subtitle', event.currentTarget.value)
            }
          />
          {isABWToDoList && (
            <Select
              label="Task Category"
              placeholder="Select a category"
              data={taskCategories.map((type) => ({
                value: type.key,
                label: type.name,
              }))}
              value={metadata?.taskCategoryKey || ''}
              onChange={(value) =>
                handleMetadataChange('taskCategoryKey', value)
              }
              styles={{ dropdown: { zIndex: 9999 } }}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default TaskMetadataEditor;
