import { CommonExternalCourse } from '@btrway/external-course-service-provider';
import { Card, Text } from '@mantine/core';
import React from 'react';

export const ExternalCourseCard: React.FC<{
  course: CommonExternalCourse;
  onSelect: (course: CommonExternalCourse) => void;
  isSelected: boolean;
}> = ({ course, onSelect, isSelected }) => (
  <Card
    shadow="none"
    withBorder
    mb="xs"
    onClick={() => onSelect(course)}
    style={{
      cursor: 'pointer',
      backgroundColor: isSelected ? 'var(--mantine-color-blue-0)' : undefined,
      borderColor: isSelected
        ? 'var(--mantine-color-blue-5)'
        : 'var(--mantine-color-gray-4)',
    }}
  >
    <Text size="sm" fw={500}>
      {course.courseConfiguration?.title || 'No title'}
    </Text>
  </Card>
);
