import {
  getGetWorkflowTemplateByKeyQueryKey,
  getGetWorkflowTemplatesByTypeQueryKey,
  getGetWorkflowTemplatesQueryKey,
  useDeleteWorkflowTemplate,
  useGetWorkflowTemplateByKey,
  useGetWorkflowTemplates,
  useGetWorkflowTemplatesByType,
  useSaveWorkflowTemplate,
  useUpdateWorkflowTemplateConfig,
  WorkflowTemplateConfigUpdate,
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonWorkflow } from '../types/commonWorkflow';
import { WorkflowResult, WorkflowsResult } from '../types/service';
import {
  convertCommonToWorkflowRequest,
  convertResponseToCommon,
  validateWorkflowBeforeConversion,
} from '../utils/commonWorkflowConversions';

export function useWorkflowTemplate(workflowKey?: string) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'template';

  const { mutateAsync: updateConfig } = useUpdateWorkflowTemplateConfig();
  const { mutateAsync: saveWorkflowTemplate } = useSaveWorkflowTemplate();
  const { mutateAsync: deleteTemplate } = useDeleteWorkflowTemplate();

  const {
    data,
    isLoading,
    error,
    refetch: refetchTemplate,
  } = useGetWorkflowTemplateByKey(
    workflowKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!workflowKey,
        queryKey: getGetWorkflowTemplateByKeyQueryKey(workflowKey || ''),
      },
    }
  );

  const {
    data: templatesData,
    isLoading: templatesLoading,
    error: templatesError,
    refetch: refetchTemplates,
  } = useGetWorkflowTemplates({
    query: {
      enabled: isEnabled,
      queryKey: getGetWorkflowTemplatesQueryKey(),
    },
  });

  const getWorkflowsByType = (type: WorkflowTypeEnum) => {
    const {
      data: typeData,
      isLoading: typeLoading,
      error: typeError,
      refetch: refetchType,
    } = useGetWorkflowTemplatesByType(type, {
      query: {
        enabled: isEnabled,
        queryKey: getGetWorkflowTemplatesByTypeQueryKey(type),
      },
    });

    return {
      workflows: (typeData || []).map(convertResponseToCommon),
      isLoading: typeLoading,
      error: typeError as Error | undefined,
      refetch: refetchType,
    };
  };

  const result: WorkflowResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchTemplate,
  };

  const templatesResult: WorkflowsResult = {
    workflows: (templatesData || []).map(convertResponseToCommon),
    isLoading: templatesLoading,
    error: templatesError as Error | undefined,
    refetch: refetchTemplates,
  };

  return {
    workflowResult: result,
    workflowsResult: templatesResult,
    getWorkflowsByType,
    updateConfig: async (config: WorkflowTemplateConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'template') {
        throw new Error('Cannot save definition workflow in template context');
      }
      const validationErrors = validateWorkflowBeforeConversion(workflow);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid workflow: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToWorkflowRequest(
        workflow
      ) as WorkflowTemplateRequest;
      return await saveWorkflowTemplate({ data: request });
    },
    deleteWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'template') {
        throw new Error(
          'Cannot delete definition workflow in template context'
        );
      }
      if (!workflow.id) {
        throw new Error('Cannot delete workflow without an id');
      }
      return await deleteTemplate({ workflowTemplateId: workflow.id });
    },
  };
}
