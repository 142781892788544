import {
  PermissionGrantLevelEnum,
  PermissionTypeEnum,
} from '@btrway/api-security';
import {
  EntityContext,
  usePermissionGrant,
} from '../providers/PermissionGrantProvider';
import { PermissionNode } from '../types/permissionNode';
import { usePermissionHierarchy } from './usePermissionHierarchy';
import { usePermissions } from './usePermissions';

interface UsePermissionValueResult {
  value: PermissionGrantLevelEnum;
  setValue: (value: PermissionGrantLevelEnum) => void;
  isModified: boolean;
  isDisabledByParent: boolean;
  isAllHidden: boolean;
  isValid: boolean;
}

export const usePermissionValue = (
  node: PermissionNode,
  entityContext?: EntityContext
): UsePermissionValueResult => {
  const { state, actions } = usePermissionGrant();
  const {
    ensureParentVisibility,
    ensureChildrenVisibility,
    disableChildren,
    getParentChainStatus,
  } = usePermissionHierarchy();
  const { getChildPermissions } = usePermissions();

  if (!node.id) {
    return {
      value: PermissionGrantLevelEnum.hidden,
      setValue: () => {},
      isModified: false,
      isDisabledByParent: false,
      isAllHidden: true,
      isValid: false,
    };
  }

  // Get grant based on whether this is an entity-specific permission
  const grant = state.grants.get(node.id);
  const { isDisabledByParent, isAllHidden } = getParentChainStatus(node);

  const handleSetValue = (value: PermissionGrantLevelEnum) => {
    if (value !== PermissionGrantLevelEnum.hidden) {
      if (isAllHidden || !isDisabledByParent) {
        const wasHidden =
          !grant || grant.grantLevel === PermissionGrantLevelEnum.hidden;

        if (isAllHidden) {
          // When toggling a hidden node, ensure its parent chain is visible
          ensureParentVisibility(node, false);
        }

        // Set this node's value
        actions.setPermissionValue(node.id!, value, entityContext);

        // Handle children if this is a module
        if (node.permissionType === PermissionTypeEnum.module) {
          if (wasHidden && getChildPermissions(node.clientId).length > 0) {
            ensureChildrenVisibility(node);
          }
        }
      }
    } else {
      // When hiding a node
      actions.setPermissionValue(node.id!, value, entityContext);

      // Handle children if this is a module
      if (node.permissionType === PermissionTypeEnum.module) {
        disableChildren(node);
      }
    }
  };

  return {
    value: grant?.grantLevel ?? PermissionGrantLevelEnum.hidden,
    setValue: handleSetValue,
    isModified: state.modifications.has(node.id),
    isDisabledByParent,
    isAllHidden,
    isValid: true,
  };
};
