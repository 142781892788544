import {
  TaskInstanceResponse,
  TaskListDefinitionNode,
  TaskListInstanceResponse,
} from '@btrway/api-task';
import { useCallback } from 'react';

export const useStateReconciliation = () => {
  const getTaskKey = useCallback((task: TaskInstanceResponse): string => {
    if (!task.derivedTaskData?.derivedTaskKey.compositeKey) {
      throw new Error('Task is missing derivedCompositeKey');
    }
    return task.derivedTaskData.derivedTaskKey.compositeKey;
  }, []);

  const getTaskListKey = useCallback(
    (taskList: TaskListInstanceResponse): string => {
      if (!taskList.derivedTaskListData?.derivedTaskListKey.compositeKey) {
        throw new Error('TaskList is missing derivedCompositeKey');
      }
      return taskList.derivedTaskListData.derivedTaskListKey.compositeKey;
    },
    []
  );

  const getTaskGraphNodeKey = useCallback(
    (node: TaskListDefinitionNode): string => {
      if (!node.taskListKey) {
        throw new Error('TaskListDefinitionNode is missing taskListKey');
      }
      return node.taskListKey;
    },
    []
  );

  const reconcileTasks = useCallback(
    (
      existingTasks: TaskInstanceResponse[] = [],
      newTasks: TaskInstanceResponse[] = []
    ) => {
      const reconciled = new Map<string, TaskInstanceResponse>();

      existingTasks.forEach((task) => {
        const key = getTaskKey(task);
        reconciled.set(key, task);
      });

      newTasks.forEach((newTask) => {
        const key = getTaskKey(newTask);
        reconciled.set(key, newTask);
      });

      return Array.from(reconciled.values());
    },
    [getTaskKey]
  );

  const reconcileTaskLists = useCallback(
    (
      existingTaskLists: TaskListInstanceResponse[] = [],
      newTaskLists: TaskListInstanceResponse[] = []
    ) => {
      const reconciled = new Map<string, TaskListInstanceResponse>();

      existingTaskLists.forEach((taskList) => {
        const key = getTaskListKey(taskList);
        reconciled.set(key, taskList);
      });

      newTaskLists.forEach((newTaskList) => {
        const key = getTaskListKey(newTaskList);
        reconciled.set(key, newTaskList);
      });

      return Array.from(reconciled.values());
    },
    [getTaskListKey]
  );

  const reconcileTaskGraphs = useCallback(
    (
      existingGraphs: TaskListDefinitionNode[] = [],
      newGraphs: TaskListDefinitionNode[] = []
    ) => {
      const reconciled = new Map<string, TaskListDefinitionNode>();

      existingGraphs.forEach((graph) => {
        const key = getTaskGraphNodeKey(graph);
        reconciled.set(key, graph);
      });

      newGraphs.forEach((newGraph) => {
        const key = getTaskGraphNodeKey(newGraph);
        reconciled.set(key, newGraph);
      });

      console.log('reconciled size: ', reconciled.size);

      return Array.from(reconciled.values());
    },
    [getTaskGraphNodeKey]
  );

  return {
    getTaskKey,
    getTaskListKey,
    getTaskGraphNodeKey,
    reconcileTasks,
    reconcileTaskLists,
    reconcileTaskGraphs,
  };
};
