import {
  OrganizationFilter,
  TaskListTemplateSettings,
} from '@btrway/api-tasklist';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { Drawer } from '@mantine/core';
import React from 'react';
import { DeploymentType } from '../../types/deploymentType';
import { DeploymentFilterEdit } from '../DeploymentFilterEdit/DeploymentFilterEdit';

interface DeploymentFilterDrawerProps {
  deploymentType: DeploymentType;
  opened: boolean;
  onClose: () => void;
  onSave: (data: {
    organizationFilter: OrganizationFilter;
    taskListSettings?: TaskListTemplateSettings;
  }) => void;
  initialOrganizationFilter?: OrganizationFilter;
  initialTaskListTemplateSettings?: TaskListTemplateSettings;
}

export const DeploymentFilterDrawer: React.FC<DeploymentFilterDrawerProps> = ({
  deploymentType,
  opened,
  onClose,
  onSave,
  initialOrganizationFilter,
  initialTaskListTemplateSettings,
}) => {
  const { isTemplateAdministration } = useTemplateDefinition();

  if (!isTemplateAdministration) {
    return null;
  }

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginRight: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <DeploymentFilterEdit
        deploymentType={deploymentType}
        initialOrganizationFilter={initialOrganizationFilter}
        initialTaskListTemplateSettings={initialTaskListTemplateSettings}
        onSave={onSave}
        onCancel={onClose}
      />
    </Drawer>
  );
};

export default DeploymentFilterDrawer;
