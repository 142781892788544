import { CertificationRequiredConfig } from '@btrway/api-tasklist';
import { Chip, Group, NumberInput, Select, Stack, Text } from '@mantine/core';
import React from 'react';

interface CertificationRequiredEditorProps {
  value: CertificationRequiredConfig;
  onChange: (config: CertificationRequiredConfig) => void;
}

const TIME_UNIT_OPTIONS = [
  { value: 'day', label: 'days' },
  { value: 'month', label: 'months' },
  { value: 'year', label: 'years' },
];

const PROPERTY_OPTIONS = [{ value: 'hire', label: 'date of hire' }];

export const CertificationRequiredEditor: React.FC<
  CertificationRequiredEditorProps
> = ({ value, onChange }) => {
  const handleRequiredTypeChange = (newType: string) => {
    onChange({
      ...value,
      requiredType: newType as 'yes' | 'withinTimeframe',
    });
  };

  const handleTimeValueChange = (newValue: string | number) => {
    const numValue =
      typeof newValue === 'string' ? parseInt(newValue) : newValue;
    onChange({
      ...value,
      timeValue: numValue,
    });
  };

  const handleTimeUnitChange = (newUnit: string | null) => {
    if (newUnit === null) return;
    onChange({
      ...value,
      timeUnit: newUnit as CertificationRequiredConfig['timeUnit'],
    });
  };

  const handlePropertyChange = (newProperty: string | null) => {
    if (newProperty === null) return;
    onChange({
      ...value,
      property: newProperty,
    });
  };

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Text size="sm" c="dimmed">
          Specify whether this item is required immediately for certification or
          within a timeframe.
        </Text>
        <Chip.Group
          multiple={false}
          value={value.requiredType}
          onChange={handleRequiredTypeChange}
        >
          <Group gap="xs">
            <Chip value="yes">Required Now</Chip>
            <Chip value="withinTimeframe">Within timeframe</Chip>
          </Group>
        </Chip.Group>
      </Stack>

      {value.requiredType === 'withinTimeframe' && (
        <Group align="center" gap="xs">
          <Text>Required within</Text>
          <NumberInput
            size="sm"
            w={70}
            value={value.timeValue}
            onChange={handleTimeValueChange}
            min={0}
            styles={{ input: { textAlign: 'center' } }}
            hideControls
          />
          <Select
            size="sm"
            w={100}
            value={value.timeUnit}
            onChange={handleTimeUnitChange}
            data={TIME_UNIT_OPTIONS}
          />
          <Text>from the person's</Text>
          <Select
            size="sm"
            value={value.property}
            onChange={handlePropertyChange}
            data={PROPERTY_OPTIONS}
          />
          <Text c="dimmed" size="sm">
            Until that date, overall certification can be met without this
            requirement.
          </Text>
        </Group>
      )}
    </Stack>
  );
};

export default CertificationRequiredEditor;
