import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { read, utils } from 'xlsx';
import { fileAtom } from '../atoms/fileAtom';
import { fileDataAtom } from '../atoms/fileDataAtom';
import { FileData } from '../types/FileData';
import { getSheetNames } from '../utils';
import { useAutoMatching } from './useAutoMatching';

export const useFileData = () => {
  const [file, setFile] = useAtom(fileAtom);
  const [fileData, setFileData] = useAtom(fileDataAtom);
  const { autoMatchFileData } = useAutoMatching();

  const handleFileUpload = useCallback(
    async (uploadedFile: File) => {
      setFile(uploadedFile);
      // await handleFileUploaded();
      return uploadedFile;
    },
    [setFile]
  );

  const setFileDataFromSheet = useCallback(
    async (file: File, sheetName?: string): Promise<void> => {
      const workbook = read(await file.arrayBuffer(), { type: 'array' });
      const selectedSheet = sheetName || workbook.SheetNames[0];
      const worksheet = workbook.Sheets[selectedSheet];
      const sheetData = utils.sheet_to_json(worksheet, {
        header: 1,
      }) as string[][];

      const newFileData: FileData = {
        selectedSheet,
        headers: sheetData[0],
        rows: sheetData.slice(1),
      };

      setFileData(newFileData);
      autoMatchFileData(newFileData);
    },
    [setFileData]
  );

  const getAvailableSheets = useCallback(async () => {
    if (!file) {
      throw new Error('No file available');
    }
    return await getSheetNames(file);
  }, [file]);

  return {
    file,
    fileData,
    setFileDataFromSheet,
    getAvailableSheets,
    handleFileUpload,
  };
};
