// import { TaskListTypeEnum } from '@btrway/api-tasklist';
// import { useAuthenticatedUser } from '@btrway/current-user';
// import { useTemplateDefinition } from '@btrway/template-definition-provider';
// import React, { createContext, useContext } from 'react';
// import { useTaskListDefinition } from '../hooks/useTaskListDefinition';
// import { useTaskListTemplate } from '../hooks/useTaskListTemplate';
// import { TaskListService } from '../types/service';

// export const TaskListServiceContext = createContext<TaskListService | null>(
//   null
// );

// interface TaskListServiceProviderProps {
//   children: React.ReactNode;
//   taskListKey?: string;
// }

// // providers/TaskListServiceProvider.tsx
// export const TaskListServiceProvider: React.FC<
//   TaskListServiceProviderProps
// > = ({ children, taskListKey: defaultTaskListKey }) => {
//   const { currentOrganization } = useAuthenticatedUser();
//   const { sourceType } = useTemplateDefinition();

//   const template = useTaskListTemplate(defaultTaskListKey);
//   const definition = useTaskListDefinition(
//     currentOrganization?.id!,
//     defaultTaskListKey
//   );

//   const service: TaskListService =
//     sourceType === 'template'
//       ? {
//           taskListKey: defaultTaskListKey,
//           getByKey: (key?: string) => {
//             // Use provided key or fall back to default
//             const effectiveKey = key || defaultTaskListKey;
//             if (effectiveKey !== defaultTaskListKey) {
//               // If a different key is provided, fetch new data
//               const newTemplate = useTaskListTemplate(effectiveKey);
//               return newTemplate.tasklistResult;
//             }
//             return template.tasklistResult;
//           },
//           getTaskLists: () => template.tasklistsResult,
//           getTaskListsByType: (type: TaskListTypeEnum) =>
//             template.getTaskListsByType(type),
//           updateConfig: async (key, config) =>
//             template.updateConfig({
//               templateKey: key,
//               ...config,
//             }),
//           updateTaskList: async (tasklist) => {
//             if (tasklist.sourceType !== 'template') {
//               throw new Error(
//                 'Cannot save definition tasklist in template context'
//               );
//             }
//             await template.updateTaskList(tasklist);
//           },
//           deleteTaskList: async (tasklist) => {
//             if (tasklist.sourceType !== 'template') {
//               throw new Error(
//                 'Cannot delete definition tasklist in template context'
//               );
//             }
//             if (!tasklist.id) {
//               throw new Error('Cannot delete tasklist without an id');
//             }
//             await template.deleteTaskList(tasklist);
//           },
//         }
//       : {
//           taskListKey: defaultTaskListKey,
//           getByKey: (key?: string) => {
//             // Use provided key or fall back to default
//             const effectiveKey = key || defaultTaskListKey;
//             if (effectiveKey !== defaultTaskListKey) {
//               // If a different key is provided, fetch new data
//               const newDefinition = useTaskListDefinition(
//                 currentOrganization?.id!,
//                 effectiveKey
//               );
//               return newDefinition.tasklistResult;
//             }
//             return definition.tasklistResult;
//           },
//           getTaskLists: () => definition.tasklistsResult,
//           getTaskListsByType: (type: TaskListTypeEnum) =>
//             definition.getTaskListsByType(type),
//           updateConfig: async (key, config) => {
//             return definition.updateConfig({
//               taskListDefinitionKey: key,
//               organizationId: currentOrganization?.id!,
//               ...config,
//             });
//           },
//           updateTaskList: async (tasklist) => {
//             if (tasklist.sourceType !== 'definition') {
//               throw new Error(
//                 'Cannot save template tasklist in definition context'
//               );
//             }
//             await definition.updateTaskList(tasklist);
//           },
//           deleteTaskList: async (tasklist) => {
//             if (tasklist.sourceType !== 'definition') {
//               throw new Error(
//                 'Cannot delete template tasklist in definition context'
//               );
//             }
//             if (!tasklist.id) {
//               throw new Error('Cannot delete workflow without an id');
//             }
//             await template.deleteTaskList(tasklist);
//           },
//         };

//   return (
//     <TaskListServiceContext.Provider value={service}>
//       {children}
//     </TaskListServiceContext.Provider>
//   );
// };

// export const useTaskListService = (): TaskListService => {
//   const context = useContext(TaskListServiceContext);
//   if (!context) {
//     throw new Error(
//       'useTaskListService must be used within a TaskListServiceProvider'
//     );
//   }
//   return context;
// };

import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext } from 'react';
import { useTaskListDefinition } from '../hooks/useTaskListDefinition';
import { useTaskListTemplate } from '../hooks/useTaskListTemplate';
import { TaskListService } from '../types/service';

export const TaskListServiceContext = createContext<TaskListService | null>(
  null
);

interface TaskListServiceProviderProps {
  children: React.ReactNode;
  taskListKey?: string;
}

export const TaskListServiceProvider: React.FC<
  TaskListServiceProviderProps
> = ({ children, taskListKey: defaultTaskListKey }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { sourceType } = useTemplateDefinition();
  const queryClient = useQueryClient();

  const template = useTaskListTemplate(defaultTaskListKey);
  const definition = useTaskListDefinition(
    currentOrganization?.id!,
    defaultTaskListKey
  );

  const handleMutationSuccess = useCallback(async () => {
    // Invalidate all relevant queries
    const promises: Promise<unknown>[] = [];

    if (sourceType === 'template') {
      if (defaultTaskListKey) {
        promises.push(
          queryClient.invalidateQueries({
            queryKey: ['taskListTemplate', defaultTaskListKey],
          })
        );
      }
      promises.push(
        queryClient.invalidateQueries({
          queryKey: ['taskListTemplates'],
        })
      );
      promises.push(
        queryClient.invalidateQueries({
          queryKey: ['taskListTemplatesByType'],
        })
      );
    } else {
      if (defaultTaskListKey) {
        promises.push(
          queryClient.invalidateQueries({
            queryKey: [
              'taskListDefinition',
              currentOrganization?.id,
              defaultTaskListKey,
            ],
          })
        );
      }
      promises.push(
        queryClient.invalidateQueries({
          queryKey: ['taskListDefinitions', currentOrganization?.id],
        })
      );
      promises.push(
        queryClient.invalidateQueries({
          queryKey: ['taskListDefinitionsByType', currentOrganization?.id],
        })
      );
    }

    // Wait for all invalidations to complete
    await Promise.all(promises);

    // Trigger an immediate refetch of the current task list
    if (sourceType === 'template') {
      await template.tasklistResult.refetch();
    } else {
      await definition.tasklistResult.refetch();
    }
  }, [
    sourceType,
    defaultTaskListKey,
    currentOrganization?.id,
    queryClient,
    template,
    definition,
  ]);

  const service: TaskListService =
    sourceType === 'template'
      ? {
          taskListKey: defaultTaskListKey,
          getByKey: (key?: string) => {
            const effectiveKey = key || defaultTaskListKey;
            if (effectiveKey !== defaultTaskListKey) {
              const newTemplate = useTaskListTemplate(effectiveKey);
              return newTemplate.tasklistResult;
            }
            return template.tasklistResult;
          },
          getTaskLists: () => template.tasklistsResult,
          getTaskListsByType: (type: TaskListTypeEnum) =>
            template.getTaskListsByType(type),
          updateConfig: async (key, config) => {
            await template.updateConfig({
              templateKey: key,
              ...config,
            });
            await handleMutationSuccess();
          },
          updateTaskList: async (tasklist) => {
            if (tasklist.sourceType !== 'template') {
              throw new Error(
                'Cannot save definition tasklist in template context'
              );
            }
            await template.updateTaskList(tasklist);
            await handleMutationSuccess();
          },
          deleteTaskList: async (tasklist) => {
            if (tasklist.sourceType !== 'template') {
              throw new Error(
                'Cannot delete definition tasklist in template context'
              );
            }
            if (!tasklist.id) {
              throw new Error('Cannot delete tasklist without an id');
            }
            await template.deleteTaskList(tasklist);
            await handleMutationSuccess();
          },
        }
      : {
          taskListKey: defaultTaskListKey,
          getByKey: (key?: string) => {
            const effectiveKey = key || defaultTaskListKey;
            if (effectiveKey !== defaultTaskListKey) {
              const newDefinition = useTaskListDefinition(
                currentOrganization?.id!,
                effectiveKey
              );
              return newDefinition.tasklistResult;
            }
            return definition.tasklistResult;
          },
          getTaskLists: () => definition.tasklistsResult,
          getTaskListsByType: (type: TaskListTypeEnum) =>
            definition.getTaskListsByType(type),
          updateConfig: async (key, config) => {
            await definition.updateConfig({
              taskListDefinitionKey: key,
              organizationId: currentOrganization?.id!,
              ...config,
            });
            await handleMutationSuccess();
          },
          updateTaskList: async (tasklist) => {
            if (tasklist.sourceType !== 'definition') {
              throw new Error(
                'Cannot save template tasklist in definition context'
              );
            }
            await definition.updateTaskList(tasklist);
            await handleMutationSuccess();
          },
          deleteTaskList: async (tasklist) => {
            if (tasklist.sourceType !== 'definition') {
              throw new Error(
                'Cannot delete template tasklist in definition context'
              );
            }
            if (!tasklist.id) {
              throw new Error('Cannot delete workflow without an id');
            }
            await definition.deleteTaskList(tasklist);
            await handleMutationSuccess();
          },
        };

  return (
    <TaskListServiceContext.Provider value={service}>
      {children}
    </TaskListServiceContext.Provider>
  );
};
export const useTaskListService = (): TaskListService => {
  const context = useContext(TaskListServiceContext);
  if (!context) {
    throw new Error(
      'useTaskListService must be used within a TaskListServiceProvider'
    );
  }
  return context;
};
