// src/components/PermissionControl/PermissionControl.tsx

import {
  PermissionGrantLevelEnum,
  PermissionTypeEnum,
} from '@btrway/api-security';
import {
  PermissionNode,
  usePermissionMode,
  usePermissionValue,
} from '@btrway/permission-manager';
import { Tooltip } from '@mantine/core';
import React from 'react';
import { PermissionGrantSegmentedControl } from '../PermissionGrantSegmentedControl/PermissionGrantSegmentedControl';
import { PermissionGrantSwitch } from '../PermissionGrantSwitch/PermissionGrantSwitch';

interface BasePermissionControlProps {
  node: PermissionNode;
  value: PermissionGrantLevelEnum;
  onValueChange: (value: PermissionGrantLevelEnum) => void;
  isDisabledByParent?: boolean;
  isAllHidden?: boolean;
  disabled?: boolean;
}

// Base component without permission value hook dependency
const BasePermissionControl: React.FC<BasePermissionControlProps> = ({
  node,
  value,
  onValueChange,
  isDisabledByParent = false,
  isAllHidden = false,
  disabled = false,
}) => {
  const { getPermissionControlSettings } = usePermissionMode();
  const { show } = getPermissionControlSettings(node);

  if (!show) return null;

  // Only show tooltip and disabled state if parent is disabled and not all nodes are hidden
  const shouldShowDisabled = isDisabledByParent && !isAllHidden;

  // Create tooltip message for disabled state
  const tooltipMessage = shouldShowDisabled
    ? 'This permission is inactive because a parent module is disabled'
    : undefined;

  // Combine disabled states - but allow interaction if all nodes are hidden
  const isDisabled = disabled || shouldShowDisabled;

  const control =
    node.permissionType === PermissionTypeEnum.entity ? (
      <PermissionGrantSegmentedControl
        value={value}
        onChange={onValueChange}
        disabled={isDisabled}
      />
    ) : node.permissionType === PermissionTypeEnum.setting ||
      node.permissionType === PermissionTypeEnum.tool ||
      node.permissionType === PermissionTypeEnum.module ? (
      <PermissionGrantSwitch
        value={value}
        onChange={onValueChange}
        disabled={isDisabled}
      />
    ) : null;

  // Wrap in tooltip only if showing disabled state
  return shouldShowDisabled ? (
    <Tooltip label={tooltipMessage}>
      <div style={{ opacity: 0.6 }}>{control}</div>
    </Tooltip>
  ) : (
    control
  );
};

// Main PermissionControl component that decides which version to use
export const PermissionControl: React.FC<
  { node: PermissionNode } | (BasePermissionControlProps & { forceBase?: true })
> = (props) => {
  const { mode } = usePermissionMode();

  // If forceBase is true, use BasePermissionControl with provided props
  if ('forceBase' in props) {
    if (!('value' in props)) {
      throw new Error(
        'BasePermissionControl requires value and onValueChange props when using forceBase'
      );
    }
    return <BasePermissionControl {...props} />;
  }

  // In grant modes, use the permission value hook
  if (mode === 'organizationGrant' || mode === 'userRoleGrant') {
    const { value, setValue, isDisabledByParent, isAllHidden } =
      usePermissionValue(props.node);
    return (
      <BasePermissionControl
        node={props.node}
        value={value}
        onValueChange={setValue}
        isDisabledByParent={isDisabledByParent}
        isAllHidden={isAllHidden}
      />
    );
  }

  // In admin mode, use a disabled version with default values
  return (
    <BasePermissionControl
      node={props.node}
      value={PermissionGrantLevelEnum.hidden}
      onValueChange={() => {}}
      disabled={true}
    />
  );
};

// Also export the base component in case it's needed directly
export { BasePermissionControl };
