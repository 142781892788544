import {
  PermissionGrantLevelEnum,
  PermissionTypeEnum,
} from '@btrway/api-security';
import { useCallback } from 'react';
import { usePermissionGrant } from '../providers/PermissionGrantProvider';
import { PermissionNode } from '../types/permissionNode';
import { usePermissions } from './usePermissions';

export const usePermissionHierarchy = () => {
  const { state, actions } = usePermissionGrant();
  const { getPermissionByClientId, getChildPermissions } = usePermissions();

  const ensureChildrenVisibility = useCallback(
    (node: PermissionNode) => {
      // Only proceed if the node is a module
      if (node.permissionType !== PermissionTypeEnum.module) {
        return;
      }

      const children = getChildPermissions(node.clientId);

      // Filter for only module-type children
      const moduleChildren = children.filter(
        (child) => child.permissionType === PermissionTypeEnum.module
      );

      // Check if all module children are currently hidden
      const allModuleChildrenHidden = moduleChildren.every((child) => {
        const childGrant = child.id ? state.grants.get(child.id) : undefined;
        return (
          childGrant?.grantLevel === PermissionGrantLevelEnum.hidden ||
          !childGrant
        );
      });

      // If all module children are hidden, make them visible
      if (allModuleChildrenHidden && moduleChildren.length > 0) {
        moduleChildren.forEach((child) => {
          if (child.id) {
            actions.setPermissionValue(
              child.id,
              PermissionGrantLevelEnum.visible
            );
          }
        });
      }
    },
    [state.grants, actions.setPermissionValue, getChildPermissions]
  );

  const ensureParentVisibility = useCallback(
    (node: PermissionNode, isDirectParentToggle: boolean = false) => {
      let currentNode = node;

      while (currentNode.parentClientId) {
        const parentNode = getPermissionByClientId(currentNode.parentClientId);
        if (!parentNode) break;

        if (parentNode.id) {
          const parentGrant = parentNode.id
            ? state.grants.get(parentNode.id)
            : undefined;
          const wasHidden =
            !parentGrant ||
            parentGrant.grantLevel === PermissionGrantLevelEnum.hidden;

          // Always set parent to visible
          actions.setPermissionValue(
            parentNode.id,
            PermissionGrantLevelEnum.visible
          );

          // Only ensure children visibility if this is a direct toggle of the parent,
          // the parent was previously hidden, and it's a module
          if (
            isDirectParentToggle &&
            wasHidden &&
            parentNode.permissionType === PermissionTypeEnum.module
          ) {
            ensureChildrenVisibility(parentNode);
          }
        }

        currentNode = parentNode;
      }
    },
    [
      state.grants,
      actions.setPermissionValue,
      getPermissionByClientId,
      ensureChildrenVisibility,
    ]
  );

  const disableChildren = useCallback(
    (node: PermissionNode) => {
      // Only proceed if the node is a module
      if (node.permissionType !== PermissionTypeEnum.module) {
        return;
      }

      const children = getChildPermissions(node.clientId);

      children.forEach((child) => {
        if (child.id) {
          const childGrant = state.grants.get(child.id);
          if (childGrant?.grantLevel !== PermissionGrantLevelEnum.hidden) {
            // We don't actually change the grant level here, the UI will show it as disabled
            // based on the parent's hidden state
          }
        }
      });
    },
    [state.grants, getChildPermissions]
  );

  const isModuleWithAllChildrenHidden = useCallback(
    (node: PermissionNode): boolean => {
      if (node.permissionType !== PermissionTypeEnum.module) {
        return false;
      }

      const children = getChildPermissions(node.clientId);
      const moduleChildren = children.filter(
        (child) => child.permissionType === PermissionTypeEnum.module
      );

      if (moduleChildren.length === 0) return false;

      return moduleChildren.every((child) => {
        const childGrant = child.id ? state.grants.get(child.id) : undefined;
        return (
          childGrant?.grantLevel === PermissionGrantLevelEnum.hidden ||
          !childGrant
        );
      });
    },
    [state.grants, getChildPermissions]
  );

  const getParentChainStatus = useCallback(
    (
      node: PermissionNode
    ): { isDisabledByParent: boolean; isAllHidden: boolean } => {
      const currentGrant = node.id ? state.grants.get(node.id) : undefined;
      const currentLevel =
        currentGrant?.grantLevel ?? PermissionGrantLevelEnum.hidden;

      let currentNode = node;
      let parentNode = currentNode.parentClientId
        ? getPermissionByClientId(currentNode.parentClientId)
        : null;

      if (!parentNode) {
        return {
          isDisabledByParent: false,
          isAllHidden: currentLevel === PermissionGrantLevelEnum.hidden,
        };
      }

      const parentGrant = parentNode.id
        ? state.grants.get(parentNode.id)
        : undefined;
      const parentLevel =
        parentGrant?.grantLevel ?? PermissionGrantLevelEnum.hidden;

      const parentAllChildrenHidden = isModuleWithAllChildrenHidden(parentNode);

      if (
        parentLevel === PermissionGrantLevelEnum.hidden &&
        parentAllChildrenHidden
      ) {
        return {
          isDisabledByParent: false,
          isAllHidden: true,
        };
      }

      if (parentLevel === PermissionGrantLevelEnum.hidden) {
        return {
          isDisabledByParent: true,
          isAllHidden: false,
        };
      }

      return {
        isDisabledByParent: false,
        isAllHidden: false,
      };
    },
    [state.grants, getPermissionByClientId, isModuleWithAllChildrenHidden]
  );

  return {
    ensureParentVisibility,
    ensureChildrenVisibility,
    disableChildren,
    getParentChainStatus,
  };
};
