import { ProviderRequest, ProviderResponse } from "@btrway/api-external-provider";
import { Stack } from "@mantine/core";
import { useMemo } from "react";
import { ProviderCard } from "../ProviderCard/ProviderCard";

interface ExternalCourseProviderAdminViewProps {
    providers: ProviderResponse[];
    onEditProvider: (provider: ProviderRequest) => void;
    onAddProvider: () => void;
}

export const ExternalCourseProviderAdminView: React.FC<ExternalCourseProviderAdminViewProps> = ({
    providers,
    onEditProvider,
    onAddProvider,
}) => {
    const handleEditProvider = (provider: ProviderResponse) => {
        const convertedProvider: ProviderRequest = {
            id: provider.id,
            name: provider.name
        };
        onEditProvider(convertedProvider);
    };

    const sortedProviders = useMemo(() => {
        return providers
            .slice()
            .sort((a,b) =>
                a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})
            );
    }, [providers]);

    return (
        <Stack gap="md">
          {sortedProviders.map((provider) => {
             const convertedProvider: ProviderRequest = {
                id: provider.id,
                name: provider.name
            };
            return (
              <ProviderCard
                key={provider.id}
                provider={convertedProvider}
                onEdit={() => handleEditProvider(provider)}
              />
            );
          })}
        </Stack>
      );
}