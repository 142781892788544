import { DataTypeEnum } from '@btrway/api-core';
import { TaskInstanceResponse, TaskTypeEnum } from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { EntityTag } from '@btrway/entity-tags';
import { useGetPersonTasks, useTaskModal } from '@btrway/task-manager';
import { formatDateTime } from '@btrway/utils';
import { TaskTypeIcon } from '@btrway/workflow-icons';
import { Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface ApprovalsViewProps {
  personId?: number;
}

const ApprovalsView: React.FC<ApprovalsViewProps> = ({ personId }) => {
  const { openTask } = useTaskModal();

  const { getApprovalTasksByPersonId } = useGetPersonTasks();
  const { currentPerson } = useAuthenticatedUser();
  const effectivePersonId = personId || currentPerson.id;

  const approvalTasks: TaskInstanceResponse[] =
    getApprovalTasksByPersonId(effectivePersonId);

  const handleTaskClick = (task: TaskInstanceResponse) => {
    openTask(task);
  };

  if (!approvalTasks.length) {
    return (
      <Text c="dimmed" ta="center">
        No pending approvals
      </Text>
    );
  }

  return (
    <Stack gap="xs" style={{ width: '100%' }}>
      {approvalTasks.map((task) => (
        <Card
          key={task.id || task.taskKey}
          padding="sm"
          radius="sm"
          withBorder
          shadow="none"
          onClick={() => handleTaskClick(task)}
          style={{ cursor: 'pointer' }}
        >
          <Group justify="space-between" mb="xs">
            <Group gap="sm">
              {task.taskType && (
                <TaskTypeIcon iconName={TaskTypeEnum.submitForm} size={20} />
              )}
              <Text fw={500}>{task.taskMetadata?.title}</Text>
            </Group>
            {task.workgroupId && (
              <EntityTag
                dataType={DataTypeEnum.workgroup}
                id={task.workgroupId}
                allowClick={false}
              />
            )}
          </Group>

          {task.assignedAt && (
            <Text size="sm" c="dimmed">
              Assigned: {formatDateTime(task.assignedAt)}
            </Text>
          )}
        </Card>
      ))}
    </Stack>
  );
};

export default ApprovalsView;
