import {
  TaskListTypeEnum,
  TaskStatisticsGroupByEnum,
  TaskStatisticsRequest,
  TaskStatisticsResponse,
  useGetTaskStatistics,
} from '@btrway/api-taskstats';
import { useEffect, useState } from 'react';
import {
  EnrichedPersonStats,
  EnrichedTaskListStats,
  TaskListTypeTaskStatisticsResponse,
} from '../types/stats';

interface UseFetchPersonStatsResult {
  data: EnrichedPersonStats[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const calculateTaskListTypeStats = (
  taskLists: TaskStatisticsResponse[],
  taskListType: TaskListTypeEnum
): TaskListTypeTaskStatisticsResponse => {
  const filteredTaskLists = taskLists.filter(
    (w) => w.taskListType === taskListType
  );

  return {
    completedTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.completedTasks || 0),
      0
    ),
    completedTaskLists: filteredTaskLists.reduce(
      (sum, w) => sum + (w.completedTaskLists || 0),
      0
    ),
    overdueCompletedTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.overdueCompletedTasks || 0),
      0
    ),
    totalTaskLists: filteredTaskLists.reduce(
      (sum, w) => sum + (w.totalTaskLists || 0),
      0
    ),
    totalTasks: filteredTaskLists.reduce(
      (sum, w) => sum + (w.totalTasks || 0),
      0
    ),
    taskListType,
  };
};

const calculateTaskListProgress = (
  completedTaskLists: number = 0,
  totalTaskLists: number = 0
): number => {
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

const calculateTaskProgress = (
  completedTasks: number = 0,
  totalTasks: number = 0
): number => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};

const transformToEnrichedTaskList = (
  taskList: TaskStatisticsResponse
): EnrichedTaskListStats => {
  const completedTasks = taskList.completedTasks || 0;
  const totalTasks = taskList.totalTasks || 0;
  const completedTaskLists = taskList.completedTaskLists || 0;
  const totalTaskLists = taskList.totalTaskLists || 0;

  return {
    ...taskList,
    taskListKey: taskList.taskListKey,
    taskListName: taskList.taskListName,
    taskListType: taskList.taskListType,
    taskListClassification: taskList.taskListClassification,
    averageOverdueDays: taskList.averageOverdueDays,
    completedTaskLists,
    completedTasks,
    overdueCompletedTasks: taskList.overdueCompletedTasks,
    totalTaskLists,
    totalTasks,
    progressPercentage: calculateTaskProgress(completedTasks, totalTasks),
    taskListProgressPercentage: calculateTaskListProgress(
      completedTaskLists,
      totalTaskLists
    ),
    remainingTasks: totalTasks - completedTasks,
    remainingTaskLists: totalTaskLists - completedTaskLists,
    isOverdue: (taskList.averageOverdueDays || 0) > 0,
  };
};

const transformToEnrichedPerson = (
  personId: number,
  personName: string,
  taskLists: TaskStatisticsResponse[]
): EnrichedPersonStats => {
  const workgroupInfo = taskLists[0];

  const enrichedTaskLists = taskLists.map((taskList) => ({
    ...transformToEnrichedTaskList(taskList),
    taskListType: taskList.taskListType,
  }));

  const taskListTypes = [
    ...new Set(taskLists.map((w) => w.taskListType).filter(Boolean)),
  ] as TaskListTypeEnum[];

  const totalRemaining = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.remainingTasks,
    0
  );

  const totalRemainingTaskLists = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.remainingTaskLists,
    0
  );

  const totalTaskProgress = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.progressPercentage,
    0
  );

  const totalTaskListProgress = enrichedTaskLists.reduce(
    (sum, taskList) => sum + taskList.taskListProgressPercentage,
    0
  );

  const overdueCount = enrichedTaskLists.filter(
    (taskList) => taskList.isOverdue
  ).length;

  return {
    personId,
    personName,
    workgroupId: workgroupInfo.workgroupId || 0, // Add this
    workgroupName: workgroupInfo.workgroupName || '', // Add this
    taskLists: enrichedTaskLists,
    taskListStatistics: enrichedTaskLists,
    taskListTypeStats: taskListTypes.map((type) =>
      calculateTaskListTypeStats(taskLists, type)
    ),
    totalRemainingTasks: totalRemaining,
    totalRemainingTaskLists: totalRemainingTaskLists,
    averageTaskProgress:
      enrichedTaskLists.length > 0
        ? totalTaskProgress / enrichedTaskLists.length
        : 0,
    averageTaskListProgress:
      enrichedTaskLists.length > 0
        ? totalTaskListProgress / enrichedTaskLists.length
        : 0,
    overdueTaskListsCount: overdueCount,
  };
};

export const useFetchPersonStats = (
  request: TaskStatisticsRequest
): UseFetchPersonStatsResult => {
  const [data, setData] = useState<EnrichedPersonStats[] | undefined>(
    undefined
  );

  const {
    mutateAsync: getTaskStatistics,
    isPending: isLoading,
    isError,
    error: queryError,
  } = useGetTaskStatistics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const personRequest: TaskStatisticsRequest = {
          ...request,
          groupBy: TaskStatisticsGroupByEnum.person,
        };

        const response = await getTaskStatistics({ data: personRequest });

        const statistics = Array.isArray(response) ? response : [response];

        // Group by person
        const personMap = new Map<number, TaskStatisticsResponse[]>();
        statistics.forEach((stat) => {
          if (!stat.personId) return;
          const existing = personMap.get(stat.personId) || [];
          personMap.set(stat.personId, [...existing, stat]);
        });

        // Transform to enriched persons
        const enrichedStats = Array.from(personMap.entries()).map(
          ([personId, taskLists]) =>
            transformToEnrichedPerson(
              personId,
              taskLists[0].personName || '',
              taskLists
            )
        );

        setData(enrichedStats);
      } catch (err) {
        console.error('Error fetching person stats:', err);
        setData(undefined);
      }
    };

    fetchData();
  }, [getTaskStatistics, request]);

  const refetch = async () => {
    await getTaskStatistics({ data: request });
  };

  return {
    data,
    isLoading,
    isError,
    error: queryError instanceof Error ? queryError : null,
    refetch,
  };
};
