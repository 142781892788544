import { TaskAssignmentConfig, TaskTypeEnum } from '@btrway/api-task';
import {
  AssignTaskInputProperties,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { ApproverSelectOptions } from '@btrway/select-approver';
import { ToolboxItem } from '@btrway/workflow-builder-toolbox';
import {
  useConfigFactory,
  useWorkflowConfig,
} from '@btrway/workflow-configuration-manager';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  ScrollArea,
  Stepper,
  Text,
} from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { useState } from 'react';
import WorkflowToolbox from '../WorkflowToolbox/WorkflowToolbox';

interface StepCreatorProps {
  workflowType: WorkflowTypeEnum;
  onComplete: (stepConfig: StepConfig) => void;
  onCancel: () => void;
}

type SelectedType =
  | { type: 'taskType'; code: TaskTypeEnum }
  | { type: 'stepType'; code: StepTypeEnum }
  | { type: 'eventType'; code: EventTypeEnum };

export const StepCreator: React.FC<StepCreatorProps> = ({
  workflowType,
  onComplete,
  onCancel,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedType, setSelectedType] = useState<SelectedType | null>(null);
  const [taskAssignmentConfig, setTaskAssignmentConfig] = useState<
    TaskAssignmentConfig | undefined
  >();
  const configFactory = useConfigFactory();
  const { processActions } = useWorkflowConfig();

  const handleToolboxSelect = (item: ToolboxItem) => {
    console.log('Selected item:', item);

    const newSelectedType = {
      type: item.type,
      code: item.data.code,
    } as SelectedType;

    setSelectedType(newSelectedType);
    setActiveStep(1);
  };

  const handleApproverConfigChange = async (
    config: TaskAssignmentConfig,
    description: string
  ) => {
    const assignTaskProperties: AssignTaskInputProperties = {
      taskType: TaskTypeEnum.completeWorkflowStep,
      taskAssignmentConfig: config,
      taskProperties: {},
    };

    // Create the full step config
    const stepConfig = configFactory.createStepConfig(
      'task',
      TaskTypeEnum.completeWorkflowStep
    );
    stepConfig.title = description + ' Approval';
    stepConfig.inputProperties = assignTaskProperties;

    // Append the step and close
    await processActions.appendStep(stepConfig);
    onComplete(stepConfig);
  };

  const renderApproverSelect = () => (
    <Box style={{ height: 'calc(100vh - 280px)' }}>
      <ApproverSelectOptions
        taskAssignmentConfig={taskAssignmentConfig}
        onConfigChange={handleApproverConfigChange}
        onClose={onCancel}
      />
    </Box>
  );

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box style={{ height: 'calc(100vh - 280px)' }}>
            <WorkflowToolbox
              workflowType={workflowType}
              onSelect={handleToolboxSelect}
            />
          </Box>
        );
      case 1:
        if (
          selectedType?.type === 'taskType' &&
          selectedType.code === TaskTypeEnum.completeWorkflowStep
        ) {
          return renderApproverSelect();
        }
        return (
          <Box style={{ height: 'calc(100vh - 280px)' }}>
            <Text>
              Selected {selectedType?.type}: {selectedType?.code}
            </Text>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ScrollArea style={{ flex: 1 }} offsetScrollbars>
        <Box p="md">
          <Stepper
            active={activeStep}
            onStepClick={setActiveStep}
            allowNextStepsSelect={false}
            styles={{
              root: { width: '100%' },
              steps: { display: 'none' },
              content: {
                marginTop: 0,
                '--stepper-content-padding': '0',
              },
            }}
          >
            <Stepper.Step>
              <Box>
                <Group mb="md" align="center">
                  {activeStep > 0 && (
                    <ActionIcon
                      variant="subtle"
                      onClick={() => setActiveStep((current) => current - 1)}
                      size="lg"
                    >
                      <IconChevronLeft size={20} />
                    </ActionIcon>
                  )}
                  <Text size="lg" fw={500}>
                    {activeStep === 0 ? 'Select Step Type' : 'Configure Step'}
                  </Text>
                </Group>
                {renderStepContent()}
              </Box>
            </Stepper.Step>
            <Stepper.Step>{renderStepContent()}</Stepper.Step>
          </Stepper>
        </Box>
      </ScrollArea>

      <Box
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          backgroundColor: 'var(--mantine-color-gray-0)',
          padding: 'var(--mantine-spacing-md)',
          borderBottomLeftRadius: 'var(--mantine-radius-lg)',
          borderBottomRightRadius: 'var(--mantine-radius-lg)',
        }}
      >
        <Group justify="flex-end">
          <Button variant="subtle" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

export default StepCreator;
