import {
  OrganizationFilter,
  TaskListTemplateSettings,
  TaskListTypeEnum,
} from '@btrway/api-tasklist';
import {
  TaskCreatorActionIcon,
  TaskCreatorDrawer,
  useTaskCreator,
} from '@btrway/task-creator';
import {
  CommonTaskList,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { TaskListSettingsActionIcon } from '@btrway/task-list-settings';
import {
  DeploymentButton,
  DeploymentFilterView,
} from '@btrway/template-deployment';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { Box, Group, Stack, Text } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface TaskListBuilderLayoutProps {
  children: React.ReactNode;
  taskList?: CommonTaskList;
}

const getBackLinkText = (
  taskListType: TaskListTypeEnum | undefined
): string => {
  switch (taskListType) {
    case TaskListTypeEnum.curriculum:
      return 'Back to Curriculums';
    case TaskListTypeEnum.todoList:
      return 'Back to Todo Lists';
    case TaskListTypeEnum.certification:
      return 'Back to Certifications';
    case TaskListTypeEnum.formPacket:
      return 'Back to Form Packets';
    default:
      return 'Back';
  }
};

export const TaskListBuilderLayout: React.FC<TaskListBuilderLayoutProps> = ({
  children,
  taskList,
}) => {
  const navigate = useNavigate();
  const mainContentBackground = '#f0f0f0';
  const taskListService = useTaskListService();
  const {
    isOpen,
    taskList: selectedTaskList,
    taskContainerKey,
    onTaskAdded,
    closeCreator,
    openCreator,
  } = useTaskCreator();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (
      taskList &&
      (!taskList.taskListConfiguration?.tasks ||
        taskList.taskListConfiguration.tasks.length === 0)
    ) {
      const timeoutId = setTimeout(() => {
        openCreator({ taskList });
      }, 200);

      return () => clearTimeout(timeoutId);
    }
  }, [taskList, openCreator]);

  const backLinkText = getBackLinkText(taskList?.taskListType);

  const handleSettingsUpdate = async (settings: {
    organizationFilter: OrganizationFilter;
    taskListTemplateSettings?: TaskListTemplateSettings;
  }) => {
    if (!taskList) return;

    // Create the updated workflow maintaining all required fields
    const updatedTaskList: CommonTaskList = {
      ...taskList,
      organizationFilter: settings.organizationFilter,
      taskListTemplateSettings: settings.taskListTemplateSettings,
    };

    await taskListService.updateTaskList(updatedTaskList);
  };

  return (
    <>
      <Stack h="100vh" gap={0} style={{ width: '100%' }}>
        <Group
          align="center"
          bg="dark.6"
          h={60}
          px="sm"
          style={{ position: 'relative', width: '100%' }}
        >
          <Box style={{ position: 'absolute', left: 16 }}>
            <div
              onClick={() => navigate(-1)}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Group wrap="nowrap">
                <IconChevronLeft size={24} color="white" />
                <Text size="md" c="white" fw={600}>
                  {backLinkText}
                </Text>
              </Group>
            </div>
          </Box>

          <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Group justify="space-between" align="center">
              <Text size="xl" c="white" fw={700}>
                {taskList?.name || ''}
              </Text>
            </Group>
          </Box>
        </Group>

        <Box
          style={{
            backgroundColor: mainContentBackground,
            display: 'flex',
            height: 'calc(100vh - 60px)',
            overflow: 'hidden',
            width: '100%',
            flex: '1 1 auto',
          }}
        >
          {taskList && (
            <Box style={{ position: 'absolute', top: 76, left: 16, zIndex: 1 }}>
              <TaskCreatorActionIcon taskList={taskList} />
            </Box>
          )}
          <Box style={{ position: 'absolute', top: 76, right: 16, zIndex: 1 }}>
            <TaskListSettingsActionIcon />
          </Box>
          <Box
            style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 1 }}
          >
            <Stack gap="md">
              <DeploymentFilterView
                deploymentType="taskList"
                initialOrganizationFilter={taskList?.organizationFilter}
                initialTaskListTemplateSettings={
                  taskList?.taskListTemplateSettings
                }
                onSettingsUpdate={handleSettingsUpdate}
              />
              <DeploymentButton type="taskList" taskList={taskList} />
            </Stack>
          </Box>

          <Box
            style={{
              flex: '1 1 auto',
              overflow: 'auto',
              transition: 'flex 0.3s ease',
              width: '100%',
              minWidth: 0,
            }}
          >
            <Suspense fallback={<div>Loading builder...</div>}>
              <Box
                style={{
                  position: 'relative',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <RightSectionProvider>
                  <SettingsDisplayProvider>
                    {/* <WorkflowBuilderPanelProvider> */}
                    {children}
                    {/* </WorkflowBuilderPanelProvider> */}
                  </SettingsDisplayProvider>
                </RightSectionProvider>
              </Box>
            </Suspense>
          </Box>
        </Box>
      </Stack>
      <TaskCreatorDrawer
        opened={isOpen}
        onClose={closeCreator}
        taskList={selectedTaskList}
        taskContainerKey={taskContainerKey}
        onTaskAdded={(task) => {
          onTaskAdded?.(task);
          closeCreator();
        }}
      />
    </>
  );
};
