import {
  ExternalCourseTaskProperties,
  TaskConfig,
  TaskTypeEnum,
} from '@btrway/api-task';
import {
  CommonExternalCourse,
  useExternalCourseService,
} from '@btrway/external-course-service-provider';
import { SelectExternalCourse } from '@btrway/select-external-course';
import { getTypedTaskProperties } from '@btrway/task-list-config-provider';
import { Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskConfigProps } from '../../types/taskTypeComponentProps';

export const ExternalCourseTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.externalCourse
  );

  const service = useExternalCourseService();

  const [properties, setProperties] = useState<ExternalCourseTaskProperties>({
    externalCourseKey: {
      value: taskProperties?.externalCourseKey?.value,
    },
  });

  console.log('externalCourseKey', properties.externalCourseKey?.value);
  // Get the currently selected course based on the course key
  const { data: selectedCourse } = service.getCourseByKey(
    properties.externalCourseKey?.value
  );

  console.log('selectedCourse', selectedCourse);

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        externalCourseKey: {
          value: taskProperties.externalCourseKey?.value,
        },
      });
    }
  }, [taskProperties]);

  const handleCourseChange = (course: CommonExternalCourse | null) => {
    // Get the current course key and title before making changes
    const currentCourseKey = properties.externalCourseKey?.value;
    const { data: currentCourse } = service.getCourseByKey(currentCourseKey);
    const currentCourseTitle =
      currentCourse?.courseKey || 'Take External Course';

    const updatedProperties: ExternalCourseTaskProperties = {
      externalCourseKey: {
        value: course?.courseKey,
      },
    };

    setProperties(updatedProperties);

    const newTitle = course?.courseKey || 'Take External Course';
    const shouldUpdateTitle = config.metadata?.title === currentCourseTitle;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        title: shouldUpdateTitle ? newTitle : config.metadata?.title,
      },
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  // Find the course ID based on the selected course key
  const selectedCourseId = selectedCourse?.id || null;

  return (
    <Stack>
      <SelectExternalCourse
        value={selectedCourse}
        onChange={handleCourseChange}
        placeholder="Select an external course"
        label="External Course"
        required
      />
    </Stack>
  );
};
