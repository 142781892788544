import { TaskConfig, TaskListConfig } from '@btrway/api-tasklist';
import { useTaskListUpdater } from '@btrway/task-list-service-provider';
import { uuid } from '@btrway/utils';
import { useCallback } from 'react';
import { TaskActions } from '../types/actions';
import {
  addChildTask,
  findTaskByKey,
  removeTaskFromTree,
  updateTaskInTree,
} from '../utils/taskTreeUtils';

export const useTaskActions = (
  tasklistUpdater: ReturnType<typeof useTaskListUpdater>,
  taskListConfig: TaskListConfig,
  setTaskListConfig: React.Dispatch<React.SetStateAction<TaskListConfig>>
): TaskActions => {
  const addTask = useCallback(
    async (newTask: TaskConfig, taskContainerKey?: string) => {
      const taskWithKey = {
        ...newTask,
        taskKey: newTask.taskKey || uuid(),
      };

      let updatedRootTask: TaskConfig | undefined;

      setTaskListConfig((prev) => {
        if (!taskContainerKey) {
          console.log('Adding taskWithKey to taskListConfig: ', taskWithKey);
          // Add to root tasks array if no parent specified
          return {
            ...prev,
            tasks: [...(prev.tasks || []), taskWithKey],
          };
        }

        // Add to parent's childTasks using utility function
        const updatedTasks = addChildTask(
          prev.tasks,
          taskContainerKey,
          taskWithKey
        );

        // Find the top-level task that contains our updated tree
        updatedRootTask = updatedTasks.find(
          (task) =>
            findTaskByKey(task.childTasks || [], taskWithKey.taskKey) ||
            task.taskKey === taskContainerKey
        );

        return {
          ...prev,
          tasks: updatedTasks,
        };
      });

      // If we're adding to a parent, send only the updated root task
      // Otherwise, send just the new task
      await tasklistUpdater.updateConfig({
        taskListConfig: {
          tasks: [taskContainerKey ? updatedRootTask! : taskWithKey],
        },
      });

      return taskWithKey;
    },
    [tasklistUpdater]
  );

  const removeTask = useCallback(
    async (taskKey: string) => {
      let updatedRootTask: TaskConfig | undefined;

      setTaskListConfig((prev) => {
        // First find the task to determine if it's a child task
        const foundTask = findTaskByKey(prev.tasks, taskKey);
        if (!foundTask) {
          throw new Error(`Task with key ${taskKey} not found`);
        }

        const updatedTasks = removeTaskFromTree(prev.tasks, taskKey);

        // If this was a child task, find the root task that contained it
        if (foundTask.parent) {
          // Find the top-level task that was containing our deleted task
          updatedRootTask = prev.tasks?.find((task) => {
            // Check if this root task or any of its descendants contained our task
            return findTaskByKey(task.childTasks || [], taskKey) !== null;
          });

          if (updatedRootTask) {
            // Get the updated version of the root task after removal
            updatedRootTask = updatedTasks.find(
              (task) => task.taskKey === updatedRootTask!.taskKey
            );
          }
        }

        return {
          ...prev,
          tasks: updatedTasks,
        };
      });

      // If we removed a child task, send the updated parent task to the server
      // If we removed a root task, send just the taskKey for deletion
      await tasklistUpdater.updateConfig({
        deleteConfigs: updatedRootTask ? [] : [taskKey],
        taskListConfig: updatedRootTask
          ? { tasks: [updatedRootTask] }
          : undefined,
      });
    },
    [tasklistUpdater]
  );

  const updateTask = useCallback(
    async (taskKey: string, updatedTask: Partial<TaskConfig>) => {
      let updatedConfig: TaskConfig | undefined;
      let updatedRootTask: TaskConfig | undefined;

      setTaskListConfig((prev) => {
        const foundTask = findTaskByKey(prev.tasks, taskKey);

        if (!foundTask) {
          throw new Error(`Task with key ${taskKey} not found`);
        }

        updatedConfig = {
          ...foundTask.task,
          ...updatedTask,
          taskKey,
        };

        const updatedTasks = updateTaskInTree(
          prev.tasks,
          taskKey,
          () => updatedConfig!
        );

        // If this task has a parent, find the root task that contains it
        if (foundTask.parent) {
          updatedRootTask = updatedTasks.find((task) => {
            const searchResult = findTaskByKey(task.childTasks || [], taskKey);
            return searchResult !== null;
          });
        }

        return {
          ...prev,
          tasks: updatedTasks,
        };
      });

      if (!updatedConfig) {
        throw new Error(`Failed to update task ${taskKey}`);
      }

      // If we're updating a child task, send the updated root task
      // Otherwise, send just the updated task itself
      await tasklistUpdater.updateConfig({
        taskListConfig: {
          tasks: [updatedRootTask || updatedConfig],
        },
      });

      return updatedConfig;
    },
    [tasklistUpdater]
  );

  return {
    addTask,
    removeTask,
    updateTask,
  };
};
