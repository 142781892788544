// hooks/useWorkflowDefinition.ts
import {
  getGetWorkflowDefinitionByKeyQueryKey,
  getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey,
  getGetWorkflowDefinitionsByOrganizationQueryKey,
  useDeleteWorkflowDefinition,
  useGetWorkflowDefinitionByKey,
  useGetWorkflowDefinitionsByOrganization,
  useGetWorkflowDefinitionsByOrganizationAndType,
  useSaveWorkflowDefinition,
  useUpdateWorkflowDefinitionConfig,
  WorkflowDefinitionConfigUpdate,
  WorkflowDefinitionRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonWorkflow } from '../types/commonWorkflow';
import { WorkflowResult, WorkflowsResult } from '../types/service';
import {
  convertCommonToWorkflowRequest,
  convertResponseToCommon,
  validateWorkflowBeforeConversion,
} from '../utils/commonWorkflowConversions';

export function useWorkflowDefinition(
  organizationId: number,
  workflowKey?: string
) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'definition';
  const { mutateAsync: updateConfig } = useUpdateWorkflowDefinitionConfig();
  const { mutateAsync: saveWorkflowDefinition } = useSaveWorkflowDefinition();
  const { mutateAsync: deleteDefinition } = useDeleteWorkflowDefinition();

  const {
    data,
    isLoading,
    error,
    refetch: refetchDefinition,
  } = useGetWorkflowDefinitionByKey(
    organizationId,
    workflowKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!workflowKey,
        queryKey: getGetWorkflowDefinitionByKeyQueryKey(
          organizationId,
          workflowKey || ''
        ),
      },
    }
  );

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    refetch: refetchDefinitions,
  } = useGetWorkflowDefinitionsByOrganization(organizationId, {
    query: {
      enabled: isEnabled,
      queryKey: getGetWorkflowDefinitionsByOrganizationQueryKey(organizationId),
    },
  });

  const getWorkflowsByType = (type: WorkflowTypeEnum) => {
    const {
      data: typeData,
      isLoading: typeLoading,
      error: typeError,
      refetch: refetchType,
    } = useGetWorkflowDefinitionsByOrganizationAndType(organizationId, type, {
      query: {
        enabled: isEnabled,
        queryKey: getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey(
          organizationId,
          type
        ),
      },
    });

    return {
      workflows: (typeData || []).map(convertResponseToCommon),
      isLoading: typeLoading,
      error: typeError as Error | undefined,
      refetch: refetchType,
    };
  };

  const result: WorkflowResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchDefinition,
  };

  const definitionsResult: WorkflowsResult = {
    workflows: (definitionsData || []).map(convertResponseToCommon),
    isLoading: definitionsLoading,
    error: definitionsError as Error | undefined,
    refetch: refetchDefinitions,
  };

  return {
    workflowResult: result,
    workflowsResult: definitionsResult,
    getWorkflowsByType,
    updateConfig: async (config: WorkflowDefinitionConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'definition') {
        throw new Error('Cannot save template workflow in definition context');
      }
      const validationErrors = validateWorkflowBeforeConversion(workflow);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid workflow: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToWorkflowRequest(
        workflow
      ) as WorkflowDefinitionRequest;
      return await saveWorkflowDefinition({ data: request });
    },
    deleteWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'definition') {
        throw new Error(
          'Cannot delete template workflow in definition context'
        );
      }
      if (!workflow.id) {
        throw new Error('Cannot delete workflow without an id');
      }
      return await deleteDefinition({ workflowDefinitionId: workflow.id });
    },
  };
}
