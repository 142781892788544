import { TaskConfig } from '@btrway/api-tasklist';
import { useTaskCreator } from '@btrway/task-creator';
import { useTaskListConfig } from '@btrway/task-list-config-provider';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Button, Group, Stack } from '@mantine/core';
import React from 'react';
import TaskCard from '../TaskCard/TaskCard';

interface TaskContainerProps {
  taskList: CommonTaskList;
  taskConfig: TaskConfig;
}

export const TaskContainer: React.FC<TaskContainerProps> = ({
  taskList,
  taskConfig,
}) => {
  const { openCreator } = useTaskCreator();
  const { taskActions } = useTaskListConfig();

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!taskList?.taskListType) return;

    console.log('opening add task from task container');
    openCreator({
      taskList,
      taskContainerKey: taskConfig.taskKey,
      onTaskAdded: (task) => {
        console.log('Task added to container:', task);
        // taskActions.addTask(task, taskConfig.taskKey);
      },
    });
  };

  return (
    <Stack gap="md">
      {taskConfig.childTasks?.map((childConfig, index) => (
        <TaskCard
          taskList={taskList}
          taskConfig={childConfig}
          key={childConfig.taskKey}
          index={index}
          isLast={index === (taskConfig.childTasks?.length ?? 0) - 1}
        />
      ))}
      <Group justify="center">
        <Button variant="light" onClick={handleAddClick}>
          {taskConfig.childTasks?.length
            ? 'Add Another Option'
            : 'Add an Option'}
        </Button>
      </Group>
    </Stack>
  );
};
