import { TaskCreatorProvider } from '@btrway/task-creator';
import { TaskListConfigProvider } from '@btrway/task-list-config-provider';
import {
  TaskListServiceContext,
  TaskListServiceProvider,
} from '@btrway/task-list-service-provider';
import { Card, ScrollArea } from '@mantine/core';
import React from 'react';
import TaskListView from '../TaskListView/TaskListView';

interface NestedTaskListProps {
  taskListKey: string;
  parentTaskListKey: string;
}

export const NestedTaskList: React.FC<NestedTaskListProps> = ({
  taskListKey,
  parentTaskListKey,
}) => {
  console.log('Nested Workflow View', taskListKey, parentTaskListKey);
  if (!taskListKey || taskListKey === parentTaskListKey) return null;

  return (
    <TaskListServiceProvider taskListKey={taskListKey}>
      <TaskListServiceContext.Consumer>
        {(service) => {
          if (!service) return null;
          const { data: taskList, isLoading } = service.getByKey();

          if (!taskList || isLoading) {
            return null;
          }

          return (
            <TaskListConfigProvider
              initialConfig={taskList.taskListConfiguration}
            >
              <TaskCreatorProvider>
                <Card bg="gray.3" withBorder p={0} radius="md">
                  <ScrollArea
                    h={300}
                    offsetScrollbars
                    type="auto"
                    pl="xs"
                    pt={0}
                    pb={0}
                  >
                    <TaskListView taskList={taskList} nestedTaskList={true} />
                  </ScrollArea>
                </Card>
              </TaskCreatorProvider>
            </TaskListConfigProvider>
          );
        }}
      </TaskListServiceContext.Consumer>
    </TaskListServiceProvider>
  );
};
