// import { useAuthenticatedUser } from '@btrway/current-user';
// import { TaskCompleter } from '@btrway/task-completer';
// import {
//   TasksManagerProvider,
//   useTaskModal,
//   useTasksManager,
// } from '@btrway/task-manager';
// import { memo, useEffect, useRef } from 'react';

// interface TaskManagerProps {
//   children: React.ReactNode;
// }

// const TaskManagerContent: React.FC<TaskManagerProps> = memo(({ children }) => {
//   const { modalState, closeTask } = useTaskModal();
//   const { fetchTasks } = useTasksManager();
//   const { currentPerson, currentOrganization } = useAuthenticatedUser();
//   const hasInitialFetch = useRef(false);

//   useEffect(() => {
//     if (
//       currentPerson?.id &&
//       currentOrganization?.id &&
//       !hasInitialFetch.current
//     ) {
//       hasInitialFetch.current = true;
//       fetchTasks({
//         personIds: [currentPerson.id],
//         organizationId: currentOrganization.id,
//       });
//     }
//   }, [currentPerson?.id, currentOrganization?.id, fetchTasks]);

//   return (
//     <>
//       {children}
//       <TaskCompleter
//         isOpen={modalState.isOpen}
//         taskInstance={modalState.taskInstance}
//         onClose={closeTask}
//       />
//     </>
//   );
// });

// TaskManagerContent.displayName = 'TaskManagerContent';

// const TaskManager: React.FC<TaskManagerProps> = ({ children }) => {
//   return (
//     <TasksManagerProvider>
//       <TaskManagerContent>{children}</TaskManagerContent>
//     </TasksManagerProvider>
//   );
// };

// TaskManager.displayName = 'TaskManager';

// export default TaskManager;

import { DataTypeEnum } from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { TaskCompleter } from '@btrway/task-completer';
import {
  TasksManagerProvider,
  useApprovalTasks,
  useTaskModal,
  useTasksManager,
} from '@btrway/task-manager';
import { memo, useEffect, useRef } from 'react';

interface TaskManagerProps {
  children: React.ReactNode;
}

const TaskManagerContent: React.FC<TaskManagerProps> = memo(({ children }) => {
  const { modalState, closeTask } = useTaskModal();
  const { fetchTasks } = useTasksManager();
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const hasInitialFetch = useRef(false);

  // Initialize approval tasks
  const { refetch: refetchApprovalTasks } = useApprovalTasks({
    personId: currentPerson?.id,
  });

  useEffect(() => {
    if (
      currentPerson?.id &&
      currentOrganization?.id &&
      !hasInitialFetch.current
    ) {
      hasInitialFetch.current = true;

      // Fetch both regular tasks and approval tasks
      Promise.all([
        fetchTasks({
          organizationId: currentOrganization.id,
          assignedEntityType: DataTypeEnum.person,
          assignedEntityId: currentPerson.id,
        }),
        refetchApprovalTasks(),
      ]);
    }
  }, [
    currentPerson?.id,
    currentOrganization?.id,
    fetchTasks,
    refetchApprovalTasks,
  ]);

  return (
    <>
      {children}
      <TaskCompleter
        isOpen={modalState.isOpen}
        taskInstance={modalState.taskInstance}
        onClose={closeTask}
      />
    </>
  );
});

TaskManagerContent.displayName = 'TaskManagerContent';

const TaskManager: React.FC<TaskManagerProps> = ({ children }) => {
  return (
    <TasksManagerProvider>
      <TaskManagerContent>{children}</TaskManagerContent>
    </TasksManagerProvider>
  );
};

TaskManager.displayName = 'TaskManager';

export default TaskManager;
