import { TaskDateProperties, TaskListTaskConfig } from '@btrway/api-tasklist';
import { Grid, NumberInput, Stack } from '@mantine/core';
import React from 'react';

interface TaskDateEditorProps {
  config?: TaskListTaskConfig;
  onChange: (config: TaskListTaskConfig) => void;
}

export const TaskDateEditor: React.FC<TaskDateEditorProps> = ({
  config,
  onChange,
}) => {
  const handleDateChange = (
    dateField: 'startDate' | 'endDate',
    offsetDays: number | string
  ) => {
    const dateConfig: TaskDateProperties = {
      offsetDays:
        typeof offsetDays === 'string' ? parseInt(offsetDays) : offsetDays,
    };

    // Create a new config object if none exists
    const baseConfig: TaskListTaskConfig = config || {};

    onChange({
      ...baseConfig,
      [dateField]: dateConfig,
    });
  };

  const getOffsetDays = (dateField: 'startDate' | 'endDate'): number => {
    if (!config) return 0;
    const dateConfig = config[dateField];
    return dateConfig?.offsetDays ?? 0;
  };

  return (
    <Stack gap="md">
      <Grid>
        <Grid.Col span={6}>
          <NumberInput
            label="Start Day"
            value={getOffsetDays('startDate')}
            onChange={(value) => handleDateChange('startDate', value ?? 0)}
            min={0}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput
            label="End Day"
            value={getOffsetDays('endDate')}
            onChange={(value) => handleDateChange('endDate', value ?? 0)}
            min={0}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};

export default TaskDateEditor;
