import { ExternalCourseTemplateRequest, ExternalCourseTemplateResponse, useGetProviders } from '@btrway/api-external-provider';
import { uuid } from '@btrway/utils';
import { Badge, Button, Card, Group, Select, Spoiler, Stack, Text, TextInput, Textarea, UnstyledButton } from '@mantine/core';
import { IconExternalLink, IconEdit, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';

interface NewExternalCourseCardProps {
  onSave?: (updatedCourse: ExternalCourseTemplateRequest) => void;
  onCancel: () => void;
}

const NewExternalCourseCard: React.FC<NewExternalCourseCardProps> = ({
  onSave,
  onCancel
}) => {
  const [values, setValues] = useState({
    title: '',
    description: '',
    url: '',
    providerId: 0
  });
  const {data: providers} = useGetProviders();

  const handleSave = () => {
    if (onSave) {
      const updatedCourse: ExternalCourseTemplateRequest = {
        courseConfiguration: {
          title: values?.title,
          description: values?.description,
          url: values.url
        },
        providerId: values.providerId,
        courseKey: uuid(),
      };
      onSave(updatedCourse);
    }
  };


  const providerSelectData = providers?.map(provider => ({
    value: provider.id.toString(),
    label: provider.name
  })) ?? [];

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack gap="xs">
        <Group justify="space-between" align="center" mb={4}>
          {/* Title and Provider section */}
          <Group align="center" gap="sm">
              <TextInput
                value={values.title}
                onChange={(e) =>
                  setValues({ ...values, title: e.target.value })
                }
                placeholder="Course Title"
                size="lg"
                style={{ flex: 1, width: 400 }}
              />
           
              <Select
                data={providerSelectData}
                value={values.providerId.toString()}
                placeholder='Course Provider'
                onChange={(e) => {
                  const providerId = Number(e);
                  setValues({...values, providerId: providerId})
                  }
                }
                size="sm"
              />
          </Group>
          
            <Group>
              <Button
                variant="light"
                color="green"
                size="xs"
                onClick={handleSave}
                leftSection={<IconCheck size="1rem" />}
              >
                Save
              </Button>
              <Button
                variant="light"
                color="red"
                size="xs"
                onClick={onCancel}
                leftSection={<IconX size="1rem" />}
              >
                Cancel
              </Button>
            </Group>
        </Group>
          <Stack gap="xs">
            <TextInput
              value={values.url}
              onChange={(e) =>
                setValues({ ...values, url: e.target.value })
              }
              placeholder="Course URL"
              size="sm"
            />
            <Textarea
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              placeholder="Course Description"
              size="sm"
              autosize
              minRows={2}
            />
          </Stack>
      </Stack>
    </Card>
  );
};

export default NewExternalCourseCard;