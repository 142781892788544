import { useTaskListConfig } from '@btrway/task-list-config-provider';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { ActionIcon } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import { useTaskCreator } from '../../providers/TaskCreatorProvider';
import { TaskCreatorDrawer } from '../TaskCreatorDrawer/TaskCreatorDrawer';

interface TaskCreatorActionIconProps {
  taskList: CommonTaskList;
}

export const TaskCreatorActionIcon: React.FC<TaskCreatorActionIconProps> = ({
  taskList,
}) => {
  const { isOpen, closeCreator, openCreator } = useTaskCreator();
  const { taskActions } = useTaskListConfig();

  const handleOpenToolbox = () => {
    openCreator({
      taskList: taskList,
      onTaskAdded: (task) => {
        // Handle task addition here
        console.log('adding task from taskcreatoractionicon');
        taskActions.addTask(task);
      },
    });
  };

  return (
    <>
      <ActionIcon
        size="xl"
        bg="white"
        color="gray.4"
        variant="outline"
        radius="xl"
        onClick={handleOpenToolbox}
      >
        <IconPlus size={24} color="var(--mantine-color-gray-8)" />
      </ActionIcon>

      <TaskCreatorDrawer
        opened={isOpen}
        onClose={closeCreator}
        taskList={taskList}
        onTaskAdded={(task) => {
          // taskActions.addTask(task);
          closeCreator();
        }}
      />
    </>
  );
};

export default TaskCreatorActionIcon;
