import { useGetDemoUsers } from '@btrway/api-core';
import { Select } from '@mantine/core';

const DEMO_PASSWORD = 'betterway24';

interface DemoAccountsProps {
  onSelect: (email: string, password: string) => void;
}

export function DemoAccounts({ onSelect }: DemoAccountsProps) {
  const { data: demoUsers, isLoading } = useGetDemoUsers();

  const handleSelect = (email: string | null) => {
    if (email) {
      onSelect(email, DEMO_PASSWORD);
    }
  };

  // Transform API data into format for select component
  const transformedData = demoUsers
    ? Object.entries(
        demoUsers
          // First sort the users by organizationName, firstName, and lastName
          .sort((a, b) => {
            // First sort by organization name
            const orgCompare = a.organizationName.localeCompare(
              b.organizationName
            );
            if (orgCompare !== 0) return orgCompare;

            // Then by firstName
            const firstNameCompare = a.firstName.localeCompare(b.firstName);
            if (firstNameCompare !== 0) return firstNameCompare;

            // Finally by lastName
            return a.lastName.localeCompare(b.lastName);
          })
          .reduce((acc, user) => {
            // Group by organization name
            if (!acc[user.organizationName]) {
              acc[user.organizationName] = [];
            }

            // Create item for each user
            acc[user.organizationName].push({
              value: user.email,
              label: `${user.firstName} ${user.lastName} (${user.userRoleName} at ${user.workgroupName})`,
            });

            return acc;
          }, {} as Record<string, { value: string; label: string }[]>)
      )
        // Sort the groups by organization name
        .sort(([orgNameA], [orgNameB]) => orgNameA.localeCompare(orgNameB))
        // Sort items within each group
        .map(([orgName, items]) => ({
          group: orgName,
          items: items.sort((a, b) => a.label.localeCompare(b.label)),
        }))
    : [];

  return (
    <>
      <Select
        placeholder={isLoading ? 'Loading...' : 'Select a demo account'}
        data={transformedData}
        searchable
        clearable
        onChange={handleSelect}
        disabled={isLoading}
        styles={(theme) => ({
          input: {
            backgroundColor: 'white',
            borderColor: '#ced4da',
          },
        })}
      />
    </>
  );
}
