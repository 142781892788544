import {
  DataTypeEnum,
  PermissionGrantLevelEnum,
  useGetPermissionGrants,
  useSavePermissionGrants,
} from '@btrway/api-security';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { GrantState } from '../types/state';
import { createGrantsMap, responseToGrantRequest } from '../utils/grantUtils';
import { usePermissionManager } from './PermissionManagerProvider';

export interface EntityContext {
  entityType?: DataTypeEnum;
  entityId?: number;
}

export interface PermissionGrantContextValue {
  state: GrantState;
  actions: {
    setPermissionValue: (
      permissionId: number,
      value: PermissionGrantLevelEnum,
      entityContext?: EntityContext
    ) => void;
    saveChanges: () => Promise<void>;
    discardChanges: () => void;
  };
}
const PermissionGrantContext = createContext<
  PermissionGrantContextValue | undefined
>(undefined);

export const usePermissionGrant = () => {
  const context = useContext(PermissionGrantContext);
  if (!context) {
    throw new Error(
      'usePermissionGrant must be used within a PermissionGrantProvider'
    );
  }
  return context;
};

interface PermissionGrantProviderProps {
  children: React.ReactNode;
  organizationId: number;
  userRoleId?: number;
  workgroupId?: number;
  personId?: number;
}

export const PermissionGrantProvider: React.FC<
  PermissionGrantProviderProps
> = ({ children, organizationId, userRoleId, workgroupId, personId }) => {
  const {
    state: {
      admin: { selectedApplication },
    },
  } = usePermissionManager();

  // Initialize state
  const [state, setState] = useState<GrantState>({
    organizationId,
    userRoleId,
    workgroupId,
    personId,
    grants: new Map(),
    organizationGrants: new Map(),
    modifications: new Set(),
    loading: true,
    error: null,
  });

  // Get organization grants for both org and role modes
  const {
    data: orgPermissionGrants,
    isLoading: orgLoading,
    error: orgError,
  } = useGetPermissionGrants(organizationId, {});

  // Get role/workgroup/person grants
  const {
    data: permissionGrants,
    isLoading,
    error,
  } = useGetPermissionGrants(organizationId, {
    userRoleId,
    workgroupId,
    personId,
  });

  const savePermissionMutation = useSavePermissionGrants();

  // Initialize grants from API response
  useEffect(() => {
    if (orgPermissionGrants) {
      // Store organization grants separately
      const orgGrants = new Map(
        orgPermissionGrants.map((grant) => [
          grant.permission.id,
          grant.grantLevel,
        ])
      );

      if (!userRoleId) {
        // In organization mode, use org grants for both
        const grants = orgPermissionGrants.map((response) =>
          responseToGrantRequest(response, organizationId)
        );
        setState((prev) => ({
          ...prev,
          grants: createGrantsMap(grants),
          organizationGrants: orgGrants,
          loading: false,
        }));
      } else if (permissionGrants) {
        // In role mode, use role grants for active grants but keep org grants
        const grants = permissionGrants.map((response) =>
          responseToGrantRequest(
            response,
            organizationId,
            userRoleId,
            workgroupId,
            personId
          )
        );
        setState((prev) => ({
          ...prev,
          grants: createGrantsMap(grants),
          organizationGrants: orgGrants,
          loading: false,
        }));
      }
    }
  }, [
    userRoleId,
    orgPermissionGrants,
    permissionGrants,
    organizationId,
    workgroupId,
    personId,
  ]);

  // Update loading/error state
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      loading: isLoading || (Boolean(userRoleId) && orgLoading),
      error:
        error instanceof Error
          ? error
          : orgError instanceof Error
          ? orgError
          : error || orgError
          ? new Error(String(error || orgError))
          : null,
    }));
  }, [isLoading, orgLoading, error, orgError]);

  const setPermissionValue = (
    permissionId: number,
    value: PermissionGrantLevelEnum,
    entityContext?: EntityContext
  ) => {
    setState((prev) => {
      const newGrants = new Map(prev.grants);
      const existingGrant = newGrants.get(permissionId);

      if (existingGrant) {
        // Update existing grant, preserving its properties
        newGrants.set(permissionId, {
          ...existingGrant,
          grantLevel: value,
          ...(entityContext?.entityType && entityContext?.entityId
            ? {
                entityType: entityContext.entityType,
                entityId: entityContext.entityId,
              }
            : {}),
        });
      } else {
        // Create new grant
        newGrants.set(permissionId, {
          permissionId,
          grantLevel: value,
          organizationId,
          userRoleId,
          workgroupId,
          personId,
          ...(entityContext?.entityType && entityContext?.entityId
            ? {
                entityType: entityContext.entityType,
                entityId: entityContext.entityId,
              }
            : {}),
        });
      }

      return {
        ...prev,
        grants: newGrants,
        modifications: new Set(prev.modifications).add(permissionId),
      };
    });
  };

  const saveChanges = async () => {
    try {
      const modifiedGrants = Array.from(state.modifications).map(
        (permissionId) => state.grants.get(permissionId)!
      );

      if (modifiedGrants.length > 0) {
        await savePermissionMutation.mutateAsync({ data: modifiedGrants });
        setState((prev) => ({
          ...prev,
          modifications: new Set(),
        }));
      }
    } catch (err) {
      setState((prev) => ({
        ...prev,
        error: err instanceof Error ? err : new Error('Failed to save changes'),
      }));
      throw err;
    }
  };

  const discardChanges = () => {
    if (permissionGrants) {
      const grants = permissionGrants.map((response) =>
        responseToGrantRequest(
          response,
          organizationId,
          userRoleId,
          workgroupId,
          personId
        )
      );
      setState((prev) => ({
        ...prev,
        grants: createGrantsMap(grants),
        modifications: new Set(),
      }));
    }
  };

  const value: PermissionGrantContextValue = {
    state,
    actions: {
      setPermissionValue,
      saveChanges,
      discardChanges,
    },
  };

  return (
    <PermissionGrantContext.Provider value={value}>
      {children}
    </PermissionGrantContext.Provider>
  );
};
