// ExternalCourses.tsx
import { ExternalCourseTemplateRequest, ExternalCourseTemplateResponse, useGetExternalCourseTemplates, useGetProviders, useSaveExternalCourseTemplate } from '@btrway/api-external-provider';
import { ActionIcon, Alert, Button, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { IconAlertCircle, IconPlus, IconSettings } from '@tabler/icons-react';
import React, { useState } from 'react';
import ExternalCourseCard from '../ExternalCourseCard/ExternalCourseCard';
import { useDisclosure } from '@mantine/hooks';
import { ExternalCourseProviderDrawer } from '../ExternalCourseProviderDrawer/ExternalCourseProviderDrawer';
import { uuid } from '@btrway/utils';
import NewExternalCourseCard from '../NewExternalCourseCard/NewExternalCourseCard';

const ExternalCourses: React.FC = () => {
  const {
    data: externalCourseTemplates,
    isLoading,
    error,
    refetch
  } = useGetExternalCourseTemplates();

  const {mutate: saveExternalCourse} = useSaveExternalCourseTemplate();
  const [opened, { open, close }] = useDisclosure(false);
  const {data: providers, refetch: refetchProviders} = useGetProviders();
  const [isAddingNew, setIsAddingNew] = useState(false);
  
  const handleProviderChange = () => {
    refetchProviders();
  };
  
  const handleSaveExternalCourse = (updatedCourse: ExternalCourseTemplateRequest) => {
    saveExternalCourse({data: updatedCourse});
    if(isAddingNew) setIsAddingNew(false);
    setTimeout(() => refetch(), 25);
  };

  

  if (isLoading) {
    return (
      <Stack align="center" py="xl">
        <Loader size="lg" />
        <Text size="sm" c="dimmed">
          Loading external courses...
        </Text>
      </Stack>
    );
  }

  if (error) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="Error loading courses"
        color="red"
        variant="filled"
      >
        Unable to load external courses. Please try again later.
      </Alert>
    );
  }

  if (!externalCourseTemplates?.length) {
    return (
      <Alert
        icon={<IconAlertCircle size="1rem" />}
        title="No courses found"
        color="gray"
      >
        No external courses are currently available.
      </Alert>
    );
  }

  return (
    <Stack gap="md">
      <Group justify="space-between" align="center" >
            <Button
              leftSection={<IconPlus />}
              onClick={() => setIsAddingNew(true)}
            >
              Add External Course
            </Button>
            <ActionIcon
              variant="subtle"
              color="gray"
              size="lg"
              aria-label="Manage facilities"
              onClick={open}
            >
              <IconSettings style={{ width: '70%', height: '70%' }} />
            </ActionIcon>
          </Group>
          {isAddingNew && 
            <NewExternalCourseCard onSave={handleSaveExternalCourse} onCancel={() => setIsAddingNew(false)} />
          }
          {externalCourseTemplates.map((course) => (
            <ExternalCourseCard key={course.id} externalCourse={course} onSave={handleSaveExternalCourse}/>
          ))}
        <ExternalCourseProviderDrawer
              opened={opened}
              onClose={close}
              providers={providers ?? []}
              onExternalCourseChange={handleProviderChange}
            />
    </Stack>
  );
};

export default ExternalCourses;
