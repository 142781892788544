import { useTaskListService } from '../providers/TaskListServiceProvider';
import { TaskListService, TaskListServiceConfig } from '../types/service';

export function useTaskListUpdater(providedService?: TaskListService) {
  const contextService = useTaskListService();
  const tasklistService = providedService || contextService;
  const { data: tasklist } = tasklistService.getByKey();

  const getEffectiveTaskListKey = (overrideKey?: string) => {
    if (overrideKey) return overrideKey;
    if (!tasklistService.taskListKey) {
      throw new Error('No tasklistKey available');
    }
    return tasklistService.taskListKey;
  };

  const updateConfig = async (
    config: TaskListServiceConfig,
    tasklistKey?: string
  ) => {
    const effectiveKey = getEffectiveTaskListKey(tasklistKey);
    await tasklistService.updateConfig(effectiveKey, config);
    const result = tasklistService.getByKey(effectiveKey);
    await result.refetch();
  };

  return {
    updateConfig,
  };
}
