import {
  TaskConfig,
  TaskMetadata,
  TaskProperties,
  TaskTypeEnum,
} from '@btrway/api-tasklist';
import { uuid } from '@btrway/utils';

export const createBaseTaskConfig = (
  taskType: TaskTypeEnum,
  title: string = ''
): TaskConfig => ({
  taskKey: uuid(),
  taskType,
  metadata: {
    title,
    description: '',
  } as TaskMetadata,
  taskProperties: {} as TaskProperties,
});

export const createBaseWorkflowTaskConfig = (
  workflowKey: string,
  workflowName: string,
  taskType: TaskTypeEnum
): TaskConfig => ({
  ...createBaseTaskConfig(taskType, workflowName),
  taskProperties: {
    workflowSelection: {
      workflowDefinitionKey: { value: workflowKey },
    },
  } as TaskProperties,
});

export const createBaseTaskListTaskConfig = (
  taskListKey: string,
  taskListName: string,
  taskType: TaskTypeEnum
): TaskConfig => ({
  ...createBaseTaskConfig(taskType, taskListName),
  taskProperties: {
    taskListSelection: {
      taskListKey: { value: taskListKey },
    },
  } as TaskProperties,
});

export const createBaseCourseTaskConfig = (
  courseUid: string,
  courseName: string
): TaskConfig => ({
  ...createBaseTaskConfig(TaskTypeEnum.completeCourse, courseName),
  taskProperties: {
    courseSelection: {
      courseUid: { value: courseUid },
    },
  } as TaskProperties,
});

export const createBaseExternalCourseTaskConfig = (
  externalCourseKey: string,
  courseName: string
): TaskConfig => ({
  ...createBaseTaskConfig(TaskTypeEnum.externalCourse, courseName),
  taskProperties: {
    externalCourseKey: {
      value: externalCourseKey,
    },
  } as TaskProperties,
});
