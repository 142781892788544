import {
  DateAssignedTypeEnum,
  TaskConfig,
  TaskListTaskProperties,
  TaskListTypeEnum,
  TaskTypeEnum,
} from '@btrway/api-tasklist';
import { useHelpResources, useTaskHelpResources } from '@btrway/help-manager';
import { ResourcesPanel } from '@btrway/help-resources-panel';
import { useTaskListConfig } from '@btrway/task-list-config-provider';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import { TaskTypeIcon } from '@btrway/workflow-icons';
import { useTaskTypes } from '@btrway/workflow-manager';
import { Card, Group, Stack, Text, Tooltip } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { CertificationInfo } from '../CertificationInfo/CertificationInfo';
import { NestedTaskList } from '../NestedTaskList/NestedTaskList';
import { TaskCardDateBadges } from '../TaskCardDateBadges/TaskCardDateBadges';
import { TaskCardResourcesButton } from '../TaskCardResourcesButton/TaskCardResourcesButton';
import { TaskContainer } from '../TaskContainer/TaskContainer';

interface TaskCardProps {
  taskList: CommonTaskList;
  taskConfig: TaskConfig;
  index: number;
  isLast: boolean;
}

const TaskCard: React.FC<TaskCardProps> = ({
  taskList,
  taskConfig,
  index,
  isLast,
}) => {
  const taskListKey = taskList.taskListKey || '';
  const { taskKey, taskType } = taskConfig;

  const [isExpanded, setIsExpanded] = useState(false);
  const { openDisplay } = useSettingsDisplay();
  const { getTaskType } = useTaskTypes();
  const { selectedTaskKey, selectTask } = useTaskListConfig();

  const { allHelpResources, isLoadingActive, refetchAllResources } =
    useHelpResources();
  const { getTaskHelpUsages, updateHelpResourceAssociations } =
    useTaskHelpResources(taskListKey);

  const helpResources = getTaskHelpUsages(taskListKey, taskKey, taskType);
  const helpResourceKeys = helpResources
    .map((resource) => resource.helpResourceKey)
    .filter(Boolean);

  const isCertification =
    taskList.taskListType === TaskListTypeEnum.certification;

  const handleResourcesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const handleUpdateResources = async (resourceKeys: string[]) => {
        await updateHelpResourceAssociations(taskKey, taskType, resourceKeys);
      };

      const handleRefetch = async () => {
        await refetchAllResources();
      };

      openDisplay(
        <ResourcesPanel
          workflowKey={taskListKey}
          taskConfig={taskConfig}
          allHelpResources={allHelpResources}
          isLoadingActive={isLoadingActive}
          initialSelectedResources={helpResourceKeys}
          onUpdateResources={handleUpdateResources}
          onRefetchResources={handleRefetch}
        />,
        { mode: 'panel', position: 'right' }
      );
    },
    [
      taskListKey,
      taskConfig,
      taskKey,
      taskType,
      allHelpResources,
      isLoadingActive,
      helpResourceKeys,
      updateHelpResourceAssociations,
      refetchAllResources,
      openDisplay,
    ]
  );

  const handleCardClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      selectTask(taskKey);
    },
    [taskKey]
  );

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const renderNestedContent = () => {
    let nestedTaskListKey: string | undefined;

    if (taskType === TaskTypeEnum.taskContainer) {
      return <TaskContainer taskList={taskList} taskConfig={taskConfig} />;
    }

    if (
      taskType === TaskTypeEnum.completeTaskList ||
      taskType === TaskTypeEnum.completeCurriculum ||
      taskType === TaskTypeEnum.completeFormPacket ||
      taskType === TaskTypeEnum.certification
    ) {
      const properties = taskConfig.taskProperties as TaskListTaskProperties;
      nestedTaskListKey = properties?.taskListSelection?.taskListKey?.value;
    }

    if (!isExpanded || !nestedTaskListKey) return null;

    return (
      <NestedTaskList
        taskListKey={nestedTaskListKey}
        parentTaskListKey={taskListKey}
      />
    );
  };

  const hasNestedContent =
    taskType === TaskTypeEnum.completeTaskList ||
    taskType === TaskTypeEnum.completeCurriculum ||
    taskType === TaskTypeEnum.certification ||
    taskType === TaskTypeEnum.completeFormPacket;

  const isAbwTaskList =
    taskList.taskListMetadata?.taskListClassification === 'ABW To-Do List';
  const isSpecificDays =
    taskList.taskListSettings?.assignedDateType ===
    DateAssignedTypeEnum.taskSpecific;
  const taskTypeData = getTaskType(taskType);

  return (
    <div onClick={handleCardClick} style={{ width: '100%' }}>
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
      >
        <Card
          p="md"
          w="100%"
          radius="md"
          withBorder
          shadow="xs"
          style={{
            marginBottom: 0,
            cursor: 'pointer',
            borderColor:
              taskKey === selectedTaskKey
                ? 'var(--mantine-color-blue-6)'
                : undefined,
            borderWidth: taskKey === selectedTaskKey ? 2 : 1,
          }}
        >
          <Group wrap="nowrap" align="flex-start">
            <div style={{ flexShrink: 0 }}>
              <Tooltip label={taskTypeData?.name ?? taskType}>
                <span>
                  <TaskTypeIcon iconName={taskType} size={24} />
                </span>
              </Tooltip>
            </div>
            <Stack gap="md" style={{ flex: 1, minWidth: 0 }}>
              <Group justify="space-between" wrap="nowrap" align="flex-start">
                <Stack gap="xs" style={{ flex: 1, minWidth: 0 }}>
                  <Group justify="flex-start" align="center" wrap="nowrap">
                    <Text fz="md" fw={600} truncate>
                      {taskConfig.metadata?.title || 'No title'}
                    </Text>
                    {hasNestedContent && (
                      <div
                        onClick={handleExpandClick}
                        style={{ cursor: 'pointer', display: 'flex' }}
                      >
                        {isExpanded ? (
                          <IconChevronDown size={20} />
                        ) : (
                          <IconChevronRight size={20} />
                        )}
                      </div>
                    )}
                  </Group>
                  {taskConfig.metadata?.subtitle && (
                    <Text fz="xs" fw={500} style={{ wordBreak: 'break-word' }}>
                      {taskConfig.metadata?.subtitle}
                    </Text>
                  )}
                </Stack>
                <Group gap="md" wrap="nowrap" style={{ flexShrink: 0 }}>
                  {isCertification && (
                    <CertificationInfo
                      requiredConfig={taskConfig.certificationRequiredConfig}
                      validConfig={taskConfig.certificationValidConfig}
                    />
                  )}
                  {isSpecificDays && (
                    <TaskCardDateBadges
                      startDate={
                        taskConfig.taskListConfig?.startDate?.offsetDays
                      }
                      endDate={taskConfig.taskListConfig?.endDate?.offsetDays}
                    />
                  )}
                  {isAbwTaskList && (
                    <TaskCardResourcesButton
                      resourceCount={helpResources.length}
                      onClick={handleResourcesClick}
                    />
                  )}
                </Group>
              </Group>
              {renderNestedContent()}
            </Stack>
          </Group>
        </Card>
      </motion.div>
    </div>
  );
};

export default TaskCard;
