import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowConfigProvider } from '@btrway/workflow-configuration-manager';
import {
  useWorkflowService,
  WorkflowServiceProvider,
} from '@btrway/workflow-service-provider';
import { StepCreatorProvider } from '@btrway/workflow-step-creator';
import React from 'react';
import { useParams } from 'react-router-dom';
import { WorkflowBuilder } from '../../components/WorkflowBuilder/WorkflowBuilder';
import { WorkflowBuilderLayout } from '../../components/WorkflowBuilderLayout/WorkflowBuilderLayout';

const WorkflowBuilderPageInner: React.FC = () => {
  const { workflowKey } = useParams<{ workflowKey: string }>();
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();

  if (!workflowKey) {
    return <div>No workflow key provided</div>;
  }

  if (isLoading) {
    return <div>Loading workflow...</div>;
  }

  if (!workflow) {
    return <div>No workflow found</div>;
  }

  return (
    <RightSectionProvider>
      <SettingsDisplayProvider>
        <WorkflowConfigProvider initialConfig={workflow.workflowConfiguration}>
          <StepCreatorProvider>
            <WorkflowBuilderLayout workflow={workflow}>
              <WorkflowBuilder workflowKey={workflowKey} />
            </WorkflowBuilderLayout>
          </StepCreatorProvider>
        </WorkflowConfigProvider>
      </SettingsDisplayProvider>
    </RightSectionProvider>
  );
};

const WorkflowBuilderPage: React.FC = () => {
  const { workflowKey } = useParams<{ workflowKey: string }>();

  if (!workflowKey) {
    return <div>No workflow key provided</div>;
  }

  return (
    <WorkflowServiceProvider workflowKey={workflowKey}>
      <WorkflowBuilderPageInner />
    </WorkflowServiceProvider>
  );
};

export default WorkflowBuilderPage;
