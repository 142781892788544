import { TaskListTypeEnum, TaskTypeEnum } from '@btrway/api-tasklist';

export function getTaskListTypeForTask(
  taskType: TaskTypeEnum
): TaskListTypeEnum | undefined {
  switch (taskType) {
    case TaskTypeEnum.completeTaskList:
      return TaskListTypeEnum.todoList;
    case TaskTypeEnum.completeCurriculum:
      return TaskListTypeEnum.curriculum;
    case TaskTypeEnum.completeFormPacket:
      return TaskListTypeEnum.formPacket;
    case TaskTypeEnum.certification:
      return TaskListTypeEnum.certification;
    default:
      return undefined;
  }
}
