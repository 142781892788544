import { UserRoleRequest, UserRoleResponse } from '@btrway/api-security';
import { RoleNode } from '../types/roleNode';

export const responseToNode = (response: UserRoleResponse): RoleNode => {
  const pathParts = response.path.split('.');
  const level = pathParts.length - 1;
  const parentId = level > 0 ? parseInt(pathParts[level - 1]) : null;

  return {
    ...response,
    parentId,
    level,
  };
};

export const nodeToRequest = (node: RoleNode): UserRoleRequest => {
  return {
    id: node.id >= 0 ? node.id : undefined, // Only include id if it's not temporary
    name: node.name,
    slug: node.slug,
    description: node.description,
    canCascade: node.canCascade,
    organizationId: node.organizationId,
    parentUserRoleId: node.parentId !== null ? node.parentId : undefined,
    userRoleGroup: node.userRoleGroup,
  };
};

export const setupInitialParentRelationships = (
  nodes: RoleNode[]
): RoleNode[] => {
  // Group nodes by level for sorting
  const nodesByLevel = new Map<number, RoleNode[]>();

  nodes.forEach((node) => {
    const levelNodes = nodesByLevel.get(node.level) || [];
    levelNodes.push(node);
    nodesByLevel.set(node.level, levelNodes);
  });

  // Process each level, starting from root (level 0)
  const sortedLevels = Array.from(nodesByLevel.keys()).sort((a, b) => a - b);

  // Sort nodes within each level by name
  let normalizedNodes: RoleNode[] = [];
  sortedLevels.forEach((level) => {
    const levelNodes = nodesByLevel.get(level) || [];
    const sortedNodes = levelNodes.sort((a, b) => a.name.localeCompare(b.name));
    normalizedNodes = [...normalizedNodes, ...sortedNodes];
  });

  return normalizedNodes;
};

export const validateNode = (
  node: RoleNode,
  existingNodes: RoleNode[],
  isNewNode: boolean = false
): string[] => {
  const errors: string[] = [];

  // Basic validation
  if (!node.name) {
    errors.push('Role name is required');
  }

  if (!node.slug) {
    errors.push('Role slug is required');
  } else if (!/^[a-z0-9_-]+$/.test(node.slug)) {
    errors.push(
      'Role slug can only contain lowercase letters, numbers, hyphens, and underscores'
    );
  }

  // Check for duplicate slugs in the same organization
  const slugExists = existingNodes.some(
    (existingNode) =>
      existingNode.slug === node.slug &&
      existingNode.id !== node.id &&
      existingNode.organizationId === node.organizationId
  );

  if (slugExists) {
    errors.push(`Role slug '${node.slug}' already exists in this organization`);
  }

  // Parent validation
  if (node.parentId !== null) {
    const parentExists = existingNodes.some(
      (existingNode) => existingNode.id === node.parentId
    );
    if (!parentExists) {
      errors.push('Parent role does not exist');
    }

    const parentNode = existingNodes.find(
      (existingNode) => existingNode.id === node.parentId
    );
    if (parentNode && !parentNode.userRoleGroup) {
      errors.push('Parent role must be a role group');
    }
  }

  // Prevent cyclic relationships
  if (node.parentId !== null) {
    let currentParentId: number | null = node.parentId;
    const visitedIds = new Set<number>();

    while (currentParentId !== null) {
      if (visitedIds.has(currentParentId)) {
        errors.push('Cyclic role relationship detected');
        break;
      }

      visitedIds.add(currentParentId);
      const parentNode = existingNodes.find((n) => n.id === currentParentId);
      currentParentId = parentNode?.parentId ?? null;

      // Check if this would create a cycle through the current node
      if (currentParentId === node.id) {
        errors.push('Cyclic role relationship detected');
        break;
      }
    }
  }

  return errors;
};

export const createNode = (params: {
  name: string;
  slug: string;
  organizationId: number;
  parentId?: number | null;
  description?: string;
  canCascade?: boolean;
  userRoleGroup?: boolean;
}): RoleNode => {
  const {
    name,
    slug,
    organizationId,
    parentId = null,
    description = '',
    canCascade = false,
    userRoleGroup = false,
  } = params;

  // Use a temporary negative ID
  const tempId = -Math.floor(Math.random() * 1000000);
  const level = parentId !== null ? 1 : 0; // Will be properly set when saved

  return {
    id: tempId,
    name,
    slug,
    description,
    systemRole: false, // New roles can't be system roles
    canCascade,
    userRoleGroup,
    organizationId,
    parentId,
    path: parentId !== null ? `${parentId}.${tempId}` : `${tempId}`,
    level,
  };
};
