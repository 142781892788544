import { TaskListTypeEnum } from '@btrway/api-tasklist';
import {
  CommonTaskList,
  TaskListServiceProvider,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { Stack, Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { NewTaskListCard } from '../NewTaskListCard/NewTaskListCard';
import { TaskListCard } from '../TaskListCard/TaskListCard';

// Create the set of creatable task list types
const CREATABLE_TASKLIST_TYPES: Set<TaskListTypeEnum> = new Set([
  TaskListTypeEnum.todoList,
  TaskListTypeEnum.curriculum,
  TaskListTypeEnum.certification,
  TaskListTypeEnum.formPacket,
]);

interface SelectTaskListOptionsProps {
  selectedTaskList?: CommonTaskList;
  taskListType: TaskListTypeEnum;
  onSelect: (taskList: CommonTaskList, isNew?: boolean) => void;
  onClose: () => void;
  allowCreation?: boolean;
}

const SelectTaskListOptionsInner: React.FC<SelectTaskListOptionsProps> = ({
  selectedTaskList,
  taskListType,
  onSelect,
  onClose,
  allowCreation = false,
}) => {
  const { sourceType } = useTemplateDefinition();

  const [searchQuery, setSearchQuery] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const taskListService = useTaskListService();
  const { taskLists, isLoading } =
    taskListService.getTaskListsByType(taskListType);

  const filteredTaskLists = useMemo(() => {
    return taskLists
      ?.filter((taskList) =>
        taskList.name?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [taskLists, searchQuery]);

  const canCreateNew =
    allowCreation && CREATABLE_TASKLIST_TYPES.has(taskListType);

  const handleCreateNewTaskList = (name: string) => {
    const newTaskList: CommonTaskList = {
      name: name.trim(),
      taskListType,
      published: false,
      sourceType,
    };
    onSelect(newTaskList, true);
    setIsCreatingNew(false);
  };

  return (
    <Stack>
      {canCreateNew && (
        <NewTaskListCard
          isEditing={isCreatingNew}
          onStartNew={() => setIsCreatingNew(true)}
          onSave={handleCreateNewTaskList}
          onCancel={() => setIsCreatingNew(false)}
        />
      )}

      <TextInput
        autoFocus
        data-autofocus
        placeholder={`Search ${pluralize(taskListType)}...`}
        leftSection={<IconSearch size={16} />}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.currentTarget.value)}
        mb="sm"
      />

      {isLoading ? (
        <Text>Loading task lists...</Text>
      ) : (
        <div style={{ overflowY: 'auto' }}>
          {filteredTaskLists?.map((taskList) => (
            <TaskListCard
              key={taskList.id || taskList.templateKey}
              taskList={taskList}
              isSelected={selectedTaskList?.id === taskList.id}
              onClick={() => onSelect(taskList, false)}
            />
          ))}
        </div>
      )}
    </Stack>
  );
};

export const SelectTaskListOptions: React.FC<SelectTaskListOptionsProps> = (
  props
) => {
  return (
    <TaskListServiceProvider>
      <SelectTaskListOptionsInner {...props} />
    </TaskListServiceProvider>
  );
};
