// components/StepCreatorDrawer/StepCreatorDrawer.tsx
import { StepConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { Box, Drawer } from '@mantine/core';
import React from 'react';
import StepCreator from '../StepCreator/StepCreator';

export interface StepCreatorDrawerProps {
  opened: boolean;
  onClose: () => void;
  workflowType: WorkflowTypeEnum;
  onStepAdded: (step: StepConfig) => void;
}

export const StepCreatorDrawer: React.FC<StepCreatorDrawerProps> = ({
  opened,
  onClose,
  workflowType,
  onStepAdded,
}) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="left"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginLeft: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <Box style={{ height: '100%' }}>
        <StepCreator
          workflowType={workflowType}
          onComplete={(step) => {
            onStepAdded(step);
            onClose();
          }}
          onCancel={onClose}
        />
      </Box>
    </Drawer>
  );
};

export default StepCreatorDrawer;
