import { WorkgroupResponse } from "@btrway/api-core";
import { ExternalCourseTemplateResponse, ProviderRequest, ProviderResponse } from "@btrway/api-external-provider";
import { useAuthenticatedUser } from "@btrway/current-user";
import { ScrollableDrawer, useScrollableDrawer } from "@btrway/scrollable-drawer";
import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { ExternalCourseProviderAdminView } from "../ExternalCourseProviderAdminView/ExternalCourseProviderAdminView";
import { ExternalCourseProviderEdit } from "../ExternalCourseProviderEdit/ExternalCourseProviderEdit";

interface ExternalCourseProviderDrawerProps {
    opened: boolean;
    onClose: () => void;
    providers: ProviderResponse[];
    onExternalCourseChange: () => void;
}

export function ExternalCourseProviderDrawer({
    opened,
    onClose,
    providers,
    onExternalCourseChange
}: ExternalCourseProviderDrawerProps) {
    const [editingProvider, setEditingProvider] = useState<ProviderRequest | null>(null);

    const { currentOrganization } = useAuthenticatedUser();
    const {setDrawerHeader} = useScrollableDrawer();

    const handleAddProvider = useCallback(() => {
        const newProvider = {
            name: '',
        };
        setEditingProvider(newProvider);
    }, []);

    useEffect(() => {
        if (!editingProvider) {
            setDrawerHeader(
                <Button
                    leftSection={<IconPlus size={16} />}
                    variant="light"
                    onClick={handleAddProvider}
                >
                    Add Provider
                </Button>
            );
        } else {
            setDrawerHeader(null);
        }

        return () => {
            setDrawerHeader(null);
        };
    }, [editingProvider, handleAddProvider, setDrawerHeader]);

    const handleEditProvider = useCallback((provider: ProviderRequest) => {
        setEditingProvider(provider);
    }, []);

    const handleSaveProvider = useCallback(() => {
        onExternalCourseChange();
        setEditingProvider(null);
    }, [onExternalCourseChange]);

    const handleCancelEdit = useCallback(() => {
        setEditingProvider(null);
    }, []);

    const handleClose = () => {
        setEditingProvider(null);
        onClose();
    }

     const renderContent = () => {
        if (editingProvider) {
          return (
            <ExternalCourseProviderEdit
              provider={editingProvider}
              isNew={!editingProvider.id}
              onSave={handleSaveProvider}
              onCancel={handleCancelEdit}
            />
          );
        }
    
        return (
          <ExternalCourseProviderAdminView
            providers={providers}
            onEditProvider={handleEditProvider}
            onAddProvider={handleAddProvider}
          />
        );
      };

    return (
        <ScrollableDrawer
            opened={opened}
            onClose={handleClose}
            title={
                editingProvider
                    ? editingProvider.id
                        ? 'Edit Provider'
                        : 'Add Provider'
                    : 'Manage Providers'
            }
        >
            {renderContent()}
        </ScrollableDrawer>
    )
}