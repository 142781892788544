import { AssignTaskInputProperties, StepConfig } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { useUserRoles } from '@btrway/role-manager';
import { Badge, Text } from '@mantine/core';
import React from 'react';

interface StepConfigAssignmentViewProps {
  step: StepConfig;
  workflowKey: string;
}

export const StepConfigAssignmentView: React.FC<
  StepConfigAssignmentViewProps
> = ({ step, workflowKey }) => {
  const inputProps = step.inputProperties as AssignTaskInputProperties;
  const taskAssignment = inputProps?.taskAssignmentConfig;
  const { getUserRoleBySlug } = useUserRoles();

  if (!taskAssignment)
    return (
      <Text component="span" c="blue.8" fw={600} inherit>
        Unassigned
      </Text>
    );

  switch (taskAssignment.assignmentType) {
    case 'userRole': {
      const roleSlug = taskAssignment.userRoleSlug?.value;
      const role = roleSlug ? getUserRoleBySlug(roleSlug) : undefined;
      return (
        <Text component="span" c="blue.8" fw={600} inherit>
          {role ? role.name : 'Unknown Role'}
        </Text>
      );
    }
    case 'person':
      if (taskAssignment.personId?.value) {
        return (
          <EntityTag
            dataType="person"
            id={taskAssignment.personId.value}
            c="blue.8"
            fw={600}
            inherit
            allowClick={false}
          />
        );
      }
      return (
        <Text component="span" c="blue.8" fw={600} inherit>
          {taskAssignment.personId?.reference?.label || 'Unknown Person'}
        </Text>
      );
    case 'primaryWorkflowPerson':
      return (
        <Badge
          size="lg"
          color="blue"
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        >
          {/* {workflow?.entityName || 'Primary Entity'} */}
          Primary Entity TODO
        </Badge>
      );
    default:
      return (
        <Text component="span" c="blue.8" fw={600} inherit>
          Unassigned
        </Text>
      );
  }
};
