import { FormSettings, WorkflowTypeEnum } from '@btrway/api-workflow';
import { FormSettingsEditor } from '@btrway/form-settings';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-service-provider';
import { Box, Button, Group, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { WorkflowDescriptionEditor } from '../WorkflowDescriptionEditor/WorkflowDescriptionEditor';

interface WorkflowSettingsPanelProps {
  onSave?: () => void;
  onCancel?: () => void;
}

export const WorkflowSettingsPanel: React.FC<WorkflowSettingsPanelProps> = ({
  onSave,
  onCancel,
}) => {
  const { settingsActions } = useWorkflowConfig();
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();
  const [activeTab, setActiveTab] = useState<string | null>('description');
  const [localWorkflow, setLocalWorkflow] = useState<CommonWorkflow | null>(
    null
  );

  useEffect(() => {
    if (workflow) {
      setLocalWorkflow(workflow);
    }
  }, [workflow]);

  const handleWorkflowChange = (updatedWorkflow: CommonWorkflow) => {
    setLocalWorkflow(updatedWorkflow);
  };

  const handleFormSettingsChange = (formSettings: FormSettings) => {
    if (!localWorkflow) return;

    setLocalWorkflow((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        workflowConfiguration: {
          ...prev.workflowConfiguration,
          settings: formSettings,
        },
      };
    });
  };

  const handleSave = async () => {
    if (!localWorkflow) return;

    try {
      // Update workflow
      await workflowService.updateWorkflow(localWorkflow);

      // Update form settings if applicable
      if (
        localWorkflow.workflowConfiguration &&
        localWorkflow.workflowType === WorkflowTypeEnum.form
      ) {
        await settingsActions.updateFormSettings(
          localWorkflow.workflowConfiguration.settings as FormSettings
        );
      }

      onSave?.();
    } catch (error) {
      console.error('Error saving workflow settings:', error);
      // Handle error appropriately
    }
  };

  const handleCancel = () => {
    if (workflow) {
      setLocalWorkflow(workflow);
    }
    onCancel?.();
  };

  if (!localWorkflow) return null;

  const showFormSettings = localWorkflow.workflowType === WorkflowTypeEnum.form;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Tabs value={activeTab} onChange={setActiveTab} style={{ flex: 1 }}>
        <Tabs.List>
          <Tabs.Tab value="description">Description</Tabs.Tab>
          {showFormSettings && (
            <Tabs.Tab value="formSettings">Form Settings</Tabs.Tab>
          )}
        </Tabs.List>

        <Box p="md" style={{ flex: 1, overflow: 'auto' }}>
          <Tabs.Panel value="description">
            <WorkflowDescriptionEditor
              workflow={localWorkflow}
              onChange={handleWorkflowChange}
            />
          </Tabs.Panel>

          {showFormSettings && (
            <Tabs.Panel value="formSettings">
              <FormSettingsEditor
                initialFormSettings={
                  localWorkflow.workflowConfiguration?.settings as FormSettings
                }
                onChange={handleFormSettingsChange}
              />
            </Tabs.Panel>
          )}
        </Box>
      </Tabs>

      <Box
        p="md"
        style={{ borderTop: '1px solid var(--mantine-color-gray-3)' }}
      >
        <Group justify="flex-end" gap="md">
          <Button variant="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Box>
    </Box>
  );
};
