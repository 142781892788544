import { AssetResponse } from '@btrway/api-courseware';
import { TaskTypeEnum } from '@btrway/api-task';
import { TaskConfig } from '@btrway/api-tasklist';
import { CommonExternalCourse } from '@btrway/external-course-service-provider';
import {
  useConfigFactory,
  useTaskListConfig,
} from '@btrway/task-list-config-provider';
import {
  CommonTaskList,
  useTaskListService,
} from '@btrway/task-list-service-provider';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { uuid } from '@btrway/utils';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Box } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useTaskCreatorStepper } from '../../hooks/useTaskCreatorStepper';
import {
  isDirectConfigType,
  isMultiStepType,
  isTaskListSelectorType,
  isWorkflowSelectorType,
  taskListTaskTypes,
} from '../../utils/taskCreatorUtils';
import { TaskCreatorFooter } from '../TaskCreatorFooter/TaskCreatorFooter';
import { TaskCreatorStepContent } from '../TaskCreatorStepContent/TaskCreatorStepContent';
import { TaskCreatorStepper } from '../TaskCreatorStepper/TaskCreatorStepper';

interface TaskCreatorProps {
  taskList: CommonTaskList;
  taskContainerKey?: string;
  onComplete: (taskConfig: TaskConfig) => void;
  onCancel: () => void;
}

export const TaskCreator: React.FC<TaskCreatorProps> = ({
  taskList,
  taskContainerKey,
  onComplete,
  onCancel,
}) => {
  const {
    activeStep,
    selectedTaskType,
    setActiveStep,
    setSelectedTaskType,
    getStepTitle,
    handleBack,
  } = useTaskCreatorStepper();

  const [currentDirectConfig, setCurrentDirectConfig] =
    useState<TaskConfig | null>(null);
  const [newTaskList, setNewTaskList] = useState<CommonTaskList | null>(null);
  const [selectedCourses, setSelectedCourses] = useState<TaskConfig[]>([]);
  const [selectedForms, setSelectedForms] = useState<TaskConfig[]>([]);
  const { taskActions } = useTaskListConfig();
  const configFactory = useConfigFactory();
  const taskListService = useTaskListService();
  const { sourceType } = useTemplateDefinition();

  const handleToolboxSelect = useCallback(
    (item: any) => {
      if (item.type === 'taskType') {
        const taskType = item.data.code as TaskTypeEnum;

        // Special handling for taskContainer type
        if (taskType === TaskTypeEnum.taskContainer) {
          const config = configFactory.createTaskContainerConfig();
          taskActions
            .addTask(config, taskContainerKey)
            .then((newConfig) => {
              onComplete(newConfig);
            })
            .catch((error) => {
              console.error('Failed to add task container:', error);
            });
          return;
        }

        // Normal flow for other task types
        setSelectedTaskType(taskType);
        setActiveStep(1);
      }
    },
    [configFactory, taskActions, taskContainerKey, onComplete]
  );

  const handleWorkflowSelect = useCallback(
    async (workflow: CommonWorkflow) => {
      if (!selectedTaskType || !isWorkflowSelectorType(selectedTaskType))
        return;

      const config = configFactory.createWorkflowTaskConfig(
        workflow.workflowKey || '',
        workflow.name || '',
        selectedTaskType
      );
      const newConfig = await taskActions.addTask(config, taskContainerKey);
      onComplete(newConfig);
    },
    [selectedTaskType, taskActions, taskContainerKey, onComplete, configFactory]
  );

  const handleTaskListSelect = useCallback(
    async (taskList: CommonTaskList, isNew?: boolean) => {
      if (!selectedTaskType || !isTaskListSelectorType(selectedTaskType))
        return;

      if (!isNew) {
        const config = configFactory.createTaskListTaskConfig(
          taskList.taskListKey || '',
          taskList.name || '',
          selectedTaskType
        );
        const newConfig = await taskActions.addTask(config, taskContainerKey);
        onComplete(newConfig);
      } else {
        const newTaskListKey = uuid();
        const newTaskListObj: CommonTaskList = {
          sourceType,
          taskListType: taskListTaskTypes[selectedTaskType].taskListType,
          published: false,
          name: taskList.name?.trim() || 'New Task List',
          taskListKey: newTaskListKey,
          templateKey: sourceType === 'template' ? newTaskListKey : undefined,
        };

        if (isMultiStepType(selectedTaskType)) {
          setNewTaskList(newTaskListObj);
          setActiveStep(2);
        } else {
          try {
            await taskListService.updateTaskList(newTaskListObj);

            const taskListKey =
              sourceType === 'definition'
                ? newTaskListObj.taskListKey!
                : newTaskListObj.templateKey!;
            const config = configFactory.createTaskListTaskConfig(
              taskListKey,
              newTaskListObj.name || '',
              selectedTaskType
            );

            const newConfig = await taskActions.addTask(
              config,
              taskContainerKey
            );
            onComplete(newConfig);
          } catch (error) {
            console.error('Failed to save task list:', error);
          }
        }
      }
    },
    [
      selectedTaskType,
      taskActions,
      taskContainerKey,
      onComplete,
      configFactory,
      sourceType,
      taskListService,
    ]
  );

  const handleCurriculumSave = async () => {
    if (!newTaskList || !selectedTaskType) return;

    try {
      const taskListWithCourses: CommonTaskList = {
        ...newTaskList,
        taskListConfiguration: {
          tasks: selectedCourses,
        },
      };

      await taskListService.updateTaskList(taskListWithCourses);

      const taskListKey =
        sourceType === 'definition'
          ? newTaskList.taskListKey!
          : newTaskList.templateKey!;
      const config = configFactory.createTaskListTaskConfig(
        taskListKey,
        newTaskList.name || '',
        selectedTaskType
      );

      const newConfig = await taskActions.addTask(config, taskContainerKey);
      onComplete(newConfig);
    } catch (error) {
      console.error('Failed to save task list:', error);
    }
  };

  const handleFormPacketSave = async () => {
    if (!newTaskList || !selectedTaskType) return;

    try {
      const taskListWithForms: CommonTaskList = {
        ...newTaskList,
        taskListConfiguration: {
          tasks: selectedForms,
        },
      };

      await taskListService.updateTaskList(taskListWithForms);

      const taskListKey =
        sourceType === 'definition'
          ? newTaskList.taskListKey!
          : newTaskList.templateKey!;
      const config = configFactory.createTaskListTaskConfig(
        taskListKey,
        newTaskList.name || '',
        selectedTaskType
      );

      const newConfig = await taskActions.addTask(config, taskContainerKey);
      onComplete(newConfig);
    } catch (error) {
      console.error('Failed to save task list:', error);
    }
  };

  const handleCourseSelect = useCallback(
    async (course: AssetResponse) => {
      const { uid, title } = course;
      const config = configFactory.createCourseTaskConfig(
        uid.toString(),
        title
      );
      const newConfig = await taskActions.addTask(config, taskContainerKey);
      onComplete(newConfig);
    },
    [taskActions, taskContainerKey, onComplete, configFactory]
  );

  const handleExternalCourseSelect = useCallback(
    async (externalCourse: CommonExternalCourse) => {
      const config = configFactory.createExternalCourseTaskConfig(
        externalCourse.courseKey,
        externalCourse.courseConfiguration?.title || ''
      );
      const newConfig = await taskActions.addTask(config, taskContainerKey);
      onComplete(newConfig);
    },
    [taskActions, taskContainerKey, onComplete, configFactory]
  );

  const handleDirectConfigChange = useCallback((updatedConfig: TaskConfig) => {
    setCurrentDirectConfig(updatedConfig);
  }, []);

  const handleTaskSave = async () => {
    if (!currentDirectConfig) return;
    const savedConfig = await taskActions.addTask(
      currentDirectConfig,
      taskContainerKey
    );
    onComplete(savedConfig);
  };

  const handleSave = async () => {
    if (activeStep === 2) {
      if (selectedTaskType === 'completeCurriculum') {
        await handleCurriculumSave();
      } else if (selectedTaskType === 'completeFormPacket') {
        await handleFormPacketSave();
      }
    } else if (
      activeStep === 1 &&
      selectedTaskType &&
      isDirectConfigType(selectedTaskType)
    ) {
      await handleTaskSave();
    }
  };

  const canSave = () => {
    if (
      activeStep === 1 &&
      selectedTaskType &&
      isDirectConfigType(selectedTaskType)
    ) {
      return !!currentDirectConfig;
    }
    if (activeStep === 2) {
      if (selectedTaskType === 'completeCurriculum') {
        return selectedCourses.length > 0;
      }
      if (selectedTaskType === 'completeFormPacket') {
        return selectedForms.length > 0;
      }
    }
    return false;
  };

  const showSaveButton =
    activeStep === 2 ||
    (activeStep === 1 &&
      selectedTaskType &&
      isDirectConfigType(selectedTaskType));

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        style={{
          flex: '1 1 auto',
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <TaskCreatorStepper
          activeStep={activeStep}
          stepTitle={getStepTitle()}
          onStepClick={setActiveStep}
          onBack={handleBack}
        >
          <TaskCreatorStepContent
            activeStep={activeStep}
            selectedTaskType={selectedTaskType}
            taskList={taskList}
            newTaskList={newTaskList}
            selectedCourses={selectedCourses}
            selectedForms={selectedForms}
            currentDirectConfig={currentDirectConfig}
            onToolboxSelect={handleToolboxSelect}
            onWorkflowSelect={handleWorkflowSelect}
            onTaskListSelect={handleTaskListSelect}
            onCourseSelect={handleCourseSelect}
            onExternalCourseSelect={handleExternalCourseSelect}
            onDirectConfigChange={handleDirectConfigChange}
            setSelectedCourses={setSelectedCourses}
            setSelectedForms={setSelectedForms}
            onCancel={onCancel}
            configFactory={configFactory}
          />
        </TaskCreatorStepper>
      </Box>

      <Box
        style={{
          flexShrink: 0,
        }}
      >
        <TaskCreatorFooter
          showSaveButton={showSaveButton || false}
          canSave={canSave()}
          onSave={handleSave}
          onCancel={onCancel}
        />
      </Box>
    </Box>
  );
};

export default TaskCreator;
