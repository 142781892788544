import { useAuthenticatedUser } from '@btrway/current-user';
import { LoadingSpinner } from '@btrway/shared-components';
import { ThemeEffectWrapper } from '@btrway/theme-scheme-provider';
import React, { Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useRouteGeneration } from '../../hooks/useRouteGeneration';
import { TitleUpdater } from '../../hooks/useTitleUpdate';
import { RouteContextProvider } from '../../providers/RouteProvider';
import { useRoutesContext } from '../../providers/RoutesProvider';
import { ProcessedRouteConfig } from '../../types/route';

export const RouteGenerator: React.FC = () => {
  const { routes } = useRoutesContext();
  const { allPermissions, currentPerson, currentOrganization } =
    useAuthenticatedUser();

  const { firstAccessibleRoute, processRouteConfigs } = useRouteGeneration(
    routes,
    allPermissions,
    currentPerson.id,
    currentOrganization.id
  );

  // Memoize the processed routes
  const processedRoutes = useMemo(
    () => processRouteConfigs(),
    [processRouteConfigs]
  );

  // Memoize the route rendering function
  const renderRoutes = useMemo(() => {
    function _renderRoutes(routes: ProcessedRouteConfig[]): React.ReactNode[] {
      return routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <RouteContextProvider
                childRoutes={route.childRoutes || []}
                currentRoute={route}
              >
                <ThemeEffectWrapper forceDarkMode={route.forceDarkMode}>
                  {route.element}
                </ThemeEffectWrapper>
              </RouteContextProvider>
            </Suspense>
          }
        >
          {route.firstAccessibleChild && (
            <Route
              index
              element={<Navigate to={route.firstAccessibleChild} replace />}
            />
          )}
          {route.childRoutes && _renderRoutes(route.childRoutes)}
        </Route>
      ));
    }
    return _renderRoutes;
  }, []);

  return (
    <>
      <TitleUpdater />

      <Routes>
        {firstAccessibleRoute && (
          <Route
            index
            element={<Navigate to={firstAccessibleRoute} replace />}
          />
        )}
        {renderRoutes(processedRoutes)}
      </Routes>
    </>
  );
};
