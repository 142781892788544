import { useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useRoutesContext } from '../providers/RoutesProvider';
import { RouteConfig } from '../types/route';

export const useTitleUpdate = () => {
  const location = useLocation();
  const { routes } = useRoutesContext();
  const lastStableTitle = useRef<string>('Better Way');
  const timeoutRef = useRef<number>();

  // Function to strip /app prefix from pathname
  const normalizePathname = (pathname: string) => {
    return pathname.replace(/^\/app/, '');
  };

  const findMatchingRoutes = (
    routesToSearch: RouteConfig[],
    pathname: string,
    parentPath: string = ''
  ): RouteConfig[] => {
    const matches: RouteConfig[] = [];

    for (const route of routesToSearch) {
      // Construct full path considering parent path
      const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, '/');

      // Check if this route matches
      const match = matchPath(fullPath, normalizePathname(pathname));
      if (match) {
        matches.push(route);
      }

      // If this route has children, recursively search them
      if (route.children) {
        const childMatches = findMatchingRoutes(
          route.children,
          pathname,
          fullPath
        );
        matches.push(...childMatches);
      }
    }

    return matches;
  };

  const getRouteTitle = (route: RouteConfig): string => {
    if (!route.label) return 'Better Way';
    return typeof route.label === 'string' ? route.label : 'Better Way';
  };

  useEffect(() => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    // Find all matching routes for the current path
    const matchingRoutes = findMatchingRoutes(routes, location.pathname);

    // Sort routes by path length to get most specific match last
    matchingRoutes.sort((a, b) => {
      const aPath = a.path || '';
      const bPath = b.path || '';
      return aPath.length - bPath.length;
    });

    // If we have matches, use the most specific (last) match for the title
    if (matchingRoutes.length > 0) {
      const mostSpecificRoute = matchingRoutes[matchingRoutes.length - 1];
      const title = getRouteTitle(mostSpecificRoute);

      // If this is a parent route with children, it might redirect
      // Wait a brief moment to see if we're going to redirect
      if (mostSpecificRoute.children?.length) {
        document.title = `${lastStableTitle.current} - Better Way`;

        timeoutRef.current = window.setTimeout(() => {
          // After the delay, update if we haven't redirected
          if (location.pathname === window.location.pathname) {
            document.title = `${title} - Better Way`;
            lastStableTitle.current = title;
          }
        }, 100); // Small delay to handle redirects
      } else {
        // No children, safe to update immediately
        document.title = `${title} - Better Way`;
        lastStableTitle.current = title;
      }
    } else {
      // No matching route, use default
      document.title = 'Better Way';
      lastStableTitle.current = 'Better Way';
    }

    // Cleanup timeout on unmount or route change
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [location.pathname, routes]);
};

// Simple component to use in RouteGenerator
export const TitleUpdater: React.FC = () => {
  useTitleUpdate();
  return null;
};
