import {
  EventConfig,
  StepConfig,
  TransitionConfig,
  WorkflowConfig,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowService } from '../providers/WorkflowServiceProvider';
import { WorkflowService, WorkflowServiceConfig } from '../types/service';

// Pure functions for workflow configuration updates
const addStepToConfig = (
  config: WorkflowConfig | null,
  newStep: StepConfig
): { steps: StepConfig[] } & Partial<WorkflowConfig> => {
  const stepWithKey = { ...newStep, stepKey: newStep.stepKey || uuid() };
  return {
    ...config,
    steps: [...(config?.steps || []), stepWithKey],
  };
};

const removeStepFromConfig = (
  config: WorkflowConfig | null,
  stepKeyToRemove: string
): {
  steps: StepConfig[];
  transitions: TransitionConfig[];
} & Partial<WorkflowConfig> => {
  if (!config?.steps) return { steps: [], transitions: [] };

  const updatedSteps = config.steps.filter(
    (step) => step.stepKey !== stepKeyToRemove
  );

  const updatedTransitions = updatedSteps.slice(0, -1).map((step, index) => ({
    transitionKey: uuid(),
    sourceStepKey: step.stepKey,
    targetStepKey: updatedSteps[index + 1].stepKey,
    index,
  }));

  return {
    ...config,
    steps: updatedSteps,
    transitions: updatedTransitions,
  };
};

const appendStepToConfig = (
  config: WorkflowConfig | null,
  newStep: StepConfig
): { config: WorkflowConfig; transitions: TransitionConfig[] } => {
  const updatedStep = { ...newStep, stepKey: newStep.stepKey || uuid() };
  const lastStep = config?.steps?.[config.steps?.length - 1];

  const newTransition = lastStep
    ? [
        {
          transitionKey: uuid(),
          sourceStepKey: lastStep.stepKey,
          targetStepKey: updatedStep.stepKey,
          index: 0,
        },
      ]
    : [];

  return {
    config: {
      ...config,
      steps: [...(config?.steps || []), updatedStep],
      transitions: [...(config?.transitions || []), ...newTransition],
    },
    transitions: newTransition,
  };
};

const insertStepInConfig = (
  config: WorkflowConfig | null,
  newStep: StepConfig,
  newIndex: number
): { config: WorkflowConfig; transitions: TransitionConfig[] } => {
  const updatedStep = { ...newStep, stepKey: newStep.stepKey || uuid() };

  if (!config) {
    return {
      config: { steps: [updatedStep], transitions: [] },
      transitions: [],
    };
  }

  let updatedSteps = [...(config.steps || [])];
  updatedSteps.splice(newIndex, 0, updatedStep);

  const updatedTransitions = updatedSteps.slice(0, -1).map((step, index) => ({
    transitionKey: uuid(),
    sourceStepKey: step.stepKey,
    targetStepKey: updatedSteps[index + 1].stepKey,
    index,
  }));

  return {
    config: {
      ...config,
      steps: updatedSteps,
      transitions: updatedTransitions,
    },
    transitions: updatedTransitions,
  };
};

const reorderStepInConfig = (
  config: WorkflowConfig | null,
  stepKey: string,
  newIndex: number
): {
  config: WorkflowConfig | null;
  steps: StepConfig[];
  transitions: TransitionConfig[];
} => {
  if (!config?.steps) {
    return { config, steps: [], transitions: [] };
  }

  const steps = [...config.steps];
  const oldIndex = steps.findIndex((step) => step.stepKey === stepKey);
  if (oldIndex === -1 || oldIndex === newIndex) {
    return { config, steps: [], transitions: [] };
  }

  const [movedStep] = steps.splice(oldIndex, 1);
  steps.splice(newIndex, 0, movedStep);

  const updatedTransitions = steps.slice(0, -1).map((step, index) => ({
    transitionKey: uuid(),
    sourceStepKey: step.stepKey,
    targetStepKey: steps[index + 1].stepKey,
    index,
  }));

  return {
    config: {
      ...config,
      steps,
      transitions: updatedTransitions,
    },
    steps,
    transitions: updatedTransitions,
  };
};

const addEventToConfig = (
  config: WorkflowConfig | null,
  newEvent: EventConfig
): { events: EventConfig[] } & Partial<WorkflowConfig> => {
  const eventWithKey = {
    ...newEvent,
    eventKey: newEvent.eventKey || uuid(),
  };

  return {
    ...config,
    events: [...(config?.events || []), eventWithKey],
  };
};

const removeEventFromConfig = (
  config: WorkflowConfig | null,
  eventKey: string
): { events: EventConfig[] } & Partial<WorkflowConfig> => {
  if (!config?.events) return { events: [] };

  return {
    ...config,
    events: config.events.filter((event) => event.eventKey !== eventKey),
  };
};

export function useWorkflowUpdater(providedService?: WorkflowService) {
  const contextService = useWorkflowService();
  const workflowService = providedService || contextService;
  const { data: workflow, refetch } = workflowService.getByKey();

  const getEffectiveWorkflowKey = (overrideKey?: string) => {
    if (overrideKey) return overrideKey;
    if (!workflowService.workflowKey) {
      throw new Error('No workflowKey available');
    }
    return workflowService.workflowKey;
  };

  const updateConfig = async (
    config: WorkflowServiceConfig,
    workflowKey?: string
  ) => {
    const effectiveKey = getEffectiveWorkflowKey(workflowKey);
    await workflowService.updateConfig(effectiveKey, config);
    const result = workflowService.getByKey(effectiveKey);
    await result.refetch();
  };

  const addStep = async (newStep: StepConfig, workflowKey?: string) => {
    const updatedConfig = addStepToConfig(
      workflow?.workflowConfiguration || null,
      newStep
    );
    const newStepAdded = updatedConfig.steps[updatedConfig.steps.length - 1];
    if (newStepAdded) {
      await updateConfig(
        {
          workflowConfig: { steps: [newStepAdded] },
        },
        workflowKey
      );
    }
  };

  const removeStep = async (stepKeyToRemove: string, workflowKey?: string) => {
    const updatedConfig = removeStepFromConfig(
      workflow?.workflowConfiguration || null,
      stepKeyToRemove
    );
    await updateConfig(
      {
        deleteConfigs: [stepKeyToRemove],
      },
      workflowKey
    );
  };

  const updateSteps = async (
    updatedSteps: StepConfig[],
    workflowKey?: string
  ) => {
    await updateConfig(
      {
        workflowConfig: { steps: updatedSteps },
      },
      workflowKey
    );
  };

  const appendStep = async (newStep: StepConfig, workflowKey?: string) => {
    const { config, transitions } = appendStepToConfig(
      workflow?.workflowConfiguration || null,
      newStep
    );
    const newStepAdded = config.steps?.[config.steps.length - 1];
    if (newStepAdded) {
      await updateConfig(
        {
          workflowConfig: {
            steps: [newStepAdded],
            transitions,
          },
        },
        workflowKey
      );
    }
  };

  const insertStep = async (
    newStep: StepConfig,
    newIndex: number,
    workflowKey?: string
  ) => {
    const { config, transitions } = insertStepInConfig(
      workflow?.workflowConfiguration || null,
      newStep,
      newIndex
    );
    const insertedStep = config.steps?.[newIndex];
    if (insertedStep) {
      await updateConfig(
        {
          workflowConfig: {
            steps: [insertedStep],
            transitions,
          },
        },
        workflowKey
      );
    }
  };

  const reorderStep = async (
    stepKey: string,
    newIndex: number,
    workflowKey?: string
  ) => {
    const { steps, transitions } = reorderStepInConfig(
      workflow?.workflowConfiguration || null,
      stepKey,
      newIndex
    );

    if (steps.length > 0 && transitions.length > 0) {
      await updateConfig(
        {
          workflowConfig: {
            steps,
            transitions,
          },
        },
        workflowKey
      );
    }
  };

  const addEvent = async (newEvent: EventConfig, workflowKey?: string) => {
    const updatedConfig = addEventToConfig(
      workflow?.workflowConfiguration || null,
      newEvent
    );
    const newEventAdded = updatedConfig.events[updatedConfig.events.length - 1];
    if (newEventAdded) {
      await updateConfig(
        {
          workflowConfig: {
            events: [newEventAdded],
          },
        },
        workflowKey
      );
    }
  };

  const removeEvent = async (eventKey: string, workflowKey?: string) => {
    removeEventFromConfig(workflow?.workflowConfiguration || null, eventKey);
    await updateConfig(
      {
        deleteConfigs: [eventKey],
      },
      workflowKey
    );
  };

  const removeAllTasks = async (taskKeys: string[], workflowKey?: string) => {
    if (taskKeys.length === 0) return;

    await updateConfig(
      {
        deleteConfigs: taskKeys,
      },
      workflowKey
    );
  };

  return {
    updateConfig,
    addStep,
    removeStep,
    updateSteps,
    appendStep,
    insertStep,
    reorderStep,
    addEvent,
    removeEvent,
    removeAllTasks,
  };
}
