import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/role-manager';
import Fuse from 'fuse.js';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { importConfigurationAtom } from '../atoms/importConfigurationAtom';
import { baseFields } from '../constants';
import { ColumnMapping, ImportConfiguration, ValueMapping } from '../types';
import { FileData } from '../types/FileData';
import { useFuzzyWorkgroupMapping } from './useFuzzyWorkgroupMapping';

export const useAutoMatching = () => {
  const [importConfiguration, setImportConfiguration] = useAtom(
    importConfigurationAtom
  );

  const { currentOrganization } = useAuthenticatedUser();
  const { userRoles } = useUserRoles();

  const { executeWorkgroupMatching } = useFuzzyWorkgroupMapping(
    currentOrganization.id
  );

  const targetOptions = useMemo(
    () =>
      userRoles?.map((role) => ({
        id: role.id.toString(),
        name: role.name,
      })) || [],
    [userRoles]
  );

  const fuse = useMemo(() => {
    return new Fuse(targetOptions, {
      keys: ['name'],
      threshold: 0.3,
      includeScore: true,
    });
  }, [targetOptions]);

  const findBestMatch = useCallback(
    (sourceValue: string) => {
      const results = fuse.search(sourceValue);
      if (results.length === 0) return null;

      const sortedResults = results.sort((a, b) => {
        if (a.item.name.toLowerCase() === sourceValue.toLowerCase()) return -1;
        if (b.item.name.toLowerCase() === sourceValue.toLowerCase()) return 1;

        const aIsPrefix = sourceValue
          .toLowerCase()
          .startsWith(a.item.name.toLowerCase());
        const bIsPrefix = sourceValue
          .toLowerCase()
          .startsWith(b.item.name.toLowerCase());
        if (aIsPrefix && !bIsPrefix) return -1;
        if (bIsPrefix && !aIsPrefix) return 1;

        return (a.score || 1) - (b.score || 1);
      });

      return sortedResults[0];
    },
    [fuse]
  );

  const autoMatchColumns = useCallback((fileData: FileData) => {
    if (fileData && fileData.headers) {
      const newColumnMappings: ColumnMapping[] = [];
      baseFields.forEach((field) => {
        const match = fileData.headers.find(
          (header) =>
            header &&
            [field.label, ...(field.alternateMatches || [])].some((match) =>
              header.toLowerCase().includes(match.toLowerCase())
            )
        );
        if (match) {
          newColumnMappings.push({
            columnIndex: fileData.headers.indexOf(match),
            columnHeader: match,
            targetField: field.key,
            valueMappings: [],
          });
        }
      });
      return newColumnMappings;
    }
    return [];
  }, []);

  const autoMatchValues = useCallback(
    (fileData: FileData, columnMappings: ColumnMapping[]) => {
      const updatedColumnMappings = columnMappings.map((columnMapping) => {
        const field = baseFields.find(
          (f) => f.key === columnMapping.targetField
        );
        if (
          field &&
          (field.fieldType.type === 'select' ||
            field.fieldType.type === 'custom')
        ) {
          const distinctValues = Array.from(
            new Set(fileData.rows.map((row) => row[columnMapping.columnIndex]))
          ).filter(Boolean);

          let valueMappings: ValueMapping[];

          if (columnMapping.targetField === 'rootWorkgroup') {
            valueMappings = executeWorkgroupMatching({
              ...columnMapping,
              valueMappings: distinctValues.map((value) => ({
                sourceValue: value,
                targetValue: null,
              })),
            });
          } else if (columnMapping.targetField === 'userRole') {
            valueMappings = distinctValues.map((sourceValue) => {
              const match = findBestMatch(sourceValue);
              return {
                sourceValue,
                targetValue: match ? match.item.id : null,
                autoMatched: !!match,
                autoMatchedValue: match ? 1 - (match.score || 0) : undefined,
              };
            });
          } else {
            valueMappings = distinctValues.map((sourceValue) => ({
              sourceValue,
              targetValue: sourceValue,
            }));
          }

          return { ...columnMapping, valueMappings };
        }
        return columnMapping;
      });

      return updatedColumnMappings;
    },
    [findBestMatch, executeWorkgroupMatching]
  );

  const autoMatchFileData = useCallback(
    (fileData: FileData) => {
      const newColumnMappings = autoMatchColumns(fileData);
      const updatedColumnMappings = autoMatchValues(
        fileData,
        newColumnMappings
      );

      setImportConfiguration((prev: ImportConfiguration) => ({
        ...prev,
        sheet: fileData.selectedSheet,
        columnMappings: updatedColumnMappings,
      }));
    },
    [autoMatchColumns, autoMatchValues, setImportConfiguration]
  );

  return {
    autoMatchFileData,
    importConfiguration,
  };
};
