import { useRouteContext } from '@btrway/web-routing';
import { Group, MantineColor, Stack, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';
import classes from './HeaderLayout.module.css';

interface HeaderLayoutProps {
  children: ReactNode;
  header?: ReactNode | string;
  rightComponent?: ReactNode;
  subtitleComponent?: ReactNode;
  headerBg?: MantineColor;
  showIcon?: boolean;
}

export const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  children,
  header,
  rightComponent,
  subtitleComponent,
  headerBg,
  showIcon = false,
}) => {
  const { currentRoute } = useRouteContext();

  const renderHeader = () => {
    if (React.isValidElement(header)) {
      return header;
    }

    if (typeof header === 'string') {
      return <Title order={3}>{header}</Title>;
    }

    // Fall back to the route label if no header is provided
    if (typeof currentRoute.label === 'string') {
      return <Title order={3}>{currentRoute.label}</Title>;
    }

    // If there's a labelComponent, render it with a fallback
    if (currentRoute.labelComponent) {
      const LabelComponent = currentRoute.labelComponent;
      return <LabelComponent fallbackLabel={currentRoute.path} />;
    }

    // If the label is a ReactNode, render it directly
    if (React.isValidElement(currentRoute.label)) {
      return currentRoute.label;
    }

    // Final fallback to the route path
    return <Title order={3}>{currentRoute.path}</Title>;
  };

  return (
    <Stack gap={0} justify="flex-start" p={0} className={classes.headerLayout}>
      <Group
        justify="space-between"
        align="flex-start"
        gap="xs"
        p="md"
        wrap="nowrap"
        bg={headerBg}
        className={classes.headerGroup}
      >
        <Stack gap="xs">
          <Group justify="flex-start" gap="xs">
            {showIcon && currentRoute.icon && (
              <currentRoute.icon
                size={24}
                style={{ color: 'var(--mantine-color-gray-6)' }}
              />
            )}
            {renderHeader()}
          </Group>
          {subtitleComponent && (
            <Group justify="flex-start" gap="xs">
              <Text size="sm" c="dimmed">
                {subtitleComponent}
              </Text>
            </Group>
          )}
        </Stack>
        {rightComponent && (
          <Group h="100%" align="flex-start" gap="md">
            {rightComponent}
          </Group>
        )}
      </Group>
      <div className={classes.contentContainer}>{children}</div>
    </Stack>
  );
};
