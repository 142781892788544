import { TaskListTypeEnum } from '@btrway/api-task';
import {
  MetricContext,
  PersonStatsProvider,
  usePersonStats,
} from '@btrway/task-stats-provider';
import { Badge, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router';

interface PersonDetailCoreProps {
  metricContext: MetricContext;
  filterWorkgroupId?: number;
}

const PersonDetailCore: React.FC<PersonDetailCoreProps> = ({
  metricContext,
  filterWorkgroupId,
}) => {
  const { stats } = usePersonStats();
  const navigate = useNavigate();

  if (!stats?.length) {
    return (
      <Text c="dimmed" ta="center">
        No data available
      </Text>
    );
  }

  const filteredStats = filterWorkgroupId
    ? stats.filter((person) => person.workgroupId === filterWorkgroupId)
    : stats;

  const sortedStats = [...filteredStats].sort((a, b) =>
    a.personName.localeCompare(b.personName)
  );

  return (
    <Stack gap={0}>
      {sortedStats.map((person) => {
        const firstTaskList = person.taskListStatistics[0];

        return (
          <Card
            key={person.personId}
            padding="xs"
            radius="none"
            withBorder
            shadow="none"
            onClick={() => navigate(`/app/person/${person.personId}`)}
            style={{ cursor: 'pointer' }}
          >
            <Group justify="space-between" align="center">
              <Group>
                <Text fw={500}>{person.personName}</Text>
                <Text size="sm" c="dimmed">
                  {person.workgroupName}
                </Text>
              </Group>
              <Group gap="lg">
                <Text size="sm" c="dimmed">
                  {firstTaskList?.taskListProgressPercentage}%
                </Text>
                {firstTaskList && (
                  <Badge
                    color={
                      firstTaskList.taskListProgressPercentage === 100
                        ? 'green'
                        : firstTaskList.taskListProgressPercentage === 0
                        ? 'red'
                        : 'blue'
                    }
                  >
                    {firstTaskList.taskListProgressPercentage === 100
                      ? 'CERTIFIED'
                      : firstTaskList.taskListProgressPercentage === 0
                      ? 'NOT STARTED'
                      : 'IN PROGRESS'}
                  </Badge>
                )}
              </Group>
            </Group>
          </Card>
        );
      })}
    </Stack>
  );
};

interface PersonDetailProps {
  workgroupId: number;
  filterWorkgroupId?: number;
  personId?: number;
  taskListKey?: string;
  workgroupTypeId?: number;
  taskListType?: TaskListTypeEnum;
  taskListClassification?: string;
  metricContext: MetricContext;
}

export const PersonDetail: React.FC<PersonDetailProps> = ({
  workgroupId,
  filterWorkgroupId,
  personId,
  taskListKey,
  workgroupTypeId,
  taskListType,
  taskListClassification,
  metricContext,
}) => {
  return (
    <PersonStatsProvider
      workgroupId={workgroupId}
      personId={personId}
      taskListKey={taskListKey}
      workgroupTypeId={workgroupTypeId}
      taskListType={taskListType}
      taskListClassification={taskListClassification}
    >
      <PersonDetailCore
        metricContext={metricContext}
        filterWorkgroupId={filterWorkgroupId}
      />
    </PersonStatsProvider>
  );
};
