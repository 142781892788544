import { getGetProviderByIdQueryKey, ProviderRequest, useDeleteProvider, useGetProviderById, useSaveProvider } from "@btrway/api-external-provider";
import { useScrollableDrawer } from "@btrway/scrollable-drawer";
import { Button, Group, LoadingOverlay, Stack, TextInput } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";

interface ExternalCourseProviderEditProps {
    provider: ProviderRequest;
    isNew?: boolean;
    onSave: () => void;
    onCancel: () => void;
}

export const ExternalCourseProviderEdit = ({
    provider,
    isNew = false,
    onSave,
    onCancel,
}: ExternalCourseProviderEditProps) => {
    const {data: providerResponse, isLoading: isLoadingProvider} = useGetProviderById(provider.id || 0, {
          query: {
            enabled: !isNew && !!provider.id,
            queryKey: getGetProviderByIdQueryKey(provider.id || 0),
          },
        });

    const [formData, setFormData] = useState<ProviderRequest>({
        ...provider
    });

    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: saveProvider } = useSaveProvider();
    const { mutateAsync: deleteProvider } = useDeleteProvider();
    const { setDrawerFooter } = useScrollableDrawer();

    useEffect(() => {
        if(providerResponse && !isNew) {
            const convertedProvider: ProviderRequest = {
                id: providerResponse.id,
                name: providerResponse.name
            };
            setFormData(convertedProvider);
        }
    }, [providerResponse, isNew]);

    const handleFormChange = useCallback((field: string, value: any) => {
        setFormData((prev) => {
            if(field === 'name') {
                return { ...prev, name: value };
            }
        })
    }, []);

    const handleSaveProvider = async () => {
        if (!formData.name.trim()) return;

        setIsLoading(true);
        try {
            const providerToSave: ProviderRequest = {
                ...formData
            };

            await saveProvider({data: providerToSave});
            onSave();
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteProvider = async () => {
        if(!provider.id) return;

        setIsLoading(true);
        try {
            await deleteProvider({providerId: provider.id});
            onSave();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setDrawerFooter(
          <Group justify="space-between" gap="md">
            {!isNew && (
              <Button
                variant="light"
                color="red"
                onClick={handleDeleteProvider}
                loading={isLoading}
              >
                Delete
              </Button>
            )}
            <Group ml="auto">
              <Button variant="light" onClick={onCancel} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                onClick={handleSaveProvider}
                loading={isLoading}
                disabled={!formData.name.trim()}
              >
                Save Changes
              </Button>
            </Group>
          </Group>
        );
    
        return () => setDrawerFooter(null);
      }, [isNew, isLoading, formData.name]);

      return (
          <Stack gap="md">
            <LoadingOverlay visible={isLoading || isLoadingProvider} />
            <TextInput
              label="Provider Name"
              value={formData.name}
              onChange={(e) => handleFormChange('name', e.target.value)}
              placeholder="Enter provider name"
              required
            />
          </Stack>
        );
}