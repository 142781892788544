import { PermissionGrantLevelEnum } from '@btrway/api-security';
import { Switch } from '@mantine/core';
import React from 'react';

interface PermissionGrantSwitchProps {
  value?: PermissionGrantLevelEnum;
  onChange: (value: PermissionGrantLevelEnum) => void;
  disabled?: boolean;
}

export const PermissionGrantSwitch: React.FC<PermissionGrantSwitchProps> = ({
  value = PermissionGrantLevelEnum.hidden,
  onChange,
  disabled = false,
}) => {
  const isChecked = value !== PermissionGrantLevelEnum.hidden;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Stop event propagation
    event.stopPropagation();

    onChange(
      event.currentTarget.checked
        ? PermissionGrantLevelEnum.visible
        : PermissionGrantLevelEnum.hidden
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Switch checked={isChecked} onChange={handleChange} disabled={disabled} />
    </div>
  );
};
