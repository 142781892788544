import { PermissionNode, usePermissionMode } from '@btrway/permission-manager';
import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React from 'react';
import { usePermissionDrag } from '../../hooks/usePermissionDrag';
import { usePermissionDrop } from '../../hooks/usePermissionDrop';
import { PermissionControl } from '../PermissionControl/PermissionControl';

export interface ModuleCardProps {
  module: PermissionNode;
  expanded?: boolean;
  onExpand?: () => void;
  className?: string;
  children?: React.ReactNode;
  level?: number;
  hasChildren?: boolean;
  onDrop?: (targetClientId: string, draggedClientId: string) => void;
}

export const ModuleCard: React.FC<ModuleCardProps> = ({
  module,
  expanded = false,
  onExpand,
  className,
  children,
  level = 0,
  hasChildren = false,
  onDrop,
}) => {
  const {
    mode,
    selectedModuleId,
    canDragNode,
    showBetaBadge,
    setSelectedModuleId,
  } = usePermissionMode();

  const isSelected = selectedModuleId === module.clientId;

  const { isDragging, drag } = usePermissionDrag(module);

  const { isOver, canDrop, drop } = usePermissionDrop({
    parentClientId: module.clientId,
    canAcceptChild: (draggedType) => mode === 'admin',
    onDrop: (draggedNode) => onDrop?.(module.clientId, draggedNode.clientId),
    level,
  });

  const dragDropRef = (el: HTMLDivElement) => {
    if (mode === 'admin') {
      drag(el);
      drop(el);
    }
  };

  const cardStyles = {
    marginLeft: level > 0 ? `${level * 10}px` : 0,
    cursor: 'pointer',
    position: 'relative' as const,
    borderLeft: isSelected
      ? '4px solid var(--mantine-color-blue-filled)'
      : undefined,
    backgroundColor: isSelected
      ? 'var(--mantine-color-blue-light)'
      : 'transparent',
    opacity: isDragging ? 0.4 : 1,
    ...(isOver && canDrop
      ? { backgroundColor: 'rgba(51, 154, 240, 0.1)' }
      : {}),
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent bubbling to parent modules

    // Don't trigger selection when clicking controls
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('[data-control]') ||
        e.target.closest('[data-expand-button]'))
    ) {
      return;
    }

    setSelectedModuleId(module.clientId);
  };

  return (
    <div ref={dragDropRef}>
      <Card
        py={8}
        px="sm"
        className={className}
        style={cardStyles}
        onClick={handleClick}
      >
        <Stack gap="sm">
          <Group justify="space-between" align="center">
            <Group gap="sm">
              <Text size="sm" fw={module.level === 0 ? 600 : 400}>
                {module.name}
              </Text>
              {children}
              {hasChildren && (
                <ActionIcon
                  variant="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand?.();
                  }}
                  size="sm"
                  data-expand-button
                >
                  {expanded ? (
                    <IconChevronDown size={16} />
                  ) : (
                    <IconChevronRight size={16} />
                  )}
                </ActionIcon>
              )}
            </Group>
            <PermissionControl node={module} />
          </Group>
        </Stack>
      </Card>
    </div>
  );
};
