import {
  PermissionApplicationEnum,
  useGetPermissions,
} from '@btrway/api-security';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PermissionNode } from '../types/permissionNode';
import { AdminState, PermissionManagerState } from '../types/state';
import {
  responseToNode,
  setupInitialParentRelationships,
} from '../utils/nodeUtils';

interface PermissionManagerContextValue {
  state: PermissionManagerState;
  actions: {
    setPermissions: React.Dispatch<React.SetStateAction<PermissionNode[]>>;
    setLoading: (loading: boolean) => void;
    setError: (error: Error | null) => void;
    setPendingChanges: (changes: Map<string, PermissionNode>) => void;
    setDeletedIds: (ids: Set<string>) => void;
    setSelectedApplication: (application: PermissionApplicationEnum) => void;
  };
}

const PermissionManagerContext = createContext<
  PermissionManagerContextValue | undefined
>(undefined);

export const usePermissionManager = () => {
  const context = useContext(PermissionManagerContext);
  if (!context) {
    throw new Error(
      'usePermissionManager must be used within a PermissionManagerProvider'
    );
  }
  return context;
};

interface PermissionManagerProviderProps {
  children: React.ReactNode;
  defaultApplication?: PermissionApplicationEnum;
}

export const PermissionManagerProvider: React.FC<
  PermissionManagerProviderProps
> = ({ children, defaultApplication = PermissionApplicationEnum.client }) => {
  // Core state
  const [permissions, setPermissions] = useState<PermissionNode[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Admin state
  const [admin, setAdmin] = useState<AdminState>({
    pendingChanges: new Map(),
    deletedIds: new Set(),
    selectedApplication: defaultApplication,
  });

  // Create derived state
  const permissionsMap = useMemo(
    () => new Map(permissions.map((node) => [node.clientId, node])),
    [permissions]
  );

  // Fetch permissions for selected application
  const { data } = useGetPermissions({
    application: admin.selectedApplication,
  });

  // Update permissions when data changes
  useEffect(() => {
    if (data) {
      const nodes = data.map(responseToNode);
      const nodesWithParents = setupInitialParentRelationships(nodes);
      setPermissions(nodesWithParents);
    }
  }, [data]);

  // Admin actions
  const setPendingChanges = (changes: Map<string, PermissionNode>) => {
    setAdmin((prev) => ({
      ...prev,
      pendingChanges: changes,
    }));
  };

  const setDeletedIds = (ids: Set<string>) => {
    setAdmin((prev) => ({
      ...prev,
      deletedIds: ids,
    }));
  };

  const setSelectedApplication = (application: PermissionApplicationEnum) => {
    setPermissions([]); // Clear existing permissions
    setPendingChanges(new Map()); // Clear pending changes
    setDeletedIds(new Set()); // Clear deleted ids
    setAdmin((prev) => ({
      ...prev,
      selectedApplication: application,
    }));
  };

  const state: PermissionManagerState = {
    permissions,
    permissionsMap,
    loading,
    error,
    admin,
  };

  const actions = {
    setPermissions,
    setLoading,
    setError,
    setPendingChanges,
    setDeletedIds,
    setSelectedApplication,
  };

  return (
    <PermissionManagerContext.Provider value={{ state, actions }}>
      {children}
    </PermissionManagerContext.Provider>
  );
};
