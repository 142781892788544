import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
// import CertificationsView from '../../components/CertificationsView/CertificationsView';
import styles from './CurriculumsPage.module.css';

const CurriculumsPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">{/* <CertificationsView /> */}</Stack>
          </Stack>
        </Box>
        {/* <Box visibleFrom="md" style={{ flexShrink: 0 }}>
          <CalendarWidget />
        </Box> */}
      </Flex>
    </Stack>
  );
};

export default CurriculumsPage;
