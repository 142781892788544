import {
  PermissionGrantLevelEnum,
  PermissionTypeEnum,
} from '@btrway/api-security';
import { PermissionNode } from '../types/permissionNode';
import { PermissionFilterStrategy } from '../types/permissionStrategy';

export class GrantPermissionStrategy implements PermissionFilterStrategy {
  constructor(
    private organizationGrants: Map<number, PermissionGrantLevelEnum>,
    private mode: 'organizationGrant' | 'userRoleGrant'
  ) {}

  filterPermissions(permissions: PermissionNode[]): PermissionNode[] {
    if (this.mode === 'organizationGrant') {
      return permissions;
    }

    return permissions.filter((permission) => {
      if (permission.permissionType === PermissionTypeEnum.setting) {
        return false;
      }

      if (!permission.id) return false;

      if (this.hasVisibleOrgGrant(permission.id)) {
        return true;
      }

      if (permission.permissionType === PermissionTypeEnum.module) {
        if (
          !permission.parentClientId ||
          this.hasVisibleParentGrant(permission, permissions)
        ) {
          return (
            this.hasVisibleOrgGrant(permission.id) ||
            this.hasVisibleChildren(permission, permissions)
          );
        }
      }

      return this.hasVisibleOrgGrant(permission.id);
    });
  }

  private hasVisibleOrgGrant(permissionId: number): boolean {
    const grantLevel = this.organizationGrants.get(permissionId);
    return grantLevel === PermissionGrantLevelEnum.visible;
  }

  private hasVisibleParentGrant(
    permission: PermissionNode,
    allPermissions: PermissionNode[]
  ): boolean {
    let currentNode = permission;

    while (currentNode.parentClientId) {
      const parent = allPermissions.find(
        (p) => p.clientId === currentNode.parentClientId
      );
      if (!parent || !parent.id) break;
      if (this.hasVisibleOrgGrant(parent.id)) {
        return true;
      }
      currentNode = parent;
    }
    return false;
  }

  private hasVisibleChildren(
    permission: PermissionNode,
    allPermissions: PermissionNode[]
  ): boolean {
    return allPermissions.some(
      (child) =>
        child.parentClientId === permission.clientId &&
        child.id &&
        this.hasVisibleOrgGrant(child.id)
    );
  }
}
