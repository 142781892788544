import { TaskConfig } from '@btrway/api-task';
import { useTaskCategories, useTaskTypes } from '@btrway/workflow-manager';
import { useMemo } from 'react';

export type TaskSortType = 'date' | 'type' | 'category' | 'none';

interface GroupedTasks {
  label: string;
  tasks: TaskConfig[];
}

const sortByTitle = (a: TaskConfig, b: TaskConfig) =>
  (a.metadata?.title ?? '').localeCompare(b.metadata?.title ?? '');

const sortByDateAndTitle = (a: TaskConfig, b: TaskConfig) => {
  const dateA = a.taskListConfig?.startDate?.offsetDays ?? 0;
  const dateB = b.taskListConfig?.startDate?.offsetDays ?? 0;

  if (dateA !== dateB) {
    return dateA - dateB;
  }

  return sortByTitle(a, b);
};

export const useTaskGrouping = (
  tasks: TaskConfig[],
  sortType: TaskSortType
) => {
  const { getTaskType } = useTaskTypes();
  const { getTaskCategory } = useTaskCategories();

  const groupedTasks = useMemo((): GroupedTasks[] => {
    if (!tasks.length) {
      return [{ label: '', tasks: [] }];
    }

    if (sortType === 'none') {
      return [{ label: '', tasks: [...tasks].sort(sortByTitle) }];
    }

    if (sortType === 'date') {
      const groups = new Map<number, TaskConfig[]>();
      const increment = 30;

      tasks.forEach((task) => {
        const offsetDays = task.taskListConfig?.startDate?.offsetDays ?? 0;
        const groupKey = Math.floor(offsetDays / increment) * increment;
        if (!groups.has(groupKey)) {
          groups.set(groupKey, []);
        }
        groups.get(groupKey)!.push(task);
      });

      groups.forEach((groupTasks, key) => {
        groups.set(key, [...groupTasks].sort(sortByDateAndTitle));
      });

      return Array.from(groups.entries())
        .sort(([a], [b]) => a - b)
        .map(([key, groupTasks]) => ({
          label: `Day ${key} - ${key + increment - 1}`,
          tasks: groupTasks,
        }));
    }

    if (sortType === 'type') {
      const groups = new Map<string, TaskConfig[]>();

      tasks.forEach((task) => {
        const taskType = getTaskType(task.taskType);
        const label = taskType?.name ?? 'Unknown Type';
        if (!groups.has(label)) {
          groups.set(label, []);
        }
        groups.get(label)!.push(task);
      });

      groups.forEach((groupTasks, key) => {
        groups.set(key, [...groupTasks].sort(sortByDateAndTitle));
      });

      return Array.from(groups.entries())
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([label, groupTasks]) => ({
          label,
          tasks: groupTasks,
        }));
    }

    if (sortType === 'category') {
      const groups = new Map<string, TaskConfig[]>();

      tasks.forEach((task) => {
        const category = getTaskCategory(task.metadata?.taskCategoryKey);
        const label = category?.name ?? 'Uncategorized';
        if (!groups.has(label)) {
          groups.set(label, []);
        }
        groups.get(label)!.push(task);
      });

      groups.forEach((groupTasks, key) => {
        groups.set(key, [...groupTasks].sort(sortByDateAndTitle));
      });

      return Array.from(groups.entries())
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([label, groupTasks]) => ({
          label,
          tasks: groupTasks,
        }));
    }

    return [{ label: '', tasks: [...tasks].sort(sortByTitle) }];
  }, [tasks, sortType, getTaskType, getTaskCategory]);

  return { groupedTasks };
};
