import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GetRowIdFunc, ModuleRegistry } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { TaskInstanceResponse } from '@btrway/api-task';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ResponsiveGrid, WorkgroupCell } from '@btrway/grid-components';
import { useGetPersonTasks, useTaskModal } from '@btrway/task-manager';
import { formatDateTime } from '@btrway/utils';
import { useStepTypes } from '@btrway/workflow-manager';
import { Box, Flex } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import styles from './ApprovalsGrid.module.css';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

const ApprovalsGrid: React.FC = () => {
  const { getStepType } = useStepTypes();
  const { openTask } = useTaskModal();

  const { getApprovalTasksByPersonId } = useGetPersonTasks();
  const { currentPerson } = useAuthenticatedUser();

  const approvalTasks: TaskInstanceResponse[] = getApprovalTasksByPersonId(
    currentPerson.id
  );

  const handleTaskClick = useCallback(
    (task: TaskInstanceResponse) => {
      openTask(task);
    },
    [openTask]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        field: 'taskMetadata.title',
        headerName: 'Task',
        sortable: true,
        flex: 2,
      },
      {
        field: 'assignedAt',
        headerName: 'Assigned',
        sortable: true,
        sort: 'asc',
        cellRenderer: (params: any) => {
          return params.value ? formatDateTime(params.value) : '';
        },
      },
      {
        field: 'workgroupId',
        headerName: 'Workgroup',
        sortable: true,
        cellRenderer: WorkgroupCell,
        flex: 1,
      },
    ],
    [getStepType]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      sortable: true,
      filter: false,
    }),
    []
  );

  const getRowId: GetRowIdFunc<TaskInstanceResponse> = (params) => {
    // Use taskInstanceId if available, otherwise create a composite key
    return params.data.id
      ? params.data.id.toString()
      : params.data.derivedTaskData?.derivedTaskKey.compositeKey.toString() ||
          '';
  };

  return (
    <Flex
      direction="column"
      style={{ height: '100%', width: '100%', position: 'absolute' }}
    >
      <Box style={{ flex: 1, width: '100%', height: '100%' }}>
        <ResponsiveGrid
          columnDefs={columnDefs}
          rowData={approvalTasks}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          rowModelType="clientSide"
          rowSelection="multiple"
          responsiveRowSpacing={10}
          className={styles.taskAssignmentsGrid}
          onRowClick={(data) => {
            handleTaskClick(data as TaskInstanceResponse);
          }}
        />
      </Box>
    </Flex>
  );
};

export default ApprovalsGrid;
