// providers/StepCreatorProvider.tsx
import { StepConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import React, { createContext, useCallback, useContext, useState } from 'react';

interface StepCreatorContextState {
  isOpen: boolean;
  workflowType: WorkflowTypeEnum | null;
  onStepAdded?: (step: StepConfig) => void;
}

interface StepCreatorContextValue extends StepCreatorContextState {
  openCreator: (params: {
    workflowType: WorkflowTypeEnum;
    onStepAdded?: (step: StepConfig) => void;
  }) => void;
  closeCreator: () => void;
}

const StepCreatorContext = createContext<StepCreatorContextValue | null>(null);

export const StepCreatorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<StepCreatorContextState>({
    isOpen: false,
    workflowType: null,
  });

  const openCreator = useCallback(
    (params: {
      workflowType: WorkflowTypeEnum;
      onStepAdded?: (step: StepConfig) => void;
    }) => {
      setState({
        isOpen: true,
        workflowType: params.workflowType,
        onStepAdded: params.onStepAdded,
      });
    },
    []
  );

  const closeCreator = useCallback(() => {
    setState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <StepCreatorContext.Provider
      value={{ ...state, openCreator, closeCreator }}
    >
      {children}
    </StepCreatorContext.Provider>
  );
};

export const useStepCreator = () => {
  const context = useContext(StepCreatorContext);
  if (!context) {
    throw new Error('useStepCreator must be used within a StepCreatorProvider');
  }
  return context;
};
