import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { Select, type ComboboxItem } from '@mantine/core';
import React from 'react';

interface TaskListClassificationEditorProps {
  taskListType: TaskListTypeEnum;
  taskListClassification: string;
  onChange: (classification: string) => void;
}

export const TaskListClassificationEditor: React.FC<
  TaskListClassificationEditorProps
> = ({ taskListType, taskListClassification, onChange }) => {
  const getSelectData = () => {
    if (taskListType === TaskListTypeEnum.todoList) {
      return [
        { label: 'To-Do List', value: 'To-Do List' },
        { label: 'ABW To-Do List', value: 'ABW To-Do List' },
        { label: 'Startup Packet', value: 'Startup Packet' },
      ];
    } else if (taskListType === TaskListTypeEnum.certification) {
      return [
        { label: 'Coach Certification', value: 'Coach Certification' },
        {
          label: 'Student-Athlete Eligibility',
          value: 'Student-Athlete Eligibility',
        },
        { label: 'Certification', value: 'Certification' },
      ];
    }
    return [];
  };

  const selectData = getSelectData();

  if (selectData.length === 0) {
    return null;
  }

  const handleChange = (value: string | null, _item: ComboboxItem) => {
    if (value) {
      onChange(value);
    }
  };

  return (
    <Select
      label="Classification"
      value={taskListClassification}
      onChange={handleChange}
      data={selectData}
      clearable={false}
    />
  );
};
