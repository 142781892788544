import type {
  ExternalCourseDefinitionRequest,
  ExternalCourseDefinitionResponse,
  ExternalCourseTemplateRequest,
  ExternalCourseTemplateResponse,
} from '@btrway/api-external-provider';
import { CommonExternalCourse } from '../types/commonExternalCourse';

export const convertResponseToCommon = (
  response: ExternalCourseDefinitionResponse | ExternalCourseTemplateResponse
): CommonExternalCourse => {
  const isDefinition = 'organizationId' in response;

  const common: CommonExternalCourse = {
    id: response.id,
    courseKey: response.courseKey,
    courseConfiguration: response.courseConfiguration,
    sourceType: isDefinition ? 'definition' : 'template',
  };

  if (isDefinition) {
    const def = response as ExternalCourseDefinitionResponse;
    return {
      ...common,
      organizationId: def.organizationId,
      courseTemplateId: def.courseTemplate?.id,
      additionalMetadata: def.additionalMetadata,
    };
  } else {
    const template = response as ExternalCourseTemplateResponse;
    return {
      ...common,
      providerId: template.provider.id,
      provider: template.provider,
      organizationFilter: template.organizationFilter,
    };
  }
};

export const convertCommonToCourseRequest = (
  common: CommonExternalCourse
): ExternalCourseDefinitionRequest | ExternalCourseTemplateRequest => {
  if (common.sourceType === 'definition') {
    const request: ExternalCourseDefinitionRequest = {
      id: common.id,
      courseKey: common.courseKey,
      organizationId: common.organizationId!, // Required for definition
      courseConfiguration: common.courseConfiguration,
      courseTemplateId: common.courseTemplateId,
      additionalMetadata: common.additionalMetadata,
    };
    return request;
  } else {
    const request: ExternalCourseTemplateRequest = {
      id: common.id,
      courseKey: common.courseKey,
      providerId: common.providerId!, // Required for template
      courseConfiguration: common.courseConfiguration,
      organizationFilter: common.organizationFilter,
    };
    return request;
  }
};

// Type guard utility for checking if required fields are present
export const isValidDefinitionCourse = (
  common: CommonExternalCourse
): common is CommonExternalCourse & {
  sourceType: 'definition';
  organizationId: number;
} => {
  return (
    common.sourceType === 'definition' &&
    typeof common.organizationId === 'number'
  );
};

export const isValidTemplateCourse = (
  common: CommonExternalCourse
): common is CommonExternalCourse & {
  sourceType: 'template';
  providerId: number;
} => {
  return (
    common.sourceType === 'template' && typeof common.providerId === 'number'
  );
};

// Utility to validate before converting to request
export const validateCourseBeforeConversion = (
  common: CommonExternalCourse
): string[] => {
  const errors: string[] = [];

  if (common.sourceType === 'definition') {
    if (common.organizationId === undefined) {
      errors.push('Organization ID is required');
    }
  } else {
    if (common.providerId === undefined) {
      errors.push('Provider ID is required');
    }
  }

  if (!common.courseKey) {
    errors.push('Course key is required');
  }

  return errors;
};
