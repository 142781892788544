import { TaskConfig } from '@btrway/api-tasklist';
import { Box, Button, Group } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskEditorCore } from '../TaskEditorCore/TaskEditorCore';

interface TaskEditorProps {
  config: TaskConfig;
  onConfigChange: (config: TaskConfig) => void;
  onCancel: () => void;
  onDelete?: (taskKey: string) => void;
}

export const TaskEditor: React.FC<TaskEditorProps> = ({
  config,
  onConfigChange,
  onCancel,
  onDelete,
}) => {
  console.log('TaskEditor: config', config);
  const [currentConfig, setCurrentConfig] = useState<TaskConfig>(config);

  // Update local state when config prop changes
  useEffect(() => {
    setCurrentConfig(config);
  }, [config]);

  const handleConfigChange = (updatedConfig: TaskConfig) => {
    setCurrentConfig(updatedConfig);
  };

  const handleSave = () => {
    onConfigChange(currentConfig);
  };

  const handleDelete = () => {
    if (onDelete && config.taskKey) {
      onDelete(config.taskKey);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box style={{ flex: 1, overflowY: 'auto' }} p="md">
        <TaskEditorCore
          config={currentConfig}
          onConfigChange={handleConfigChange}
        />
      </Box>

      <Box
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          backgroundColor: 'var(--mantine-color-gray-0)',
          padding: 'var(--mantine-spacing-md)',
          borderBottomLeftRadius: 'var(--mantine-radius-lg)',
          borderBottomRightRadius: 'var(--mantine-radius-lg)',
        }}
      >
        <Group justify="space-between" gap="sm">
          {onDelete && (
            <Button color="red" variant="subtle" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Group gap="sm" ml="auto">
            <Button variant="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Group>
        </Group>
      </Box>
    </div>
  );
};

export default TaskEditor;
