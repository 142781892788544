/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type WorkflowTypeEnum = typeof WorkflowTypeEnum[keyof typeof WorkflowTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkflowTypeEnum = {
  automation: 'automation',
  form: 'form',
  file: 'file',
} as const;
