import {
  TaskConfig,
  TaskTypeEnum,
  ThirdPartyTaskProperties,
} from '@btrway/api-task';
import { UrlTextInput } from '@btrway/shared-components';
import { getTypedTaskProperties } from '@btrway/task-list-config-provider';
import { Stack, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskConfigProps } from '../../types/taskTypeComponentProps';

export const ThirdPartyTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.thirdParty
  );

  const [properties, setProperties] = useState<ThirdPartyTaskProperties>({
    url: taskProperties?.url || '',
    price: taskProperties?.price || 0,
  });

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        url: taskProperties.url || '',
        price: taskProperties.price || 0,
      });
    }
  }, [taskProperties]);

  const handlePropertyChange = (
    field: keyof ThirdPartyTaskProperties,
    value: string | number | null
  ) => {
    const updatedProperties = {
      ...properties,
      [field]: value ?? '',
    };
    setProperties(updatedProperties);

    const updatedConfig: TaskConfig = {
      ...config,
      taskProperties: updatedProperties,
    };
    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <UrlTextInput
        label="URL"
        placeholder="Enter task URL"
        value={properties.url || ''}
        onUrlChange={(value) => handlePropertyChange('url', value)}
      />
      <TextInput
        label="Price"
        type="number"
        placeholder="Enter price"
        value={properties.price?.toString() || '0'}
        onChange={(e) =>
          handlePropertyChange('price', parseFloat(e.currentTarget.value) || 0)
        }
      />
    </Stack>
  );
};
