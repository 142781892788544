import { PermissionNode, usePermissionMode } from '@btrway/permission-manager';
import { Box } from '@mantine/core';
import { usePermissionDrop } from '../../hooks/usePermissionDrop';

interface DropZoneProps {
  parentClientId: string | null;
  index: number;
  onDrop?: (draggedNode: PermissionNode, targetIndex: number) => void;
  level?: number;
}

export const ModuleDropZone: React.FC<DropZoneProps> = ({
  parentClientId,
  index,
  onDrop,
  level = 0,
}) => {
  const { mode } = usePermissionMode();

  const { isOver, canDrop, drop } = usePermissionDrop({
    parentClientId,
    canAcceptChild: () => mode === 'admin',
    onDrop: (draggedNode) => onDrop?.(draggedNode, index),
    index,
    level,
  });

  // Only render drop zones at root level
  if (mode !== 'admin' || level !== 0) return null;

  return (
    <Box
      ref={drop}
      style={{
        height: isOver && canDrop ? '60px' : '6px',
        backgroundColor:
          isOver && canDrop ? 'rgba(51, 154, 240, 0.1)' : undefined,
        border: isOver && canDrop ? '2px dashed rgb(51, 154, 240)' : undefined,
        borderRadius: '4px',
        transition: 'all 0.2s ease',
      }}
    />
  );
};
