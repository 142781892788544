// src/hooks/usePermissionOrdering.ts

import { useCallback } from 'react';
import { usePermissionManager } from '../providers/PermissionManagerProvider';
import { PermissionNode } from '../types/permissionNode';

interface ReorderNodesParams {
  targetParentId: string | null;
  draggedNode: PermissionNode;
  targetIndex?: number;
}

interface ReorderResult {
  success: boolean;
  changes: Map<string, PermissionNode>;
  error?: string;
}

export const usePermissionOrdering = () => {
  const {
    state: { permissions, permissionsMap },
    actions: { setPermissions, setPendingChanges },
  } = usePermissionManager();

  const canReorderNode = useCallback(
    (params: {
      nodeClientId: string;
      targetParentId: string | null;
      targetLevel: number;
    }): boolean => {
      const { nodeClientId, targetParentId, targetLevel } = params;
      const node = permissionsMap.get(nodeClientId);

      if (!node) return false;

      // Prevent moving to own parent
      if (nodeClientId === targetParentId) return false;

      // Get source parent node level
      const sourceParentNode = node.parentClientId
        ? permissionsMap.get(node.parentClientId)
        : null;
      const sourceLevel = sourceParentNode?.level ?? 0;

      // Only allow moves between same level or to root
      const isRootLevelMove = targetLevel === 0;
      const isMovingToRootModule = targetParentId
        ? permissionsMap.get(targetParentId)?.level === 0
        : false;
      const isSourceAtRootLevel = !node.parentClientId || sourceLevel === 0;

      if (!isRootLevelMove && !isMovingToRootModule) return false;
      if (isMovingToRootModule && !isSourceAtRootLevel) return false;

      return true;
    },
    [permissionsMap]
  );

  const reorderNodes = useCallback(
    ({
      targetParentId,
      draggedNode,
      targetIndex,
    }: ReorderNodesParams): ReorderResult => {
      // Validate the move is allowed
      const canMove = canReorderNode({
        nodeClientId: draggedNode.clientId,
        targetParentId,
        targetLevel: targetParentId
          ? (permissionsMap.get(targetParentId)?.level ?? 0) + 1
          : 0,
      });

      if (!canMove) {
        return {
          success: false,
          changes: new Map(),
          error: 'Invalid node movement',
        };
      }

      // Get siblings at target location
      const siblings = permissions.filter(
        (p) =>
          p.parentClientId === targetParentId &&
          p.clientId !== draggedNode.clientId
      );

      // Calculate final index
      const finalIndex =
        typeof targetIndex === 'number' ? targetIndex : siblings.length;

      // Create reordered array
      const reorderedNodes = [
        ...siblings.slice(0, finalIndex),
        draggedNode,
        ...siblings.slice(finalIndex),
      ];

      // Normalize indexes
      const normalizedNodes = reorderedNodes.map((node, idx) => ({
        ...node,
        index: idx,
      }));

      // Build changes map
      const changes = new Map<string, PermissionNode>();

      // Add dragged node changes
      const updatedDraggedNode: PermissionNode = {
        ...draggedNode,
        parentClientId: targetParentId,
        index:
          normalizedNodes.find((n) => n.clientId === draggedNode.clientId)
            ?.index ?? 0,
        level: targetParentId
          ? (permissionsMap.get(targetParentId)?.level ?? 0) + 1
          : 0,
      };
      changes.set(draggedNode.clientId, updatedDraggedNode);

      // Add sibling changes
      normalizedNodes.forEach((node) => {
        if (node.clientId !== draggedNode.clientId) {
          const existingNode = permissionsMap.get(node.clientId);
          if (existingNode) {
            changes.set(node.clientId, {
              ...existingNode,
              index: node.index,
            });
          }
        }
      });

      // Update state
      setPermissions((prev) => prev.map((p) => changes.get(p.clientId) || p));
      setPendingChanges(new Map([...changes]));

      return {
        success: true,
        changes,
      };
    },
    [
      permissions,
      permissionsMap,
      canReorderNode,
      setPermissions,
      setPendingChanges,
    ]
  );

  return {
    canReorderNode,
    reorderNodes,
  };
};
