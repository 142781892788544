import { useGetDistinctWorkflowCategories } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Box, Stack, TagsInput, Textarea, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconList, IconPicker } from 'react-fa-icon-picker';

interface WorkflowDescriptionEditorProps {
  workflow: CommonWorkflow;
  onChange: (workflow: CommonWorkflow) => void;
}

export const WorkflowDescriptionEditor: React.FC<
  WorkflowDescriptionEditorProps
> = ({ workflow, onChange }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [suggestedCategories, setSuggestedCategories] = useState<string[]>([]);
  const { data: categoriesResponse } = useGetDistinctWorkflowCategories(
    currentOrganization.id
  );

  useEffect(() => {
    if (categoriesResponse) {
      setSuggestedCategories(categoriesResponse);
    }
  }, [categoriesResponse]);

  const handleChange = (field: keyof CommonWorkflow, value: any) => {
    onChange({
      ...workflow,
      [field]: value,
    });
  };

  return (
    <Box>
      <Stack gap="md">
        <TextInput
          label="Name"
          value={workflow.name}
          onChange={(event) => handleChange('name', event.currentTarget.value)}
        />

        <Box style={{ position: 'relative' }}>
          <IconPicker
            value={(workflow.workflowMetadata?.iconName as IconList) || ''}
            onChange={(v: IconList) =>
              handleChange('workflowMetadata', {
                ...workflow.workflowMetadata,
                iconName: v,
              })
            }
            buttonStyles={{ height: '36px' }}
          />
        </Box>

        <TagsInput
          label="Categories"
          placeholder="Type a category and press enter"
          value={workflow.workflowMetadata?.categories || []}
          onChange={(value) =>
            handleChange('workflowMetadata', {
              ...workflow.workflowMetadata,
              categories: value,
            })
          }
          data={suggestedCategories}
          clearable
        />

        <Textarea
          label="Short Description"
          value={workflow.workflowMetadata?.shortDescription || ''}
          onChange={(event) =>
            handleChange('workflowMetadata', {
              ...workflow.workflowMetadata,
              shortDescription: event.currentTarget.value,
            })
          }
          minRows={2}
        />

        <Textarea
          label="Long Description"
          value={workflow.workflowMetadata?.longDescription || ''}
          onChange={(event) =>
            handleChange('workflowMetadata', {
              ...workflow.workflowMetadata,
              longDescription: event.currentTarget.value,
            })
          }
          minRows={4}
        />
      </Stack>
    </Box>
  );
};
