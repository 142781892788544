import { TaskAssignmentConfig } from '@btrway/api-task';
import {
  Box,
  Card,
  CloseButton,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconSearch,
  IconUserCircle,
  IconUsers,
} from '@tabler/icons-react';
import React, { useMemo } from 'react';
import { useApproverSelectOptions } from '../../hooks/useApproverSelectOptions';
import { PeopleSearch } from '../PeopleSearch/PeopleSearch';

interface ApproverSelectOptionsProps {
  taskAssignmentConfig?: TaskAssignmentConfig;
  onConfigChange: (config: TaskAssignmentConfig, description: string) => void;
  onClose: () => void;
  primaryEntityDescription?: string;
}

interface SectionHeaderProps {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
}

export const ApproverSelectOptions: React.FC<ApproverSelectOptionsProps> = ({
  taskAssignmentConfig,
  onConfigChange,
  onClose,
  primaryEntityDescription,
}) => {
  const {
    searchValue,
    setSearchValue,
    sections,
    toggleSection,
    isSearching,
    filteredRoles,
    searchResults,
    filteredPreviousStepProperties,
    handlePrimaryEntitySelect,
    handleRoleSelect,
    handlePersonSelect,
    handlePropertyReferenceSelect,
  } = useApproverSelectOptions({
    taskAssignmentConfig,
    onConfigChange,
    onClose,
  });

  const isSelectedPrimary =
    taskAssignmentConfig?.assignmentType === 'primaryWorkflowPerson';

  const isSelectedRole = (roleSlug: string) =>
    taskAssignmentConfig?.assignmentType === 'userRole' &&
    taskAssignmentConfig.userRoleSlug?.value === roleSlug;

  const isSelectedPropertyReference = (propertyKey: string) =>
    taskAssignmentConfig?.assignmentType === 'person' &&
    taskAssignmentConfig.personId?.reference?.propertyKey === propertyKey;

  const getSelectedCardStyle = (isSelected: boolean) => ({
    cursor: 'pointer',
    backgroundColor: isSelected ? 'var(--mantine-color-blue-light)' : undefined,
    borderColor: isSelected ? 'var(--mantine-color-blue-filled)' : undefined,
  });

  const SectionHeader: React.FC<SectionHeaderProps> = ({
    title,
    isExpanded,
    onToggle,
  }) => (
    <Group
      justify="space-between"
      mb="xs"
      style={{ cursor: 'pointer' }}
      onClick={onToggle}
    >
      <Text fw={600}>{title}</Text>
      {isExpanded ? (
        <IconChevronDown size={16} />
      ) : (
        <IconChevronRight size={16} />
      )}
    </Group>
  );

  const filteredInvolvedItems = useMemo(() => {
    const primaryEntityMatch =
      !searchValue ||
      primaryEntityDescription
        ?.toLowerCase()
        .includes(searchValue.toLowerCase());

    const filteredProperties = filteredPreviousStepProperties.filter(
      (prop) =>
        !searchValue ||
        prop.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
        prop.propertyKey.toLowerCase().includes(searchValue.toLowerCase())
    );

    return {
      showPrimaryEntity: primaryEntityMatch,
      properties: filteredProperties,
      hasResults: primaryEntityMatch || filteredProperties.length > 0,
    };
  }, [searchValue, primaryEntityDescription, filteredPreviousStepProperties]);

  const visibleSections = useMemo(
    () => ({
      involved: filteredInvolvedItems.hasResults,
      roles: filteredRoles.length > 0,
    }),
    [filteredInvolvedItems.hasResults, filteredRoles.length]
  );

  return (
    <Stack gap="md" h="500px">
      <Box>
        <TextInput
          autoFocus
          placeholder="Search people and roles..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          leftSection={<IconSearch size={16} />}
          rightSection={
            searchValue ? (
              <CloseButton onClick={() => setSearchValue('')} />
            ) : null
          }
        />
      </Box>

      <Box style={{ overflowY: 'auto', flex: 1 }}>
        <Stack gap="md">
          {visibleSections.involved && (
            <Box>
              <SectionHeader
                title="Involved in this Process"
                isExpanded={sections.involved}
                onToggle={() => toggleSection('involved')}
              />
              {sections.involved && (
                <Stack gap="xs">
                  {filteredInvolvedItems.showPrimaryEntity && (
                    <Card
                      p="xs"
                      radius="md"
                      withBorder
                      style={getSelectedCardStyle(isSelectedPrimary)}
                      onClick={() =>
                        handlePrimaryEntitySelect(
                          primaryEntityDescription || 'Primary Entity'
                        )
                      }
                    >
                      <Group gap="xs">
                        <IconUserCircle size={16} />
                        <Text>
                          {primaryEntityDescription || 'Primary Entity'}
                        </Text>
                      </Group>
                    </Card>
                  )}
                  {filteredInvolvedItems.properties.map((prop) => (
                    <Card
                      key={prop.propertyKey}
                      p="xs"
                      radius="md"
                      withBorder
                      style={getSelectedCardStyle(
                        isSelectedPropertyReference(prop.propertyKey)
                      )}
                      onClick={() => handlePropertyReferenceSelect(prop)}
                    >
                      <Group gap="xs">
                        <IconUserCircle size={16} />
                        <Text>{prop.label || prop.propertyKey}</Text>
                      </Group>
                    </Card>
                  ))}
                </Stack>
              )}
            </Box>
          )}

          {visibleSections.roles && (
            <Box>
              <SectionHeader
                title="Assign by Role"
                isExpanded={sections.roles}
                onToggle={() => toggleSection('roles')}
              />
              {sections.roles && (
                <Stack gap="xs">
                  {filteredRoles.map((role) => (
                    <Card
                      key={role.id}
                      p="xs"
                      radius="md"
                      withBorder
                      style={getSelectedCardStyle(isSelectedRole(role.slug))}
                      onClick={() => handleRoleSelect(role.slug)}
                    >
                      <Group gap="xs">
                        <IconUsers size={16} />
                        <Text>{role.name}</Text>
                      </Group>
                    </Card>
                  ))}
                </Stack>
              )}
            </Box>
          )}

          <PeopleSearch
            searchValue={searchValue}
            isSearching={isSearching}
            searchResults={searchResults}
            taskAssignmentConfig={taskAssignmentConfig}
            onPersonSelect={handlePersonSelect}
            isExpanded={sections.people}
            onToggleExpand={() => toggleSection('people')}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default ApproverSelectOptions;
