import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { APIClientProvider } from '@btrway/api-client-provider';
import { AuthProvider } from '@btrway/auth-core';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/tiptap/styles.css';
import { RootRouter } from '@web/routes/RootRouter';
import { BrowserRouter } from 'react-router-dom';
import './app.module.css';

const App: React.FC = () => {
  const apiUrl = import.meta.env.VITE_API_URL;

  return (
    <BrowserRouter>
      <APIClientProvider initialApiUrl={apiUrl}>
        <AuthProvider apiUrl={apiUrl}>
          <MantineProvider>
            <Notifications limit={3} position="bottom-center" />
            <RootRouter />
          </MantineProvider>
        </AuthProvider>
      </APIClientProvider>
    </BrowserRouter>
  );
};

export default App;
