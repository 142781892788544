import {
  ExternalCourseTemplateRequest,
  getGetExternalCourseTemplateByIdQueryKey,
  getGetExternalCourseTemplateByKeyQueryKey,
  getGetExternalCourseTemplatesQueryKey,
  useDeleteExternalCourseTemplate,
  useGetExternalCourseTemplateById,
  useGetExternalCourseTemplateByKey,
  useGetExternalCourseTemplates,
  useSaveExternalCourseTemplate,
} from '@btrway/api-external-provider';
import { useTemplateDefinition } from '@btrway/template-definition-provider';
import { CommonExternalCourse } from '../types/commonExternalCourse';
import { ExternalCourseResult, ExternalCoursesResult } from '../types/service';
import {
  convertCommonToCourseRequest,
  convertResponseToCommon,
  validateCourseBeforeConversion,
} from '../utils/commonExternalCourseConversions';

export function useExternalCourseTemplate(
  courseKey?: string,
  courseId?: number
) {
  const { sourceType } = useTemplateDefinition();
  const isEnabled = sourceType === 'template';

  const { mutateAsync: saveExternalCourseTemplate } =
    useSaveExternalCourseTemplate();
  const { mutateAsync: deleteTemplate } = useDeleteExternalCourseTemplate();

  const {
    data: dataByKey,
    isLoading: isLoadingByKey,
    error: errorByKey,
    refetch: refetchByKey,
  } = useGetExternalCourseTemplateByKey(courseKey || '', {
    query: {
      enabled: isEnabled && !!courseKey,
      queryKey: getGetExternalCourseTemplateByKeyQueryKey(courseKey || ''),
    },
  });

  const {
    data: dataById,
    isLoading: isLoadingById,
    error: errorById,
    refetch: refetchById,
  } = useGetExternalCourseTemplateById(courseId || 0, {
    query: {
      enabled: isEnabled && !!courseId,
      queryKey: getGetExternalCourseTemplateByIdQueryKey(courseId || 0),
    },
  });

  const {
    data: templatesData,
    isLoading: templatesLoading,
    error: templatesError,
    refetch: refetchTemplates,
  } = useGetExternalCourseTemplates({
    query: {
      enabled: isEnabled,
      queryKey: getGetExternalCourseTemplatesQueryKey(),
    },
  });

  const getByKeyResult: ExternalCourseResult = {
    data: dataByKey ? convertResponseToCommon(dataByKey) : null,
    isLoading: isLoadingByKey,
    error: errorByKey as Error | null,
    refetch: refetchByKey,
  };

  const getByIdResult: ExternalCourseResult = {
    data: dataById ? convertResponseToCommon(dataById) : null,
    isLoading: isLoadingById,
    error: errorById as Error | null,
    refetch: refetchById,
  };

  const templatesResult: ExternalCoursesResult = {
    courses: (templatesData || []).map(convertResponseToCommon),
    isLoading: templatesLoading,
    error: templatesError as Error | undefined,
    refetch: refetchTemplates,
  };

  return {
    getByKeyResult,
    getByIdResult,
    coursesResult: templatesResult,
    updateCourse: async (course: CommonExternalCourse) => {
      if (course.sourceType !== 'template') {
        throw new Error('Cannot save definition course in template context');
      }
      const validationErrors = validateCourseBeforeConversion(course);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid course: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToCourseRequest(
        course
      ) as ExternalCourseTemplateRequest;
      return await saveExternalCourseTemplate({ data: request });
    },
    deleteCourse: async (course: CommonExternalCourse) => {
      if (course.sourceType !== 'template') {
        throw new Error('Cannot delete definition course in template context');
      }
      if (!course.id) {
        throw new Error('Cannot delete course without an id');
      }
      return await deleteTemplate({ externalCourseTemplateId: course.id });
    },
  };
}
