import { PermissionGrantLevelEnum } from '@btrway/api-security';
import { SegmentedControl } from '@mantine/core';
import React from 'react';

interface PermissionGrantSegmentedControlProps {
  value?: PermissionGrantLevelEnum;
  onChange: (value: PermissionGrantLevelEnum) => void;
  disabled?: boolean;
}

export const PermissionGrantSegmentedControl: React.FC<
  PermissionGrantSegmentedControlProps
> = ({
  value = PermissionGrantLevelEnum.hidden,
  onChange,
  disabled = false,
}) => {
  const handleChange = (newValue: string) => {
    // Stop event propagation
    const event = window.event;
    if (event) {
      event.stopPropagation();
    }
    onChange(newValue as PermissionGrantLevelEnum);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <SegmentedControl
        value={value}
        onChange={handleChange}
        disabled={disabled}
        data={[
          { label: 'Hidden', value: PermissionGrantLevelEnum.hidden },
          { label: 'Visible', value: PermissionGrantLevelEnum.visible },
          { label: 'Editable', value: PermissionGrantLevelEnum.editable },
          { label: 'Deleteable', value: PermissionGrantLevelEnum.deleteable },
        ]}
      />
    </div>
  );
};
