import {
  EventTypeEnum,
  StepTypeEnum,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import {
  useConfigFactory,
  useWorkflowConfig,
} from '@btrway/workflow-configuration-manager';
import { useWorkflowUpdater } from '@btrway/workflow-service-provider';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import React, { useCallback } from 'react';

interface ProcessPanelDragDropProps {
  children: React.ReactNode;
}

export const ProcessPanelDragDrop: React.FC<ProcessPanelDragDropProps> = ({
  children,
}) => {
  const { processActions } = useWorkflowConfig();
  const { getOrderedSteps, addEvent } = processActions;
  const { reorderStep, insertStep } = useWorkflowUpdater();
  const { createStepConfig } = useConfigFactory();

  const stepConfigs = getOrderedSteps();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const newIndex = result.destination.index;

      if (
        result.source.droppableId === 'process-toolbox-droppable' &&
        result.destination.droppableId === 'process-panel-droppable'
      ) {
        // Handle new steps from toolbox
        const [type, code] = result.draggableId.split('-');
        let newStepConfig;
        if (type === 'taskType') {
          newStepConfig = createStepConfig('task', code as TaskTypeEnum);
        } else if (type === 'stepType') {
          newStepConfig = createStepConfig('step', code as StepTypeEnum);
        }

        if (newStepConfig) {
          insertStep(newStepConfig, newIndex);
        }
      } else if (
        result.source.droppableId === 'process-panel-droppable' &&
        result.destination.droppableId === 'process-panel-droppable'
      ) {
        // Handle reordering existing steps
        const stepKey = result.draggableId;
        const oldIndex = stepConfigs.findIndex(
          (step) => step.stepKey === stepKey
        );
        if (oldIndex !== -1 && oldIndex !== newIndex) {
          reorderStep(stepKey, newIndex);
        }
      } else if (
        result.source.droppableId === 'events-toolbox-droppable' &&
        result.destination.droppableId === 'events-panel-droppable'
      ) {
        // Handle new events from toolbox
        const [type, code] = result.draggableId.split('-');
        if (type === 'eventType') {
          const newEvent = {
            eventKey: '',
            eventTypeCode: code as EventTypeEnum,
            title: code,
          };
          addEvent(newEvent);
        }
      }
    },
    [stepConfigs, createStepConfig, insertStep, reorderStep, addEvent]
  );

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};
