import { PermissionNode } from '@btrway/permission-manager';
import { Stack } from '@mantine/core';
import React, { useState } from 'react';
import { sortNodes } from '../../utils/sortNodes';
import { ModuleCard } from '../ModuleCard/ModuleCard';
import { ModuleDropZone } from '../ModuleDropzone/ModuleDropzone';

interface ModuleTreeNodeProps {
  node: PermissionNode;
  level?: number;
  getChildModules: (node: PermissionNode) => PermissionNode[];
  onDrop?: (draggedNode: PermissionNode, targetIndex: number) => void;
}

export const ModuleTreeNode: React.FC<ModuleTreeNodeProps> = ({
  node,
  level = 0,
  getChildModules,
  onDrop,
}) => {
  const [expanded, setExpanded] = useState(true);

  // Filter out empty-named modules before sorting
  const visibleChildModules = sortNodes(
    getChildModules(node).filter((module) => module.name !== '')
  );

  const hasChildren = visibleChildModules.length > 0;

  return (
    <Stack gap={0} p={0}>
      {level === 0 && (
        <ModuleDropZone
          parentClientId={node.parentClientId}
          index={0}
          onDrop={onDrop}
          level={level}
        />
      )}

      <ModuleCard
        module={node}
        level={level}
        expanded={expanded}
        hasChildren={hasChildren}
        onExpand={() => setExpanded(!expanded)}
      />

      {expanded && hasChildren && (
        <Stack gap={0}>
          {visibleChildModules.map((childModule, index) => (
            <React.Fragment key={childModule.clientId}>
              <ModuleTreeNode
                node={childModule}
                level={level + 1}
                getChildModules={getChildModules}
                onDrop={onDrop}
              />
              {level === 0 && (
                <ModuleDropZone
                  parentClientId={node.parentClientId}
                  index={index + 1}
                  onDrop={onDrop}
                  level={level}
                />
              )}
            </React.Fragment>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
