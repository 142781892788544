import { CommonExternalCourse } from '@btrway/external-course-service-provider';
import { Card, Group, Popover, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';
import { SelectExternalCourseOptions } from '../SelectExternalCourseOptions/SelectExternalCourseOptions';

interface SelectExternalCourseProps {
  value: CommonExternalCourse | null;
  onChange: (value: CommonExternalCourse | null) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  label?: string;
}

export const SelectExternalCourse: React.FC<SelectExternalCourseProps> = ({
  value,
  onChange,
  placeholder = 'Select a course',
  disabled = false,
  required = false,
  error,
  label,
}) => {
  const [opened, setOpened] = useState(false);

  const handleSelect = (course: CommonExternalCourse) => {
    onChange(course);
    setOpened(false);
  };

  const getDisplayValue = (course: CommonExternalCourse | null): string => {
    if (!course) return placeholder;
    return course.courseConfiguration?.title || placeholder;
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      width={400}
      shadow="md"
    >
      <Popover.Target>
        <div>
          {label && (
            <Text size="sm" mb="xs">
              {label}
            </Text>
          )}
          <Card
            shadow="none"
            miw={300}
            px="xs"
            py={5}
            radius="sm"
            withBorder
            onClick={() => !disabled && setOpened(true)}
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
              width: 'fit-content',
              borderColor: error
                ? 'var(--mantine-color-red-6)'
                : 'var(--mantine-color-gray-4)',
              opacity: disabled ? 0.6 : 1,
            }}
          >
            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Text size="sm" c={value ? undefined : 'dimmed'}>
                {getDisplayValue(value)}
              </Text>
              <IconChevronDown size={16} />
            </Group>
          </Card>
          {error && (
            <Text size="xs" c="red" mt="xs">
              {error}
            </Text>
          )}
        </div>
      </Popover.Target>

      <Popover.Dropdown>
        <SelectExternalCourseOptions
          selectedCourseKey={value?.courseKey || null}
          onSelect={handleSelect}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
