/**
 * Maps data type strings to their human-readable labels
 * @param dataType - The data type string to get a label for
 * @returns The human-readable label for the data type
 */
export function getDataTypeLabel(dataType: string): string {
  const DATA_TYPE_LABELS: Record<string, string> = {
    userRole: 'By Role',
    text: 'Text',
    number: 'Number',
    integer: 'Integer',
    email: 'Email',
    phone: 'Phone',
    boolean: 'Boolean',
    date: 'Date',
    dateTime: 'Date Time',
    time: 'Time',
    location: 'Location',
    stateProvince: 'State/Province',
    country: 'Country',
    postalcode: 'Postal Code',
    systemId: 'System ID',
    systemDateTime: 'System Date Time',
    organization: 'Organization',
    workgroup: 'Workgroup',
    workgroupMember: 'Workgroup Member',
    person: 'Person',
    calendarEvent: 'Calendar Event',
    document: 'Document',
    curriculum: 'Curriculum',
    course: 'Course',
    chapter: 'Chapter',
    taskInstance: 'Task Instance',
    workflowDefinition: 'Workflow Definition',
    workflowInstance: 'Workflow Instance',
    sentEmail: 'Sent Email',
    importJob: 'Import Job',
    registration: 'Registration',
    entityGroup: 'Entity Group',
    facility: 'Facility',
  };

  return DATA_TYPE_LABELS[dataType] || dataType;
}
