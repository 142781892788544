import {
  CommonExternalCourse,
  useExternalCourseService,
} from '@btrway/external-course-service-provider';
import { Box, Stack, Text, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { ExternalCourseGroup } from '../../types/externalCourseGroup';
import { ExternalCourseCard } from '../ExternalCourseCard/ExternalCourseCard';

interface SelectExternalCourseOptionsProps {
  selectedCourseKey: string | null;
  onSelect: (course: CommonExternalCourse) => void;
}

export const SelectExternalCourseOptions: React.FC<
  SelectExternalCourseOptionsProps
> = ({ selectedCourseKey, onSelect }) => {
  const service = useExternalCourseService();
  const { courses, isLoading } = service.getCourses();
  const [searchTerm, setSearchTerm] = useState('');

  const groupedCourses = useMemo((): ExternalCourseGroup[] => {
    // Group courses by provider
    const groupsMap = new Map<number, ExternalCourseGroup>();

    courses.forEach((course) => {
      // Skip courses without provider information
      if (!course.provider) return;

      const providerId = course.provider.id;

      if (!groupsMap.has(providerId)) {
        groupsMap.set(providerId, {
          provider: course.provider,
          courses: [],
        });
      }

      groupsMap.get(providerId)?.courses.push(course);
    });

    // Convert map to array and sort by provider name
    return Array.from(groupsMap.values())
      .sort((a, b) => a.provider.name.localeCompare(b.provider.name))
      .map((group) => ({
        ...group,
        courses: group.courses.sort((a, b) => {
          // Get titles, falling back to courseKey if title is not available
          const titleA =
            a.courseConfiguration?.title?.toLowerCase() ??
            a.courseKey.toLowerCase();
          const titleB =
            b.courseConfiguration?.title?.toLowerCase() ??
            b.courseKey.toLowerCase();
          return titleA.localeCompare(titleB);
        }),
      }));
  }, [courses]);

  const filteredGroups = useMemo(() => {
    if (!searchTerm) return groupedCourses;

    const searchTermLower = searchTerm.toLowerCase();
    return groupedCourses
      .map((group) => ({
        ...group,
        courses: group.courses.filter((course) => {
          const title = course.courseConfiguration?.title?.toLowerCase() ?? '';
          const key = course.courseKey.toLowerCase();
          return (
            title.includes(searchTermLower) || key.includes(searchTermLower)
          );
        }),
      }))
      .filter((group) => group.courses.length > 0);
  }, [groupedCourses, searchTerm]);

  if (isLoading) {
    return (
      <Box w="100%">
        <Text p="md">Loading courses...</Text>
      </Box>
    );
  }

  return (
    <Box w="100%">
      <Box>
        <TextInput
          autoFocus
          data-autofocus
          placeholder="Search courses..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
          rightSection={
            searchTerm && (
              <IconX
                size="1rem"
                style={{ cursor: 'pointer' }}
                onClick={() => setSearchTerm('')}
              />
            )
          }
        />
      </Box>

      <Stack gap="xs" pt={0}>
        {filteredGroups.map((group) => (
          <Stack key={group.provider.id} gap="xs">
            <Text fw={500} fz="sm" mt="sm">
              {group.provider.name}
            </Text>
            {group.courses.map((course) => (
              <ExternalCourseCard
                key={course.id}
                course={course}
                onSelect={onSelect}
                isSelected={course.courseKey === selectedCourseKey}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
