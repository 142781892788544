import { CertificationValidConfig } from '@btrway/api-tasklist';
import { Chip, Group, NumberInput, Stack, Text } from '@mantine/core';
import React from 'react';

interface CertificationValidEditorProps {
  value: CertificationValidConfig;
  onChange: (config: CertificationValidConfig) => void;
}

export const CertificationValidEditor: React.FC<
  CertificationValidEditorProps
> = ({ value, onChange }) => {
  const handleValidTypeChange = (newType: string) => {
    onChange({
      ...value,
      validType: newType as CertificationValidConfig['validType'],
    });
  };

  const handleWithinDaysChange = (newValue: string | number) => {
    const numValue =
      typeof newValue === 'string' ? parseInt(newValue) : newValue;
    onChange({
      ...value,
      withinDays: numValue || 0,
    });
  };

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Text size="sm" c="dimmed">
          Specify whether previous task completions can be used to satisfy this
          requirement.
        </Text>
        <Chip.Group
          multiple={false}
          value={value.validType}
          onChange={handleValidTypeChange}
        >
          <Group gap="xs">
            <Chip value="thisYear">This Year</Chip>
            <Chip value="ever">Any Time</Chip>
            <Chip value="withinDays">Limited to...</Chip>
          </Group>
        </Chip.Group>
      </Stack>

      {value.validType === 'withinDays' && (
        <NumberInput
          label="Valid if within this many days"
          value={value.withinDays}
          onChange={handleWithinDaysChange}
          min={0}
        />
      )}
    </Stack>
  );
};

export default CertificationValidEditor;
