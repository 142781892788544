import { PermissionTypeEnum } from '@btrway/api-security';
import { useCallback, useMemo } from 'react';
import { usePermissionGrant } from '../providers/PermissionGrantProvider';
import { usePermissionManager } from '../providers/PermissionManagerProvider';
import { usePermissionMode } from '../providers/PermissionModeProvider';
import { AdminPermissionStrategy } from '../strategies/adminPermissionStrategy';
import { GrantPermissionStrategy } from '../strategies/grantPermissionStrategy';
import { PermissionNode } from '../types/permissionNode';

export const usePermissions = () => {
  const { mode } = usePermissionMode();
  const {
    state: {
      permissions,
      permissionsMap,
      loading,
      error,
      admin: { deletedIds },
    },
  } = usePermissionManager();

  // Try to get grant context but don't error if not available
  const grantContext = (() => {
    try {
      return usePermissionGrant();
    } catch {
      return null;
    }
  })();

  // Determine which strategy to use
  const filterStrategy = useMemo(() => {
    if (mode === 'admin' || !grantContext) {
      return new AdminPermissionStrategy(deletedIds);
    }

    return new GrantPermissionStrategy(
      grantContext.state.organizationGrants,
      mode as 'organizationGrant' | 'userRoleGrant'
    );
  }, [mode, grantContext]);

  // Apply filtering
  const filteredPermissions = useMemo(() => {
    return filterStrategy.filterPermissions(permissions);
  }, [permissions, filterStrategy]);

  // Create filtered map
  const filteredPermissionsMap = useMemo(() => {
    return new Map(filteredPermissions.map((p) => [p.clientId, p]));
  }, [filteredPermissions]);

  // Permission retrieval functions
  const getPermissionByClientId = useCallback(
    (clientId: string): PermissionNode | undefined => {
      return filteredPermissionsMap.get(clientId);
    },
    [filteredPermissionsMap]
  );

  const getPermissionsByPath = useCallback(
    (path: string): PermissionNode[] => {
      return filteredPermissions.filter((permission) =>
        permission.path.startsWith(path)
      );
    },
    [filteredPermissions]
  );

  const getRootModules = useCallback((): PermissionNode[] => {
    return filteredPermissions
      .filter(
        (p) =>
          p.permissionType === PermissionTypeEnum.module && !p.parentClientId
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [filteredPermissions]);

  const getChildPermissions = useCallback(
    (parentClientId: string): PermissionNode[] => {
      return filteredPermissions
        .filter((p) => p.parentClientId === parentClientId)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    [filteredPermissions]
  );

  const getChildPermissionsByType = useCallback(
    (
      parentClientId: string,
      permissionType: PermissionTypeEnum
    ): PermissionNode[] => {
      return filteredPermissions
        .filter(
          (p) =>
            p.parentClientId === parentClientId &&
            p.permissionType === permissionType
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    [filteredPermissions]
  );

  const getDescendants = useCallback(
    (parentClientId: string): PermissionNode[] => {
      const parent = filteredPermissionsMap.get(parentClientId);
      if (!parent) return [];

      return filteredPermissions.filter((p) =>
        p.path.startsWith(`${parent.path}.`)
      );
    },
    [filteredPermissions, filteredPermissionsMap]
  );

  const getChildCount = useCallback(
    (parentClientId: string): number => {
      return filteredPermissions.filter(
        (p) => p.parentClientId === parentClientId
      ).length;
    },
    [filteredPermissions]
  );

  return {
    // State
    permissions: filteredPermissions,
    permissionsMap: filteredPermissionsMap,
    loading,
    error,

    // Methods
    getPermissionByClientId,
    getPermissionsByPath,
    getRootModules,
    getChildPermissions,
    getChildPermissionsByType,
    getDescendants,
    getChildCount,
  };
};
