import { TaskConfig } from '@btrway/api-task';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import TaskCard from '../TaskCard/TaskCard';

interface TaskGroupProps {
  label: string;
  tasks: TaskConfig[];
  taskList: CommonTaskList;
  onTaskSelected: (taskKey: string) => void;
  onTaskDeleted: (taskKey: string) => void;
}

const TaskGroup: React.FC<TaskGroupProps> = ({
  label,
  tasks,
  taskList,
  onTaskSelected,
  onTaskDeleted,
}) => {
  const renderTaskCards = () => {
    return tasks.map((task, index) => (
      <React.Fragment key={task.taskKey}>
        <TaskCard
          taskList={taskList}
          taskConfig={task}
          index={index}
          isLast={index === tasks.length - 1}
          // onClick={() => onTaskSelected(task.taskKey)}
          //onDelete={() => onTaskDeleted(task.taskKey)}
        />
      </React.Fragment>
    ));
  };

  if (!label) {
    return <>{renderTaskCards()}</>;
  }

  return (
    <Stack gap="xs">
      <Text fw={700} fz="md">
        {label}
      </Text>
      {renderTaskCards()}
    </Stack>
  );
};

export default TaskGroup;
