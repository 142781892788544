import { PermissionTypeEnum } from '@btrway/api-security';
import {
  PermissionNode,
  usePermissionAdmin,
  usePermissionMode,
  usePermissions,
} from '@btrway/permission-manager';
import { Card, Group, Stack, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import { usePermissionDrop } from '../../hooks/usePermissionDrop';
import { AddPermissionActionIcon } from '../AddPermissionActionIcon/AddPermissionActionIcon';

interface PermissionNodesContainerProps {
  title: string;
  description: string;
  parentNode: PermissionNode;
  permissionType: PermissionTypeEnum;
  renderNode: (node: PermissionNode) => React.ReactNode;
}

export const PermissionNodesContainer: React.FC<
  PermissionNodesContainerProps
> = ({ title, description, parentNode, permissionType, renderNode }) => {
  const { mode, canAddChildren } = usePermissionMode();
  const { getChildPermissionsByType } = usePermissions();
  const { selectedApplication, addPermission, updatePermission } =
    usePermissionAdmin();

  const nodes = getChildPermissionsByType(parentNode.clientId, permissionType);

  // Add drop handling
  const { isOver, canDrop, drop } = usePermissionDrop({
    parentClientId: parentNode.clientId,
    canAcceptChild: (draggedNode: PermissionNode) => {
      const draggedType = draggedNode.permissionType;
      return (
        mode === 'admin' &&
        draggedType !== permissionType &&
        (draggedType === PermissionTypeEnum.tool ||
          draggedType === PermissionTypeEnum.setting)
      );
    },
    onDrop: (draggedNode) => {
      // Update both the permission type and parent
      const updatedNode = {
        ...draggedNode,
        permissionType,
        parentClientId: parentNode.clientId,
      };

      // Use updatePermission to persist the changes
      updatePermission(draggedNode, {
        permissionType,
        parentClientId: parentNode.clientId,
      });
    },
  });

  // Don't render empty containers in grant modes
  if (nodes.length === 0 && mode !== 'admin') {
    return null;
  }

  const handleAddPermission = () => {
    if (mode !== 'admin') return;

    addPermission({
      permissionType,
      application: selectedApplication,
      parentNode,
      code: `${parentNode.code}_`,
    });
  };

  return (
    <Card
      withBorder
      p="md"
      ref={drop}
      style={{
        backgroundColor:
          isOver && canDrop ? 'rgba(51, 154, 240, 0.1)' : undefined,
        border: isOver && canDrop ? '2px dashed rgb(51, 154, 240)' : undefined,
      }}
    >
      <Stack gap="sm">
        <Stack gap="xs">
          <Group justify="space-between">
            <Title order={4}>{title}</Title>
            {canAddChildren(parentNode) && (
              <AddPermissionActionIcon
                icon={IconPlus}
                tooltipLabel={`Add ${permissionType}`}
                permissionType={permissionType}
                onClick={handleAddPermission}
              />
            )}
          </Group>
          {description && (
            <Text size="sm" c="dimmed">
              {description}
            </Text>
          )}
        </Stack>
        <Stack gap="xs">{nodes.map((node) => renderNode(node))}</Stack>
      </Stack>
    </Card>
  );
};
