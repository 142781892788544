import { PermissionRequest, PermissionResponse } from '@btrway/api-security';
import { uuid } from '@btrway/utils';
import { PermissionNode } from '../types/permissionNode';

export const responseToNode = (
  response: PermissionResponse
): PermissionNode => {
  return {
    // Fields from response
    application: response.application,
    code: response.code,
    name: response.name,
    active: true,
    description: response.description,
    id: response.id,
    path: response.path,
    permissionAttributes: response.permissionAttributes,
    permissionType: response.permissionType,
    index: response.index,
    entityType: response.entityType,

    // Tree structure fields
    clientId: uuid(),
    level: response.path.split('.').length - 1,
    parentClientId: null, // This will be set after all nodes are created
  };
};

export const nodeToRequest = (
  node: PermissionNode,
  nodesMap: Map<string, PermissionNode>
): PermissionRequest => {
  // Find parent node to get its code
  const parentNode = node.parentClientId
    ? nodesMap.get(node.parentClientId)
    : null;

  return {
    application: node.application,
    code: node.code,
    description: node.description,
    name: node.name,
    active: node.active,
    id: node.id,
    parentCode: parentNode?.code || '', // Construct parentCode only when needed
    permissionAttributes: node.permissionAttributes,
    permissionType: node.permissionType,
    index: node.index,
    entityType: node.entityType,
  };
};

// Helper to normalize indexes within a group of nodes
export const normalizeIndexes = (nodes: PermissionNode[]): PermissionNode[] => {
  // First sort by existing index and then by name for stable ordering
  const sortedNodes = [...nodes].sort((a, b) => {
    if (a.index === b.index) {
      return a.name.localeCompare(b.name);
    }
    return a.index - b.index;
  });

  // Reassign consecutive indexes starting from 0
  return sortedNodes.map((node, idx) => ({
    ...node,
    index: idx,
  }));
};

// Add a new function to set up initial parent relationships and normalize indexes
export const setupInitialParentRelationships = (
  nodes: PermissionNode[]
): PermissionNode[] => {
  // Create a map for quick lookups
  const nodesByCode = new Map(nodes.map((node) => [node.code, node]));

  // Group nodes by parent to normalize indexes within each group
  const nodesByParent = new Map<string | null, PermissionNode[]>();

  nodes.forEach((node) => {
    const pathParts = node.path.split('.');
    let parentCode = null;

    if (pathParts.length > 1) {
      parentCode = pathParts[pathParts.length - 2];
    }

    const parentNodes = nodesByParent.get(parentCode) || [];
    parentNodes.push(node);
    nodesByParent.set(parentCode, parentNodes);
  });

  // Normalize indexes within each group and update parent relationships
  let normalizedNodes: PermissionNode[] = [];

  nodesByParent.forEach((groupNodes, parentCode) => {
    const normalizedGroupNodes = normalizeIndexes(groupNodes).map((node) => {
      if (parentCode) {
        const parent = nodesByCode.get(parentCode);
        return {
          ...node,
          parentClientId: parent?.clientId || null,
        };
      }
      return node;
    });

    normalizedNodes = [...normalizedNodes, ...normalizedGroupNodes];
  });

  return normalizedNodes;
};
