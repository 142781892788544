import { TaskTypeEnum } from '@btrway/api-task';
import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { useCallback, useState } from 'react';
import {
  getTaskListType,
  isTaskListSelectorType,
  isWorkflowSelectorType,
} from '../utils/taskCreatorUtils';

export interface TaskCreatorStepperState {
  activeStep: number;
  selectedTaskType: TaskTypeEnum | null;
}

export const useTaskCreatorStepper = () => {
  const [state, setState] = useState<TaskCreatorStepperState>({
    activeStep: 0,
    selectedTaskType: null,
  });

  const setActiveStep = useCallback((step: number) => {
    setState((prev) => ({ ...prev, activeStep: step }));
  }, []);

  const setSelectedTaskType = useCallback((type: TaskTypeEnum | null) => {
    setState((prev) => ({ ...prev, selectedTaskType: type }));
  }, []);

  const formatTaskListType = (type: keyof typeof TaskListTypeEnum): string => {
    const formatMap: Record<keyof typeof TaskListTypeEnum, string> = {
      curriculum: 'Curriculum',
      formPacket: 'Form Packet',
      todoList: 'To-Do List',
      certification: 'Certification',
    };
    return formatMap[type] || type;
  };

  const getStepTitle = (): string => {
    const { activeStep, selectedTaskType } = state;

    switch (activeStep) {
      case 0:
        return 'Select Task Type';
      case 1:
        if (!selectedTaskType) return 'Configure Task';
        if (selectedTaskType === 'completeCourse') return 'Select Course';
        if (isWorkflowSelectorType(selectedTaskType)) return 'Select Form';
        if (isTaskListSelectorType(selectedTaskType)) {
          const taskListType = getTaskListType(selectedTaskType);
          return `Select ${formatTaskListType(
            taskListType as TaskListTypeEnum
          )}`;
        }
        return 'Configure Task';
      case 2:
        if (!selectedTaskType) return '';
        return selectedTaskType === 'completeCurriculum'
          ? 'Select Courses'
          : 'Select Forms';
      default:
        return '';
    }
  };

  const handleBack = useCallback(() => {
    setState((prev) => ({ ...prev, activeStep: prev.activeStep - 1 }));
  }, []);

  return {
    ...state,
    setActiveStep,
    setSelectedTaskType,
    getStepTitle,
    handleBack,
  };
};
