import { TaskConfig } from '@btrway/api-tasklist';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Box, Drawer } from '@mantine/core';
import React from 'react';
import TaskCreator from '../TaskCreator/TaskCreator';

export interface TaskCreatorDrawerProps {
  opened: boolean;
  onClose: () => void;
  taskList?: CommonTaskList | null;
  taskContainerKey?: string;
  onTaskAdded: (task: TaskConfig) => void;
}

export const TaskCreatorDrawer: React.FC<TaskCreatorDrawerProps> = ({
  opened,
  onClose,
  taskList,
  taskContainerKey,
  onTaskAdded,
}) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="left"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginLeft: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <Box style={{ height: '100%' }}>
        {taskList && (
          <TaskCreator
            taskList={taskList}
            taskContainerKey={taskContainerKey}
            onComplete={(task) => {
              onTaskAdded(task);
              onClose();
            }}
            onCancel={onClose}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default TaskCreatorDrawer;
