import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  LeftNavigationLayout,
  TopNavigationLayout,
} from '@btrway/layout-components';
import { TaskListServiceProvider } from '@btrway/task-list-service-provider';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowServiceProvider } from '@btrway/workflow-service-provider';
import {
  IconAlignBoxLeftTop,
  IconAward,
  IconBuilding,
  IconCalendarCheck,
  IconCalendarTime,
  IconDeviceTv,
  IconFileStack,
  IconHome,
  IconListCheck,
  IconSettings,
  IconUserCheck,
  IconUsers,
  IconWand,
} from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded components
const OrganizationalSettingsTab = lazy(() =>
  import('@btrway/settings-components').then((module) => ({
    default: module.OrganizationalSettingsTab,
  }))
);
const YearsView = lazy(() =>
  import('@btrway/settings-components').then((module) => ({
    default: module.YearsView,
  }))
);
const EventTypesPage = lazy(() =>
  import('@btrway/event-type-admin').then((module) => ({
    default: module.EventTypesPage,
  }))
);
const RolesPage = lazy(() =>
  import('@btrway/security-administration').then((module) => ({
    default: module.RolesPage,
  }))
);

const WorkflowTaskListDashboard = lazy(() =>
  import('@btrway/workflow-tasklist-dashboard').then((module) => ({
    default: module.WorkflowTaskListDashboard,
  }))
);

const WorkflowsPage = lazy(() =>
  import('@btrway/workflow-administration').then((module) => ({
    default: module.WorkflowAdministrationView,
  }))
);

const TaskListsPage = lazy(() =>
  import('@btrway/task-list-administration').then((module) => ({
    default: module.TaskListAdministrationView,
  }))
);

const TaskListAssignmentsView = lazy(() =>
  import('@btrway/task-assignment-administration').then((module) => ({
    default: module.TaskListAssignmentsView,
  }))
);

const WorkflowsPageByType: React.FC<{
  workflowType: WorkflowTypeEnum;
}> = ({ workflowType }) => {
  return <WorkflowsPage workflowType={workflowType} />;
};

const TaskListsPageByType: React.FC<{
  taskListType: TaskListTypeEnum;
}> = ({ taskListType }) => {
  return <TaskListsPage taskListType={taskListType} />;
};

export const settingsRoutes: RouteConfig[] = [
  {
    path: 'settings',
    element: <TopNavigationLayout contentPadding={0} />,
    label: 'Settings',
    icon: IconSettings,
    navLevel: 'primary',
    navContainer: 'bottom',
    // permissions: ['SETTINGS'],
    children: [
      {
        path: 'organization',
        element: <LeftNavigationLayout displayHeader={false} />,
        label: 'Organization',
        navLevel: 'secondary',
        children: [
          {
            path: 'organizational',
            element: <OrganizationalSettingsTab />,
            label: 'Organization',
            icon: IconBuilding,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'event-types',
            element: <EventTypesPage />,
            label: 'Event Types',
            icon: IconCalendarCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'years',
            element: <YearsView />,
            label: 'Years',
            icon: IconCalendarTime,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
      {
        path: 'workflow',
        element: (
          <WorkflowServiceProvider>
            <TaskListServiceProvider>
              <LeftNavigationLayout displayHeader={false} />
            </TaskListServiceProvider>
          </WorkflowServiceProvider>
        ),
        label: 'Workflow and Task Lists',
        navLevel: 'secondary',
        children: [
          {
            path: 'overview',
            element: <WorkflowTaskListDashboard />,
            label: 'Overview',
            icon: IconHome,
            displayType: 'icon',
            navLevel: 'secondary',
          },
          {
            path: 'forms',
            element: <WorkflowsPageByType workflowType="form" />,
            label: 'Forms',
            icon: IconAlignBoxLeftTop,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'form-packets',
            element: <TaskListsPageByType taskListType="formPacket" />,
            label: 'Form Packets',
            icon: IconFileStack,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'todo-lists',
            element: <TaskListsPageByType taskListType="todoList" />,
            label: 'To-Do Lists',
            icon: IconListCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'certifications',
            element: <TaskListsPageByType taskListType="certification" />,
            label: 'Certifications',
            navLevel: 'secondary',
            icon: IconAward,
            displayType: 'label',
          },
          {
            path: 'curriculums',
            element: <TaskListsPageByType taskListType="curriculum" />,
            label: 'Curriculums',
            navLevel: 'secondary',
            icon: IconDeviceTv,
            displayType: 'label',
          },
          {
            path: 'automations',
            element: <WorkflowsPageByType workflowType="automation" />,
            label: 'Automations',
            navLevel: 'secondary',
            icon: IconWand,
            displayType: 'label',
          },
          {
            path: 'task-assignments',
            element: <TaskListAssignmentsView />,
            label: 'Task Assignments',
            icon: IconUserCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
      {
        path: 'security',
        element: <LeftNavigationLayout displayHeader={false} />,
        label: 'Security',
        navLevel: 'secondary',
        children: [
          {
            path: 'roles',
            element: <RolesPage />,
            label: 'Roles',
            icon: IconUsers,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
    ],
  },
];
