import { Box, Drawer } from '@mantine/core';
import React from 'react';
import { TaskListTasksEditor } from '../TaskListTasksEditor/TaskListTasksEditor';

export interface TaskListTasksEditorDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const TaskListTasksEditorDrawer: React.FC<
  TaskListTasksEditorDrawerProps
> = ({ opened, onClose }) => {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position="right"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginRight: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <Box style={{ height: '100%' }}>
        <TaskListTasksEditor onClose={onClose} />
      </Box>
    </Drawer>
  );
};

export default TaskListTasksEditorDrawer;
