import { useSavePermissions } from '@btrway/api-security';
import { useCallback, useState } from 'react';
import { usePermissionManager } from '../providers/PermissionManagerProvider';
import { PermissionNode } from '../types/permissionNode';
import { ModificationError } from '../types/state';
import { createNode, CreateNodeParams } from '../utils/createNode';
import { nodeToRequest } from '../utils/nodeUtils';

export const usePermissionAdmin = () => {
  const {
    state: {
      permissionsMap,
      admin: { pendingChanges, deletedIds, selectedApplication },
    },
    actions: { setPermissions, setPendingChanges, setDeletedIds },
  } = usePermissionManager();

  const { mutateAsync: savePermissions } = useSavePermissions();
  const [errors, setErrors] = useState<ModificationError[]>([]);

  const validateNode = useCallback(
    (
      node: PermissionNode,
      existingNodes: PermissionNode[],
      isNewNode: boolean = false
    ): ModificationError[] => {
      const validationErrors: ModificationError[] = [];

      if (!isNewNode) {
        if (!node.code) {
          validationErrors.push({
            type: 'validation',
            message: 'Permission code is required',
          });
        }

        if (!node.name) {
          validationErrors.push({
            type: 'validation',
            message: 'Name is required',
          });
        }
      }

      if (node.code) {
        const codeExists = existingNodes.some(
          (existingNode) =>
            existingNode.code === node.code &&
            existingNode.clientId !== node.clientId &&
            existingNode.application === node.application
        );

        if (codeExists) {
          validationErrors.push({
            type: 'validation',
            message: `Permission code '${node.code}' already exists`,
          });
        }
      }

      return validationErrors;
    },
    []
  );

  const addPermission = useCallback(
    (params: CreateNodeParams) => {
      const newNode = createNode({
        ...params,
        application: selectedApplication,
      });

      if (newNode.code) {
        const validationErrors = validateNode(
          newNode,
          Array.from(permissionsMap.values()),
          true
        );

        if (validationErrors.length > 0) {
          setErrors(validationErrors);
          return { success: false };
        }
      }

      setPermissions((prevPermissions) => [...prevPermissions, newNode]);
      setPendingChanges(new Map(pendingChanges).set(newNode.clientId, newNode));

      return { success: true, clientId: newNode.clientId };
    },
    [
      permissionsMap,
      setPermissions,
      pendingChanges,
      setPendingChanges,
      selectedApplication,
    ]
  );

  const updatePermission = useCallback(
    (node: PermissionNode, updates: Partial<PermissionNode>) => {
      const updatedNode = { ...node, ...updates };

      console.log('updatedNode', updatedNode);
      if (updatedNode.code !== node.code && updatedNode.code) {
        const validationErrors = validateNode(
          updatedNode,
          Array.from(permissionsMap.values())
        );

        if (validationErrors.length > 0) {
          setErrors(validationErrors);
          return false;
        }
      }

      if ('parentClientId' in updates) {
        const newParent = updates.parentClientId
          ? permissionsMap.get(updates.parentClientId)
          : null;
        updatedNode.level = newParent ? newParent.level + 1 : 0;
      }

      setPermissions((prevPermissions) =>
        prevPermissions.map((p) =>
          p.clientId === node.clientId ? updatedNode : p
        )
      );

      setPendingChanges(
        new Map(pendingChanges).set(node.clientId, updatedNode)
      );

      return true;
    },
    [
      permissionsMap,
      setPermissions,
      setPendingChanges,
      pendingChanges,
      validateNode,
    ]
  );

  const removePermission = useCallback(
    (clientId: string) => {
      setPermissions((prevPermissions) =>
        prevPermissions.filter((p) => p.clientId !== clientId)
      );

      const newPendingChanges = new Map(pendingChanges);
      newPendingChanges.delete(clientId);
      setPendingChanges(newPendingChanges);
    },
    [pendingChanges, setPermissions, setPendingChanges]
  );

  const markForDeletion = useCallback(
    (clientId: string) => {
      const node = permissionsMap.get(clientId);
      if (!node) return false;

      // No longer need to check for children since backend handles updating their parent codes
      const nodeWithUpdates = { ...node, active: false };

      // Add to pending changes to be saved with active=false
      setPendingChanges(new Map(pendingChanges).set(clientId, nodeWithUpdates));

      // Still track in deletedIds for UI filtering purposes
      setDeletedIds(new Set(deletedIds).add(clientId));
      return true;
    },
    [
      permissionsMap,
      pendingChanges,
      deletedIds,
      setPendingChanges,
      setDeletedIds,
    ]
  );

  const saveChanges = useCallback(async () => {
    setErrors([]);
    try {
      // Get all nodes that need to be saved (both updates and deletions)
      const nodesToSave = new Map(pendingChanges);

      // Add deleted nodes to the save request with active=false
      for (const deletedId of deletedIds) {
        const node = permissionsMap.get(deletedId);
        if (node && !nodesToSave.has(deletedId)) {
          nodesToSave.set(deletedId, { ...node, active: false });
        }
      }

      // Convert all nodes to requests
      const permissionRequests = Array.from(nodesToSave.values()).map(
        (node) => {
          const request = nodeToRequest(node, permissionsMap);
          // Ensure active status is set correctly
          if (deletedIds.has(node.clientId)) {
            request.active = false;
          }
          return request;
        }
      );

      // Save everything in a single request
      if (permissionRequests.length > 0) {
        await savePermissions({ data: permissionRequests });
      }

      // Remove deleted permissions from state and reset tracking maps
      setPermissions((prevPermissions) =>
        prevPermissions.filter((p) => !deletedIds.has(p.clientId))
      );
      setPendingChanges(new Map());
      setDeletedIds(new Set());
      return true;
    } catch (error) {
      setErrors([
        {
          type: 'save',
          message:
            error instanceof Error ? error.message : 'Failed to save changes',
        },
      ]);
      return false;
    }
  }, [
    pendingChanges,
    deletedIds,
    permissionsMap,
    savePermissions,
    setPendingChanges,
    setDeletedIds,
  ]);

  const discardChanges = useCallback(() => {
    setPendingChanges(new Map());
    setDeletedIds(new Set());
    setErrors([]);
  }, [setPendingChanges, setDeletedIds]);

  const clearErrors = useCallback(() => {
    setErrors([]);
  }, []);

  return {
    // Actions
    addPermission,
    updatePermission,
    removePermission,
    markForDeletion,
    saveChanges,
    discardChanges,
    clearErrors,

    setPermissions,
    setPendingChanges,

    // State
    hasPendingChanges: pendingChanges.size > 0 || deletedIds.size > 0,
    pendingChanges,
    deletedIds,
    errors,
    selectedApplication,
  };
};
