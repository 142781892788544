import { CommonTaskList } from '@btrway/task-list-service-provider';
import { Container, ScrollArea } from '@mantine/core';
import React from 'react';
import TaskListView from '../TaskListView/TaskListView';

interface TaskListBuilderProps {
  taskList: CommonTaskList;
}

const TaskListBuilder: React.FC<TaskListBuilderProps> = ({ taskList }) => {
  console.log('TaskListBuilder', taskList);
  return (
    <ScrollArea h="100%" w="100%">
      <Container size="md" px="md" style={{ maxWidth: 800 }}>
        <TaskListView taskList={taskList} />
      </Container>
    </ScrollArea>
  );
};

export default TaskListBuilder;
