/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ExternalCourseDefinitionRequest,
  ExternalCourseDefinitionResponse,
  ExternalCourseTemplateRequest,
  ExternalCourseTemplateResponse,
  ProviderRequest,
  ProviderResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getProviders = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ProviderResponse[]>(
      {url: `/api/external-provider/providers`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProvidersQueryKey = () => {
    return [`/api/external-provider/providers`] as const;
    }

    
export const getGetProvidersQueryOptions = <TData = Awaited<ReturnType<typeof getProviders>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviders>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProvidersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProviders>>> = ({ signal }) => getProviders(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProviders>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProvidersQueryResult = NonNullable<Awaited<ReturnType<typeof getProviders>>>
export type GetProvidersQueryError = unknown



export function useGetProviders<TData = Awaited<ReturnType<typeof getProviders>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviders>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProvidersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveProvider = (
    providerRequest: ProviderRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/external-provider/providers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: providerRequest
    },
      options);
    }
  


export const getSaveProviderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveProvider>>, TError,{data: ProviderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveProvider>>, TError,{data: ProviderRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveProvider>>, {data: ProviderRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveProvider(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveProviderMutationResult = NonNullable<Awaited<ReturnType<typeof saveProvider>>>
    export type SaveProviderMutationBody = ProviderRequest
    export type SaveProviderMutationError = unknown

    export const useSaveProvider = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveProvider>>, TError,{data: ProviderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveProvider>>,
        TError,
        {data: ProviderRequest},
        TContext
      > => {

      const mutationOptions = getSaveProviderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalCourseTemplates = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseTemplateResponse[]>(
      {url: `/api/external-provider/external-course-templates`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseTemplatesQueryKey = () => {
    return [`/api/external-provider/external-course-templates`] as const;
    }

    
export const getGetExternalCourseTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseTemplates>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseTemplates>>> = ({ signal }) => getExternalCourseTemplates(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseTemplates>>>
export type GetExternalCourseTemplatesQueryError = unknown



export function useGetExternalCourseTemplates<TData = Awaited<ReturnType<typeof getExternalCourseTemplates>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveExternalCourseTemplate = (
    externalCourseTemplateRequest: ExternalCourseTemplateRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/external-provider/external-course-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: externalCourseTemplateRequest
    },
      options);
    }
  


export const getSaveExternalCourseTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseTemplate>>, TError,{data: ExternalCourseTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseTemplate>>, TError,{data: ExternalCourseTemplateRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveExternalCourseTemplate>>, {data: ExternalCourseTemplateRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveExternalCourseTemplate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveExternalCourseTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof saveExternalCourseTemplate>>>
    export type SaveExternalCourseTemplateMutationBody = ExternalCourseTemplateRequest
    export type SaveExternalCourseTemplateMutationError = unknown

    export const useSaveExternalCourseTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseTemplate>>, TError,{data: ExternalCourseTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveExternalCourseTemplate>>,
        TError,
        {data: ExternalCourseTemplateRequest},
        TContext
      > => {

      const mutationOptions = getSaveExternalCourseTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalCourseDefinitions = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseDefinitionResponse[]>(
      {url: `/api/external-provider/external-course-definitions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseDefinitionsQueryKey = () => {
    return [`/api/external-provider/external-course-definitions`] as const;
    }

    
export const getGetExternalCourseDefinitionsQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseDefinitions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseDefinitionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseDefinitions>>> = ({ signal }) => getExternalCourseDefinitions(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseDefinitions>>>
export type GetExternalCourseDefinitionsQueryError = unknown



export function useGetExternalCourseDefinitions<TData = Awaited<ReturnType<typeof getExternalCourseDefinitions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseDefinitionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveExternalCourseDefinition = (
    externalCourseDefinitionRequest: ExternalCourseDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/external-provider/external-course-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: externalCourseDefinitionRequest
    },
      options);
    }
  


export const getSaveExternalCourseDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseDefinition>>, TError,{data: ExternalCourseDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseDefinition>>, TError,{data: ExternalCourseDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveExternalCourseDefinition>>, {data: ExternalCourseDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveExternalCourseDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveExternalCourseDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveExternalCourseDefinition>>>
    export type SaveExternalCourseDefinitionMutationBody = ExternalCourseDefinitionRequest
    export type SaveExternalCourseDefinitionMutationError = unknown

    export const useSaveExternalCourseDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalCourseDefinition>>, TError,{data: ExternalCourseDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveExternalCourseDefinition>>,
        TError,
        {data: ExternalCourseDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveExternalCourseDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getProviderById = (
    providerId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ProviderResponse>(
      {url: `/api/external-provider/providers/${providerId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProviderByIdQueryKey = (providerId: number,) => {
    return [`/api/external-provider/providers/${providerId}`] as const;
    }

    
export const getGetProviderByIdQueryOptions = <TData = Awaited<ReturnType<typeof getProviderById>>, TError = unknown>(providerId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviderById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProviderByIdQueryKey(providerId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProviderById>>> = ({ signal }) => getProviderById(providerId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(providerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProviderById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetProviderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProviderById>>>
export type GetProviderByIdQueryError = unknown



export function useGetProviderById<TData = Awaited<ReturnType<typeof getProviderById>>, TError = unknown>(
 providerId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviderById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetProviderByIdQueryOptions(providerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteProvider = (
    providerId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/external-provider/providers/${providerId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProviderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProvider>>, TError,{providerId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProvider>>, TError,{providerId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProvider>>, {providerId: number}> = (props) => {
          const {providerId} = props ?? {};

          return  deleteProvider(providerId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteProviderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProvider>>>
    
    export type DeleteProviderMutationError = unknown

    export const useDeleteProvider = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProvider>>, TError,{providerId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteProvider>>,
        TError,
        {providerId: number},
        TContext
      > => {

      const mutationOptions = getDeleteProviderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalCourseTemplateById = (
    externalCourseTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseTemplateResponse>(
      {url: `/api/external-provider/external-course-templates/${externalCourseTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseTemplateByIdQueryKey = (externalCourseTemplateId: number,) => {
    return [`/api/external-provider/external-course-templates/${externalCourseTemplateId}`] as const;
    }

    
export const getGetExternalCourseTemplateByIdQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseTemplateById>>, TError = unknown>(externalCourseTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseTemplateByIdQueryKey(externalCourseTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseTemplateById>>> = ({ signal }) => getExternalCourseTemplateById(externalCourseTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(externalCourseTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseTemplateById>>>
export type GetExternalCourseTemplateByIdQueryError = unknown



export function useGetExternalCourseTemplateById<TData = Awaited<ReturnType<typeof getExternalCourseTemplateById>>, TError = unknown>(
 externalCourseTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseTemplateByIdQueryOptions(externalCourseTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteExternalCourseTemplate = (
    externalCourseTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/external-provider/external-course-templates/${externalCourseTemplateId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteExternalCourseTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseTemplate>>, TError,{externalCourseTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseTemplate>>, TError,{externalCourseTemplateId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExternalCourseTemplate>>, {externalCourseTemplateId: number}> = (props) => {
          const {externalCourseTemplateId} = props ?? {};

          return  deleteExternalCourseTemplate(externalCourseTemplateId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteExternalCourseTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExternalCourseTemplate>>>
    
    export type DeleteExternalCourseTemplateMutationError = unknown

    export const useDeleteExternalCourseTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseTemplate>>, TError,{externalCourseTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteExternalCourseTemplate>>,
        TError,
        {externalCourseTemplateId: number},
        TContext
      > => {

      const mutationOptions = getDeleteExternalCourseTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalCourseTemplateByKey = (
    courseKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseTemplateResponse>(
      {url: `/api/external-provider/external-course-templates/key/${courseKey}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseTemplateByKeyQueryKey = (courseKey: string,) => {
    return [`/api/external-provider/external-course-templates/key/${courseKey}`] as const;
    }

    
export const getGetExternalCourseTemplateByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>, TError = unknown>(courseKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseTemplateByKeyQueryKey(courseKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>> = ({ signal }) => getExternalCourseTemplateByKey(courseKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(courseKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseTemplateByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>>
export type GetExternalCourseTemplateByKeyQueryError = unknown



export function useGetExternalCourseTemplateByKey<TData = Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>, TError = unknown>(
 courseKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseTemplateByKeyQueryOptions(courseKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getExternalCourseDefinitionById = (
    externalCourseDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseDefinitionResponse>(
      {url: `/api/external-provider/external-course-definitions/${externalCourseDefinitionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseDefinitionByIdQueryKey = (externalCourseDefinitionId: number,) => {
    return [`/api/external-provider/external-course-definitions/${externalCourseDefinitionId}`] as const;
    }

    
export const getGetExternalCourseDefinitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseDefinitionById>>, TError = unknown>(externalCourseDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseDefinitionByIdQueryKey(externalCourseDefinitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseDefinitionById>>> = ({ signal }) => getExternalCourseDefinitionById(externalCourseDefinitionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(externalCourseDefinitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseDefinitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseDefinitionById>>>
export type GetExternalCourseDefinitionByIdQueryError = unknown



export function useGetExternalCourseDefinitionById<TData = Awaited<ReturnType<typeof getExternalCourseDefinitionById>>, TError = unknown>(
 externalCourseDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseDefinitionByIdQueryOptions(externalCourseDefinitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteExternalCourseDefinition = (
    externalCourseDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/external-provider/external-course-definitions/${externalCourseDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteExternalCourseDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseDefinition>>, TError,{externalCourseDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseDefinition>>, TError,{externalCourseDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExternalCourseDefinition>>, {externalCourseDefinitionId: number}> = (props) => {
          const {externalCourseDefinitionId} = props ?? {};

          return  deleteExternalCourseDefinition(externalCourseDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteExternalCourseDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExternalCourseDefinition>>>
    
    export type DeleteExternalCourseDefinitionMutationError = unknown

    export const useDeleteExternalCourseDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalCourseDefinition>>, TError,{externalCourseDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteExternalCourseDefinition>>,
        TError,
        {externalCourseDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteExternalCourseDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalCourseDefinitionByKey = (
    courseKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalCourseDefinitionResponse>(
      {url: `/api/external-provider/external-course-definitions/key/${courseKey}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalCourseDefinitionByKeyQueryKey = (courseKey: string,) => {
    return [`/api/external-provider/external-course-definitions/key/${courseKey}`] as const;
    }

    
export const getGetExternalCourseDefinitionByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>, TError = unknown>(courseKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalCourseDefinitionByKeyQueryKey(courseKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>> = ({ signal }) => getExternalCourseDefinitionByKey(courseKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(courseKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalCourseDefinitionByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>>
export type GetExternalCourseDefinitionByKeyQueryError = unknown



export function useGetExternalCourseDefinitionByKey<TData = Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>, TError = unknown>(
 courseKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalCourseDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalCourseDefinitionByKeyQueryOptions(courseKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




