/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetPermissionGrantsParams,
  GetPermissionsParams,
  PermissionGrantRequest,
  PermissionGrantResponse,
  PermissionRequest,
  PermissionResponse,
  UserRoleRequest,
  UserRoleResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveUserRole = (
    userRoleRequest: UserRoleRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/security/user-roles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userRoleRequest
    },
      options);
    }
  


export const getSaveUserRoleMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUserRole>>, {data: UserRoleRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveUserRole(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof saveUserRole>>>
    export type SaveUserRoleMutationBody = UserRoleRequest
    export type SaveUserRoleMutationError = unknown

    export const useSaveUserRole = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveUserRole>>,
        TError,
        {data: UserRoleRequest},
        TContext
      > => {

      const mutationOptions = getSaveUserRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savePermissions = (
    permissionRequest: PermissionRequest[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/security/permissions/save`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: permissionRequest
    },
      options);
    }
  


export const getSavePermissionsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePermissions>>, TError,{data: PermissionRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof savePermissions>>, TError,{data: PermissionRequest[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePermissions>>, {data: PermissionRequest[]}> = (props) => {
          const {data} = props ?? {};

          return  savePermissions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePermissionsMutationResult = NonNullable<Awaited<ReturnType<typeof savePermissions>>>
    export type SavePermissionsMutationBody = PermissionRequest[]
    export type SavePermissionsMutationError = unknown

    export const useSavePermissions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePermissions>>, TError,{data: PermissionRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof savePermissions>>,
        TError,
        {data: PermissionRequest[]},
        TContext
      > => {

      const mutationOptions = getSavePermissionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savePermissionGrants = (
    permissionGrantRequest: PermissionGrantRequest[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/security/permission-grants`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: permissionGrantRequest
    },
      options);
    }
  


export const getSavePermissionGrantsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePermissionGrants>>, TError,{data: PermissionGrantRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof savePermissionGrants>>, TError,{data: PermissionGrantRequest[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePermissionGrants>>, {data: PermissionGrantRequest[]}> = (props) => {
          const {data} = props ?? {};

          return  savePermissionGrants(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePermissionGrantsMutationResult = NonNullable<Awaited<ReturnType<typeof savePermissionGrants>>>
    export type SavePermissionGrantsMutationBody = PermissionGrantRequest[]
    export type SavePermissionGrantsMutationError = unknown

    export const useSavePermissionGrants = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePermissionGrants>>, TError,{data: PermissionGrantRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof savePermissionGrants>>,
        TError,
        {data: PermissionGrantRequest[]},
        TContext
      > => {

      const mutationOptions = getSavePermissionGrantsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getUserRoleById = (
    userRoleId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRoleResponse>(
      {url: `/api/security/user-roles/${userRoleId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRoleByIdQueryKey = (userRoleId: number,) => {
    return [`/api/security/user-roles/${userRoleId}`] as const;
    }

    
export const getGetUserRoleByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserRoleById>>, TError = unknown>(userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRoleByIdQueryKey(userRoleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRoleById>>> = ({ signal }) => getUserRoleById(userRoleId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userRoleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRoleByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRoleById>>>
export type GetUserRoleByIdQueryError = unknown



export function useGetUserRoleById<TData = Awaited<ReturnType<typeof getUserRoleById>>, TError = unknown>(
 userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRoleByIdQueryOptions(userRoleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteUserRole = (
    userRoleId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/security/user-roles/${userRoleId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteUserRoleMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserRole>>, {userRoleId: number}> = (props) => {
          const {userRoleId} = props ?? {};

          return  deleteUserRole(userRoleId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserRole>>>
    
    export type DeleteUserRoleMutationError = unknown

    export const useDeleteUserRole = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserRole>>,
        TError,
        {userRoleId: number},
        TContext
      > => {

      const mutationOptions = getDeleteUserRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getUserRolesByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRoleResponse[]>(
      {url: `/api/security/user-roles/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRolesByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/security/user-roles/organizations/${organizationId}`] as const;
    }

    
export const getGetUserRolesByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRolesByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>> = ({ signal }) => getUserRolesByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRolesByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>>
export type GetUserRolesByOrganizationIdQueryError = unknown



export function useGetUserRolesByOrganizationId<TData = Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRolesByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPermissions = (
    params: GetPermissionsParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PermissionResponse[]>(
      {url: `/api/security/permissions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetPermissionsQueryKey = (params: GetPermissionsParams,) => {
    return [`/api/security/permissions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof getPermissions>>, TError = unknown>(params: GetPermissionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPermissionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({ signal }) => getPermissions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissions>>>
export type GetPermissionsQueryError = unknown



export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = unknown>(
 params: GetPermissionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPermissionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPermissionGrants = (
    organizationId: number,
    params?: GetPermissionGrantsParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PermissionGrantResponse[]>(
      {url: `/api/security/permission-grants/organizations/${organizationId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetPermissionGrantsQueryKey = (organizationId: number,
    params?: GetPermissionGrantsParams,) => {
    return [`/api/security/permission-grants/organizations/${organizationId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPermissionGrantsQueryOptions = <TData = Awaited<ReturnType<typeof getPermissionGrants>>, TError = unknown>(organizationId: number,
    params?: GetPermissionGrantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissionGrants>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPermissionGrantsQueryKey(organizationId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissionGrants>>> = ({ signal }) => getPermissionGrants(organizationId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPermissionGrants>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPermissionGrantsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissionGrants>>>
export type GetPermissionGrantsQueryError = unknown



export function useGetPermissionGrants<TData = Awaited<ReturnType<typeof getPermissionGrants>>, TError = unknown>(
 organizationId: number,
    params?: GetPermissionGrantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissionGrants>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPermissionGrantsQueryOptions(organizationId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




