import { HelpRepositoryEnum, HelpResourceResponse } from '@btrway/api-help';
import { TaskConfig } from '@btrway/api-task';
import {
  HelpResourceEditorModal,
  useHelpResourceEditor,
} from '@btrway/help-admin-components';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import {
  Badge,
  Box,
  Button,
  Group,
  Loader,
  Stack,
  Tabs,
  Text,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { repositoryTabs } from '../../config/tabs';
import { useResourceFiltering } from '../../hooks/useResourceFiltering';
import { ResourceCard } from '../ResourceCard/ResourceCard';
import { SearchBar } from '../SearchBar/SearchBar';
interface ResourcesPanelProps {
  workflowKey: string;
  taskConfig: TaskConfig;
  allHelpResources: HelpResourceResponse[];
  isLoadingActive: boolean;
  initialSelectedResources: string[];
  onUpdateResources: (resourceKeys: string[]) => Promise<void>;
  onRefetchResources: () => Promise<void>;
}

export const ResourcesPanel: React.FC<ResourcesPanelProps> = React.memo(
  ({
    workflowKey,
    taskConfig,
    allHelpResources,
    isLoadingActive,
    initialSelectedResources,
    onUpdateResources,
    onRefetchResources,
  }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedResources, setSelectedResources] = useState<Set<string>>(
      new Set(initialSelectedResources)
    );
    const [isSaving, setIsSaving] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('');
    const initialTabSet = useRef(false);
    const previousTaskKey = useRef(taskConfig.taskKey);

    const { closeDisplay } = useSettingsDisplay();
    const { openHelpResourceEditor } = useHelpResourceEditor();

    // Update selected resources when taskConfig changes
    useEffect(() => {
      if (previousTaskKey.current !== taskConfig.taskKey) {
        setSelectedResources(new Set(initialSelectedResources));
        initialTabSet.current = false; // Reset initial tab selection
        previousTaskKey.current = taskConfig.taskKey;
      }
    }, [taskConfig.taskKey, initialSelectedResources]);

    // Get selected resources by repository
    const getSelectedCountByRepo = useCallback(
      (repo: HelpRepositoryEnum) => {
        return allHelpResources.filter(
          (resource) =>
            selectedResources.has(resource.helpResourceKey) &&
            resource.repository === repo
        ).length;
      },
      [allHelpResources, selectedResources]
    );

    // Set initial active tab once when resources are loaded
    useEffect(() => {
      if (!initialTabSet.current && allHelpResources.length > 0) {
        const tabWithSelections = repositoryTabs
          .filter((tab) => tab.value !== 'selected')
          .find(
            (tab) => getSelectedCountByRepo(tab.value as HelpRepositoryEnum) > 0
          );

        if (tabWithSelections) {
          setActiveTab(tabWithSelections.value);
        } else {
          const firstTab = repositoryTabs.find(
            (tab) => tab.value !== 'selected'
          );
          if (firstTab) {
            setActiveTab(firstTab.value);
          }
        }
        initialTabSet.current = true;
      }
    }, [allHelpResources, getSelectedCountByRepo]);

    const handleUpdateResources = useCallback(async () => {
      setIsSaving(true);
      try {
        await onUpdateResources(Array.from(selectedResources));
        closeDisplay();
      } catch (error) {
        console.error('Error updating help resources:', error);
      } finally {
        setIsSaving(false);
      }
    }, [selectedResources, onUpdateResources, closeDisplay]);

    const handleTabChange = (value: string | null) => {
      if (value) {
        setActiveTab(value);
      }
    };

    const handleResourceSelection = useCallback(
      (resourceKey: string, selected: boolean) => {
        setSelectedResources((prev) => {
          const next = new Set(prev);
          if (selected) {
            next.add(resourceKey);
          } else {
            next.delete(resourceKey);
          }
          return next;
        });
      },
      []
    );

    const handleCreateResource = useCallback(
      (repository: HelpRepositoryEnum) => {
        openHelpResourceEditor(null, repository, async () => {
          await onRefetchResources();
          const newResource = allHelpResources[allHelpResources.length - 1];
          if (newResource) {
            handleResourceSelection(newResource.helpResourceKey, true);
          }
        });
      },
      [
        openHelpResourceEditor,
        onRefetchResources,
        allHelpResources,
        handleResourceSelection,
      ]
    );

    const filteredResources = useResourceFiltering(
      allHelpResources,
      searchQuery,
      selectedResources
    );

    const renderResourceList = useCallback(
      (resources: HelpResourceResponse[] | undefined, tabValue: string) => {
        if (isLoadingActive) {
          return (
            <Box py="xl" ta="center">
              <Loader />
            </Box>
          );
        }

        const showAddButton = Object.values(HelpRepositoryEnum).includes(
          tabValue as HelpRepositoryEnum
        );

        return (
          <Stack gap="md">
            {showAddButton && (
              <Button
                leftSection={<IconPlus size={16} />}
                onClick={() =>
                  handleCreateResource(tabValue as HelpRepositoryEnum)
                }
                variant="light"
              >
                Add New Resource
              </Button>
            )}
            {!resources?.length ? (
              <Text c="dimmed" ta="center" py="xl">
                No resources found matching your search
              </Text>
            ) : (
              resources.map((resource) => (
                <ResourceCard
                  key={resource.helpResourceKey}
                  resource={resource}
                  isSelected={selectedResources.has(resource.helpResourceKey)}
                  onSelectionChange={handleResourceSelection}
                />
              ))
            )}
          </Stack>
        );
      },
      [
        isLoadingActive,
        selectedResources,
        handleResourceSelection,
        handleCreateResource,
      ]
    );

    return (
      <>
        <Stack gap={0} style={{ height: '100%', position: 'relative' }}>
          <Box style={{ flex: 1, overflow: 'auto' }}>
            <Stack gap="md" p="md">
              <SearchBar
                searchQuery={searchQuery}
                onSearchChange={setSearchQuery}
              />

              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                styles={{
                  tab: {
                    flex: 1,
                    padding: '12px',
                  },
                  list: {
                    gap: 0,
                    borderBottom: '1px solid var(--mantine-color-gray-3)',
                  },
                }}
              >
                <Tabs.List>
                  {repositoryTabs
                    .filter((tab) => tab.value !== 'selected')
                    .map((tab) => {
                      const selectedCount = getSelectedCountByRepo(
                        tab.value as HelpRepositoryEnum
                      );
                      return (
                        <Tabs.Tab key={tab.value} value={tab.value}>
                          <Group gap="xs" justify="center" wrap="nowrap">
                            {tab.label}
                            {selectedCount > 0 && (
                              <Badge size="sm" variant="filled" radius="xl">
                                {selectedCount}
                              </Badge>
                            )}
                          </Group>
                        </Tabs.Tab>
                      );
                    })}
                </Tabs.List>

                {repositoryTabs
                  .filter((tab) => tab.value !== 'selected')
                  .map((tab) => (
                    <Tabs.Panel key={tab.value} value={tab.value} pt="md">
                      {renderResourceList(
                        filteredResources[tab.value as HelpRepositoryEnum],
                        tab.value
                      )}
                    </Tabs.Panel>
                  ))}
              </Tabs>
            </Stack>
          </Box>

          <Group
            justify="flex-end"
            p="sm"
            bg="gray.1"
            style={{
              borderTop: '1px solid var(--mantine-color-gray-3)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'var(--mantine-color-gray-1)',
            }}
          >
            <Button
              onClick={handleUpdateResources}
              loading={isSaving}
              disabled={isSaving}
            >
              Update Resources
            </Button>
          </Group>
        </Stack>
        <HelpResourceEditorModal title="Help Resource Editor" />
      </>
    );
  }
);
