import { TaskTypeEnum } from '@btrway/api-task';
import {
  AssignTaskInputProperties,
  EventConfig,
  FormConfig,
  StepConfig,
  StepTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Droppable } from '@hello-pangea/dnd';
import { Card, Center, Space, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import ApprovalCard from '../../step-type-components/ApprovalCard/ApprovalCard';
import FormCard from '../../step-type-components/FormCard/FormCard';
import StepConfigCard from '../StepConfigCard/StepConfigCard';

interface ProcessViewProps {
  workflow: CommonWorkflow;
}

const isApprovalStep = (step: StepConfig): boolean => {
  if (step.stepTypeCode !== StepTypeEnum.assignTask) return false;

  const assignTaskInputProperties =
    step.inputProperties as AssignTaskInputProperties;

  return (
    assignTaskInputProperties.taskType === TaskTypeEnum.completeWorkflowStep
  );
};

export const ProcessView: React.FC<ProcessViewProps> = ({ workflow }) => {
  const workflowKey = workflow.workflowKey;
  const workflowConfiguration = workflow.workflowConfiguration;

  const { steps, events } = workflow.workflowConfiguration || {};

  const { selectedStepKey, selectStep, processActions } = useWorkflowConfig();

  const [localEvents, setLocalEvents] = useState<EventConfig[]>(
    events as EventConfig[]
  );
  const [localSteps, setLocalSteps] = useState<StepConfig[]>(
    steps as StepConfig[]
  );

  useEffect(() => {
    setLocalSteps(workflowConfiguration?.steps || []);
  }, [workflowConfiguration?.steps]);

  const stepConfigs = steps as StepConfig[];
  const startStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.flowStart
  );
  const formStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.formSubmission
  );
  const fileUploadStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.fileUpload
  );
  const formStepConfigs = stepConfigs.filter(
    (step) =>
      step.stepTypeCode !== StepTypeEnum.flowStart &&
      step.stepTypeCode !== StepTypeEnum.formSubmission &&
      step.stepTypeCode !== StepTypeEnum.fileUpload
  );

  const isFormWorkflow = workflow?.workflowType === WorkflowTypeEnum.form;
  const isFileWorkflow = workflow?.workflowType === WorkflowTypeEnum.file;

  const handleSelectedStep = (stepKey: string) => {
    selectStep(stepKey);
  };

  // const handleOpenToolbox = () => {
  //   openCreator({
  //     workflowType: workflow.workflowType!,
  //     onStepAdded: (stepConfig) => {
  //       processActions.appendStep(stepConfig);
  //     },
  //   });
  // };

  const handleSaveForm = (formConfig: FormConfig) => {
    const updatedStepConfig = localSteps.find(
      (step) => step.stepKey === selectedStepKey
    );

    if (updatedStepConfig) {
      updatedStepConfig.formConfig = formConfig;

      processActions.updateStep(updatedStepConfig.stepKey, {
        ...updatedStepConfig,
        formConfig,
      });
    }

    selectStep(null);
  };

  const handleSaveStep = (stepConfig: StepConfig) => {
    const updatedSteps = localSteps.map((step) =>
      step.stepKey === stepConfig.stepKey ? stepConfig : step
    );

    setLocalSteps(updatedSteps);

    processActions.updateStep(stepConfig.stepKey, stepConfig);

    selectStep(null);
  };

  const handleCancel = () => {
    selectStep(null);
  };

  const handleDeleteStep = (stepKey: string) => {
    processActions.removeStep(stepKey);
    selectStep(null);
  };

  const filteredSteps = localSteps.filter(
    (step) =>
      step.stepTypeCode !== StepTypeEnum.flowStart &&
      step.stepTypeCode !== StepTypeEnum.formSubmission
  );
  const isDropDisabled = filteredSteps.length === 0;

  if (!workflowKey) {
    return null;
  }

  const shouldShowCard = (stepKey: string | null) => {
    return !selectedStepKey || selectedStepKey === stepKey;
  };

  return (
    <>
      <Center>
        <Stack pt="lg" px="md" gap="xs" align="center" w="100%" maw={900}>
          {isFormWorkflow && formStep && shouldShowCard(formStep.stepKey) && (
            <FormCard
              formStep={formStep}
              workflowTitle={workflow.name || 'Form'}
              isSelected={formStep.stepKey === selectedStepKey}
              onSelect={handleSelectedStep}
              onSave={handleSaveForm}
              onCancel={handleCancel}
            />
          )}

          {!selectedStepKey && (
            <Text fz="lg" fw={600} mb="sm">
              After form is submitted...
            </Text>
          )}

          <Card w="100%" radius="md" mb="md" bg="transparent" p={0}>
            <Droppable
              droppableId="process-panel-droppable"
              isDropDisabled={isDropDisabled}
            >
              {(provided) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  gap={0}
                  align="center"
                >
                  {filteredSteps.map(
                    (step, index) =>
                      shouldShowCard(step.stepKey) &&
                      (isApprovalStep(step) ? (
                        <ApprovalCard
                          key={step.stepKey}
                          workflowKey={workflowKey}
                          step={step}
                          workflowTitle={workflow.name || 'Approval'}
                          isSelected={selectedStepKey === step.stepKey}
                          isLast={index === filteredSteps.length - 1}
                          isAutomatedStep={false}
                          stepType={step.stepTypeCode}
                          onSelect={handleSelectedStep}
                          onSave={handleSaveStep}
                          onCancel={handleCancel}
                          onDelete={handleDeleteStep}
                        />
                      ) : (
                        <StepConfigCard
                          key={step.stepKey}
                          workflowKey={workflowKey}
                          step={step}
                          index={index}
                          isLast={index === filteredSteps.length - 1}
                          isSelected={selectedStepKey === step.stepKey}
                          onSelect={handleSelectedStep}
                        />
                      ))
                  )}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
            {/* {!selectedStepKey && (
              <AddStep
                hasSteps={filteredSteps.length > 0}
                onAdd={handleOpenToolbox}
              />
            )} */}
          </Card>

          <Space h="xl" />
        </Stack>
      </Center>
    </>
  );
};
