import {
  TaskInstanceRequestTaskInstanceData,
  TaskInstanceResponse,
} from '@btrway/api-task';
import { TaskCompletionResult, useTaskCompletion } from '@btrway/task-manager';
import React, { createContext, useContext } from 'react';

interface TaskActionContextType {
  completeTask: (
    comment?: string,
    taskData?: TaskInstanceRequestTaskInstanceData
  ) => Promise<TaskCompletionResult>;
  markTaskViewed: () => Promise<void>;
}

interface TaskActionProviderProps {
  children: React.ReactNode;
  taskInstance: TaskInstanceResponse;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const TaskActionContext = createContext<TaskActionContextType | undefined>(
  undefined
);

export const useTaskActionContext = () => {
  const context = useContext(TaskActionContext);
  if (!context) {
    throw new Error(
      'useTaskActionContext must be used within TaskActionProvider'
    );
  }
  return context;
};

export const TaskActionProvider: React.FC<TaskActionProviderProps> = ({
  children,
  taskInstance,
  onSuccess,
  onError,
}) => {
  const taskActions = useTaskCompletion({
    taskInstance,
    onSuccess,
    onError,
  });

  return (
    <TaskActionContext.Provider value={taskActions}>
      {children}
    </TaskActionContext.Provider>
  );
};
