import { FieldConfig, PersonFieldProperties } from '@btrway/api-workflow';
import { UserRoleHierarchicalSelector } from '@btrway/role-components';
import { Stack, Switch } from '@mantine/core';
import React from 'react';

interface PersonFieldSettingsProps {
  field: FieldConfig;
  onChange: (updates: Partial<PersonFieldProperties>) => void;
}

const defaultPersonFieldProperties: PersonFieldProperties = {
  limitToWorkflowWorkgroup: false,
  userRoleSlugs: [],
};

export const PersonFieldSettings: React.FC<PersonFieldSettingsProps> = ({
  field,
  onChange,
}) => {
  const personProps = {
    ...defaultPersonFieldProperties,
    ...(field.fieldProperties as PersonFieldProperties),
  };

  const handleRoleSelection = (selection: {
    roleSlugs: string[];
    roleIds: number[];
    additionalData?: Map<number, boolean>;
  }) => {
    onChange({
      ...personProps,
      userRoleSlugs: selection.roleSlugs,
    });
  };

  const handleWorkgroupLimitChange = (checked: boolean) => {
    onChange({
      ...personProps,
      limitToWorkflowWorkgroup: checked,
    });
  };

  return (
    <Stack gap="md">
      <UserRoleHierarchicalSelector
        selectedRoleSlugs={personProps.userRoleSlugs}
        onChange={handleRoleSelection}
      />

      <Switch
        label="Limit to workflow workgroup"
        description="Only allow selection of people within the current workflow workgroup"
        checked={personProps.limitToWorkflowWorkgroup}
        onChange={(event) =>
          handleWorkgroupLimitChange(event.currentTarget.checked)
        }
      />
    </Stack>
  );
};

export default PersonFieldSettings;
