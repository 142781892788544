import type { WorkflowTypeEnum } from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-service-provider';
import { Card, Group, Popover, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import { SelectWorkflowOptions } from '../SelectWorkflowOptions/SelectWorkflowOptions';

interface SelectWorkflowProps {
  value?: CommonWorkflow;
  workflowType: WorkflowTypeEnum;
  onChange: (workflow: CommonWorkflow) => void;
}

export const SelectWorkflow: React.FC<SelectWorkflowProps> = ({
  value,
  workflowType,
  onChange,
}) => {
  const [opened, setOpened] = useState(false);

  const getDisplayValue = useCallback((workflow?: CommonWorkflow): string => {
    if (!workflow?.name) return 'Select Workflow';
    return workflow.name;
  }, []);

  const handleClick = () => {
    setOpened(true);
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      width={400}
      shadow="md"
    >
      <Popover.Target>
        <Card
          shadow="none"
          miw={300}
          px="xs"
          py={5}
          radius="sm"
          withBorder
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            width: 'fit-content',
            borderColor: 'var(--mantine-color-gray-4)',
          }}
        >
          <Group gap="xs" justify="space-between" wrap="nowrap">
            <Text size="sm">{getDisplayValue(value)}</Text>
            <IconChevronDown size={16} />
          </Group>
        </Card>
      </Popover.Target>

      <Popover.Dropdown>
        <SelectWorkflowOptions
          selectedWorkflow={value}
          workflowType={workflowType}
          onSelect={(workflow) => {
            onChange(workflow);
            setOpened(false);
          }}
          onClose={() => setOpened(false)}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
