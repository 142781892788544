import { AssetResponse } from '@btrway/api-courseware';
import { useCourses } from '@btrway/courseware-manager';
import { Box, Stack, Text, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { GroupedCourses } from '../../types/groupedCourses';
import { InternalCourseCard } from '../InternalCourseCard/InternalCourseCard';

interface SelectInternalCourseOptionsProps {
  selectedCourseUid: number | null;
  onSelect: (course: AssetResponse) => void;
}

export const SelectInternalCourseOptions: React.FC<
  SelectInternalCourseOptionsProps
> = ({ selectedCourseUid, onSelect }) => {
  const { courses } = useCourses();
  const [searchTerm, setSearchTerm] = useState('');

  const groupedCourses = useMemo((): GroupedCourses[] => {
    const groupsMap = new Map<number, GroupedCourses>();

    courses.forEach((course) => {
      const folderId = course.assetFolder.id;
      const folderTitle = course.assetFolder.title;

      if (!groupsMap.has(folderId)) {
        groupsMap.set(folderId, {
          folderId,
          folderTitle,
          courses: [],
        });
      }

      groupsMap.get(folderId)?.courses.push(course);
    });

    return Array.from(groupsMap.values())
      .sort((a, b) => a.folderTitle.localeCompare(b.folderTitle))
      .map((group) => ({
        ...group,
        courses: group.courses.sort((a, b) => a.title.localeCompare(b.title)),
      }));
  }, [courses]);

  const filteredGroups = useMemo(() => {
    if (!searchTerm) return groupedCourses;

    const searchTermLower = searchTerm.toLowerCase();
    return groupedCourses
      .map((group) => ({
        ...group,
        courses: group.courses.filter((course) =>
          course.title.toLowerCase().includes(searchTermLower)
        ),
      }))
      .filter((group) => group.courses.length > 0);
  }, [groupedCourses, searchTerm]);

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <TextInput
          autoFocus
          data-autofocus
          placeholder="Search courses..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
          rightSection={
            searchTerm && (
              <IconX
                size="1rem"
                style={{ cursor: 'pointer' }}
                onClick={() => setSearchTerm('')}
              />
            )
          }
        />
      </Box>

      <Stack gap="xs" pt={0}>
        {filteredGroups.map((group) => (
          <Stack key={group.folderId} gap="xs">
            <Text fw={500} fz="sm" mt="sm">
              {group.folderTitle}
            </Text>
            {group.courses.map((course) => (
              <InternalCourseCard
                key={course.uid}
                course={course}
                onSelect={onSelect}
                isSelected={course.uid === selectedCourseUid}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
