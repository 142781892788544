import { OrganizationFilter, WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  DeploymentButton,
  DeploymentFilterView,
} from '@btrway/template-deployment';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { useWorkflowConfig } from '@btrway/workflow-configuration-manager';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-service-provider';
import { WorkflowSettingsActionIcon } from '@btrway/workflow-settings';
import {
  StepCreatorActionIcon,
  StepCreatorDrawer,
  useStepCreator,
} from '@btrway/workflow-step-creator';
import { Box, Group, Stack, Text } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface WorkflowBuilderLayoutProps {
  children: React.ReactNode;
  workflow?: CommonWorkflow;
}

const getBackLinkText = (
  workflowType: WorkflowTypeEnum | undefined
): string => {
  switch (workflowType) {
    case WorkflowTypeEnum.form:
      return 'Back to Forms';
    case WorkflowTypeEnum.automation:
      return 'Back to Automations';
    default:
      return 'Back';
  }
};

export const WorkflowBuilderLayout: React.FC<WorkflowBuilderLayoutProps> = ({
  children,
  workflow,
}) => {
  const navigate = useNavigate();
  const mainContentBackground = '#f0f0f0';
  const workflowService = useWorkflowService();
  const { selectedStepKey } = useWorkflowConfig();
  const { isOpen, onStepAdded, closeCreator } = useStepCreator();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const backLinkText = getBackLinkText(workflow?.workflowType);

  const handleSettingsUpdate = async (settings: {
    organizationFilter: OrganizationFilter;
  }) => {
    if (!workflow) return;

    // Create the updated workflow maintaining all required fields
    const updatedWorkflow: CommonWorkflow = {
      ...workflow,
      organizationFilter: settings.organizationFilter,
    };

    await workflowService.updateWorkflow(updatedWorkflow);
  };

  if (!workflow) {
    return null;
  }

  return (
    <>
      <Stack h="100vh" gap={0} style={{ width: '100%' }}>
        <Group
          align="center"
          bg="dark.6"
          h={60}
          px="sm"
          style={{ position: 'relative', width: '100%' }}
        >
          <Box style={{ position: 'absolute', left: 16 }}>
            <div
              onClick={() => navigate(-1)}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              <Group wrap="nowrap">
                <IconChevronLeft size={24} color="white" />
                <Text size="md" c="white" fw={600}>
                  {backLinkText}
                </Text>
              </Group>
            </div>
          </Box>

          <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Group justify="space-between" align="center">
              <Text size="xl" c="white" fw={700}>
                {workflow?.name || ''}
              </Text>
            </Group>
          </Box>
        </Group>

        <Box
          style={{
            backgroundColor: mainContentBackground,
            display: 'flex',
            height: 'calc(100vh - 60px)',
            overflow: 'hidden',
            width: '100%',
            flex: '1 1 auto',
          }}
        >
          {!selectedStepKey && (
            <>
              <Box
                style={{ position: 'absolute', top: 76, left: 16, zIndex: 1 }}
              >
                <StepCreatorActionIcon workflow={workflow} />
              </Box>
              <Box
                style={{ position: 'absolute', top: 76, right: 16, zIndex: 1 }}
              >
                <WorkflowSettingsActionIcon />
              </Box>

              <Box
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                  zIndex: 1,
                }}
              >
                <Stack gap="md">
                  <DeploymentFilterView
                    deploymentType="workflow"
                    initialOrganizationFilter={workflow?.organizationFilter}
                    onSettingsUpdate={handleSettingsUpdate}
                  />
                  <DeploymentButton type="workflow" workflow={workflow} />
                </Stack>
              </Box>
            </>
          )}

          <Box
            style={{
              flex: '1 1 auto',
              overflow: 'auto',
              transition: 'flex 0.3s ease',
              width: '100%',
              minWidth: 0, // Prevents flex items from overflowing
            }}
          >
            <Suspense fallback={<div>Loading builder...</div>}>
              <Box
                style={{
                  position: 'relative',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <RightSectionProvider>
                  <SettingsDisplayProvider>{children}</SettingsDisplayProvider>
                </RightSectionProvider>
              </Box>
            </Suspense>
          </Box>
        </Box>
      </Stack>
      <StepCreatorDrawer
        workflowType={workflow.workflowType!}
        opened={isOpen}
        onClose={closeCreator}
        onStepAdded={(step) => {
          onStepAdded?.(step);
          closeCreator();
        }}
      />
    </>
  );
};
