import { TaskConfig } from '@btrway/api-task';
import { useTaskListConfig } from '@btrway/task-list-config-provider';
import { Box, Drawer } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import TaskEditor from '../TaskEditor/TaskEditor';

export const TaskEditorDrawer: React.FC = () => {
  const { taskActions, selectTask, selectedTaskKey, findTask } =
    useTaskListConfig();
  const [currentTask, setCurrentTask] = useState<TaskConfig | null>(null);

  // Update current task when selectedTaskKey changes
  useEffect(() => {
    if (selectedTaskKey) {
      const task = findTask(selectedTaskKey);
      setCurrentTask(task);
    } else {
      setCurrentTask(null);
    }
  }, [selectedTaskKey, findTask]);

  const handleSave = async (updatedConfig: TaskConfig) => {
    await taskActions.updateTask(updatedConfig.taskKey, updatedConfig);
    selectTask(null);
  };

  const handleDelete = async (taskKey: string) => {
    await taskActions.removeTask(taskKey);
    selectTask(null);
  };

  const handleCancel = () => {
    selectTask(null);
  };

  console.log('TaskEditorDrawer: currentTask', currentTask);

  return (
    <Drawer
      opened={!!selectedTaskKey}
      onClose={handleCancel}
      position="right"
      padding={0}
      withOverlay={false}
      closeOnClickOutside={false}
      trapFocus={false}
      size={400}
      shadow="lg"
      withCloseButton={false}
      lockScroll={false}
      styles={{
        content: {
          marginTop: 70,
          marginBottom: 10,
          marginRight: 10,
          borderRadius: 'var(--mantine-radius-lg)',
          height: 'calc(100vh - 80px)',
        },
        inner: {
          height: '100%',
        },
        body: {
          height: '100%',
        },
        header: {
          borderTopLeftRadius: 'var(--mantine-radius-md)',
          borderTopRightRadius: 'var(--mantine-radius-md)',
        },
      }}
    >
      <Box style={{ height: '100%' }}>
        {currentTask && (
          <TaskEditor
            config={currentTask}
            onConfigChange={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default TaskEditorDrawer;
