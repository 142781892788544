import { PermissionNode } from '../types/permissionNode';
import { PermissionFilterStrategy } from '../types/permissionStrategy';

export class AdminPermissionStrategy implements PermissionFilterStrategy {
  constructor(private deletedIds: Set<string>) {}

  filterPermissions(permissions: PermissionNode[]): PermissionNode[] {
    // Filter out deleted permissions
    return permissions.filter((p) => !this.deletedIds.has(p.clientId));
  }
}
