import { TaskConfig } from '@btrway/api-task';
import { TaskListTypeEnum } from '@btrway/api-tasklist';
import { useTaskHelpResources } from '@btrway/help-manager';
import { TaskCreatorDrawer, useTaskCreator } from '@btrway/task-creator';
import { TaskEditorDrawer } from '@btrway/task-editor';
import { useTaskListConfig } from '@btrway/task-list-config-provider';
import { TaskListTasksEditorDrawer } from '@btrway/task-list-editor';
import { CommonTaskList } from '@btrway/task-list-service-provider';
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskSortType, useTaskGrouping } from '../../hooks/useTaskGrouping';
import TaskGroup from '../TaskGroup/TaskGroup';

interface TaskListViewProps {
  taskList: CommonTaskList;
  nestedTaskList?: boolean;
  onAddTask?: (taskList: CommonTaskList) => void;
}

const TaskListView: React.FC<TaskListViewProps> = ({
  taskList,
  nestedTaskList = false,
  onAddTask,
}) => {
  const { taskActions, selectTask, selectedTaskKey } = useTaskListConfig();
  const [localTasks, setLocalTasks] = useState<TaskConfig[]>([]);
  const [currentSort, setCurrentSort] = useState<TaskSortType>('date');
  const [isTasksEditorOpen, setIsTasksEditorOpen] = useState(false);
  const {
    isOpen,
    taskList: selectedTaskList,
    taskContainerKey,
    onTaskAdded,
    closeCreator,
    openCreator,
  } = useTaskCreator();

  // Get unique task types for fetching help resources
  const uniqueTaskTypes = useMemo(
    () => Array.from(new Set(localTasks.map((task) => task.taskType))),
    [localTasks]
  );

  const { fetchHelpUsages } = useTaskHelpResources(
    taskList?.taskListKey || '',
    uniqueTaskTypes
  );

  // Fetch help resources on mount and when tasks change
  useEffect(() => {
    fetchHelpUsages();
  }, [uniqueTaskTypes]);

  useEffect(() => {
    if (!taskList) return;

    // Set local tasks
    const taskListTasks = taskList.taskListConfiguration?.tasks ?? [];
    setLocalTasks(taskListTasks);
  }, [taskList]);

  const handleDelete = async (taskKey: string) => {
    try {
      await taskActions.removeTask(taskKey);
      setLocalTasks((prev) => prev.filter((task) => task.taskKey !== taskKey));
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!taskList) return;

    openCreator({
      taskList: taskList,
      onTaskAdded: (task) => {
        setLocalTasks((prev) => [...prev, task]);
      },
    });
  };

  const handleTaskClick = (task: TaskConfig) => {
    if (!taskList) return;

    selectTask(task.taskKey);
  };

  const handleEditWorkflow = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsTasksEditorOpen(true);
  };

  const taskListType = taskList?.taskListType;
  const isFormPacket = taskListType === TaskListTypeEnum.formPacket;
  const isCurriculum = taskListType === TaskListTypeEnum.curriculum;
  const isTaskList = taskListType === TaskListTypeEnum.todoList;
  const shouldUseGrouping = isTaskList;

  const { groupedTasks } = useTaskGrouping(
    localTasks,
    shouldUseGrouping ? (isCurriculum ? 'date' : currentSort) : 'none'
  );

  const handleSort = (sortType: TaskSortType) => {
    setCurrentSort(currentSort === sortType ? 'none' : sortType);
  };

  if (!taskList) {
    return <Center>No task list found</Center>;
  }

  return (
    <>
      <Center>
        <Stack gap="xs" align="stretch" p="md" w="100%">
          <Group justify="space-between" w="100%">
            <Group gap="xs" justify="flex-start">
              {isTaskList && (
                <>
                  <Button.Group>
                    <Button
                      size="xs"
                      color="blue"
                      variant={currentSort === 'date' ? 'filled' : 'outline'}
                      onClick={() => handleSort('date')}
                    >
                      Date
                    </Button>
                    <Button
                      size="xs"
                      color="blue"
                      variant={currentSort === 'type' ? 'filled' : 'outline'}
                      onClick={() => handleSort('type')}
                    >
                      Type
                    </Button>
                    <Button
                      size="xs"
                      color="blue"
                      variant={
                        currentSort === 'category' ? 'filled' : 'outline'
                      }
                      onClick={() => handleSort('category')}
                    >
                      Category
                    </Button>
                  </Button.Group>
                </>
              )}
            </Group>

            <Group justify="flex-end" gap="xs">
              {isFormPacket || isCurriculum ? (
                <Button
                  size="xs"
                  variant="light"
                  color="blue"
                  leftSection={<IconEdit size={16} />}
                  onClick={handleEditWorkflow}
                >
                  Select {isFormPacket ? 'Forms' : 'Courses'}
                </Button>
              ) : nestedTaskList ? (
                <Group gap="xs">
                  <Tooltip label="Add New Task">
                    <ActionIcon
                      variant="light"
                      color="blue"
                      size="md"
                      radius="xl"
                      onClick={handleAddClick}
                    >
                      <IconPlus size={16} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              ) : null}
            </Group>
          </Group>

          <Stack gap="xs">
            {groupedTasks.map(
              (group) =>
                taskListType && (
                  <TaskGroup
                    key={group.label}
                    label={group.label}
                    tasks={group.tasks}
                    taskList={taskList}
                    onTaskSelected={(taskKey) => {
                      const task = localTasks.find(
                        (t) => t.taskKey === taskKey
                      );
                      if (task) handleTaskClick(task);
                    }}
                    onTaskDeleted={handleDelete}
                  />
                )
            )}
          </Stack>
        </Stack>
      </Center>
      {taskList && <TaskEditorDrawer />}
      <TaskCreatorDrawer
        opened={isOpen}
        onClose={closeCreator}
        taskList={selectedTaskList}
        taskContainerKey={taskContainerKey}
        onTaskAdded={(task) => {
          onTaskAdded?.(task);
          closeCreator();
        }}
      />
      <TaskListTasksEditorDrawer
        opened={isTasksEditorOpen}
        onClose={() => setIsTasksEditorOpen(false)}
      />
    </>
  );
};

export default TaskListView;
