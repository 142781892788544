// import { Stepper } from '@mantine/core';
// import React from 'react';
// import { StepContent } from '../StepContent/StepContent';

// interface TaskCreatorStepperProps {
//   activeStep: number;
//   stepTitle: string;
//   onStepClick: (step: number) => void;
//   onBack: () => void;
//   children: React.ReactNode;
// }

// export const TaskCreatorStepper: React.FC<TaskCreatorStepperProps> = ({
//   activeStep,
//   stepTitle,
//   onStepClick,
//   onBack,
//   children,
// }) => {
//   return (
//     <Stepper
//       active={activeStep}
//       onStepClick={onStepClick}
//       allowNextStepsSelect={false}
//       styles={{
//         root: { width: '100%' },
//         steps: { display: 'none' },
//         content: {
//           marginTop: 0,
//           '--stepper-content-padding': '0',
//         },
//       }}
//     >
//       <Stepper.Step>
//         <StepContent
//           activeStep={activeStep}
//           stepTitle={stepTitle}
//           onBack={onBack}
//         >
//           {children}
//         </StepContent>
//       </Stepper.Step>
//       <Stepper.Step>
//         <StepContent
//           activeStep={activeStep}
//           stepTitle={stepTitle}
//           onBack={onBack}
//         >
//           {children}
//         </StepContent>
//       </Stepper.Step>
//       <Stepper.Step>
//         <StepContent
//           activeStep={activeStep}
//           stepTitle={stepTitle}
//           onBack={onBack}
//         >
//           {children}
//         </StepContent>
//       </Stepper.Step>
//     </Stepper>
//   );
// };

import { Stepper } from '@mantine/core';
import React from 'react';
import { StepContent } from '../StepContent/StepContent';

interface TaskCreatorStepperProps {
  activeStep: number;
  stepTitle: string;
  onStepClick: (step: number) => void;
  onBack: () => void;
  children: React.ReactNode;
}

export const TaskCreatorStepper: React.FC<TaskCreatorStepperProps> = ({
  activeStep,
  stepTitle,
  onStepClick,
  onBack,
  children,
}) => {
  return (
    <Stepper
      active={activeStep}
      onStepClick={onStepClick}
      allowNextStepsSelect={false}
      styles={{
        root: {
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        steps: {
          display: 'none',
        },
        content: {
          marginTop: 0,
          '--stepper-content-padding': '0',
          flex: '1 1 auto',
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        stepBody: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        step: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stepper.Step>
        <StepContent
          activeStep={activeStep}
          stepTitle={stepTitle}
          onBack={onBack}
        >
          {children}
        </StepContent>
      </Stepper.Step>
      <Stepper.Step>
        <StepContent
          activeStep={activeStep}
          stepTitle={stepTitle}
          onBack={onBack}
        >
          {children}
        </StepContent>
      </Stepper.Step>
      <Stepper.Step>
        <StepContent
          activeStep={activeStep}
          stepTitle={stepTitle}
          onBack={onBack}
        >
          {children}
        </StepContent>
      </Stepper.Step>
    </Stepper>
  );
};
