import { PermissionTypeEnum } from '@btrway/api-security';
import { usePermissionMode, usePermissions } from '@btrway/permission-manager';
import { Card, Grid, Stack } from '@mantine/core';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ModuleTree } from '../ModuleTree/ModuleTree';
import { PermissionNodeCard } from '../PermissionNodeCard/PermissionNodeCard';

export const PermissionsLayout: React.FC = () => {
  const { getPermissionByClientId, getRootModules, getChildPermissionsByType } =
    usePermissions();
  const { selectedModuleId, setSelectedModuleId } = usePermissionMode();

  // Auto-select first root module
  useEffect(() => {
    if (!selectedModuleId) {
      const rootModules = getRootModules();
      if (rootModules.length > 0) {
        setSelectedModuleId(rootModules[0].clientId);
      }
    }
  }, [getRootModules, selectedModuleId, setSelectedModuleId]);

  // Only try to get the permission if we have a selectedModuleId
  const selectedModule = selectedModuleId
    ? getPermissionByClientId(selectedModuleId)
    : undefined;

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack>
        <Grid>
          {/* Left Panel - Module Tree */}
          <Grid.Col span={3}>
            <ModuleTree
              modules={getRootModules()}
              getChildModules={(node) =>
                getChildPermissionsByType(
                  node.clientId,
                  PermissionTypeEnum.module
                )
              }
            />
          </Grid.Col>

          {/* Right Panel - Selected Module Content */}
          <Grid.Col span={9}>
            <Card withBorder bg="white" radius="md" mih="100vh" p="md">
              {selectedModule && (
                <Stack gap="md">
                  <PermissionNodeCard
                    node={selectedModule}
                    selected
                    level={0}
                  />
                </Stack>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Stack>
    </DndProvider>
  );
};
