import { TaskListTypeEnum } from '@btrway/api-task';
import { useWorkgroupStats } from '@btrway/task-stats-provider';
import { Badge, Card, Group, Progress, Text } from '@mantine/core';
import React from 'react';
import styles from './CertificationsTopSection.module.css';

interface RequirementsCardProps {
  count: number;
}

const RequirementsCard: React.FC<RequirementsCardProps> = ({ count }) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {count}
      </Badge>
      <Text fz="lg" fw={600} c="white">
        Requirements Left
      </Text>
    </Group>
  </Card>
);

interface ProgressCardProps {
  progress: number;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ progress }) => (
  <Card className={styles.card} p="md" style={{ flex: '2' }} radius="lg">
    <Group justify="center" align="center" wrap="nowrap" w="100%">
      <Progress
        value={progress}
        color="blue"
        size="xl"
        radius="xl"
        style={{ flex: '1 1 auto' }}
      />
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {progress.toFixed(1)}%
      </Badge>
    </Group>
  </Card>
);

interface StatusCardProps {
  completed: number;
  total: number;
}

const StatusCard: React.FC<StatusCardProps> = ({ completed, total }) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Text fz="lg" fw={600} c="white">
        Complete:
      </Text>
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {completed} of {total}
      </Badge>
    </Group>
  </Card>
);

interface CertificationsTopSectionProps {
  taskListType?: TaskListTypeEnum;
  taskListClassification?: string;
}

export const CertificationsTopSection: React.FC<
  CertificationsTopSectionProps
> = ({ taskListType, taskListClassification }) => {
  const { getStatsByFilter, getAggregatedStats } = useWorkgroupStats();

  // Get filtered stats based on provided taskListType and taskListType
  const filteredStats = getStatsByFilter(
    taskListType || taskListType
      ? {
          taskListType,
          taskListClassification,
        }
      : undefined
  );

  // Get aggregated stats for the filtered data
  const aggregatedStats = getAggregatedStats(
    taskListType || taskListType
      ? {
          taskListType,
          taskListClassification,
        }
      : undefined
  );

  if (!filteredStats?.[0] || !aggregatedStats) {
    return null;
  }

  const completedTaskLists = aggregatedStats.completedTaskLists;
  const completedTasks = aggregatedStats.completedTasks;
  const totalTaskLists = aggregatedStats.totalTaskLists;
  const totalTasks = aggregatedStats.totalTasks;
  const remainingTasks = totalTasks - completedTasks;
  const progress = aggregatedStats.taskListCompletionPercentage;

  return (
    <Group gap="md" style={{ width: '100%' }}>
      <StatusCard completed={completedTaskLists} total={totalTaskLists} />
      <ProgressCard progress={progress} />
      <RequirementsCard count={remainingTasks} />
    </Group>
  );
};

export default CertificationsTopSection;
