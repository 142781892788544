import {
  CertificationRequiredConfig,
  CertificationValidConfig,
  TaskConfig,
  TaskListTaskConfig,
  TaskListTypeEnum,
  TaskMetadata,
} from '@btrway/api-tasklist';
import { useTaskListService } from '@btrway/task-list-service-provider';
import { Box, Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { taskTypeEditorRegistry } from '../../registry/taskTypeEditorRegistry';
import { CertificationRequiredEditor } from '../CertificationRequiredEditor/CertificationRequiredEditor';
import { CertificationValidEditor } from '../CertificationValidEditor/CertificationValidEditor';
import { TaskDateEditor } from '../TaskDateEditor/TaskDateEditor';
import { TaskMetadataEditor } from '../TaskMetadataEditor/TaskMetadataEditor';

interface SectionHeaderProps {
  children: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ children }) => (
  <Title order={4}>{children}</Title>
);

interface TaskEditorCoreProps {
  config: TaskConfig;
  onConfigChange: (config: TaskConfig) => void;
}

export const TaskEditorCore: React.FC<TaskEditorCoreProps> = ({
  config,
  onConfigChange,
}) => {
  const [localConfig, setLocalConfig] = useState<TaskConfig>(config);
  const taskListService = useTaskListService();
  const taskList = taskListService.getByKey().data;

  // Update local state when config prop changes
  useEffect(() => {
    setLocalConfig(config);
  }, [config]);

  const handleMetadataChange = (metadata: TaskMetadata) => {
    const updatedConfig = {
      ...localConfig,
      metadata: {
        ...localConfig.metadata,
        ...metadata,
      },
    };
    setLocalConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  const handleDateConfigChange = (dateConfig: TaskListTaskConfig) => {
    const updatedConfig = {
      ...localConfig,
      taskListConfig: dateConfig,
    };
    setLocalConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  const handleCertificationValidChange = (
    validConfig: CertificationValidConfig
  ) => {
    const updatedConfig = {
      ...localConfig,
      certificationValidConfig: validConfig,
    };
    setLocalConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  const handleCertificationRequiredChange = (
    requiredConfig: CertificationRequiredConfig
  ) => {
    const updatedConfig = {
      ...localConfig,
      certificationRequiredConfig: requiredConfig,
    };
    setLocalConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  const handleTaskTypeSpecificChange = (updatedConfig: TaskConfig) => {
    setLocalConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  // Get the type-specific component from the registry
  const TaskTypeSpecificComponent = localConfig.taskType
    ? taskTypeEditorRegistry.getComponent(localConfig.taskType)
    : null;

  return (
    <Box>
      <Stack gap="md">
        {/* Metadata Section */}
        <div>
          <SectionHeader>Task Details</SectionHeader>
          <TaskMetadataEditor
            metadata={localConfig.metadata || {}}
            onChange={handleMetadataChange}
          />
        </div>

        {/* Task Type Specific Configuration */}
        {TaskTypeSpecificComponent && (
          <div>
            <TaskTypeSpecificComponent
              config={localConfig}
              onConfigChange={handleTaskTypeSpecificChange}
            />
          </div>
        )}

        {/* Task Date Editor for Todo Lists */}
        {taskList?.taskListType === TaskListTypeEnum.todoList && (
          <div>
            <SectionHeader>Task Date</SectionHeader>
            <TaskDateEditor
              config={localConfig.taskListConfig}
              onChange={handleDateConfigChange}
            />
          </div>
        )}

        {/* Certification Editors */}
        {taskList?.taskListType === TaskListTypeEnum.certification && (
          <>
            <div>
              <SectionHeader>Certification Validity</SectionHeader>
              <CertificationValidEditor
                value={
                  localConfig.certificationValidConfig || {
                    validType: 'thisYear',
                    withinDays: 0,
                  }
                }
                onChange={handleCertificationValidChange}
              />
            </div>
            <div>
              <SectionHeader>Certification Requirements</SectionHeader>
              <CertificationRequiredEditor
                value={
                  localConfig.certificationRequiredConfig || {
                    requiredType: 'yes',
                    timeValue: 0,
                    timeUnit: 'day',
                    property: 'hire',
                  }
                }
                onChange={handleCertificationRequiredChange}
              />
            </div>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default TaskEditorCore;
