import {
  Body,
  Container,
  Head,
  Html,
  Img,
  Section,
  Text,
} from '@react-email/components';
import {ReactNode, FC} from 'react';

interface BannerEmailTemplateProps {
  children?: ReactNode;
  previewText?: string;
  bannerImageUrl?: string;
  bannerHeight?: number;
  bannerColor?: string;
}

export const BannerEmailTemplate: FC<BannerEmailTemplateProps> = ({
  children,
  previewText = 'BetterWay message',
  bannerImageUrl,
  bannerHeight = 120,
  bannerColor = '#003366',
}) => {
  return (
    <Html>
      <Head>
        <title>{previewText}</title>
      </Head>
      <Body style={main}>
        <Container style={container}>
          {/* Banner Section */}
          <Section
            style={{
              ...banner,
              backgroundColor: bannerColor,
              height: `${bannerHeight}px`,
            }}
          >
            {bannerImageUrl && (
              <div style={bannerWrapper}>
                <Img
                  src={bannerImageUrl}
                  width="200"
                  height="auto"
                  alt="Email Banner"
                  style={bannerImage}
                />
              </div>
            )}
          </Section>

          {/* Content Section */}
          <Section style={content}>
            {children || (
              <div style={placeholderContent}>
                <Text style={placeholderText}>Email Content Area</Text>
                <Text style={placeholderSubtext}>
                  Your email content will be inserted here
                </Text>
              </div>
            )}
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

const main = {
  backgroundColor: '#f4f4f4', // Light gray background to show template bounds
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif',
  padding: '20px',
};

const container = {
  margin: '0 auto',
  padding: '0',
  maxWidth: '600px',
  backgroundColor: '#ffffff', // White container on light gray background
  borderRadius: '8px',
  overflow: 'hidden', // Ensures the border radius works with the banner
};

const banner = {
  padding: '0',
  width: '100%',
  maxWidth: '600px',
  margin: '0',
  display: 'table', // Using table for vertical centering
};

const bannerWrapper = {
  display: 'table-cell',
  verticalAlign: 'middle',
  textAlign: 'center' as const,
  height: 'inherit',
};

const bannerImage = {
  display: 'inline-block',
  maxWidth: '200px',
  margin: '0 auto',
};

const content = {
  padding: '40px 20px',
  backgroundColor: '#ffffff',
};

const placeholderContent = {
  padding: '40px 20px',
  backgroundColor: '#f8f9fa',
  border: '2px dashed #dee2e6',
  borderRadius: '4px',
  textAlign: 'center' as const,
};

const placeholderText = {
  fontSize: '20px',
  color: '#6c757d',
  marginBottom: '10px',
};

const placeholderSubtext = {
  fontSize: '14px',
  color: '#adb5bd',
};

export default BannerEmailTemplate;
