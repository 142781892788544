import { TaskInstanceResponse } from '@btrway/api-task';
import { useCallback } from 'react';
import { useTasksManagerContext } from '../providers/TasksManagerProvider';

export const useTaskModal = () => {
  const { modalState, setState } = useTasksManagerContext();

  const openTask = useCallback(
    (taskInstance: TaskInstanceResponse) => {
      setState((prev) => ({
        ...prev,
        modalState: {
          isOpen: true,
          taskInstance,
        },
      }));
    },
    [setState]
  );

  const closeTask = useCallback(() => {
    setState((prev) => ({
      ...prev,
      modalState: {
        isOpen: false,
      },
    }));
  }, [setState]);

  return {
    modalState,
    openTask,
    closeTask,
  };
};
