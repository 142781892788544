import { TaskConfig } from '@btrway/api-tasklist';

interface TaskSearchResult {
  task: TaskConfig;
  parent: TaskConfig | null;
  path: TaskConfig[];
}

export const findTaskByKey = (
  tasks: TaskConfig[] = [],
  targetKey: string,
  parentTask: TaskConfig | null = null,
  path: TaskConfig[] = []
): TaskSearchResult | null => {
  for (const task of tasks) {
    const currentPath = [...path, task];

    if (task.taskKey === targetKey) {
      return {
        task: task,
        parent: parentTask,
        path: currentPath,
      };
    }

    if (task.childTasks?.length) {
      const found = findTaskByKey(
        task.childTasks,
        targetKey,
        task,
        currentPath
      );
      if (found) return found;
    }
  }

  return null;
};

export const updateTaskInTree = (
  tasks: TaskConfig[] = [],
  targetKey: string,
  updateFn: (task: TaskConfig) => TaskConfig
): TaskConfig[] => {
  return tasks.map((task) => {
    if (task.taskKey === targetKey) {
      return updateFn(task);
    }

    if (task.childTasks?.length) {
      return {
        ...task,
        childTasks: updateTaskInTree(task.childTasks, targetKey, updateFn),
      };
    }

    return task;
  });
};

export const removeTaskFromTree = (
  tasks: TaskConfig[] = [],
  targetKey: string
): TaskConfig[] => {
  return tasks
    .filter((task) => task.taskKey !== targetKey)
    .map((task) => {
      if (task.childTasks?.length) {
        return {
          ...task,
          childTasks: removeTaskFromTree(task.childTasks, targetKey),
        };
      }
      return task;
    });
};

export const addChildTask = (
  tasks: TaskConfig[] = [],
  parentKey: string,
  newTask: TaskConfig
): TaskConfig[] => {
  return tasks.map((task) => {
    if (task.taskKey === parentKey) {
      return {
        ...task,
        childTasks: [...(task.childTasks || []), newTask],
      };
    }

    if (task.childTasks?.length) {
      return {
        ...task,
        childTasks: addChildTask(task.childTasks, parentKey, newTask),
      };
    }

    return task;
  });
};
