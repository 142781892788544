import {
  TaskListDefinitionRequest,
  TaskListDefinitionResponse,
  TaskListTemplateRequest,
  TaskListTemplateResponse,
} from '@btrway/api-tasklist';
import { CommonTaskList } from '../types/commonTaskList';

// CommonTaskListConversions.ts
export const convertResponseToCommon = (
  response: TaskListDefinitionResponse | TaskListTemplateResponse
): CommonTaskList => {
  const isDefinition = 'taskListKey' in response;

  const common: CommonTaskList = {
    id: response.id,
    name: response.name,
    published: response.published,
    versionDescription: response.versionDescription,
    versionPublishedAt: response.versionPublishedAt,
    taskListConfiguration: response.taskListConfiguration,
    taskListMetadata: response.taskListMetadata,
    taskListSettings: response.taskListSettings,
    taskListType: response.taskListType,
    sourceType: isDefinition ? 'definition' : 'template',
  };

  if (isDefinition) {
    const def = response as TaskListDefinitionResponse;
    return {
      ...common,
      taskListKey: def.taskListKey,
      enabled: def.enabled,
      organizationId: def.organizationId,
      templateKey: def.templateKey,
      versionDiff: def.versionDiff,
      taskListTemplateId: def.taskListTemplate?.id,
      workgroupId: def.workgroupId,
    };
  } else {
    const template = response as TaskListTemplateResponse;
    return {
      ...common,
      name: template.name, // Required for templates
      templateKey: template.templateKey,
      taskListKey: template.templateKey,
      organizationFilter: template.organizationFilter,
      taskListTemplateSettings: template.taskListTemplateSettings,
    };
  }
};

export const convertCommonToTaskListRequest = (
  common: CommonTaskList
): TaskListDefinitionRequest | TaskListTemplateRequest => {
  if (common.sourceType === 'definition') {
    const request: TaskListDefinitionRequest = {
      enabled: common.enabled ?? false, // Providing default as it's required
      id: common.id,
      name: common.name,
      organizationId: common.organizationId!, // Assuming this is required and set
      published: common.published,
      templateKey: common.templateKey,
      versionDescription: common.versionDescription,
      versionDiff: common.versionDiff,
      versionPublishedAt: common.versionPublishedAt,
      taskListConfiguration: common.taskListConfiguration,
      taskListKey: common.taskListKey!, // Assuming this is required and set
      taskListMetadata: common.taskListMetadata,
      taskListSettings: common.taskListSettings,
      taskListTemplateId: common.taskListTemplateId,
      taskListType: common.taskListType,
      workgroupId: common.workgroupId,
    };
    return request;
  } else {
    const request: TaskListTemplateRequest = {
      id: common.id,
      name: common.name!, // Required for templates
      published: common.published,
      templateKey: common.templateKey!, // Required for templates
      versionDescription: common.versionDescription,
      versionPublishedAt: common.versionPublishedAt,
      taskListConfiguration: common.taskListConfiguration,
      taskListMetadata: common.taskListMetadata,
      taskListSettings: common.taskListSettings,
      organizationFilter: common.organizationFilter,
      taskListTemplateSettings: common.taskListTemplateSettings,
      taskListType: common.taskListType,
    };
    return request;
  }
};

// Type guard utility for checking if required fields are present
export const isValidDefinitionTaskList = (
  common: CommonTaskList
): common is CommonTaskList & {
  sourceType: 'definition';
  taskListKey: string;
  organizationId: number;
  enabled: boolean;
} => {
  return (
    common.sourceType === 'definition' &&
    typeof common.taskListKey === 'string' &&
    typeof common.organizationId === 'number' &&
    typeof common.enabled === 'boolean'
  );
};

export const isValidTemplateTaskList = (
  common: CommonTaskList
): common is CommonTaskList & {
  sourceType: 'template';
  name: string;
  templateKey: string;
} => {
  return (
    common.sourceType === 'template' &&
    typeof common.name === 'string' &&
    typeof common.templateKey === 'string'
  );
};

// Utility to validate before converting to request
export const validateTaskListBeforeConversion = (
  common: CommonTaskList
): string[] => {
  const errors: string[] = [];

  if (common.sourceType === 'definition') {
    if (!common.taskListKey) errors.push('TaskList key is required');
    if (common.organizationId === undefined)
      errors.push('Organization ID is required');
    if (common.enabled === undefined) errors.push('Enabled flag is required');
  } else {
    if (!common.name) errors.push('Name is required for templates');
    if (!common.templateKey) errors.push('Template key is required');
  }

  return errors;
};
