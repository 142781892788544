/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  TemplateDeploymentActionProperties
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const deployWorkflowTemplates = (
    templateDeploymentActionProperties: TemplateDeploymentActionProperties,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/template/deployment/deploy`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: templateDeploymentActionProperties
    },
      options);
    }
  


export const getDeployWorkflowTemplatesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: TemplateDeploymentActionProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: TemplateDeploymentActionProperties}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployWorkflowTemplates>>, {data: TemplateDeploymentActionProperties}> = (props) => {
          const {data} = props ?? {};

          return  deployWorkflowTemplates(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployWorkflowTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof deployWorkflowTemplates>>>
    export type DeployWorkflowTemplatesMutationBody = TemplateDeploymentActionProperties
    export type DeployWorkflowTemplatesMutationError = unknown

    export const useDeployWorkflowTemplates = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: TemplateDeploymentActionProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deployWorkflowTemplates>>,
        TError,
        {data: TemplateDeploymentActionProperties},
        TContext
      > => {

      const mutationOptions = getDeployWorkflowTemplatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
