import {
  AuthenticatedRoute,
  Callback,
  LoginPage,
  Logout,
  MultiFactorAuth,
  OAuth2Success,
  PasswordRecovery,
  PasswordReset,
} from '@btrway/auth-web';
import { CoursewareProgressManager } from '@btrway/courseware-progress-manager';
import { CurrentUserProvider } from '@btrway/current-user';
import { ImpersonationProvider } from '@btrway/impersonation';
import { TaskManager } from '@btrway/task-components';
import { TemplateDefinitionProvider } from '@btrway/template-definition-provider';
import { ThemeSchemeProvider } from '@btrway/theme-scheme-provider';
import { RouteGenerator, RoutesProvider } from '@btrway/web-routing';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { appInternalRoutes } from './app-internal-routes/appInternalRoutes';
import { appRoutes } from './app-routes/appRoutes';
import JotaiCurrentPersonWrapper from './JotaiCurrentPersonWrapper';

const RegistrationRouter = lazy(() => import('./RegistrationRouter'));

export const RootRouter: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="" element={<Navigate to="login" replace />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="login/oauth2/success" element={<OAuth2Success />} />
      <Route path="recover-password" element={<PasswordRecovery />} />
      <Route path="reset-password/:token" element={<PasswordReset />} />
      <Route path="multi-factor-auth" element={<MultiFactorAuth />} />
      <Route path="callback" element={<Callback />} />
      <Route path="logout" element={<Logout />} />

      {/* Registration Routes */}
      <Route
        path="/register/*"
        element={
          <Suspense fallback={null}>
            <RegistrationRouter />
          </Suspense>
        }
      />

      {/* Regular App Routes */}
      <Route
        path="/app/*"
        element={
          <CurrentUserProvider>
            <JotaiCurrentPersonWrapper>
              <AuthenticatedRoute>
                <ImpersonationProvider>
                  <ThemeSchemeProvider>
                    <CoursewareProgressManager>
                      <TaskManager>
                        <TemplateDefinitionProvider sourceType="definition">
                          <RoutesProvider routes={appRoutes}>
                            <RouteGenerator />
                          </RoutesProvider>
                        </TemplateDefinitionProvider>
                      </TaskManager>
                    </CoursewareProgressManager>
                  </ThemeSchemeProvider>
                </ImpersonationProvider>
              </AuthenticatedRoute>
            </JotaiCurrentPersonWrapper>
          </CurrentUserProvider>
        }
      />

      {/* Internal App Routes */}
      <Route
        path="/app-internal/*"
        element={
          <CurrentUserProvider>
            <JotaiCurrentPersonWrapper>
              <ImpersonationProvider>
                <AuthenticatedRoute>
                  <ThemeSchemeProvider>
                    <TemplateDefinitionProvider sourceType="template">
                      <RoutesProvider routes={appInternalRoutes}>
                        <RouteGenerator />
                      </RoutesProvider>
                    </TemplateDefinitionProvider>
                  </ThemeSchemeProvider>
                </AuthenticatedRoute>
              </ImpersonationProvider>
            </JotaiCurrentPersonWrapper>
          </CurrentUserProvider>
        }
      />

      {/* Catch all redirect */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
