import { TaskTypeEnum } from '@btrway/api-tasklist';
import { CourseTaskConfiguration } from '../task-type-components/CourseTaskConfiguration/CourseTaskConfiguration';
import { CreateCalendarEventConfiguration } from '../task-type-components/CreateCalendarEventConfiguration/CreateCalendarEventConfiguration';
import { ExternalCourseTaskConfiguration } from '../task-type-components/ExternalCourseTaskConfiguration/ExternalCourseTaskConfiguration';
import { SendEmailTaskConfiguration } from '../task-type-components/SendEmailTaskConfiguration/SendEmailTaskConfiguration';
import { SubmitFormTaskConfiguration } from '../task-type-components/SubmitFormTaskConfiguration/SubmitFormTaskConfiguration';
import { TaskListTaskConfiguration } from '../task-type-components/TaskListTaskConfiguration/TaskListTaskConfiguration';
import { ThirdPartyTaskConfiguration } from '../task-type-components/ThirdPartyTaskConfiguration/ThirdPartyTaskConfiguration';
import { TaskConfigProps } from '../types/taskTypeComponentProps';

interface TaskTypeRegistryItem {
  component: React.FC<TaskConfigProps>;
  label: string;
}

class TaskTypeEditorRegistry {
  private registry: Map<TaskTypeEnum, TaskTypeRegistryItem> = new Map();

  constructor() {
    this.registerDefaults();
  }

  private registerDefaults() {
    this.register(TaskTypeEnum.thirdParty, {
      component: ThirdPartyTaskConfiguration,
      label: 'Third Party Task',
    });

    this.register(TaskTypeEnum.sendEmail, {
      component: SendEmailTaskConfiguration,
      label: 'Send Email Task',
    });

    this.register(TaskTypeEnum.createCalendarEvent, {
      component: CreateCalendarEventConfiguration,
      label: 'Create Calendar Event',
    });

    this.register(TaskTypeEnum.submitForm, {
      component: SubmitFormTaskConfiguration,
      label: 'Form Task',
    });

    this.register(TaskTypeEnum.completeCourse, {
      component: CourseTaskConfiguration,
      label: 'Course Task',
    });

    this.register(TaskTypeEnum.completeTaskList, {
      component: TaskListTaskConfiguration,
      label: 'Task List',
    });

    this.register(TaskTypeEnum.completeFormPacket, {
      component: TaskListTaskConfiguration,
      label: 'Form Packet',
    });

    this.register(TaskTypeEnum.completeCurriculum, {
      component: TaskListTaskConfiguration,
      label: 'Curriculum',
    });

    this.register(TaskTypeEnum.certification, {
      component: TaskListTaskConfiguration,
      label: 'Certification',
    });

    this.register(TaskTypeEnum.externalCourse, {
      component: ExternalCourseTaskConfiguration,
      label: 'Certification',
    });
  }

  register(type: TaskTypeEnum, item: TaskTypeRegistryItem) {
    this.registry.set(type, item);
  }

  getComponent(type: TaskTypeEnum): React.FC<TaskConfigProps> | null {
    return this.registry.get(type)?.component || null;
  }

  getLabel(type: TaskTypeEnum): string {
    return this.registry.get(type)?.label || type;
  }

  getSupportedTypes(): TaskTypeEnum[] {
    return Array.from(this.registry.keys());
  }
}

export const taskTypeEditorRegistry = new TaskTypeEditorRegistry();
