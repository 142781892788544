/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type PermissionApplicationEnum = typeof PermissionApplicationEnum[keyof typeof PermissionApplicationEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionApplicationEnum = {
  internal: 'internal',
  client: 'client',
} as const;
