import {
  TaskAssignmentRequest,
  TaskAssignmentResponse,
  TaskInstanceResponse,
  useGetAssignedTasks,
} from '@btrway/api-task';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useStateReconciliation } from '../hooks/useStateReconciliation';
import { TasksManagerState } from '../types/taskManagerState';

export interface TasksManagerContextValue extends TasksManagerState {
  setState: Dispatch<SetStateAction<TasksManagerState>>;
  fetchTasks: (request: TaskAssignmentRequest) => Promise<void>;
  clearTasks: () => void;
  updateTask: (updatedTask: TaskInstanceResponse) => void;
  getTaskKey: (task: TaskInstanceResponse) => string;
}

const TasksManagerContext = createContext<TasksManagerContextValue | undefined>(
  undefined
);

const initialState: TasksManagerState = {
  tasks: null,
  taskLists: null,
  taskGraphs: null,
  organizationId: null,
  isLoading: false,
  error: null,
  modalState: {
    isOpen: false,
  },
};

export const TasksManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<TasksManagerState>(initialState);
  const {
    getTaskKey,
    reconcileTasks,
    reconcileTaskLists,
    reconcileTaskGraphs,
  } = useStateReconciliation();

  const assignedTasksMutation = useGetAssignedTasks({
    mutation: {
      onSuccess: (response) => {
        setState((prev) => ({
          ...prev,
          tasks: reconcileTasks(prev.tasks ?? [], response.tasks ?? []),
          taskLists: reconcileTaskLists(
            prev.taskLists ?? [],
            response.taskLists ?? []
          ),
          taskGraphs: reconcileTaskGraphs(
            prev.taskGraphs ?? [],
            response.taskGraphs ?? []
          ),
          organizationId: response.organizationId,
          isLoading: false,
          error: null,
        }));
      },
      onError: (error) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: error as Error,
        }));
      },
    },
  });

  const fetchTasks = useCallback(
    async (request: TaskAssignmentRequest) => {
      setState((prev) => ({ ...prev, isLoading: true }));
      (await assignedTasksMutation.mutateAsync({
        data: request,
      })) as TaskAssignmentResponse;
    },
    [assignedTasksMutation]
  );

  const clearTasks = useCallback(() => {
    setState(initialState);
  }, []);

  const updateTask = useCallback(
    (updatedTask: TaskInstanceResponse) => {
      setState((prev) => {
        if (!prev.tasks) return prev;

        return {
          ...prev,
          tasks: prev.tasks.map((task) =>
            getTaskKey(task) === getTaskKey(updatedTask) ? updatedTask : task
          ),
        };
      });
    },
    [getTaskKey]
  );

  const value = {
    ...state,
    setState,
    fetchTasks,
    clearTasks,
    updateTask,
    getTaskKey,
  };

  return (
    <TasksManagerContext.Provider value={value}>
      {children}
    </TasksManagerContext.Provider>
  );
};

export const useTasksManagerContext = () => {
  const context = useContext(TasksManagerContext);
  if (!context) {
    throw new Error(
      'useTasksManagerContext must be used within a TasksManagerProvider'
    );
  }
  return context;
};

export const useTasksManager = () => {
  return useTasksManagerContext();
};
